/**
 * GQTY AUTO-GENERATED CODE: PLEASE DO NOT MODIFY MANUALLY
 */

import { SchemaUnionsKey } from 'gqty';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockAttributesArray: any;
  BlockAttributesObject: any;
}

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  PG = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X'
}

/** Arguments for filtering the BlockEditorContentNodeConnection connection */
export interface BlockEditorContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum BlockEditorPreviewIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Arguments for filtering the CategoryToCategoryConnection connection */
export interface CategoryToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export interface CategoryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface CategoryToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface CategoryToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<CategoryToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CategoryToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum CategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the CategoryToPostConnection connection */
export interface CategoryToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface CategoryToPostConnectionWhereArgsTaxArray {
  field?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<CategoryToPostConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface CategoryToPostConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<CategoryToPostConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CategoryToPostConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum CategoryToPostConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID'
}

/** The status of the comment object. */
export enum CommentStatusEnum {
  /** Comments with the Approved status */
  APPROVE = 'APPROVE',
  /** Comments with the Unapproved status */
  HOLD = 'HOLD',
  /** Comments with the Spam status */
  SPAM = 'SPAM',
  /** Comments with the Trash status */
  TRASH = 'TRASH'
}

/** Arguments for filtering the CommentToCommentConnection connection */
export interface CommentToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the CommentToParentCommentConnection connection */
export interface CommentToParentCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  COMMENT_AGENT = 'COMMENT_AGENT',
  /** Order by approval status of the comment. */
  COMMENT_APPROVED = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  COMMENT_AUTHOR = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  COMMENT_AUTHOR_EMAIL = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  COMMENT_AUTHOR_IP = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  COMMENT_AUTHOR_URL = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  COMMENT_CONTENT = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  COMMENT_DATE = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  COMMENT_DATE_GMT = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  COMMENT_ID = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  COMMENT_IN = 'COMMENT_IN',
  /** Order by the comment karma score. */
  COMMENT_KARMA = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  COMMENT_PARENT = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  COMMENT_POST_ID = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  COMMENT_TYPE = 'COMMENT_TYPE',
  /** Order by the user ID. */
  USER_ID = 'USER_ID'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CompanyCategoryIdType {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Arguments for filtering the CompanyCategoryToCompanyCategoryConnection connection */
export interface CompanyCategoryToCompanyCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the CompanyCategoryToCompanyConnection connection */
export interface CompanyCategoryToCompanyConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<CompanyCategoryToCompanyConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface CompanyCategoryToCompanyConnectionWhereArgsTaxArray {
  field?: InputMaybe<CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface CompanyCategoryToCompanyConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<CompanyCategoryToCompanyConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the CompanyCategoryToContentNodeConnection connection */
export interface CompanyCategoryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCompanyCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<CompanyCategoryToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface CompanyCategoryToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface CompanyCategoryToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<CompanyCategoryToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Set relationships between the Company to CompanyCategories */
export interface CompanyCompanyCategoriesInput {
  /** If true, this will append the CompanyCategory to existing related CompanyCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CompanyCompanyCategoriesNodeInput>>>;
}

/** List of CompanyCategories to connect the Company to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface CompanyCompanyCategoriesNodeInput {
  /** The description of the CompanyCategory. This field is used to set a description of the CompanyCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the CompanyCategory. If present, this will be used to connect to the Company. If no existing CompanyCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the CompanyCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the CompanyCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CompanyIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Arguments for filtering the CompanyToCompanyCategoryConnection connection */
export interface CompanyToCompanyCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the CompanyToTermNodeConnection connection */
export interface CompanyToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  ATTACHMENT = 'ATTACHMENT',
  /** The Type of Content object */
  COMPANY = 'COMPANY',
  /** The Type of Content object */
  GRAPHQL_DOCUMENT = 'GRAPHQL_DOCUMENT',
  /** The Type of Content object */
  PAGE = 'PAGE',
  /** The Type of Content object */
  POST = 'POST',
  /** The Type of Content object */
  PRESS = 'PRESS',
  /** The Type of Content object */
  TEAM = 'TEAM',
  /** The Type of Content object */
  WGG_PREVIEW = 'WGG_PREVIEW',
  /** The Type of Content object */
  WP_BLOCK = 'WP_BLOCK'
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  ID = 'ID',
  /** The name of the content type. */
  NAME = 'NAME'
}

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export interface ContentTypeToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface ContentTypeToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface ContentTypeToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<ContentTypeToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ContentTypeToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  POST = 'POST'
}

/** Allowed Content Types of the CompanyCategory taxonomy. */
export enum ContentTypesOfCompanyCategoryEnum {
  /** The Type of Content object */
  COMPANY = 'COMPANY'
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  POST = 'POST'
}

/** Allowed Content Types of the PressCategory taxonomy. */
export enum ContentTypesOfPressCategoryEnum {
  /** The Type of Content object */
  PRESS = 'PRESS',
  /** The Type of Content object */
  TEAM = 'TEAM'
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  POST = 'POST'
}

/** Input for the createBlockEditorPreview mutation. */
export interface CreateBlockEditorPreviewInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createCategory mutation. */
export interface CreateCategoryInput {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the category object to mutate */
  name: Scalars['String'];
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the createComment mutation. */
export interface CreateCommentInput {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']>;
}

/** Input for the createCompanyCategory mutation. */
export interface CreateCompanyCategoryInput {
  /** The slug that the company_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the company_category object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the company_category object to mutate */
  name: Scalars['String'];
  /** The ID of the company_category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the createCompany mutation. */
export interface CreateCompanyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Set connections between the Company and CompanyCategories */
  companyCategories?: InputMaybe<CompanyCompanyCategoriesInput>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createGraphqlDocument mutation. */
export interface CreateGraphqlDocumentInput {
  /** Alias names for saved GraphQL query documents */
  alias?: InputMaybe<Array<Scalars['String']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Description for the saved GraphQL document */
  description?: InputMaybe<Scalars['String']>;
  /** Allow, deny or default access grant for specific query */
  grant?: InputMaybe<Scalars['String']>;
  /** HTTP Access-Control-Max-Age Header for a saved GraphQL document */
  maxAgeHeader?: InputMaybe<Scalars['Int']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createMediaItem mutation. */
export interface CreateMediaItemInput {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createPage mutation. */
export interface CreatePageInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createPostFormat mutation. */
export interface CreatePostFormatInput {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the createPost mutation. */
export interface CreatePostInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Input for the createPressCategory mutation. */
export interface CreatePressCategoryInput {
  /** The slug that the press_categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the press_categories object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the press_categories object to mutate */
  name: Scalars['String'];
  /** The ID of the press_categories that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the createPress mutation. */
export interface CreatePressInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the Press and PressCategories */
  pressCategories?: InputMaybe<PressPressCategoriesInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createReusableBlock mutation. */
export interface CreateReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createTag mutation. */
export interface CreateTagInput {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the createTeamMember mutation. */
export interface CreateTeamMemberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the TeamMember and PressCategories */
  pressCategories?: InputMaybe<TeamMemberPressCategoriesInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the createUser mutation. */
export interface CreateUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
}

/** Date values */
export interface DateInput {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']>;
}

/** Filter the connection based on input */
export interface DateQueryInput {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>;
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars['String']>;
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']>;
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars['Int']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars['Boolean']>;
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars['Int']>;
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']>;
}

/** Input for the deleteBlockEditorPreview mutation. */
export interface DeleteBlockEditorPreviewInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the BlockEditorPreview to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteCategory mutation. */
export interface DeleteCategoryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the category to delete */
  id: Scalars['ID'];
}

/** Input for the deleteComment mutation. */
export interface DeleteCommentInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The deleted comment ID */
  id: Scalars['ID'];
}

/** Input for the deleteCompanyCategory mutation. */
export interface DeleteCompanyCategoryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the CompanyCategory to delete */
  id: Scalars['ID'];
}

/** Input for the deleteCompany mutation. */
export interface DeleteCompanyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the Company to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteGraphqlDocument mutation. */
export interface DeleteGraphqlDocumentInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the graphqlDocument to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteMediaItem mutation. */
export interface DeleteMediaItemInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID'];
}

/** Input for the deletePage mutation. */
export interface DeletePageInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the page to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deletePostFormat mutation. */
export interface DeletePostFormatInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID'];
}

/** Input for the deletePost mutation. */
export interface DeletePostInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the post to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deletePressCategory mutation. */
export interface DeletePressCategoryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the PressCategory to delete */
  id: Scalars['ID'];
}

/** Input for the deletePress mutation. */
export interface DeletePressInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the Press to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteReusableBlock mutation. */
export interface DeleteReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the ReusableBlock to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteTag mutation. */
export interface DeleteTagInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the tag to delete */
  id: Scalars['ID'];
}

/** Input for the deleteTeamMember mutation. */
export interface DeleteTeamMemberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the TeamMember to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the deleteUser mutation. */
export interface DeleteUserInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID'];
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars['ID']>;
}

/** Input for the generateAuthorizationCode mutation. */
export interface GenerateAuthorizationCodeInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Email for WordPress user */
  email?: InputMaybe<Scalars['String']>;
  /** Password for WordPress user */
  password?: InputMaybe<Scalars['String']>;
  /** Username for WordPress user */
  username?: InputMaybe<Scalars['String']>;
}

/** Types of styles to load */
export enum GlobalStylesheetTypesEnum {
  BASE_LAYOUT_STYLES = 'BASE_LAYOUT_STYLES',
  PRESETS = 'PRESETS',
  STYLES = 'STYLES',
  VARIABLES = 'VARIABLES'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray {
  field?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<
    Array<InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray>>
  >;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray {
  field?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<
    Array<InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray>>
  >;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a media item by its source url */
  SOURCE_URL = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  LARGE = 'LARGE',
  /** MediaItem with the medium size */
  MEDIUM = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MEDIUM_LARGE = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  THUMBNAIL = 'THUMBNAIL',
  /** MediaItem with the 1536x1536 size */
  _1536X1536 = '_1536X1536',
  /** MediaItem with the 2048x2048 size */
  _2048X2048 = '_2048X2048'
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AUTO_DRAFT = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  INHERIT = 'INHERIT',
  /** Objects with the private status */
  PRIVATE = 'PRIVATE',
  /** Objects with the trash status */
  TRASH = 'TRASH'
}

/** Arguments for filtering the MediaItemToCommentConnection connection */
export interface MediaItemToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID'
}

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export interface MenuItemToMenuItemConnectionWhereArgs {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
}

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer location */
  FOOTER = 'FOOTER',
  /** Put the menu in the primary location */
  PRIMARY = 'PRIMARY'
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  LOCATION = 'LOCATION',
  /** Identify a menu node by its name */
  NAME = 'NAME',
  /** Identify a menu node by its slug */
  SLUG = 'SLUG'
}

/** Arguments for filtering the MenuToMenuItemConnection connection */
export interface MenuToMenuItemConnectionWhereArgs {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
}

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** application/java mime type. */
  APPLICATION_JAVA = 'APPLICATION_JAVA',
  /** application/msword mime type. */
  APPLICATION_MSWORD = 'APPLICATION_MSWORD',
  /** application/octet-stream mime type. */
  APPLICATION_OCTET_STREAM = 'APPLICATION_OCTET_STREAM',
  /** application/onenote mime type. */
  APPLICATION_ONENOTE = 'APPLICATION_ONENOTE',
  /** application/oxps mime type. */
  APPLICATION_OXPS = 'APPLICATION_OXPS',
  /** application/pdf mime type. */
  APPLICATION_PDF = 'APPLICATION_PDF',
  /** application/rar mime type. */
  APPLICATION_RAR = 'APPLICATION_RAR',
  /** application/rtf mime type. */
  APPLICATION_RTF = 'APPLICATION_RTF',
  /** application/ttaf+xml mime type. */
  APPLICATION_TTAF_XML = 'APPLICATION_TTAF_XML',
  /** application/vnd.apple.keynote mime type. */
  APPLICATION_VND_APPLE_KEYNOTE = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** application/vnd.apple.numbers mime type. */
  APPLICATION_VND_APPLE_NUMBERS = 'APPLICATION_VND_APPLE_NUMBERS',
  /** application/vnd.apple.pages mime type. */
  APPLICATION_VND_APPLE_PAGES = 'APPLICATION_VND_APPLE_PAGES',
  /** application/vnd.ms-access mime type. */
  APPLICATION_VND_MS_ACCESS = 'APPLICATION_VND_MS_ACCESS',
  /** application/vnd.ms-excel mime type. */
  APPLICATION_VND_MS_EXCEL = 'APPLICATION_VND_MS_EXCEL',
  /** application/vnd.ms-excel.addin.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.binary.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** application/vnd.ms-excel.template.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint mime type. */
  APPLICATION_VND_MS_POWERPOINT = 'APPLICATION_VND_MS_POWERPOINT',
  /** application/vnd.ms-powerpoint.addin.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.presentation.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slideshow.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slide.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.template.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-project mime type. */
  APPLICATION_VND_MS_PROJECT = 'APPLICATION_VND_MS_PROJECT',
  /** application/vnd.ms-word.document.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** application/vnd.ms-word.template.macroEnabled.12 mime type. */
  APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-write mime type. */
  APPLICATION_VND_MS_WRITE = 'APPLICATION_VND_MS_WRITE',
  /** application/vnd.ms-xpsdocument mime type. */
  APPLICATION_VND_MS_XPSDOCUMENT = 'APPLICATION_VND_MS_XPSDOCUMENT',
  /** application/vnd.oasis.opendocument.chart mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_CHART = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  /** application/vnd.oasis.opendocument.database mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  /** application/vnd.oasis.opendocument.formula mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  /** application/vnd.oasis.opendocument.graphics mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  /** application/vnd.oasis.opendocument.presentation mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  /** application/vnd.oasis.opendocument.spreadsheet mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  /** application/vnd.oasis.opendocument.text mime type. */
  APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** application/vnd.openxmlformats-officedocument.presentationml.presentation mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** application/vnd.openxmlformats-officedocument.presentationml.slide mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  /** application/vnd.openxmlformats-officedocument.presentationml.slideshow mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** application/vnd.openxmlformats-officedocument.presentationml.template mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.sheet mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.template mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.document mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.template mime type. */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  /** application/wordperfect mime type. */
  APPLICATION_WORDPERFECT = 'APPLICATION_WORDPERFECT',
  /** application/x-7z-compressed mime type. */
  APPLICATION_X_7Z_COMPRESSED = 'APPLICATION_X_7Z_COMPRESSED',
  /** application/x-gzip mime type. */
  APPLICATION_X_GZIP = 'APPLICATION_X_GZIP',
  /** application/x-tar mime type. */
  APPLICATION_X_TAR = 'APPLICATION_X_TAR',
  /** application/zip mime type. */
  APPLICATION_ZIP = 'APPLICATION_ZIP',
  /** audio/aac mime type. */
  AUDIO_AAC = 'AUDIO_AAC',
  /** audio/flac mime type. */
  AUDIO_FLAC = 'AUDIO_FLAC',
  /** audio/midi mime type. */
  AUDIO_MIDI = 'AUDIO_MIDI',
  /** audio/mpeg mime type. */
  AUDIO_MPEG = 'AUDIO_MPEG',
  /** audio/ogg mime type. */
  AUDIO_OGG = 'AUDIO_OGG',
  /** audio/wav mime type. */
  AUDIO_WAV = 'AUDIO_WAV',
  /** audio/x-matroska mime type. */
  AUDIO_X_MATROSKA = 'AUDIO_X_MATROSKA',
  /** audio/x-ms-wax mime type. */
  AUDIO_X_MS_WAX = 'AUDIO_X_MS_WAX',
  /** audio/x-ms-wma mime type. */
  AUDIO_X_MS_WMA = 'AUDIO_X_MS_WMA',
  /** audio/x-realaudio mime type. */
  AUDIO_X_REALAUDIO = 'AUDIO_X_REALAUDIO',
  /** image/avif mime type. */
  IMAGE_AVIF = 'IMAGE_AVIF',
  /** image/bmp mime type. */
  IMAGE_BMP = 'IMAGE_BMP',
  /** image/gif mime type. */
  IMAGE_GIF = 'IMAGE_GIF',
  /** image/heic mime type. */
  IMAGE_HEIC = 'IMAGE_HEIC',
  /** image/jpeg mime type. */
  IMAGE_JPEG = 'IMAGE_JPEG',
  /** image/png mime type. */
  IMAGE_PNG = 'IMAGE_PNG',
  /** image/svg+xml mime type. */
  IMAGE_SVG_XML = 'IMAGE_SVG_XML',
  /** image/tiff mime type. */
  IMAGE_TIFF = 'IMAGE_TIFF',
  /** image/webp mime type. */
  IMAGE_WEBP = 'IMAGE_WEBP',
  /** image/x-icon mime type. */
  IMAGE_X_ICON = 'IMAGE_X_ICON',
  /** text/calendar mime type. */
  TEXT_CALENDAR = 'TEXT_CALENDAR',
  /** text/css mime type. */
  TEXT_CSS = 'TEXT_CSS',
  /** text/csv mime type. */
  TEXT_CSV = 'TEXT_CSV',
  /** text/plain mime type. */
  TEXT_PLAIN = 'TEXT_PLAIN',
  /** text/richtext mime type. */
  TEXT_RICHTEXT = 'TEXT_RICHTEXT',
  /** text/tab-separated-values mime type. */
  TEXT_TAB_SEPARATED_VALUES = 'TEXT_TAB_SEPARATED_VALUES',
  /** text/vtt mime type. */
  TEXT_VTT = 'TEXT_VTT',
  /** video/3gpp mime type. */
  VIDEO_3GPP = 'VIDEO_3GPP',
  /** video/3gpp2 mime type. */
  VIDEO_3GPP2 = 'VIDEO_3GPP2',
  /** video/avi mime type. */
  VIDEO_AVI = 'VIDEO_AVI',
  /** video/divx mime type. */
  VIDEO_DIVX = 'VIDEO_DIVX',
  /** video/mp4 mime type. */
  VIDEO_MP4 = 'VIDEO_MP4',
  /** video/mpeg mime type. */
  VIDEO_MPEG = 'VIDEO_MPEG',
  /** video/ogg mime type. */
  VIDEO_OGG = 'VIDEO_OGG',
  /** video/quicktime mime type. */
  VIDEO_QUICKTIME = 'VIDEO_QUICKTIME',
  /** video/webm mime type. */
  VIDEO_WEBM = 'VIDEO_WEBM',
  /** video/x-flv mime type. */
  VIDEO_X_FLV = 'VIDEO_X_FLV',
  /** video/x-matroska mime type. */
  VIDEO_X_MATROSKA = 'VIDEO_X_MATROSKA',
  /** video/x-ms-asf mime type. */
  VIDEO_X_MS_ASF = 'VIDEO_X_MS_ASF',
  /** video/x-ms-wm mime type. */
  VIDEO_X_MS_WM = 'VIDEO_X_MS_WM',
  /** video/x-ms-wmv mime type. */
  VIDEO_X_MS_WMV = 'VIDEO_X_MS_WMV',
  /** video/x-ms-wmx mime type. */
  VIDEO_X_MS_WMX = 'VIDEO_X_MS_WMX'
}

/** Offset pagination input type */
export interface OffsetPagination {
  /** Number of post to show per page. Passed to posts_per_page of WP_Query. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Number of post to show per page. Passed to posts_per_page of WP_Query. */
  size?: InputMaybe<Scalars['Int']>;
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  ASC = 'ASC',
  /** Sort the query result set in a descending order */
  DESC = 'DESC'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Arguments for filtering the PageToCommentConnection connection */
export interface PageToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the PageToRevisionConnection connection */
export interface PageToRevisionConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PageToRevisionConnectionWhereArgsTaxArray {
  field?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PageToRevisionConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PageToRevisionConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PageToRevisionConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PageToRevisionConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PageToRevisionConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  ACTIVE = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DROP_IN = 'DROP_IN',
  /** The plugin is currently inactive. */
  INACTIVE = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MUST_USE = 'MUST_USE',
  /** The plugin is technically active but was paused while loading. */
  PAUSED = 'PAUSED',
  /** The plugin was active recently. */
  RECENTLY_ACTIVE = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  UPGRADE = 'UPGRADE'
}

/** Set relationships between the post to categories */
export interface PostCategoriesInput {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
}

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostCategoriesNodeInput {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export interface PostFormatToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PostFormatToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PostFormatToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PostFormatToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostFormatToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the PostFormatToPostConnection connection */
export interface PostFormatToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PostFormatToPostConnectionWhereArgsTaxArray {
  field?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PostFormatToPostConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PostFormatToPostConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PostFormatToPostConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostFormatToPostConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PostFormatToPostConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  RAW = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  RENDERED = 'RENDERED'
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  DATE = 'DATE',
  /** The most recent modification date of the comment. */
  MODIFIED = 'MODIFIED'
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  AUTHOR = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  COMMENT_COUNT = 'COMMENT_COUNT',
  /** Order by publish date */
  DATE = 'DATE',
  /** Preserve the ID order given in the IN array */
  IN = 'IN',
  /** Order by the menu order value */
  MENU_ORDER = 'MENU_ORDER',
  /** Order by last modified date */
  MODIFIED = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NAME_IN = 'NAME_IN',
  /** Order by parent ID */
  PARENT = 'PARENT',
  /** Order by slug */
  SLUG = 'SLUG',
  /** Order by title */
  TITLE = 'TITLE'
}

/** Options for ordering the connection */
export interface PostObjectsConnectionOrderbyInput {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
}

/** Set relationships between the post to postFormats */
export interface PostPostFormatsInput {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
}

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostPostFormatsNodeInput {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  ACF_DISABLED = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AUTO_DRAFT = 'AUTO_DRAFT',
  /** Objects with the dp-rewrite-republish status */
  DP_REWRITE_REPUBLISH = 'DP_REWRITE_REPUBLISH',
  /** Objects with the draft status */
  DRAFT = 'DRAFT',
  /** Objects with the future status */
  FUTURE = 'FUTURE',
  /** Objects with the inherit status */
  INHERIT = 'INHERIT',
  /** Objects with the pending status */
  PENDING = 'PENDING',
  /** Objects with the private status */
  PRIVATE = 'PRIVATE',
  /** Objects with the publish status */
  PUBLISH = 'PUBLISH',
  /** Objects with the request-completed status */
  REQUEST_COMPLETED = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  REQUEST_CONFIRMED = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  REQUEST_FAILED = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  REQUEST_PENDING = 'REQUEST_PENDING',
  /** Objects with the spam status */
  SPAM = 'SPAM',
  /** Objects with the trash status */
  TRASH = 'TRASH',
  /** Objects with the wp_stream_disabled status */
  WP_STREAM_DISABLED = 'WP_STREAM_DISABLED',
  /** Objects with the wp_stream_enabled status */
  WP_STREAM_ENABLED = 'WP_STREAM_ENABLED'
}

/** Set relationships between the post to tags */
export interface PostTagsInput {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
}

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostTagsNodeInput {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Arguments for filtering the PostToCategoryConnection connection */
export interface PostToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the PostToCommentConnection connection */
export interface PostToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the PostToPostFormatConnection connection */
export interface PostToPostFormatConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the PostToRevisionConnection connection */
export interface PostToRevisionConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PostToRevisionConnectionWhereArgsTaxArray {
  field?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PostToRevisionConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PostToRevisionConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PostToRevisionConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PostToRevisionConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PostToRevisionConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the PostToTagConnection connection */
export interface PostToTagConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the PostToTermNodeConnection connection */
export interface PostToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PressCategoryIdType {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Arguments for filtering the PressCategoryToContentNodeConnection connection */
export interface PressCategoryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPressCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<PressCategoryToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PressCategoryToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<PressCategoryToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PressCategoryToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PressCategoryToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PressCategoryToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the PressCategoryToPressCategoryConnection connection */
export interface PressCategoryToPressCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the PressCategoryToPressConnection connection */
export interface PressCategoryToPressConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<PressCategoryToPressConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PressCategoryToPressConnectionWhereArgsTaxArray {
  field?: InputMaybe<PressCategoryToPressConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PressCategoryToPressConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PressCategoryToPressConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PressCategoryToPressConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PressCategoryToPressConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PressCategoryToPressConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the PressCategoryToTeamMemberConnection connection */
export interface PressCategoryToTeamMemberConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<PressCategoryToTeamMemberConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface PressCategoryToTeamMemberConnectionWhereArgsTaxArray {
  field?: InputMaybe<PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface PressCategoryToTeamMemberConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<PressCategoryToTeamMemberConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PressIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Set relationships between the Press to PressCategories */
export interface PressPressCategoriesInput {
  /** If true, this will append the PressCategory to existing related PressCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PressPressCategoriesNodeInput>>>;
}

/** List of PressCategories to connect the Press to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PressPressCategoriesNodeInput {
  /** The description of the PressCategory. This field is used to set a description of the PressCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the PressCategory. If present, this will be used to connect to the Press. If no existing PressCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the PressCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the PressCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Arguments for filtering the PressToPressCategoryConnection connection */
export interface PressToPressCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the PressToTermNodeConnection connection */
export interface PressToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the registerUser mutation. */
export interface RegisterUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** A string that contains the user's username. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
}

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  AND = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  OR = 'OR'
}

/** Input for the resetUserPassword mutation. */
export interface ResetUserPasswordInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Password reset key */
  key?: InputMaybe<Scalars['String']>;
  /** The user's login (username). */
  login?: InputMaybe<Scalars['String']>;
  /** The new password. */
  password?: InputMaybe<Scalars['String']>;
}

/** Input for the restoreComment mutation. */
export interface RestoreCommentInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID'];
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ReusableBlockIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = 'SLUG',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Arguments for filtering the ReusableBlockToRevisionConnection connection */
export interface ReusableBlockToRevisionConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<ReusableBlockToRevisionConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface ReusableBlockToRevisionConnectionWhereArgsTaxArray {
  field?: InputMaybe<ReusableBlockToRevisionConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface ReusableBlockToRevisionConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<ReusableBlockToRevisionConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum ReusableBlockToRevisionConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToBlockEditorPreviewConnection connection */
export interface RootQueryToBlockEditorPreviewConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate BlockEditorPreviews with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  previewedDatabaseId?: InputMaybe<Scalars['Int']>;
  previewedParentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToBlockEditorPreviewConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<
    Array<InputMaybe<RootQueryToBlockEditorPreviewConnectionWhereArgsTaxArray>>
  >;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export interface RootQueryToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToCommentConnection connection */
export interface RootQueryToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the RootQueryToCompanyCategoryConnection connection */
export interface RootQueryToCompanyCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToCompanyConnection connection */
export interface RootQueryToCompanyConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate Companys with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToCompanyConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToCompanyConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToCompanyConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToCompanyConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToCompanyConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToCompanyConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToCompanyConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToCompanyConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export interface RootQueryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate content nodes with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToGraphqlDocumentConnection connection */
export interface RootQueryToGraphqlDocumentConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate GraphqlDocuments with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToGraphqlDocumentConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToGraphqlDocumentConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export interface RootQueryToMediaItemConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate MediaItems with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToMediaItemConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToMediaItemConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToMediaItemConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToMediaItemConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToMenuConnection connection */
export interface RootQueryToMenuConnectionWhereArgs {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars['String']>;
}

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export interface RootQueryToMenuItemConnectionWhereArgs {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the RootQueryToPageConnection connection */
export interface RootQueryToPageConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate Pages with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToPageConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToPageConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToPageConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToPageConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPageConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToPageConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToPluginConnection connection */
export interface RootQueryToPluginConnectionWhereArgs {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>;
}

/** Arguments for filtering the RootQueryToPostConnection connection */
export interface RootQueryToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate Posts with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToPostConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToPostConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToPostConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToPostConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPostConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToPostConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export interface RootQueryToPostFormatConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToPressCategoryConnection connection */
export interface RootQueryToPressCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToPressConnection connection */
export interface RootQueryToPressConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate Presss with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToPressConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToPressConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToPressConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToPressConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToPressConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToPressConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToPressConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToPressConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToReusableBlockConnection connection */
export interface RootQueryToReusableBlockConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate ReusableBlocks with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToReusableBlockConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToReusableBlockConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToReusableBlockConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToReusableBlockConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToReusableBlockConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToReusableBlockConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToRevisionsConnection connection */
export interface RootQueryToRevisionsConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToRevisionsConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToRevisionsConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToRevisionsConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToRevisionsConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToRevisionsConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToRevisionsConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToTagConnection connection */
export interface RootQueryToTagConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToTeamMemberConnection connection */
export interface RootQueryToTeamMemberConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Paginate TeamMembers with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<RootQueryToTeamMemberConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface RootQueryToTeamMemberConnectionWhereArgsTaxArray {
  field?: InputMaybe<RootQueryToTeamMemberConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface RootQueryToTeamMemberConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<RootQueryToTeamMemberConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum RootQueryToTeamMemberConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export interface RootQueryToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the RootQueryToUserConnection connection */
export interface RootQueryToUserConnectionWhereArgs {
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** The user login. */
  login?: InputMaybe<Scalars['String']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The user nicename. */
  nicename?: InputMaybe<Scalars['String']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Paginate users with offsets */
  offsetPagination?: InputMaybe<OffsetPagination>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<Array<InputMaybe<UsersConnectionSearchColumnEnum>>>;
}

/** Types of cards */
export enum SEOCardType {
  summary = 'summary',
  summary_large_image = 'summary_large_image'
}

/** Input for the sendPasswordResetEmail mutation. */
export interface SendPasswordResetEmailInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String'];
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Arguments for filtering the TagToContentNodeConnection connection */
export interface TagToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface TagToContentNodeConnectionWhereArgsTaxArray {
  field?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<TagToContentNodeConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface TagToContentNodeConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<TagToContentNodeConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TagToContentNodeConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum TagToContentNodeConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the TagToPostConnection connection */
export interface TagToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<TagToPostConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface TagToPostConnectionWhereArgsTaxArray {
  field?: InputMaybe<TagToPostConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<TagToPostConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface TagToPostConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<TagToPostConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TagToPostConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum TagToPostConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  CATEGORY = 'CATEGORY',
  /** Taxonomy enum company_category */
  COMPANYCATEGORY = 'COMPANYCATEGORY',
  /** Taxonomy enum post_format */
  POSTFORMAT = 'POSTFORMAT',
  /** Taxonomy enum press_categories */
  PRESSCATEGORY = 'PRESSCATEGORY',
  /** Taxonomy enum post_tag */
  TAG = 'TAG'
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  ID = 'ID',
  /** The name of the taxonomy */
  NAME = 'NAME'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TeamMemberIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  ID = 'ID',
  /** Identify a resource by the URI. */
  URI = 'URI'
}

/** Set relationships between the TeamMember to PressCategories */
export interface TeamMemberPressCategoriesInput {
  /** If true, this will append the PressCategory to existing related PressCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<TeamMemberPressCategoriesNodeInput>>>;
}

/** List of PressCategories to connect the TeamMember to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface TeamMemberPressCategoriesNodeInput {
  /** The description of the PressCategory. This field is used to set a description of the PressCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the PressCategory. If present, this will be used to connect to the TeamMember. If no existing PressCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the PressCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the PressCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Arguments for filtering the TeamMemberToPressCategoryConnection connection */
export interface TeamMemberToPressCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** Arguments for filtering the TeamMemberToTermNodeConnection connection */
export interface TeamMemberToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
}

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The hashed Global ID */
  ID = 'ID',
  /** The name of the node */
  NAME = 'NAME',
  /** Url friendly name of the node */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI'
}

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  COUNT = 'COUNT',
  /** Order the connection by description. */
  DESCRIPTION = 'DESCRIPTION',
  /** Order the connection by name. */
  NAME = 'NAME',
  /** Order the connection by slug. */
  SLUG = 'SLUG',
  /** Order the connection by term group. */
  TERM_GROUP = 'TERM_GROUP',
  /** Order the connection by term id. */
  TERM_ID = 'TERM_ID',
  /** Order the connection by term order. */
  TERM_ORDER = 'TERM_ORDER'
}

/** Input for the updateBlockEditorPreview mutation. */
export interface UpdateBlockEditorPreviewInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the BlockEditorPreview object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateCategory mutation. */
export interface UpdateCategoryInput {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the category object to update */
  id: Scalars['ID'];
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the updateComment mutation. */
export interface UpdateCommentInput {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID'];
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']>;
}

/** Input for the updateCompanyCategory mutation. */
export interface UpdateCompanyCategoryInput {
  /** The slug that the company_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the company_category object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the CompanyCategory object to update */
  id: Scalars['ID'];
  /** The name of the company_category object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the company_category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the updateCompany mutation. */
export interface UpdateCompanyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Set connections between the Company and CompanyCategories */
  companyCategories?: InputMaybe<CompanyCompanyCategoriesInput>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** The ID of the Company object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateGraphqlDocument mutation. */
export interface UpdateGraphqlDocumentInput {
  /** Alias names for saved GraphQL query documents */
  alias?: InputMaybe<Array<Scalars['String']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Description for the saved GraphQL document */
  description?: InputMaybe<Scalars['String']>;
  /** Allow, deny or default access grant for specific query */
  grant?: InputMaybe<Scalars['String']>;
  /** The ID of the graphqlDocument object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** HTTP Access-Control-Max-Age Header for a saved GraphQL document */
  maxAgeHeader?: InputMaybe<Scalars['Int']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateMediaItem mutation. */
export interface UpdateMediaItemInput {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID'];
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updatePage mutation. */
export interface UpdatePageInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the page object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updatePostFormat mutation. */
export interface UpdatePostFormatInput {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID'];
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the updatePost mutation. */
export interface UpdatePostInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** The ID of the post object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Input for the updatePressCategory mutation. */
export interface UpdatePressCategoryInput {
  /** The slug that the press_categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the press_categories object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the PressCategory object to update */
  id: Scalars['ID'];
  /** The name of the press_categories object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the press_categories that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the updatePress mutation. */
export interface UpdatePressInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the Press object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the Press and PressCategories */
  pressCategories?: InputMaybe<PressPressCategoriesInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateReusableBlock mutation. */
export interface UpdateReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the ReusableBlock object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateSettings mutation. */
export interface UpdateSettingsInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<Scalars['String']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars['String']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars['String']>;
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars['String']>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: InputMaybe<Scalars['String']>;
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars['String']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars['Int']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars['String']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars['String']>;
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars['String']>;
  /** Site URL. */
  generalSettingsUrl?: InputMaybe<Scalars['String']>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: InputMaybe<Scalars['Int']>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: InputMaybe<Scalars['Int']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars['Int']>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: InputMaybe<Scalars['String']>;
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars['Int']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars['String']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars['Boolean']>;
}

/** Input for the updateTag mutation. */
export interface UpdateTagInput {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tag object to update */
  id: Scalars['ID'];
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
}

/** Input for the updateTeamMember mutation. */
export interface UpdateTeamMemberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the TeamMember object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the TeamMember and PressCategories */
  pressCategories?: InputMaybe<TeamMemberPressCategoriesInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
}

/** Input for the updateUser mutation. */
export interface UpdateUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the user */
  id: Scalars['ID'];
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
}

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DATABASE_ID = 'DATABASE_ID',
  /** The Email of the User */
  EMAIL = 'EMAIL',
  /** The hashed Global ID */
  ID = 'ID',
  /** The slug of the User */
  SLUG = 'SLUG',
  /** The URI for the node */
  URI = 'URI',
  /** The username the User uses to login with */
  USERNAME = 'USERNAME'
}

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  ADMINISTRATOR = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  AUTHOR = 'AUTHOR',
  /** User role with specific capabilities */
  CONTRIBUTOR = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  EDITOR = 'EDITOR',
  /** User role with specific capabilities */
  SEO_EDITOR = 'SEO_EDITOR',
  /** User role with specific capabilities */
  SEO_MANAGER = 'SEO_MANAGER',
  /** User role with specific capabilities */
  SUBSCRIBER = 'SUBSCRIBER'
}

/** Arguments for filtering the UserToBlockEditorPreviewConnection connection */
export interface UserToBlockEditorPreviewConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<UserToBlockEditorPreviewConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface UserToBlockEditorPreviewConnectionWhereArgsTaxArray {
  field?: InputMaybe<UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface UserToBlockEditorPreviewConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<UserToBlockEditorPreviewConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the UserToCommentConnection connection */
export interface UserToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
}

/** Arguments for filtering the UserToMediaItemConnection connection */
export interface UserToMediaItemConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface UserToMediaItemConnectionWhereArgsTaxArray {
  field?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<UserToMediaItemConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface UserToMediaItemConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<UserToMediaItemConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToMediaItemConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum UserToMediaItemConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the UserToPageConnection connection */
export interface UserToPageConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<UserToPageConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface UserToPageConnectionWhereArgsTaxArray {
  field?: InputMaybe<UserToPageConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<UserToPageConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface UserToPageConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<UserToPageConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToPageConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum UserToPageConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the UserToPostConnection connection */
export interface UserToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxQuery?: InputMaybe<UserToPostConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface UserToPostConnectionWhereArgsTaxArray {
  field?: InputMaybe<UserToPostConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<UserToPostConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface UserToPostConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<UserToPostConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToPostConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum UserToPostConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Arguments for filtering the UserToRevisionsConnection connection */
export interface UserToRevisionsConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<UserToRevisionsConnectionWhereArgsTaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
}

export interface UserToRevisionsConnectionWhereArgsTaxArray {
  field?: InputMaybe<UserToRevisionsConnectionWhereArgsTaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<UserToRevisionsConnectionWhereArgsTaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

/** Query objects based on taxonomy parameters */
export interface UserToRevisionsConnectionWhereArgsTaxQuery {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<UserToRevisionsConnectionWhereArgsTaxArray>>>;
}

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum UserToRevisionsConnectionWhereArgsTaxQueryField {
  ID = 'ID',
  NAME = 'NAME',
  SLUG = 'SLUG',
  TAXONOMY_ID = 'TAXONOMY_ID'
}

export enum UserToRevisionsConnectionWhereArgsTaxQueryOperator {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN'
}

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DISPLAY_NAME = 'DISPLAY_NAME',
  /** Order by email address */
  EMAIL = 'EMAIL',
  /** Order by login */
  LOGIN = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LOGIN_IN = 'LOGIN_IN',
  /** Order by nice name */
  NICE_NAME = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NICE_NAME_IN = 'NICE_NAME_IN',
  /** Order by registration date */
  REGISTERED = 'REGISTERED',
  /** Order by URL */
  URL = 'URL'
}

/** Options for ordering the connection */
export interface UsersConnectionOrderbyInput {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
}

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  EMAIL = 'EMAIL',
  /** The globally unique ID. */
  ID = 'ID',
  /** The username the User uses to login with. */
  LOGIN = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  NICENAME = 'NICENAME',
  /** The URL of the user's website. */
  URL = 'URL'
}

export const scalarsEnumsHash: import('gqty').ScalarsEnumsHash = {
  AvatarRatingEnum: true,
  BlockAttributesArray: true,
  BlockAttributesObject: true,
  BlockEditorPreviewIdType: true,
  Boolean: true,
  CategoryIdType: true,
  CategoryToContentNodeConnectionWhereArgsTaxQueryField: true,
  CategoryToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  CategoryToPostConnectionWhereArgsTaxQueryField: true,
  CategoryToPostConnectionWhereArgsTaxQueryOperator: true,
  CommentNodeIdTypeEnum: true,
  CommentStatusEnum: true,
  CommentsConnectionOrderbyEnum: true,
  CompanyCategoryIdType: true,
  CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField: true,
  CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator: true,
  CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField: true,
  CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  CompanyIdType: true,
  ContentNodeIdTypeEnum: true,
  ContentTypeEnum: true,
  ContentTypeIdTypeEnum: true,
  ContentTypeToContentNodeConnectionWhereArgsTaxQueryField: true,
  ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  ContentTypesOfCategoryEnum: true,
  ContentTypesOfCompanyCategoryEnum: true,
  ContentTypesOfPostFormatEnum: true,
  ContentTypesOfPressCategoryEnum: true,
  ContentTypesOfTagEnum: true,
  Float: true,
  GlobalStylesheetTypesEnum: true,
  GraphqlDocumentIdType: true,
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField: true,
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator: true,
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField: true,
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator: true,
  ID: true,
  Int: true,
  MediaItemIdType: true,
  MediaItemSizeEnum: true,
  MediaItemStatusEnum: true,
  MenuItemNodeIdTypeEnum: true,
  MenuLocationEnum: true,
  MenuNodeIdTypeEnum: true,
  MimeTypeEnum: true,
  OrderEnum: true,
  PageIdType: true,
  PageToRevisionConnectionWhereArgsTaxQueryField: true,
  PageToRevisionConnectionWhereArgsTaxQueryOperator: true,
  PluginStatusEnum: true,
  PostFormatIdType: true,
  PostFormatToContentNodeConnectionWhereArgsTaxQueryField: true,
  PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  PostFormatToPostConnectionWhereArgsTaxQueryField: true,
  PostFormatToPostConnectionWhereArgsTaxQueryOperator: true,
  PostIdType: true,
  PostObjectFieldFormatEnum: true,
  PostObjectsConnectionDateColumnEnum: true,
  PostObjectsConnectionOrderbyEnum: true,
  PostStatusEnum: true,
  PostToRevisionConnectionWhereArgsTaxQueryField: true,
  PostToRevisionConnectionWhereArgsTaxQueryOperator: true,
  PressCategoryIdType: true,
  PressCategoryToContentNodeConnectionWhereArgsTaxQueryField: true,
  PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  PressCategoryToPressConnectionWhereArgsTaxQueryField: true,
  PressCategoryToPressConnectionWhereArgsTaxQueryOperator: true,
  PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField: true,
  PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator: true,
  PressIdType: true,
  RelationEnum: true,
  ReusableBlockIdType: true,
  ReusableBlockToRevisionConnectionWhereArgsTaxQueryField: true,
  ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField: true,
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToCompanyConnectionWhereArgsTaxQueryField: true,
  RootQueryToCompanyConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToContentNodeConnectionWhereArgsTaxQueryField: true,
  RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField: true,
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToMediaItemConnectionWhereArgsTaxQueryField: true,
  RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToPageConnectionWhereArgsTaxQueryField: true,
  RootQueryToPageConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToPostConnectionWhereArgsTaxQueryField: true,
  RootQueryToPostConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToPressConnectionWhereArgsTaxQueryField: true,
  RootQueryToPressConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToReusableBlockConnectionWhereArgsTaxQueryField: true,
  RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToRevisionsConnectionWhereArgsTaxQueryField: true,
  RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator: true,
  RootQueryToTeamMemberConnectionWhereArgsTaxQueryField: true,
  RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator: true,
  SEOCardType: true,
  String: true,
  TagIdType: true,
  TagToContentNodeConnectionWhereArgsTaxQueryField: true,
  TagToContentNodeConnectionWhereArgsTaxQueryOperator: true,
  TagToPostConnectionWhereArgsTaxQueryField: true,
  TagToPostConnectionWhereArgsTaxQueryOperator: true,
  TaxonomyEnum: true,
  TaxonomyIdTypeEnum: true,
  TeamMemberIdType: true,
  TermNodeIdTypeEnum: true,
  TermObjectsConnectionOrderbyEnum: true,
  UserNodeIdTypeEnum: true,
  UserRoleEnum: true,
  UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField: true,
  UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator: true,
  UserToMediaItemConnectionWhereArgsTaxQueryField: true,
  UserToMediaItemConnectionWhereArgsTaxQueryOperator: true,
  UserToPageConnectionWhereArgsTaxQueryField: true,
  UserToPageConnectionWhereArgsTaxQueryOperator: true,
  UserToPostConnectionWhereArgsTaxQueryField: true,
  UserToPostConnectionWhereArgsTaxQueryOperator: true,
  UserToRevisionsConnectionWhereArgsTaxQueryField: true,
  UserToRevisionsConnectionWhereArgsTaxQueryOperator: true,
  UsersConnectionOrderbyEnum: true,
  UsersConnectionSearchColumnEnum: true
};
export const generatedSchema = {
  AcfFieldGroup: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    $on: { __type: '$AcfFieldGroup!' }
  },
  AcfOptionsFooter: {
    __typename: { __type: 'String!' },
    footerSettings: { __type: 'AcfOptionsFooter_Footersettings' },
    pageSlug: { __type: 'String' },
    pageTitle: { __type: 'String' }
  },
  AcfOptionsFooter_Footersettings: {
    __typename: { __type: 'String!' },
    address: { __type: 'String' },
    addressLink: { __type: 'String' },
    email: { __type: 'String' },
    externalLinks: { __type: '[AcfOptionsFooter_Footersettings_externalLinks]' },
    fieldGroupName: { __type: 'String' },
    links: { __type: '[AcfOptionsFooter_Footersettings_links]' },
    newsletterTitle: { __type: 'String' },
    phoneNumber: { __type: 'String' },
    socialMedia: { __type: '[AcfOptionsFooter_Footersettings_socialMedia]' }
  },
  AcfOptionsFooter_Footersettings_externalLinks: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    label: { __type: 'String' },
    url: { __type: 'String' }
  },
  AcfOptionsFooter_Footersettings_links: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    label: { __type: 'String' },
    url: { __type: 'String' }
  },
  AcfOptionsFooter_Footersettings_socialMedia: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    mediaLabel: { __type: 'String' },
    mediaUrl: { __type: 'String' }
  },
  AcfOptionsNotifications: {
    __typename: { __type: 'String!' },
    pageSlug: { __type: 'String' },
    pageTitle: { __type: 'String' },
    websiteNotificationBanner: { __type: 'AcfOptionsNotifications_Websitenotificationbanner' }
  },
  AcfOptionsNotifications_Websitenotificationbanner: {
    __typename: { __type: 'String!' },
    bannerContent: { __type: 'String' },
    bannerId: { __type: 'String' },
    enabled: { __type: 'Boolean' },
    fieldGroupName: { __type: 'String' }
  },
  Avatar: {
    __typename: { __type: 'String!' },
    default: { __type: 'String' },
    extraAttr: { __type: 'String' },
    forceDefault: { __type: 'Boolean' },
    foundAvatar: { __type: 'Boolean' },
    height: { __type: 'Int' },
    isRestricted: { __type: 'Boolean' },
    rating: { __type: 'String' },
    scheme: { __type: 'String' },
    size: { __type: 'Int' },
    url: { __type: 'String' },
    width: { __type: 'Int' }
  },
  Block: {
    __typename: { __type: 'String!' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' },
    $on: { __type: '$Block!' }
  },
  BlockEditorContentNode: {
    __typename: { __type: 'String!' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    id: { __type: 'ID!' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    $on: { __type: '$BlockEditorContentNode!' }
  },
  BlockEditorContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[BlockEditorContentNodeConnectionEdge!]!' },
    nodes: { __type: '[BlockEditorContentNode!]!' },
    pageInfo: { __type: 'BlockEditorContentNodeConnectionPageInfo!' }
  },
  BlockEditorContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'BlockEditorContentNode!' }
  },
  BlockEditorContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  BlockEditorContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  BlockEditorPreview: {
    __typename: { __type: 'String!' },
    author: { __type: 'NodeWithAuthorToUserConnectionEdge' },
    authorDatabaseId: { __type: 'Int' },
    authorId: { __type: 'ID' },
    blockEditorPreviewId: { __type: 'Int!' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    lastUpdateTime: { __type: 'String' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    preview: { __type: 'BlockEditorPreviewToPreviewConnectionEdge' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    previewed: { __type: 'BlockEditorContentNode' },
    previewedDatabaseId: { __type: 'Int' },
    previewedParentDatabaseId: { __type: 'Int' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  BlockEditorPreviewConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[BlockEditorPreviewConnectionEdge!]!' },
    nodes: { __type: '[BlockEditorPreview!]!' },
    pageInfo: { __type: 'BlockEditorPreviewConnectionPageInfo!' },
    $on: { __type: '$BlockEditorPreviewConnection!' }
  },
  BlockEditorPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'BlockEditorPreview!' },
    $on: { __type: '$BlockEditorPreviewConnectionEdge!' }
  },
  BlockEditorPreviewConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$BlockEditorPreviewConnectionPageInfo!' }
  },
  BlockEditorPreviewToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'BlockEditorPreview!' }
  },
  Category: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'CategoryToAncestorsCategoryConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    categoryId: { __type: 'Int' },
    children: {
      __type: 'CategoryToCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CategoryToCategoryConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    contentNodes: {
      __type: 'CategoryToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CategoryToContentNodeConnectionWhereArgs'
      }
    },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    parent: { __type: 'CategoryToParentCategoryConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    posts: {
      __type: 'CategoryToPostConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CategoryToPostConnectionWhereArgs'
      }
    },
    seo: { __type: 'TaxonomySEO' },
    slug: { __type: 'String' },
    taxonomy: { __type: 'CategoryToTaxonomyConnectionEdge' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' }
  },
  CategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CategoryConnectionEdge!]!' },
    nodes: { __type: '[Category!]!' },
    pageInfo: { __type: 'CategoryConnectionPageInfo!' },
    $on: { __type: '$CategoryConnection!' }
  },
  CategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Category!' },
    $on: { __type: '$CategoryConnectionEdge!' }
  },
  CategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$CategoryConnectionPageInfo!' }
  },
  CategoryToAncestorsCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CategoryToAncestorsCategoryConnectionEdge!]!' },
    nodes: { __type: '[Category!]!' },
    pageInfo: { __type: 'CategoryToAncestorsCategoryConnectionPageInfo!' }
  },
  CategoryToAncestorsCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Category!' }
  },
  CategoryToAncestorsCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CategoryToCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CategoryToCategoryConnectionEdge!]!' },
    nodes: { __type: '[Category!]!' },
    pageInfo: { __type: 'CategoryToCategoryConnectionPageInfo!' }
  },
  CategoryToCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Category!' }
  },
  CategoryToCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CategoryToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  CategoryToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CategoryToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'CategoryToContentNodeConnectionPageInfo!' }
  },
  CategoryToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  CategoryToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CategoryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypesOfCategoryEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'CategoryToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  CategoryToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'CategoryToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'CategoryToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  CategoryToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[CategoryToContentNodeConnectionWhereArgsTaxArray]' }
  },
  CategoryToParentCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Category!' }
  },
  CategoryToPostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CategoryToPostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'CategoryToPostConnectionPageInfo!' }
  },
  CategoryToPostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  CategoryToPostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CategoryToPostConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'CategoryToPostConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  CategoryToPostConnectionWhereArgsTaxArray: {
    field: { __type: 'CategoryToPostConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'CategoryToPostConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  CategoryToPostConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[CategoryToPostConnectionWhereArgsTaxArray]' }
  },
  CategoryToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  Comment: {
    __typename: { __type: 'String!' },
    agent: { __type: 'String' },
    approved: { __type: 'Boolean' },
    author: { __type: 'CommentToCommenterConnectionEdge' },
    authorIp: { __type: 'String' },
    commentId: { __type: 'Int' },
    commentedOn: { __type: 'CommentToContentNodeConnectionEdge' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    karma: { __type: 'Int' },
    parent: {
      __type: 'CommentToParentCommentConnectionEdge',
      __args: { where: 'CommentToParentCommentConnectionWhereArgs' }
    },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    replies: {
      __type: 'CommentToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CommentToCommentConnectionWhereArgs'
      }
    },
    status: { __type: 'CommentStatusEnum' },
    type: { __type: 'String' }
  },
  CommentAuthor: {
    __typename: { __type: 'String!' },
    avatar: {
      __type: 'Avatar',
      __args: { forceDefault: 'Boolean', rating: 'AvatarRatingEnum', size: 'Int' }
    },
    databaseId: { __type: 'Int!' },
    email: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    name: { __type: 'String' },
    url: { __type: 'String' }
  },
  CommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'CommentConnectionPageInfo!' },
    $on: { __type: '$CommentConnection!' }
  },
  CommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' },
    $on: { __type: '$CommentConnectionEdge!' }
  },
  CommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$CommentConnectionPageInfo!' }
  },
  CommentToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CommentToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'CommentToCommentConnectionPageInfo!' }
  },
  CommentToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  CommentToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CommentToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  CommentToCommenterConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Commenter!' }
  },
  CommentToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  CommentToParentCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  CommentToParentCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  Commenter: {
    __typename: { __type: 'String!' },
    avatar: { __type: 'Avatar' },
    databaseId: { __type: 'Int!' },
    email: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    name: { __type: 'String' },
    url: { __type: 'String' },
    $on: { __type: '$Commenter!' }
  },
  CommenterConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Commenter!' },
    $on: { __type: '$CommenterConnectionEdge!' }
  },
  Company: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs'
      }
    },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    children: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs'
      }
    },
    companies: { __type: 'Company_Companies' },
    companyCategories: {
      __type: 'CompanyToCompanyCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CompanyToCompanyCategoryConnectionWhereArgs'
      }
    },
    companyId: { __type: 'Int!' },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    excerpt: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    featuredImage: { __type: 'NodeWithFeaturedImageToMediaItemConnectionEdge' },
    featuredImageDatabaseId: { __type: 'Int' },
    featuredImageId: { __type: 'ID' },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    parent: { __type: 'HierarchicalContentNodeToParentContentNodeConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    preview: { __type: 'CompanyToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    terms: {
      __type: 'CompanyToTermNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CompanyToTermNodeConnectionWhereArgs'
      }
    },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  CompanyCategory: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'CompanyCategoryToAncestorsCompanyCategoryConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    children: {
      __type: 'CompanyCategoryToCompanyCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CompanyCategoryToCompanyCategoryConnectionWhereArgs'
      }
    },
    companies: {
      __type: 'CompanyCategoryToCompanyConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CompanyCategoryToCompanyConnectionWhereArgs'
      }
    },
    companyCategoryId: { __type: 'Int' },
    conditionalTags: { __type: 'ConditionalTags' },
    contentNodes: {
      __type: 'CompanyCategoryToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'CompanyCategoryToContentNodeConnectionWhereArgs'
      }
    },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    parent: { __type: 'CompanyCategoryToParentCompanyCategoryConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    seo: { __type: 'TaxonomySEO' },
    slug: { __type: 'String' },
    taxonomy: { __type: 'CompanyCategoryToTaxonomyConnectionEdge' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' }
  },
  CompanyCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyCategoryConnectionEdge!]!' },
    nodes: { __type: '[CompanyCategory!]!' },
    pageInfo: { __type: 'CompanyCategoryConnectionPageInfo!' },
    $on: { __type: '$CompanyCategoryConnection!' }
  },
  CompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'CompanyCategory!' },
    $on: { __type: '$CompanyCategoryConnectionEdge!' }
  },
  CompanyCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$CompanyCategoryConnectionPageInfo!' }
  },
  CompanyCategoryToAncestorsCompanyCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyCategoryToAncestorsCompanyCategoryConnectionEdge!]!' },
    nodes: { __type: '[CompanyCategory!]!' },
    pageInfo: { __type: 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo!' }
  },
  CompanyCategoryToAncestorsCompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'CompanyCategory!' }
  },
  CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyCategoryToCompanyCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyCategoryToCompanyCategoryConnectionEdge!]!' },
    nodes: { __type: '[CompanyCategory!]!' },
    pageInfo: { __type: 'CompanyCategoryToCompanyCategoryConnectionPageInfo!' }
  },
  CompanyCategoryToCompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'CompanyCategory!' }
  },
  CompanyCategoryToCompanyCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyCategoryToCompanyCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  CompanyCategoryToCompanyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyCategoryToCompanyConnectionEdge!]!' },
    nodes: { __type: '[Company!]!' },
    pageInfo: { __type: 'CompanyCategoryToCompanyConnectionPageInfo!' }
  },
  CompanyCategoryToCompanyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Company!' }
  },
  CompanyCategoryToCompanyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyCategoryToCompanyConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'CompanyCategoryToCompanyConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  CompanyCategoryToCompanyConnectionWhereArgsTaxArray: {
    field: { __type: 'CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  CompanyCategoryToCompanyConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[CompanyCategoryToCompanyConnectionWhereArgsTaxArray]' }
  },
  CompanyCategoryToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyCategoryToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'CompanyCategoryToContentNodeConnectionPageInfo!' }
  },
  CompanyCategoryToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  CompanyCategoryToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyCategoryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypesOfCompanyCategoryEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'CompanyCategoryToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  CompanyCategoryToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  CompanyCategoryToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[CompanyCategoryToContentNodeConnectionWhereArgsTaxArray]' }
  },
  CompanyCategoryToParentCompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'CompanyCategory!' }
  },
  CompanyCategoryToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  CompanyCompanyCategoriesInput: {
    append: { __type: 'Boolean' },
    nodes: { __type: '[CompanyCompanyCategoriesNodeInput]' }
  },
  CompanyCompanyCategoriesNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  CompanyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyConnectionEdge!]!' },
    nodes: { __type: '[Company!]!' },
    pageInfo: { __type: 'CompanyConnectionPageInfo!' },
    $on: { __type: '$CompanyConnection!' }
  },
  CompanyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Company!' },
    $on: { __type: '$CompanyConnectionEdge!' }
  },
  CompanyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$CompanyConnectionPageInfo!' }
  },
  CompanyToCompanyCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyToCompanyCategoryConnectionEdge!]!' },
    nodes: { __type: '[CompanyCategory!]!' },
    pageInfo: { __type: 'CompanyToCompanyCategoryConnectionPageInfo!' }
  },
  CompanyToCompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'CompanyCategory!' }
  },
  CompanyToCompanyCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyToCompanyCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  CompanyToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Company!' }
  },
  CompanyToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[CompanyToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'CompanyToTermNodeConnectionPageInfo!' }
  },
  CompanyToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  CompanyToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CompanyToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    taxonomies: { __type: '[TaxonomyEnum]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  Company_Companies: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    info: { __type: '[Company_Companies_info]' },
    linkedinUrl: { __type: 'String' },
    listingImage: { __type: 'MediaItem' },
    logo: { __type: 'MediaItem' },
    partner: { __type: '[Company_Companies_Partner]' },
    status: { __type: '[String]' },
    stockTicker: { __type: 'String' },
    twitterUrl: { __type: 'String' },
    websiteUrl: { __type: 'String' }
  },
  Company_Companies_Partner: {
    __typename: { __type: 'String!' },
    $on: { __type: '$Company_Companies_Partner!' }
  },
  Company_Companies_info: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    text: { __type: 'String' },
    title: { __type: 'String' }
  },
  ConditionalTags: {
    __typename: { __type: 'String!' },
    isArchive: { __type: 'Boolean' },
    isAttachment: { __type: 'Boolean' },
    isAuthor: { __type: 'Boolean' },
    isCategory: { __type: 'Boolean' },
    isDate: { __type: 'Boolean' },
    isDay: { __type: 'Boolean' },
    isFrontPage: { __type: 'Boolean' },
    isHome: { __type: 'Boolean' },
    isMonth: { __type: 'Boolean' },
    isMultiAuthor: { __type: 'Boolean' },
    isPage: { __type: 'Boolean' },
    isPageTemplate: { __type: 'Boolean' },
    isPostTypeArchive: { __type: 'Boolean' },
    isPreview: { __type: 'Boolean' },
    isPrivacyPolicy: { __type: 'Boolean' },
    isSearch: { __type: 'Boolean' },
    isSingle: { __type: 'Boolean' },
    isSingular: { __type: 'Boolean' },
    isSticky: { __type: 'Boolean' },
    isTag: { __type: 'Boolean' },
    isTax: { __type: 'Boolean' },
    isYear: { __type: 'Boolean' }
  },
  Connection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[Edge!]!' },
    nodes: { __type: '[Node!]!' },
    pageInfo: { __type: 'PageInfo!' },
    $on: { __type: '$Connection!' }
  },
  ContentNode: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' },
    $on: { __type: '$ContentNode!' }
  },
  ContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'ContentNodeConnectionPageInfo!' },
    $on: { __type: '$ContentNodeConnection!' }
  },
  ContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' },
    $on: { __type: '$ContentNodeConnectionEdge!' }
  },
  ContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$ContentNodeConnectionPageInfo!' }
  },
  ContentNodeToContentTypeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentType!' }
  },
  ContentNodeToEditLastConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'User!' }
  },
  ContentNodeToEditLockConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    lockTimestamp: { __type: 'String' },
    node: { __type: 'User!' }
  },
  ContentNodeToEnqueuedScriptConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentNodeToEnqueuedScriptConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedScript!]!' },
    pageInfo: { __type: 'ContentNodeToEnqueuedScriptConnectionPageInfo!' }
  },
  ContentNodeToEnqueuedScriptConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedScript!' }
  },
  ContentNodeToEnqueuedScriptConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  ContentNodeToEnqueuedStylesheetConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentNodeToEnqueuedStylesheetConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedStylesheet!]!' },
    pageInfo: { __type: 'ContentNodeToEnqueuedStylesheetConnectionPageInfo!' }
  },
  ContentNodeToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedStylesheet!' }
  },
  ContentNodeToEnqueuedStylesheetConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  ContentTemplate: {
    __typename: { __type: 'String!' },
    templateName: { __type: 'String' },
    $on: { __type: '$ContentTemplate!' }
  },
  ContentType: {
    __typename: { __type: 'String!' },
    canExport: { __type: 'Boolean' },
    conditionalTags: { __type: 'ConditionalTags' },
    connectedTaxonomies: {
      __type: 'ContentTypeToTaxonomyConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    contentNodes: {
      __type: 'ContentTypeToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'ContentTypeToContentNodeConnectionWhereArgs'
      }
    },
    deleteWithUser: { __type: 'Boolean' },
    description: { __type: 'String' },
    excludeFromSearch: { __type: 'Boolean' },
    graphqlPluralName: { __type: 'String' },
    graphqlSingleName: { __type: 'String' },
    hasArchive: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isFrontPage: { __type: 'Boolean!' },
    isPostsPage: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    label: { __type: 'String' },
    labels: { __type: 'PostTypeLabelDetails' },
    menuIcon: { __type: 'String' },
    menuPosition: { __type: 'Int' },
    name: { __type: 'String' },
    public: { __type: 'Boolean' },
    publiclyQueryable: { __type: 'Boolean' },
    restBase: { __type: 'String' },
    restControllerClass: { __type: 'String' },
    showInAdminBar: { __type: 'Boolean' },
    showInGraphql: { __type: 'Boolean' },
    showInMenu: { __type: 'Boolean' },
    showInNavMenus: { __type: 'Boolean' },
    showInRest: { __type: 'Boolean' },
    showUi: { __type: 'Boolean' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' }
  },
  ContentTypeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentTypeConnectionEdge!]!' },
    nodes: { __type: '[ContentType!]!' },
    pageInfo: { __type: 'ContentTypeConnectionPageInfo!' },
    $on: { __type: '$ContentTypeConnection!' }
  },
  ContentTypeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentType!' },
    $on: { __type: '$ContentTypeConnectionEdge!' }
  },
  ContentTypeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$ContentTypeConnectionPageInfo!' }
  },
  ContentTypeToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentTypeToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'ContentTypeToContentNodeConnectionPageInfo!' }
  },
  ContentTypeToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  ContentTypeToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  ContentTypeToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'ContentTypeToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  ContentTypeToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'ContentTypeToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  ContentTypeToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[ContentTypeToContentNodeConnectionWhereArgsTaxArray]' }
  },
  ContentTypeToTaxonomyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ContentTypeToTaxonomyConnectionEdge!]!' },
    nodes: { __type: '[Taxonomy!]!' },
    pageInfo: { __type: 'ContentTypeToTaxonomyConnectionPageInfo!' }
  },
  ContentTypeToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  ContentTypeToTaxonomyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  CoreArchivesBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreArchivesBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreArchivesBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    displayAsDropdown: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showLabel: { __type: 'Boolean!' },
    showPostCounts: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    type: { __type: 'String!' }
  },
  CoreAudioBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreAudioBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreAudioBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    autoplay: { __type: 'Boolean' },
    className: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    loop: { __type: 'Boolean' },
    metadata: { __type: 'BlockAttributesObject' },
    preload: { __type: 'String' },
    src: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreAudioBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreAudioBlockAttributesUnion!' }
  },
  CoreAudioBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    autoplay: { __type: 'Boolean' },
    className: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    loop: { __type: 'Boolean' },
    metadata: { __type: 'BlockAttributesObject' },
    preload: { __type: 'String' },
    src: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreAvatarBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreAvatarBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreAvatarBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    size: { __type: 'Float!' },
    style: { __type: 'BlockAttributesObject' },
    userId: { __type: 'Float' }
  },
  CoreBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    reusableBlock: { __type: 'Node!' },
    saveContent: { __type: 'String' }
  },
  CoreBlockAttributes: {
    __typename: { __type: 'String!' },
    content: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ref: { __type: 'Float' }
  },
  CoreBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreBlockAttributesUnion!' }
  },
  CoreBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    content: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ref: { __type: 'Float' }
  },
  CoreBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    content: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ref: { __type: 'Float' }
  },
  CoreButtonBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreButtonBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreButtonBlockAttributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreButtonBlockAttributesUnion!' }
  },
  CoreButtonBlockDeprecatedV10Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV11Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV7Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV8Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonBlockDeprecatedV9Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String!' },
    url: { __type: 'String' },
    width: { __type: 'Float' }
  },
  CoreButtonsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreButtonsBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreButtonsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreButtonsBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreButtonsBlockAttributesUnion!' }
  },
  CoreButtonsBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreButtonsBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreCalendarBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCalendarBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCalendarBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    month: { __type: 'Int' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    year: { __type: 'Int' }
  },
  CoreCategoriesBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCategoriesBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCategoriesBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    displayAsDropdown: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showEmpty: { __type: 'Boolean!' },
    showHierarchy: { __type: 'Boolean!' },
    showOnlyTopLevel: { __type: 'Boolean!' },
    showPostCounts: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreCodeBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCodeBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCodeBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreColumnBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreColumnBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreColumnBlockAttributes: {
    __typename: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreColumnBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreColumnBlockAttributesUnion!' }
  },
  CoreColumnBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreColumnsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreColumnsBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreColumnsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isStackedOnMobile: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreColumnsBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreColumnsBlockAttributesUnion!' }
  },
  CoreColumnsBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isStackedOnMobile: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreColumnsBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isStackedOnMobile: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreColumnsBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isStackedOnMobile: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreCommentAuthorNameBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentAuthorNameBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentAuthorNameBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentAuthorNameBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreCommentAuthorNameBlockAttributesUnion!' }
  },
  CoreCommentAuthorNameBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentContentBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentContentBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentContentBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentDateBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentDateBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentDateBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    format: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentDateBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreCommentDateBlockAttributesUnion!' }
  },
  CoreCommentDateBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    format: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentEditLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentEditLinkBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentEditLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentReplyLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentReplyLinkBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentReplyLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentTemplateBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentTemplateBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentTemplateBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreCommentsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    legacy: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreCommentsBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreCommentsBlockAttributesUnion!' }
  },
  CoreCommentsBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    legacy: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreCommentsPaginationBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsPaginationBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsPaginationBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    paginationArrow: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentsPaginationNextBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsPaginationNextBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsPaginationNextBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentsPaginationNumbersBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsPaginationNumbersBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsPaginationNumbersBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentsPaginationPreviousBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsPaginationPreviousBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsPaginationPreviousBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreCommentsTitleBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCommentsTitleBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCommentsTitleBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showCommentsCount: { __type: 'Boolean!' },
    showPostTitle: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCommentsTitleBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreCommentsTitleBlockAttributesUnion!' }
  },
  CoreCommentsTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showCommentsCount: { __type: 'Boolean!' },
    showPostTitle: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreCoverBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreCoverBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreCoverBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreCoverBlockAttributesUnion!' }
  },
  CoreCoverBlockDeprecatedV10Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV11Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV12Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV13Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV7Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV8Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreCoverBlockDeprecatedV9Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    backgroundType: { __type: 'String!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    contentPosition: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    hasParallax: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    isDark: { __type: 'Boolean!' },
    isRepeated: { __type: 'Boolean!' },
    isUserOverlayColor: { __type: 'Boolean' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minHeight: { __type: 'Float' },
    minHeightUnit: { __type: 'String' },
    overlayColor: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' },
    url: { __type: 'String' },
    useFeaturedImage: { __type: 'Boolean!' }
  },
  CoreDetailsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreDetailsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreDetailsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showContent: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreEmbedBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreEmbedBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreEmbedBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResponsive: { __type: 'Boolean!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewable: { __type: 'Boolean!' },
    providerNameSlug: { __type: 'String' },
    responsive: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreEmbedBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreEmbedBlockAttributesUnion!' }
  },
  CoreEmbedBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResponsive: { __type: 'Boolean!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewable: { __type: 'Boolean!' },
    providerNameSlug: { __type: 'String' },
    responsive: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreEmbedBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResponsive: { __type: 'Boolean!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewable: { __type: 'Boolean!' },
    providerNameSlug: { __type: 'String' },
    responsive: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreFileBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreFileBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreFileBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayPreview: { __type: 'Boolean' },
    fileId: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewHeight: { __type: 'Float!' },
    showDownloadButton: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    textLinkHref: { __type: 'String' },
    textLinkTarget: { __type: 'String' }
  },
  CoreFileBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreFileBlockAttributesUnion!' }
  },
  CoreFileBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayPreview: { __type: 'Boolean' },
    fileId: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewHeight: { __type: 'Float!' },
    showDownloadButton: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    textLinkHref: { __type: 'String' },
    textLinkTarget: { __type: 'String' }
  },
  CoreFileBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayPreview: { __type: 'Boolean' },
    fileId: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewHeight: { __type: 'Float!' },
    showDownloadButton: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    textLinkHref: { __type: 'String' },
    textLinkTarget: { __type: 'String' }
  },
  CoreFileBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayPreview: { __type: 'Boolean' },
    fileId: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    previewHeight: { __type: 'Float!' },
    showDownloadButton: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    textLinkHref: { __type: 'String' },
    textLinkTarget: { __type: 'String' }
  },
  CoreFootnotesBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreFootnotesBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreFootnotesBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreFreeformBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreFreeformBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreFreeformBlockAttributes: {
    __typename: { __type: 'String!' },
    content: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  CoreGalleryBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreGalleryBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreGalleryBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockAttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockAttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreGalleryBlockAttributesUnion!' }
  },
  CoreGalleryBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV1AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV1AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV2AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV2AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV3AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV3AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV4AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV4AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV5AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV5AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV6AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV6AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV7Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowResize: { __type: 'Boolean!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    columns: { __type: 'Float' },
    fixedHeight: { __type: 'Boolean!' },
    gradient: { __type: 'String' },
    ids: { __type: '[Float]!' },
    imageCrop: { __type: 'Boolean!' },
    images: { __type: '[CoreGalleryBlockDeprecatedV7AttributesImages]!' },
    layout: { __type: 'BlockAttributesObject' },
    linkTarget: { __type: 'String' },
    linkTo: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    randomOrder: { __type: 'Boolean!' },
    shortCodeTransforms: { __type: '[BlockAttributesObject]!' },
    sizeSlug: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreGalleryBlockDeprecatedV7AttributesImages: {
    __typename: { __type: 'String!' },
    alt: { __type: 'String!' },
    fullUrl: { __type: 'String' },
    id: { __type: 'String' },
    link: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreGroupBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreGroupBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreGroupBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreGroupBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreGroupBlockAttributesUnion!' }
  },
  CoreGroupBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreGroupBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreGroupBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreGroupBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreGroupBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    settings: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    tagName: { __type: 'String!' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreHeadingBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreHeadingBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreHeadingBlockAttributesUnion!' }
  },
  CoreHeadingBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHeadingBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreHomeLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreHomeLinkBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreHomeLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreHtmlBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreHtmlBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreHtmlBlockAttributes: {
    __typename: { __type: 'String!' },
    content: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  CoreImageBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreImageBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    mediaItem: { __type: 'CoreImageBlockToMediaItemConnectionEdge' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreImageBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreImageBlockAttributesUnion!' }
  },
  CoreImageBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV7Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockDeprecatedV8Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    alt: { __type: 'String!' },
    anchor: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String' },
    href: { __type: 'String' },
    id: { __type: 'Float' },
    lightbox: { __type: 'BlockAttributesObject' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    scale: { __type: 'String' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    url: { __type: 'String' },
    width: { __type: 'String' }
  },
  CoreImageBlockToMediaItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MediaItem!' }
  },
  CoreLatestCommentsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreLatestCommentsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreLatestCommentsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    commentsToShow: { __type: 'Float!' },
    displayAvatar: { __type: 'Boolean!' },
    displayDate: { __type: 'Boolean!' },
    displayExcerpt: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreLatestPostsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreLatestPostsBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreLatestPostsBlockAttributes: {
    __typename: { __type: 'String!' },
    addLinkToFeaturedImage: { __type: 'Boolean!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    categories: { __type: '[BlockAttributesObject]' },
    className: { __type: 'String' },
    columns: { __type: 'Float!' },
    displayAuthor: { __type: 'Boolean!' },
    displayFeaturedImage: { __type: 'Boolean!' },
    displayPostContent: { __type: 'Boolean!' },
    displayPostContentRadio: { __type: 'String!' },
    displayPostDate: { __type: 'Boolean!' },
    excerptLength: { __type: 'Float!' },
    featuredImageAlign: { __type: 'String' },
    featuredImageSizeHeight: { __type: 'Float' },
    featuredImageSizeSlug: { __type: 'String!' },
    featuredImageSizeWidth: { __type: 'Float' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    order: { __type: 'String!' },
    orderBy: { __type: 'String!' },
    postLayout: { __type: 'String!' },
    postsToShow: { __type: 'Float!' },
    selectedAuthor: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreLatestPostsBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreLatestPostsBlockAttributesUnion!' }
  },
  CoreLatestPostsBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    addLinkToFeaturedImage: { __type: 'Boolean!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    categories: { __type: '[BlockAttributesObject]' },
    className: { __type: 'String' },
    columns: { __type: 'Float!' },
    displayAuthor: { __type: 'Boolean!' },
    displayFeaturedImage: { __type: 'Boolean!' },
    displayPostContent: { __type: 'Boolean!' },
    displayPostContentRadio: { __type: 'String!' },
    displayPostDate: { __type: 'Boolean!' },
    excerptLength: { __type: 'Float!' },
    featuredImageAlign: { __type: 'String' },
    featuredImageSizeHeight: { __type: 'Float' },
    featuredImageSizeSlug: { __type: 'String!' },
    featuredImageSizeWidth: { __type: 'Float' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    order: { __type: 'String!' },
    orderBy: { __type: 'String!' },
    postLayout: { __type: 'String!' },
    postsToShow: { __type: 'Float!' },
    selectedAuthor: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreLegacyWidgetBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreLegacyWidgetBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreLegacyWidgetBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    id: { __type: 'String' },
    idBase: { __type: 'String' },
    instance: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  CoreListBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreListBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreListBlockAttributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ordered: { __type: 'Boolean!' },
    placeholder: { __type: 'String' },
    reversed: { __type: 'Boolean' },
    start: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    type: { __type: 'String' },
    values: { __type: 'String!' }
  },
  CoreListBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreListBlockAttributesUnion!' }
  },
  CoreListBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ordered: { __type: 'Boolean!' },
    placeholder: { __type: 'String' },
    reversed: { __type: 'Boolean' },
    start: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    type: { __type: 'String' },
    values: { __type: 'String!' }
  },
  CoreListBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ordered: { __type: 'Boolean!' },
    placeholder: { __type: 'String' },
    reversed: { __type: 'Boolean' },
    start: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    type: { __type: 'String' },
    values: { __type: 'String!' }
  },
  CoreListBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    ordered: { __type: 'Boolean!' },
    placeholder: { __type: 'String' },
    reversed: { __type: 'Boolean' },
    start: { __type: 'Float' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    type: { __type: 'String' },
    values: { __type: 'String!' }
  },
  CoreListItemBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreListItemBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreListItemBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreLoginoutBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreLoginoutBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreLoginoutBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    displayLoginAsForm: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    redirectToCurrent: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' }
  },
  CoreMediaTextBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreMediaTextBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreMediaTextBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreMediaTextBlockAttributesUnion!' }
  },
  CoreMediaTextBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMediaTextBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String!' },
    allowedBlocks: { __type: 'BlockAttributesArray' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    focalPoint: { __type: 'BlockAttributesObject' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    href: { __type: 'String' },
    imageFill: { __type: 'Boolean' },
    isStackedOnMobile: { __type: 'Boolean!' },
    linkClass: { __type: 'String' },
    linkDestination: { __type: 'String' },
    linkTarget: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    mediaAlt: { __type: 'String!' },
    mediaId: { __type: 'Float' },
    mediaLink: { __type: 'String' },
    mediaPosition: { __type: 'String!' },
    mediaSizeSlug: { __type: 'String' },
    mediaType: { __type: 'String' },
    mediaUrl: { __type: 'String' },
    mediaWidth: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    verticalAlignment: { __type: 'String' }
  },
  CoreMissingBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreMissingBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreMissingBlockAttributes: {
    __typename: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    originalContent: { __type: 'String' },
    originalName: { __type: 'String' },
    originalUndelimitedContent: { __type: 'String' }
  },
  CoreMoreBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreMoreBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreMoreBlockAttributes: {
    __typename: { __type: 'String!' },
    customText: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    noTeaser: { __type: 'Boolean!' }
  },
  CoreNavigationBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreNavigationBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreNavigationBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreNavigationBlockAttributesUnion!' }
  },
  CoreNavigationBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    ariaLabel: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customBackgroundColor: { __type: 'String' },
    customOverlayBackgroundColor: { __type: 'String' },
    customOverlayTextColor: { __type: 'String' },
    customTextColor: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    hasIcon: { __type: 'Boolean!' },
    icon: { __type: 'String!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    maxNestingLevel: { __type: 'Float!' },
    metadata: { __type: 'BlockAttributesObject' },
    openSubmenusOnClick: { __type: 'Boolean!' },
    overlayBackgroundColor: { __type: 'String' },
    overlayMenu: { __type: 'String!' },
    overlayTextColor: { __type: 'String' },
    ref: { __type: 'Float' },
    rgbBackgroundColor: { __type: 'String' },
    rgbTextColor: { __type: 'String' },
    showSubmenuIcon: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    unstableLocation: { __type: 'String' }
  },
  CoreNavigationLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreNavigationLinkBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreNavigationLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    description: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    id: { __type: 'Float' },
    isTopLevelLink: { __type: 'Boolean' },
    kind: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    opensInNewTab: { __type: 'Boolean!' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreNavigationLinkBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreNavigationLinkBlockAttributesUnion!' }
  },
  CoreNavigationLinkBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    description: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    id: { __type: 'Float' },
    isTopLevelLink: { __type: 'Boolean' },
    kind: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    opensInNewTab: { __type: 'Boolean!' },
    rel: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreNavigationSubmenuBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreNavigationSubmenuBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreNavigationSubmenuBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'Float' },
    isTopLevelItem: { __type: 'Boolean' },
    kind: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    opensInNewTab: { __type: 'Boolean!' },
    rel: { __type: 'String' },
    title: { __type: 'String' },
    type: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreNextpageBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreNextpageBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreNextpageBlockAttributes: {
    __typename: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  CorePageListBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePageListBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePageListBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    isNested: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    parentPageID: { __type: 'Int!' },
    style: { __type: 'BlockAttributesObject' }
  },
  CorePageListItemBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePageListItemBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePageListItemBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    hasChildren: { __type: 'Boolean' },
    id: { __type: 'Float' },
    label: { __type: 'String' },
    link: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' }
  },
  CoreParagraphBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreParagraphBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreParagraphBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreParagraphBlockAttributesUnion!' }
  },
  CoreParagraphBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreParagraphBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    direction: { __type: 'String' },
    dropCap: { __type: 'Boolean!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CorePatternBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePatternBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePatternBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    slug: { __type: 'String' }
  },
  CorePostAuthorBiographyBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostAuthorBiographyBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostAuthorBiographyBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostAuthorBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostAuthorBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostAuthorBlockAttributes: {
    __typename: { __type: 'String!' },
    avatarSize: { __type: 'Float!' },
    backgroundColor: { __type: 'String' },
    byline: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showAvatar: { __type: 'Boolean!' },
    showBio: { __type: 'Boolean' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostAuthorNameBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostAuthorNameBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostAuthorNameBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostCommentsFormBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostCommentsFormBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostCommentsFormBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostContentBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostContentBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostContentBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CorePostDateBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostDateBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostDateBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayType: { __type: 'String!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    format: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostDateBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CorePostDateBlockAttributesUnion!' }
  },
  CorePostDateBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    displayType: { __type: 'String!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    format: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostExcerptBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostExcerptBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostExcerptBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    excerptLength: { __type: 'Float!' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    moreText: { __type: 'String' },
    showMoreOnNewLine: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostFeaturedImageBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostFeaturedImageBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostFeaturedImageBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    aspectRatio: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    customGradient: { __type: 'String' },
    customOverlayColor: { __type: 'String' },
    dimRatio: { __type: 'Float!' },
    gradient: { __type: 'String' },
    height: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    overlayColor: { __type: 'String' },
    rel: { __type: 'String!' },
    scale: { __type: 'String!' },
    sizeSlug: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    useFirstImageFromPost: { __type: 'Boolean!' },
    width: { __type: 'String' }
  },
  CorePostNavigationLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostNavigationLinkBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostNavigationLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    arrow: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    label: { __type: 'String' },
    linkLabel: { __type: 'Boolean!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showTitle: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    taxonomy: { __type: 'String!' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    type: { __type: 'String!' }
  },
  CorePostTemplateBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostTemplateBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostTemplateBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CorePostTermsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostTermsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostTermsBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    prefix: { __type: 'String!' },
    separator: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    suffix: { __type: 'String!' },
    term: { __type: 'String' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostTitleBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePostTitleBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePostTitleBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    level: { __type: 'Float!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePostTitleBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CorePostTitleBlockAttributesUnion!' }
  },
  CorePostTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    level: { __type: 'Float!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePreformattedBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePreformattedBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePreformattedBlockAttributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CorePullquoteBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CorePullquoteBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CorePullquoteBlockAttributesUnion!' }
  },
  CorePullquoteBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CorePullquoteBlockDeprecatedV6Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreQueryBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreQueryBlockAttributesUnion!' }
  },
  CoreQueryBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryBlockDeprecatedV5Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    enhancedPagination: { __type: 'Boolean!' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    namespace: { __type: 'String' },
    query: { __type: 'BlockAttributesObject!' },
    queryId: { __type: 'Float' },
    tagName: { __type: 'String!' }
  },
  CoreQueryNoResultsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryNoResultsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryNoResultsBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryPaginationBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryPaginationBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryPaginationBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    paginationArrow: { __type: 'String!' },
    showLabel: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryPaginationBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreQueryPaginationBlockAttributesUnion!' }
  },
  CoreQueryPaginationBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    paginationArrow: { __type: 'String!' },
    showLabel: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryPaginationNextBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryPaginationNextBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryPaginationNextBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryPaginationNumbersBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryPaginationNumbersBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryPaginationNumbersBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    midSize: { __type: 'Float!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryPaginationPreviousBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryPaginationPreviousBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryPaginationPreviousBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreQueryTitleBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQueryTitleBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQueryTitleBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showPrefix: { __type: 'Boolean!' },
    showSearchTerm: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    type: { __type: 'String' }
  },
  CoreQueryTitleBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreQueryTitleBlockAttributesUnion!' }
  },
  CoreQueryTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    level: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    showPrefix: { __type: 'Boolean!' },
    showSearchTerm: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' },
    type: { __type: 'String' }
  },
  CoreQuoteBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreQuoteBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreQuoteBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    value: { __type: 'String!' }
  },
  CoreQuoteBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreQuoteBlockAttributesUnion!' }
  },
  CoreQuoteBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    value: { __type: 'String!' }
  },
  CoreQuoteBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    value: { __type: 'String!' }
  },
  CoreQuoteBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    value: { __type: 'String!' }
  },
  CoreQuoteBlockDeprecatedV4Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    value: { __type: 'String!' }
  },
  CoreReadMoreBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreReadMoreBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreReadMoreBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    content: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreRssBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreRssBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreRssBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    blockLayout: { __type: 'String!' },
    className: { __type: 'String' },
    columns: { __type: 'Float!' },
    displayAuthor: { __type: 'Boolean!' },
    displayDate: { __type: 'Boolean!' },
    displayExcerpt: { __type: 'Boolean!' },
    excerptLength: { __type: 'Float!' },
    feedURL: { __type: 'String!' },
    itemsToShow: { __type: 'Float!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  CoreSearchBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSearchBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSearchBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    buttonPosition: { __type: 'String!' },
    buttonText: { __type: 'String' },
    buttonUseIcon: { __type: 'Boolean!' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isSearchFieldHidden: { __type: 'Boolean!' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    placeholder: { __type: 'String!' },
    query: { __type: 'BlockAttributesObject!' },
    showLabel: { __type: 'Boolean!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' },
    width: { __type: 'Float' },
    widthUnit: { __type: 'String' }
  },
  CoreSeparatorBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSeparatorBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSeparatorBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    opacity: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreSeparatorBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreSeparatorBlockAttributesUnion!' }
  },
  CoreSeparatorBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    opacity: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreShortcodeBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreShortcodeBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreShortcodeBlockAttributes: {
    __typename: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    text: { __type: 'String' }
  },
  CoreSiteLogoBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSiteLogoBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSiteLogoBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    shouldSyncIcon: { __type: 'Boolean' },
    style: { __type: 'BlockAttributesObject' },
    width: { __type: 'Float' }
  },
  CoreSiteTaglineBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSiteTaglineBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSiteTaglineBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreSiteTaglineBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreSiteTaglineBlockAttributesUnion!' }
  },
  CoreSiteTaglineBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreSiteTitleBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSiteTitleBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSiteTitleBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    level: { __type: 'Float!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreSiteTitleBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreSiteTitleBlockAttributesUnion!' }
  },
  CoreSiteTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    isLink: { __type: 'Boolean!' },
    level: { __type: 'Float!' },
    linkTarget: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreSocialLinkBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSocialLinkBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSocialLinkBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    label: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    rel: { __type: 'String' },
    service: { __type: 'String' },
    url: { __type: 'String' }
  },
  CoreSocialLinksBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSocialLinksBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSocialLinksBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customIconBackgroundColor: { __type: 'String' },
    customIconColor: { __type: 'String' },
    gradient: { __type: 'String' },
    iconBackgroundColor: { __type: 'String' },
    iconBackgroundColorValue: { __type: 'String' },
    iconColor: { __type: 'String' },
    iconColorValue: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    openInNewTab: { __type: 'Boolean!' },
    showLabels: { __type: 'Boolean!' },
    size: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreSocialLinksBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreSocialLinksBlockAttributesUnion!' }
  },
  CoreSocialLinksBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    customIconBackgroundColor: { __type: 'String' },
    customIconColor: { __type: 'String' },
    gradient: { __type: 'String' },
    iconBackgroundColor: { __type: 'String' },
    iconBackgroundColorValue: { __type: 'String' },
    iconColor: { __type: 'String' },
    iconColorValue: { __type: 'String' },
    layout: { __type: 'BlockAttributesObject' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    openInNewTab: { __type: 'Boolean!' },
    showLabels: { __type: 'Boolean!' },
    size: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreSpacerBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreSpacerBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreSpacerBlockAttributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    width: { __type: 'String' }
  },
  CoreSpacerBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreSpacerBlockAttributesUnion!' }
  },
  CoreSpacerBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    className: { __type: 'String' },
    height: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    width: { __type: 'String' }
  },
  CoreTableBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreTableBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreTableBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    body: { __type: '[CoreTableBlockAttributesBody]!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    foot: { __type: '[CoreTableBlockAttributesFoot]!' },
    gradient: { __type: 'String' },
    hasFixedLayout: { __type: 'Boolean!' },
    head: { __type: '[CoreTableBlockAttributesHead]!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreTableBlockAttributesBody: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockAttributesBodyCells]!' }
  },
  CoreTableBlockAttributesBodyCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockAttributesFoot: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockAttributesFootCells]!' }
  },
  CoreTableBlockAttributesFootCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockAttributesHead: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockAttributesHeadCells]!' }
  },
  CoreTableBlockAttributesHeadCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreTableBlockAttributesUnion!' }
  },
  CoreTableBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    body: { __type: '[CoreTableBlockDeprecatedV1AttributesBody]!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    foot: { __type: '[CoreTableBlockDeprecatedV1AttributesFoot]!' },
    gradient: { __type: 'String' },
    hasFixedLayout: { __type: 'Boolean!' },
    head: { __type: '[CoreTableBlockDeprecatedV1AttributesHead]!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreTableBlockDeprecatedV1AttributesBody: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV1AttributesBodyCells]!' }
  },
  CoreTableBlockDeprecatedV1AttributesBodyCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV1AttributesFoot: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV1AttributesFootCells]!' }
  },
  CoreTableBlockDeprecatedV1AttributesFootCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV1AttributesHead: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV1AttributesHeadCells]!' }
  },
  CoreTableBlockDeprecatedV1AttributesHeadCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    body: { __type: '[CoreTableBlockDeprecatedV2AttributesBody]!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    foot: { __type: '[CoreTableBlockDeprecatedV2AttributesFoot]!' },
    gradient: { __type: 'String' },
    hasFixedLayout: { __type: 'Boolean!' },
    head: { __type: '[CoreTableBlockDeprecatedV2AttributesHead]!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreTableBlockDeprecatedV2AttributesBody: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV2AttributesBodyCells]!' }
  },
  CoreTableBlockDeprecatedV2AttributesBodyCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV2AttributesFoot: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV2AttributesFootCells]!' }
  },
  CoreTableBlockDeprecatedV2AttributesFootCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV2AttributesHead: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV2AttributesHeadCells]!' }
  },
  CoreTableBlockDeprecatedV2AttributesHeadCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV3Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    body: { __type: '[CoreTableBlockDeprecatedV3AttributesBody]!' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    foot: { __type: '[CoreTableBlockDeprecatedV3AttributesFoot]!' },
    gradient: { __type: 'String' },
    hasFixedLayout: { __type: 'Boolean!' },
    head: { __type: '[CoreTableBlockDeprecatedV3AttributesHead]!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  CoreTableBlockDeprecatedV3AttributesBody: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV3AttributesBodyCells]!' }
  },
  CoreTableBlockDeprecatedV3AttributesBodyCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV3AttributesFoot: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV3AttributesFootCells]!' }
  },
  CoreTableBlockDeprecatedV3AttributesFootCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTableBlockDeprecatedV3AttributesHead: {
    __typename: { __type: 'String!' },
    cells: { __type: '[CoreTableBlockDeprecatedV3AttributesHeadCells]!' }
  },
  CoreTableBlockDeprecatedV3AttributesHeadCells: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    colspan: { __type: 'String' },
    rowspan: { __type: 'String' },
    scope: { __type: 'String' },
    tag: { __type: 'String!' }
  },
  CoreTagCloudBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreTagCloudBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreTagCloudBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    largestFontSize: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    numberOfTags: { __type: 'Float!' },
    showTagCounts: { __type: 'Boolean!' },
    smallestFontSize: { __type: 'String!' },
    style: { __type: 'BlockAttributesObject' },
    taxonomy: { __type: 'String!' }
  },
  CoreTemplatePartBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreTemplatePartBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreTemplatePartBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    area: { __type: 'String' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    slug: { __type: 'String' },
    tagName: { __type: 'String' },
    theme: { __type: 'String' }
  },
  CoreTermDescriptionBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreTermDescriptionBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreTermDescriptionBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreTextColumnsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreTextColumnsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreTextColumnsBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    columns: { __type: 'Float!' },
    content: { __type: '[CoreTextColumnsBlockAttributesContent]!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    width: { __type: 'String' }
  },
  CoreTextColumnsBlockAttributesContent: {
    __typename: { __type: 'String!' },
    children: { __type: 'String' }
  },
  CoreVerseBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreVerseBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreVerseBlockAttributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreVerseBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreVerseBlockAttributesUnion!' }
  },
  CoreVerseBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreVerseBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    anchor: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    className: { __type: 'String' },
    fontFamily: { __type: 'String' },
    fontSize: { __type: 'String' },
    gradient: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textAlign: { __type: 'String' },
    textColor: { __type: 'String' }
  },
  CoreVideoBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreVideoBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreVideoBlockAttributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    autoplay: { __type: 'Boolean' },
    className: { __type: 'String' },
    controls: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    loop: { __type: 'Boolean' },
    metadata: { __type: 'BlockAttributesObject' },
    muted: { __type: 'Boolean' },
    playsInline: { __type: 'Boolean' },
    poster: { __type: 'String' },
    preload: { __type: 'String!' },
    src: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tracks: { __type: '[BlockAttributesObject]!' }
  },
  CoreVideoBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreVideoBlockAttributesUnion!' }
  },
  CoreVideoBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    align: { __type: 'String' },
    anchor: { __type: 'String' },
    autoplay: { __type: 'Boolean' },
    className: { __type: 'String' },
    controls: { __type: 'Boolean!' },
    id: { __type: 'Float' },
    lock: { __type: 'BlockAttributesObject' },
    loop: { __type: 'Boolean' },
    metadata: { __type: 'BlockAttributesObject' },
    muted: { __type: 'Boolean' },
    playsInline: { __type: 'Boolean' },
    poster: { __type: 'String' },
    preload: { __type: 'String!' },
    src: { __type: 'String' },
    style: { __type: 'BlockAttributesObject' },
    tracks: { __type: '[BlockAttributesObject]!' }
  },
  CoreWidgetGroupBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'CoreWidgetGroupBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  CoreWidgetGroupBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' }
  },
  CoreWidgetGroupBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$CoreWidgetGroupBlockAttributesUnion!' }
  },
  CoreWidgetGroupBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' }
  },
  CreateBlockEditorPreviewInput: {
    authorId: { __type: 'ID' },
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreateBlockEditorPreviewPayload: {
    __typename: { __type: 'String!' },
    blockEditorPreview: { __type: 'BlockEditorPreview' },
    clientMutationId: { __type: 'String' }
  },
  CreateCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    name: { __type: 'String!' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  CreateCategoryPayload: {
    __typename: { __type: 'String!' },
    category: { __type: 'Category' },
    clientMutationId: { __type: 'String' }
  },
  CreateCommentInput: {
    approved: { __type: 'String' },
    author: { __type: 'String' },
    authorEmail: { __type: 'String' },
    authorUrl: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    commentOn: { __type: 'Int' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    parent: { __type: 'ID' },
    status: { __type: 'CommentStatusEnum' },
    type: { __type: 'String' }
  },
  CreateCommentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    comment: { __type: 'Comment' },
    success: { __type: 'Boolean' }
  },
  CreateCompanyCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    name: { __type: 'String!' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  CreateCompanyCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    companyCategory: { __type: 'CompanyCategory' }
  },
  CreateCompanyInput: {
    clientMutationId: { __type: 'String' },
    companyCategories: { __type: 'CompanyCompanyCategoriesInput' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    excerpt: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreateCompanyPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    company: { __type: 'Company' }
  },
  CreateGraphqlDocumentInput: {
    alias: { __type: '[String!]' },
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    description: { __type: 'String' },
    grant: { __type: 'String' },
    maxAgeHeader: { __type: 'Int' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreateGraphqlDocumentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    graphqlDocument: { __type: 'GraphqlDocument' }
  },
  CreateMediaItemInput: {
    altText: { __type: 'String' },
    authorId: { __type: 'ID' },
    caption: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    description: { __type: 'String' },
    filePath: { __type: 'String' },
    fileType: { __type: 'MimeTypeEnum' },
    parentId: { __type: 'ID' },
    pingStatus: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'MediaItemStatusEnum' },
    title: { __type: 'String' }
  },
  CreateMediaItemPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    mediaItem: { __type: 'MediaItem' }
  },
  CreatePageInput: {
    authorId: { __type: 'ID' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreatePagePayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    page: { __type: 'Page' }
  },
  CreatePostFormatInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    name: { __type: 'String!' },
    slug: { __type: 'String' }
  },
  CreatePostFormatPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    postFormat: { __type: 'PostFormat' }
  },
  CreatePostInput: {
    authorId: { __type: 'ID' },
    categories: { __type: 'PostCategoriesInput' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    excerpt: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    pingStatus: { __type: 'String' },
    pinged: { __type: '[String]' },
    postFormats: { __type: 'PostPostFormatsInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    tags: { __type: 'PostTagsInput' },
    title: { __type: 'String' },
    toPing: { __type: '[String]' }
  },
  CreatePostPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    post: { __type: 'Post' }
  },
  CreatePressCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    name: { __type: 'String!' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  CreatePressCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    pressCategory: { __type: 'PressCategory' }
  },
  CreatePressInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    pressCategories: { __type: 'PressPressCategoriesInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreatePressPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    press: { __type: 'Press' }
  },
  CreateReusableBlockInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreateReusableBlockPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    reusableBlock: { __type: 'ReusableBlock' }
  },
  CreateTagInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    name: { __type: 'String!' },
    slug: { __type: 'String' }
  },
  CreateTagPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    tag: { __type: 'Tag' }
  },
  CreateTeamMemberInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    pressCategories: { __type: 'TeamMemberPressCategoriesInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  CreateTeamMemberPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    teamMember: { __type: 'TeamMember' }
  },
  CreateUserInput: {
    aim: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    displayName: { __type: 'String' },
    email: { __type: 'String' },
    firstName: { __type: 'String' },
    jabber: { __type: 'String' },
    lastName: { __type: 'String' },
    locale: { __type: 'String' },
    nicename: { __type: 'String' },
    nickname: { __type: 'String' },
    password: { __type: 'String' },
    registered: { __type: 'String' },
    richEditing: { __type: 'String' },
    roles: { __type: '[String]' },
    username: { __type: 'String!' },
    websiteUrl: { __type: 'String' },
    yim: { __type: 'String' }
  },
  CreateUserPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    user: { __type: 'User' }
  },
  DatabaseIdentifier: {
    __typename: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    $on: { __type: '$DatabaseIdentifier!' }
  },
  DateInput: { day: { __type: 'Int' }, month: { __type: 'Int' }, year: { __type: 'Int' } },
  DateQueryInput: {
    after: { __type: 'DateInput' },
    before: { __type: 'DateInput' },
    column: { __type: 'PostObjectsConnectionDateColumnEnum' },
    compare: { __type: 'String' },
    day: { __type: 'Int' },
    hour: { __type: 'Int' },
    inclusive: { __type: 'Boolean' },
    minute: { __type: 'Int' },
    month: { __type: 'Int' },
    relation: { __type: 'RelationEnum' },
    second: { __type: 'Int' },
    week: { __type: 'Int' },
    year: { __type: 'Int' }
  },
  DefaultTemplate: { __typename: { __type: 'String!' }, templateName: { __type: 'String' } },
  DeleteBlockEditorPreviewInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeleteBlockEditorPreviewPayload: {
    __typename: { __type: 'String!' },
    blockEditorPreview: { __type: 'BlockEditorPreview' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' }
  },
  DeleteCategoryInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  DeleteCategoryPayload: {
    __typename: { __type: 'String!' },
    category: { __type: 'Category' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' }
  },
  DeleteCommentInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' }
  },
  DeleteCommentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    comment: { __type: 'Comment' },
    deletedId: { __type: 'ID' }
  },
  DeleteCompanyCategoryInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  DeleteCompanyCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    companyCategory: { __type: 'CompanyCategory' },
    deletedId: { __type: 'ID' }
  },
  DeleteCompanyInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeleteCompanyPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    company: { __type: 'Company' },
    deletedId: { __type: 'ID' }
  },
  DeleteGraphqlDocumentInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeleteGraphqlDocumentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    graphqlDocument: { __type: 'GraphqlDocument' }
  },
  DeleteMediaItemInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' }
  },
  DeleteMediaItemPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    mediaItem: { __type: 'MediaItem' }
  },
  DeletePageInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeletePagePayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    page: { __type: 'Page' }
  },
  DeletePostFormatInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  DeletePostFormatPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    postFormat: { __type: 'PostFormat' }
  },
  DeletePostInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeletePostPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    post: { __type: 'Post' }
  },
  DeletePressCategoryInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  DeletePressCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    pressCategory: { __type: 'PressCategory' }
  },
  DeletePressInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeletePressPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    press: { __type: 'Press' }
  },
  DeleteReusableBlockInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeleteReusableBlockPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    reusableBlock: { __type: 'ReusableBlock' }
  },
  DeleteTagInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  DeleteTagPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    tag: { __type: 'Tag' }
  },
  DeleteTeamMemberInput: {
    clientMutationId: { __type: 'String' },
    forceDelete: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' }
  },
  DeleteTeamMemberPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    teamMember: { __type: 'TeamMember' }
  },
  DeleteUserInput: {
    clientMutationId: { __type: 'String' },
    id: { __type: 'ID!' },
    reassignId: { __type: 'ID' }
  },
  DeleteUserPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    deletedId: { __type: 'ID' },
    user: { __type: 'User' }
  },
  DiscussionSettings: {
    __typename: { __type: 'String!' },
    defaultCommentStatus: { __type: 'String' },
    defaultPingStatus: { __type: 'String' }
  },
  Edge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Node!' },
    $on: { __type: '$Edge!' }
  },
  EnqueuedAsset: {
    __typename: { __type: 'String!' },
    args: { __type: 'Boolean' },
    dependencies: { __type: '[EnqueuedScript]' },
    extra: { __type: 'String' },
    handle: { __type: 'String' },
    id: { __type: 'ID!' },
    src: { __type: 'String' },
    version: { __type: 'String' },
    $on: { __type: '$EnqueuedAsset!' }
  },
  EnqueuedScript: {
    __typename: { __type: 'String!' },
    args: { __type: 'Boolean' },
    dependencies: { __type: '[EnqueuedScript]' },
    extra: { __type: 'String' },
    handle: { __type: 'String' },
    id: { __type: 'ID!' },
    src: { __type: 'String' },
    version: { __type: 'String' }
  },
  EnqueuedScriptConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[EnqueuedScriptConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedScript!]!' },
    pageInfo: { __type: 'EnqueuedScriptConnectionPageInfo!' },
    $on: { __type: '$EnqueuedScriptConnection!' }
  },
  EnqueuedScriptConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedScript!' },
    $on: { __type: '$EnqueuedScriptConnectionEdge!' }
  },
  EnqueuedScriptConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$EnqueuedScriptConnectionPageInfo!' }
  },
  EnqueuedStylesheet: {
    __typename: { __type: 'String!' },
    args: { __type: 'Boolean' },
    dependencies: { __type: '[EnqueuedScript]' },
    extra: { __type: 'String' },
    handle: { __type: 'String' },
    id: { __type: 'ID!' },
    src: { __type: 'String' },
    version: { __type: 'String' }
  },
  EnqueuedStylesheetConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[EnqueuedStylesheetConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedStylesheet!]!' },
    pageInfo: { __type: 'EnqueuedStylesheetConnectionPageInfo!' },
    $on: { __type: '$EnqueuedStylesheetConnection!' }
  },
  EnqueuedStylesheetConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedStylesheet!' },
    $on: { __type: '$EnqueuedStylesheetConnectionEdge!' }
  },
  EnqueuedStylesheetConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$EnqueuedStylesheetConnectionPageInfo!' }
  },
  GeneralSettings: {
    __typename: { __type: 'String!' },
    dateFormat: { __type: 'String' },
    description: { __type: 'String' },
    email: { __type: 'String' },
    language: { __type: 'String' },
    startOfWeek: { __type: 'Int' },
    timeFormat: { __type: 'String' },
    timezone: { __type: 'String' },
    title: { __type: 'String' },
    url: { __type: 'String' }
  },
  GenerateAuthorizationCodeInput: {
    clientMutationId: { __type: 'String' },
    email: { __type: 'String' },
    password: { __type: 'String' },
    username: { __type: 'String' }
  },
  GenerateAuthorizationCodePayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    code: { __type: 'String' },
    error: { __type: 'String' }
  },
  GraphqlDocument: {
    __typename: { __type: 'String!' },
    alias: { __type: '[String!]' },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    description: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    grant: { __type: 'String' },
    graphqlDocumentId: { __type: 'Int!' },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    maxAgeHeader: { __type: 'Int' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    preview: { __type: 'GraphqlDocumentToPreviewConnectionEdge' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  GraphqlDocumentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[GraphqlDocumentConnectionEdge!]!' },
    nodes: { __type: '[GraphqlDocument!]!' },
    pageInfo: { __type: 'GraphqlDocumentConnectionPageInfo!' },
    $on: { __type: '$GraphqlDocumentConnection!' }
  },
  GraphqlDocumentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'GraphqlDocument!' },
    $on: { __type: '$GraphqlDocumentConnectionEdge!' }
  },
  GraphqlDocumentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$GraphqlDocumentConnectionPageInfo!' }
  },
  GraphqlDocumentToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'GraphqlDocument!' }
  },
  HierarchicalContentNode: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs'
      }
    },
    children: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    parent: { __type: 'HierarchicalContentNodeToParentContentNodeConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' },
    $on: { __type: '$HierarchicalContentNode!' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[HierarchicalContentNodeToContentNodeAncestorsConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo!' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery'
    },
    title: { __type: 'String' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray: {
    field: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField'
    },
    includeChildren: { __type: 'Boolean' },
    operator: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator'
    },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: {
      __type: '[HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxArray]'
    }
  },
  HierarchicalContentNodeToContentNodeChildrenConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[HierarchicalContentNodeToContentNodeChildrenConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo!' }
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray: {
    field: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField'
    },
    includeChildren: { __type: 'Boolean' },
    operator: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator'
    },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: {
      __type: '[HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxArray]'
    }
  },
  HierarchicalContentNodeToParentContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  HierarchicalNode: {
    __typename: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    id: { __type: 'ID!' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    $on: { __type: '$HierarchicalNode!' }
  },
  HierarchicalTermNode: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' },
    $on: { __type: '$HierarchicalTermNode!' }
  },
  Jam3AccordionBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3AccordionBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3AccordionBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3AccordionItemBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3AccordionItemBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3AccordionItemBlockAttributes: {
    __typename: { __type: 'String!' },
    body: { __type: 'String' },
    className: { __type: 'String' },
    description: { __type: 'String' },
    indexItem: { __type: 'String!' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    title: { __type: 'String' }
  },
  Jam3AcfCompanyListingImgBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3AcfCompanyListingImgBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3AcfCompanyListingImgBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3AcfCompanyLogoBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3AcfCompanyLogoBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3AcfCompanyLogoBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3CarouselBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3CarouselBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3CarouselBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3CarouselItemBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3CarouselItemBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3CarouselItemBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3CompanyImageWithLogoBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3CompanyImageWithLogoBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3CompanyImageWithLogoBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    logo: { __type: 'String' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3CompanyInfoBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3CompanyInfoBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3CompanyInfoBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3CompanyInfoItemBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3CompanyInfoItemBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3CompanyInfoItemBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    text: { __type: 'String' },
    title: { __type: 'String' }
  },
  Jam3NewsletterBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3NewsletterBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3NewsletterBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3ReadTimeBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3ReadTimeBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3ReadTimeBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundColor: { __type: 'String' },
    className: { __type: 'String' },
    fontSize: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    style: { __type: 'BlockAttributesObject' },
    textColor: { __type: 'String' }
  },
  Jam3RotateTextBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3RotateTextBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3RotateTextBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3SocialShareBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3SocialShareBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3SocialShareBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  Jam3TeamListingBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'Jam3TeamListingBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  Jam3TeamListingBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  MediaDetails: {
    __typename: { __type: 'String!' },
    file: { __type: 'String' },
    height: { __type: 'Int' },
    meta: { __type: 'MediaItemMeta' },
    sizes: {
      __type: '[MediaSize]',
      __args: { exclude: '[MediaItemSizeEnum]', include: '[MediaItemSizeEnum]' }
    },
    width: { __type: 'Int' }
  },
  MediaItem: {
    __typename: { __type: 'String!' },
    altText: { __type: 'String' },
    ancestors: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs'
      }
    },
    author: { __type: 'NodeWithAuthorToUserConnectionEdge' },
    authorDatabaseId: { __type: 'Int' },
    authorId: { __type: 'ID' },
    caption: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    children: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs'
      }
    },
    commentCount: { __type: 'Int' },
    commentStatus: { __type: 'String' },
    comments: {
      __type: 'MediaItemToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'MediaItemToCommentConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    description: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    fileSize: { __type: 'Int', __args: { size: 'MediaItemSizeEnum' } },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    mediaDetails: { __type: 'MediaDetails' },
    mediaItemId: { __type: 'Int!' },
    mediaItemUrl: { __type: 'String' },
    mediaType: { __type: 'String' },
    mimeType: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    parent: { __type: 'HierarchicalContentNodeToParentContentNodeConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    sizes: { __type: 'String', __args: { size: 'MediaItemSizeEnum' } },
    slug: { __type: 'String' },
    sourceUrl: { __type: 'String', __args: { size: 'MediaItemSizeEnum' } },
    srcSet: { __type: 'String', __args: { size: 'MediaItemSizeEnum' } },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  MediaItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MediaItemConnectionEdge!]!' },
    nodes: { __type: '[MediaItem!]!' },
    pageInfo: { __type: 'MediaItemConnectionPageInfo!' },
    $on: { __type: '$MediaItemConnection!' }
  },
  MediaItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MediaItem!' },
    $on: { __type: '$MediaItemConnectionEdge!' }
  },
  MediaItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$MediaItemConnectionPageInfo!' }
  },
  MediaItemMeta: {
    __typename: { __type: 'String!' },
    aperture: { __type: 'Float' },
    camera: { __type: 'String' },
    caption: { __type: 'String' },
    copyright: { __type: 'String' },
    createdTimestamp: { __type: 'Int' },
    credit: { __type: 'String' },
    focalLength: { __type: 'Float' },
    iso: { __type: 'Int' },
    keywords: { __type: '[String]' },
    orientation: { __type: 'String' },
    shutterSpeed: { __type: 'Float' },
    title: { __type: 'String' }
  },
  MediaItemToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MediaItemToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'MediaItemToCommentConnectionPageInfo!' }
  },
  MediaItemToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  MediaItemToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  MediaItemToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  MediaSize: {
    __typename: { __type: 'String!' },
    file: { __type: 'String' },
    fileSize: { __type: 'Int' },
    height: { __type: 'String' },
    mimeType: { __type: 'String' },
    name: { __type: 'String' },
    sourceUrl: { __type: 'String' },
    width: { __type: 'String' }
  },
  Menu: {
    __typename: { __type: 'String!' },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    locations: { __type: '[MenuLocationEnum]' },
    menuId: { __type: 'Int' },
    menuItems: {
      __type: 'MenuToMenuItemConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'MenuToMenuItemConnectionWhereArgs'
      }
    },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  MenuConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MenuConnectionEdge!]!' },
    nodes: { __type: '[Menu!]!' },
    pageInfo: { __type: 'MenuConnectionPageInfo!' },
    $on: { __type: '$MenuConnection!' }
  },
  MenuConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Menu!' },
    $on: { __type: '$MenuConnectionEdge!' }
  },
  MenuConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$MenuConnectionPageInfo!' }
  },
  MenuItem: {
    __typename: { __type: 'String!' },
    childItems: {
      __type: 'MenuItemToMenuItemConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'MenuItemToMenuItemConnectionWhereArgs'
      }
    },
    connectedNode: { __type: 'MenuItemToMenuItemLinkableConnectionEdge' },
    connectedObject: { __type: 'MenuItemObjectUnion' },
    cssClasses: { __type: '[String]' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    label: { __type: 'String' },
    linkRelationship: { __type: 'String' },
    locations: { __type: '[MenuLocationEnum]' },
    menu: { __type: 'MenuItemToMenuConnectionEdge' },
    menuItemId: { __type: 'Int' },
    order: { __type: 'Int' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    path: { __type: 'String' },
    target: { __type: 'String' },
    title: { __type: 'String' },
    uri: { __type: 'String' },
    url: { __type: 'String' }
  },
  MenuItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MenuItemConnectionEdge!]!' },
    nodes: { __type: '[MenuItem!]!' },
    pageInfo: { __type: 'MenuItemConnectionPageInfo!' },
    $on: { __type: '$MenuItemConnection!' }
  },
  MenuItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItem!' },
    $on: { __type: '$MenuItemConnectionEdge!' }
  },
  MenuItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$MenuItemConnectionPageInfo!' }
  },
  MenuItemLinkable: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    databaseId: { __type: 'Int!' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isTermNode: { __type: 'Boolean!' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' },
    $on: { __type: '$MenuItemLinkable!' }
  },
  MenuItemLinkableConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItemLinkable!' },
    $on: { __type: '$MenuItemLinkableConnectionEdge!' }
  },
  MenuItemObjectUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$MenuItemObjectUnion!' }
  },
  MenuItemToMenuConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Menu!' }
  },
  MenuItemToMenuItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MenuItemToMenuItemConnectionEdge!]!' },
    nodes: { __type: '[MenuItem!]!' },
    pageInfo: { __type: 'MenuItemToMenuItemConnectionPageInfo!' }
  },
  MenuItemToMenuItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItem!' }
  },
  MenuItemToMenuItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  MenuItemToMenuItemConnectionWhereArgs: {
    id: { __type: 'Int' },
    location: { __type: 'MenuLocationEnum' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' }
  },
  MenuItemToMenuItemLinkableConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItemLinkable!' }
  },
  MenuToMenuItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[MenuToMenuItemConnectionEdge!]!' },
    nodes: { __type: '[MenuItem!]!' },
    pageInfo: { __type: 'MenuToMenuItemConnectionPageInfo!' }
  },
  MenuToMenuItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItem!' }
  },
  MenuToMenuItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  MenuToMenuItemConnectionWhereArgs: {
    id: { __type: 'Int' },
    location: { __type: 'MenuLocationEnum' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' }
  },
  MonsterinsightsPopularPostsInlineBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'MonsterinsightsPopularPostsInlineBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  MonsterinsightsPopularPostsInlineBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundBorder: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    borderColor: { __type: 'String' },
    borderColor2: { __type: 'String' },
    className: { __type: 'String' },
    iconBackground: { __type: 'String' },
    iconColor: { __type: 'String' },
    labelBackground: { __type: 'String' },
    labelColor: { __type: 'String' },
    labelText: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    theme: { __type: 'String' },
    titleColor: { __type: 'String' },
    titleSize: { __type: 'Float' }
  },
  MonsterinsightsPopularPostsWidgetBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'MonsterinsightsPopularPostsWidgetBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  MonsterinsightsPopularPostsWidgetBlockAttributes: {
    __typename: { __type: 'String!' },
    backgroundBorder: { __type: 'String' },
    backgroundColor: { __type: 'String' },
    categories: { __type: 'BlockAttributesArray!' },
    className: { __type: 'String' },
    columns: { __type: 'Float!' },
    commentsColor: { __type: 'String' },
    labelBackground: { __type: 'String' },
    labelColor: { __type: 'String' },
    labelText: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metaAuthor: { __type: 'Boolean' },
    metaColor: { __type: 'String' },
    metaComments: { __type: 'Boolean' },
    metaDate: { __type: 'Boolean' },
    metaSize: { __type: 'String' },
    metadata: { __type: 'BlockAttributesObject' },
    postCount: { __type: 'Float!' },
    theme: { __type: 'String' },
    titleColor: { __type: 'String' },
    titleSize: { __type: 'Float' },
    widgetTitle: { __type: 'Boolean' },
    widgetTitleText: { __type: 'String' }
  },
  Node: { __typename: { __type: 'String!' }, id: { __type: 'ID!' }, $on: { __type: '$Node!' } },
  NodeWithAuthor: {
    __typename: { __type: 'String!' },
    author: { __type: 'NodeWithAuthorToUserConnectionEdge' },
    authorDatabaseId: { __type: 'Int' },
    authorId: { __type: 'ID' },
    id: { __type: 'ID!' },
    $on: { __type: '$NodeWithAuthor!' }
  },
  NodeWithAuthorToUserConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'User!' }
  },
  NodeWithComments: {
    __typename: { __type: 'String!' },
    commentCount: { __type: 'Int' },
    commentStatus: { __type: 'String' },
    id: { __type: 'ID!' },
    $on: { __type: '$NodeWithComments!' }
  },
  NodeWithContentEditor: {
    __typename: { __type: 'String!' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    id: { __type: 'ID!' },
    $on: { __type: '$NodeWithContentEditor!' }
  },
  NodeWithExcerpt: {
    __typename: { __type: 'String!' },
    excerpt: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    id: { __type: 'ID!' },
    $on: { __type: '$NodeWithExcerpt!' }
  },
  NodeWithFeaturedImage: {
    __typename: { __type: 'String!' },
    featuredImage: { __type: 'NodeWithFeaturedImageToMediaItemConnectionEdge' },
    featuredImageDatabaseId: { __type: 'Int' },
    featuredImageId: { __type: 'ID' },
    id: { __type: 'ID!' },
    $on: { __type: '$NodeWithFeaturedImage!' }
  },
  NodeWithFeaturedImageToMediaItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MediaItem!' }
  },
  NodeWithPageAttributes: {
    __typename: { __type: 'String!' },
    id: { __type: 'ID!' },
    menuOrder: { __type: 'Int' },
    $on: { __type: '$NodeWithPageAttributes!' }
  },
  NodeWithRevisions: {
    __typename: { __type: 'String!' },
    id: { __type: 'ID!' },
    isRevision: { __type: 'Boolean' },
    revisionOf: { __type: 'NodeWithRevisionsToContentNodeConnectionEdge' },
    $on: { __type: '$NodeWithRevisions!' }
  },
  NodeWithRevisionsToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  NodeWithTemplate: {
    __typename: { __type: 'String!' },
    id: { __type: 'ID!' },
    template: { __type: 'ContentTemplate' },
    $on: { __type: '$NodeWithTemplate!' }
  },
  NodeWithTitle: {
    __typename: { __type: 'String!' },
    id: { __type: 'ID!' },
    seo: { __type: 'PostTypeSEO' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    $on: { __type: '$NodeWithTitle!' }
  },
  NodeWithTrackbacks: {
    __typename: { __type: 'String!' },
    id: { __type: 'ID!' },
    pingStatus: { __type: 'String' },
    pinged: { __type: '[String]' },
    toPing: { __type: '[String]' },
    $on: { __type: '$NodeWithTrackbacks!' }
  },
  OffsetPagination: { offset: { __type: 'Int' }, size: { __type: 'Int' } },
  OffsetPaginationPageInfo: {
    __typename: { __type: 'String!' },
    hasMore: { __type: 'Boolean' },
    hasPrevious: { __type: 'Boolean' },
    total: { __type: 'Int' }
  },
  OneToOneConnection: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Node!' },
    $on: { __type: '$OneToOneConnection!' }
  },
  Page: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs'
      }
    },
    author: { __type: 'NodeWithAuthorToUserConnectionEdge' },
    authorDatabaseId: { __type: 'Int' },
    authorId: { __type: 'ID' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    children: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs'
      }
    },
    commentCount: { __type: 'Int' },
    commentStatus: { __type: 'String' },
    comments: {
      __type: 'PageToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PageToCommentConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    featuredImage: { __type: 'NodeWithFeaturedImageToMediaItemConnectionEdge' },
    featuredImageDatabaseId: { __type: 'Int' },
    featuredImageId: { __type: 'ID' },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isFrontPage: { __type: 'Boolean!' },
    isPostsPage: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isPrivacyPage: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isRevision: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    menuOrder: { __type: 'Int' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    pageId: { __type: 'Int!' },
    parent: { __type: 'HierarchicalContentNodeToParentContentNodeConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    preview: { __type: 'PageToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    revisionOf: { __type: 'NodeWithRevisionsToContentNodeConnectionEdge' },
    revisions: {
      __type: 'PageToRevisionConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PageToRevisionConnectionWhereArgs'
      }
    },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  PageConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PageConnectionEdge!]!' },
    nodes: { __type: '[Page!]!' },
    pageInfo: { __type: 'PageConnectionPageInfo!' },
    $on: { __type: '$PageConnection!' }
  },
  PageConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Page!' },
    $on: { __type: '$PageConnectionEdge!' }
  },
  PageConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PageConnectionPageInfo!' }
  },
  PageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PageInfo!' }
  },
  PageToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PageToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'PageToCommentConnectionPageInfo!' }
  },
  PageToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  PageToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PageToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  PageToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Page!' }
  },
  PageToRevisionConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PageToRevisionConnectionEdge!]!' },
    nodes: { __type: '[Page!]!' },
    pageInfo: { __type: 'PageToRevisionConnectionPageInfo!' }
  },
  PageToRevisionConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Page!' }
  },
  PageToRevisionConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PageToRevisionConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'PageToRevisionConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PageToRevisionConnectionWhereArgsTaxArray: {
    field: { __type: 'PageToRevisionConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PageToRevisionConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PageToRevisionConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PageToRevisionConnectionWhereArgsTaxArray]' }
  },
  Plugin: {
    __typename: { __type: 'String!' },
    author: { __type: 'String' },
    authorUri: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    name: { __type: 'String' },
    path: { __type: 'String' },
    pluginUri: { __type: 'String' },
    version: { __type: 'String' }
  },
  PluginConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PluginConnectionEdge!]!' },
    nodes: { __type: '[Plugin!]!' },
    pageInfo: { __type: 'PluginConnectionPageInfo!' },
    $on: { __type: '$PluginConnection!' }
  },
  PluginConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Plugin!' },
    $on: { __type: '$PluginConnectionEdge!' }
  },
  PluginConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PluginConnectionPageInfo!' }
  },
  Post: {
    __typename: { __type: 'String!' },
    author: { __type: 'NodeWithAuthorToUserConnectionEdge' },
    authorDatabaseId: { __type: 'Int' },
    authorId: { __type: 'ID' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    blogPost: { __type: 'Post_Blogpost' },
    categories: {
      __type: 'PostToCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToCategoryConnectionWhereArgs'
      }
    },
    commentCount: { __type: 'Int' },
    commentStatus: { __type: 'String' },
    comments: {
      __type: 'PostToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToCommentConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    excerpt: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    featuredImage: { __type: 'NodeWithFeaturedImageToMediaItemConnectionEdge' },
    featuredImageDatabaseId: { __type: 'Int' },
    featuredImageId: { __type: 'ID' },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isRevision: { __type: 'Boolean' },
    isSticky: { __type: 'Boolean!' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    pingStatus: { __type: 'String' },
    pinged: { __type: '[String]' },
    postFormats: {
      __type: 'PostToPostFormatConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToPostFormatConnectionWhereArgs'
      }
    },
    postId: { __type: 'Int!' },
    preview: { __type: 'PostToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    revisionOf: { __type: 'NodeWithRevisionsToContentNodeConnectionEdge' },
    revisions: {
      __type: 'PostToRevisionConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToRevisionConnectionWhereArgs'
      }
    },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    tags: {
      __type: 'PostToTagConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToTagConnectionWhereArgs'
      }
    },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    terms: {
      __type: 'PostToTermNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostToTermNodeConnectionWhereArgs'
      }
    },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    toPing: { __type: '[String]' },
    uri: { __type: 'String' }
  },
  PostCategoriesInput: {
    append: { __type: 'Boolean' },
    nodes: { __type: '[PostCategoriesNodeInput]' }
  },
  PostCategoriesNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  PostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'PostConnectionPageInfo!' },
    $on: { __type: '$PostConnection!' }
  },
  PostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' },
    $on: { __type: '$PostConnectionEdge!' }
  },
  PostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PostConnectionPageInfo!' }
  },
  PostFormat: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    contentNodes: {
      __type: 'PostFormatToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostFormatToContentNodeConnectionWhereArgs'
      }
    },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    postFormatId: { __type: 'Int' },
    posts: {
      __type: 'PostFormatToPostConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PostFormatToPostConnectionWhereArgs'
      }
    },
    seo: { __type: 'TaxonomySEO' },
    slug: { __type: 'String' },
    taxonomy: { __type: 'PostFormatToTaxonomyConnectionEdge' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' }
  },
  PostFormatConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostFormatConnectionEdge!]!' },
    nodes: { __type: '[PostFormat!]!' },
    pageInfo: { __type: 'PostFormatConnectionPageInfo!' },
    $on: { __type: '$PostFormatConnection!' }
  },
  PostFormatConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PostFormat!' },
    $on: { __type: '$PostFormatConnectionEdge!' }
  },
  PostFormatConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PostFormatConnectionPageInfo!' }
  },
  PostFormatToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostFormatToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'PostFormatToContentNodeConnectionPageInfo!' }
  },
  PostFormatToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  PostFormatToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostFormatToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypesOfPostFormatEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'PostFormatToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PostFormatToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'PostFormatToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PostFormatToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PostFormatToContentNodeConnectionWhereArgsTaxArray]' }
  },
  PostFormatToPostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostFormatToPostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'PostFormatToPostConnectionPageInfo!' }
  },
  PostFormatToPostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  PostFormatToPostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostFormatToPostConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'PostFormatToPostConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PostFormatToPostConnectionWhereArgsTaxArray: {
    field: { __type: 'PostFormatToPostConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PostFormatToPostConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PostFormatToPostConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PostFormatToPostConnectionWhereArgsTaxArray]' }
  },
  PostFormatToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  PostObjectsConnectionOrderbyInput: {
    field: { __type: 'PostObjectsConnectionOrderbyEnum!' },
    order: { __type: 'OrderEnum!' }
  },
  PostPostFormatsInput: {
    append: { __type: 'Boolean' },
    nodes: { __type: '[PostPostFormatsNodeInput]' }
  },
  PostPostFormatsNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  PostTagsInput: { append: { __type: 'Boolean' }, nodes: { __type: '[PostTagsNodeInput]' } },
  PostTagsNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  PostToCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToCategoryConnectionEdge!]!' },
    nodes: { __type: '[Category!]!' },
    pageInfo: { __type: 'PostToCategoryConnectionPageInfo!' }
  },
  PostToCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'Category!' }
  },
  PostToCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PostToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'PostToCommentConnectionPageInfo!' }
  },
  PostToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  PostToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  PostToPostFormatConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToPostFormatConnectionEdge!]!' },
    nodes: { __type: '[PostFormat!]!' },
    pageInfo: { __type: 'PostToPostFormatConnectionPageInfo!' }
  },
  PostToPostFormatConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'PostFormat!' }
  },
  PostToPostFormatConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToPostFormatConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PostToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  PostToRevisionConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToRevisionConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'PostToRevisionConnectionPageInfo!' }
  },
  PostToRevisionConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  PostToRevisionConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToRevisionConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'PostToRevisionConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PostToRevisionConnectionWhereArgsTaxArray: {
    field: { __type: 'PostToRevisionConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PostToRevisionConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PostToRevisionConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PostToRevisionConnectionWhereArgsTaxArray]' }
  },
  PostToTagConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToTagConnectionEdge!]!' },
    nodes: { __type: '[Tag!]!' },
    pageInfo: { __type: 'PostToTagConnectionPageInfo!' }
  },
  PostToTagConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'Tag!' }
  },
  PostToTagConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToTagConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PostToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PostToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'PostToTermNodeConnectionPageInfo!' }
  },
  PostToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  PostToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PostToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    taxonomies: { __type: '[TaxonomyEnum]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PostTypeLabelDetails: {
    __typename: { __type: 'String!' },
    addNew: { __type: 'String' },
    addNewItem: { __type: 'String' },
    allItems: { __type: 'String' },
    archives: { __type: 'String' },
    attributes: { __type: 'String' },
    editItem: { __type: 'String' },
    featuredImage: { __type: 'String' },
    filterItemsList: { __type: 'String' },
    insertIntoItem: { __type: 'String' },
    itemsList: { __type: 'String' },
    itemsListNavigation: { __type: 'String' },
    menuName: { __type: 'String' },
    name: { __type: 'String' },
    newItem: { __type: 'String' },
    notFound: { __type: 'String' },
    notFoundInTrash: { __type: 'String' },
    parentItemColon: { __type: 'String' },
    removeFeaturedImage: { __type: 'String' },
    searchItems: { __type: 'String' },
    setFeaturedImage: { __type: 'String' },
    singularName: { __type: 'String' },
    uploadedToThisItem: { __type: 'String' },
    useFeaturedImage: { __type: 'String' },
    viewItem: { __type: 'String' },
    viewItems: { __type: 'String' }
  },
  PostTypeSEO: {
    __typename: { __type: 'String!' },
    breadcrumbs: { __type: '[SEOPostTypeBreadcrumbs]' },
    canonical: { __type: 'String' },
    cornerstone: { __type: 'Boolean' },
    focuskw: { __type: 'String' },
    fullHead: { __type: 'String' },
    metaDesc: { __type: 'String' },
    metaKeywords: { __type: 'String' },
    metaRobotsNofollow: { __type: 'String' },
    metaRobotsNoindex: { __type: 'String' },
    opengraphAuthor: { __type: 'String' },
    opengraphDescription: { __type: 'String' },
    opengraphImage: { __type: 'MediaItem' },
    opengraphModifiedTime: { __type: 'String' },
    opengraphPublishedTime: { __type: 'String' },
    opengraphPublisher: { __type: 'String' },
    opengraphSiteName: { __type: 'String' },
    opengraphTitle: { __type: 'String' },
    opengraphType: { __type: 'String' },
    opengraphUrl: { __type: 'String' },
    readingTime: { __type: 'Float' },
    schema: { __type: 'SEOPostTypeSchema' },
    title: { __type: 'String' },
    twitterDescription: { __type: 'String' },
    twitterImage: { __type: 'MediaItem' },
    twitterTitle: { __type: 'String' }
  },
  Post_Blogpost: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    heroArticleImage: { __type: 'MediaItem' }
  },
  Press: {
    __typename: { __type: 'String!' },
    acfPress: { __type: 'Press_Acfpress' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    featuredImage: { __type: 'NodeWithFeaturedImageToMediaItemConnectionEdge' },
    featuredImageDatabaseId: { __type: 'Int' },
    featuredImageId: { __type: 'ID' },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    pressCategories: {
      __type: 'PressToPressCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressToPressCategoryConnectionWhereArgs'
      }
    },
    pressId: { __type: 'Int!' },
    preview: { __type: 'PressToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    terms: {
      __type: 'PressToTermNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressToTermNodeConnectionWhereArgs'
      }
    },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  PressCategory: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'PressCategoryToAncestorsPressCategoryConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    children: {
      __type: 'PressCategoryToPressCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressCategoryToPressCategoryConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    contentNodes: {
      __type: 'PressCategoryToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressCategoryToContentNodeConnectionWhereArgs'
      }
    },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    parent: { __type: 'PressCategoryToParentPressCategoryConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    pressCategoryId: { __type: 'Int' },
    pressPosts: {
      __type: 'PressCategoryToPressConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressCategoryToPressConnectionWhereArgs'
      }
    },
    seo: { __type: 'TaxonomySEO' },
    slug: { __type: 'String' },
    taxonomy: { __type: 'PressCategoryToTaxonomyConnectionEdge' },
    taxonomyName: { __type: 'String' },
    teamMembers: {
      __type: 'PressCategoryToTeamMemberConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'PressCategoryToTeamMemberConnectionWhereArgs'
      }
    },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' }
  },
  PressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'PressCategoryConnectionPageInfo!' },
    $on: { __type: '$PressCategoryConnection!' }
  },
  PressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PressCategory!' },
    $on: { __type: '$PressCategoryConnectionEdge!' }
  },
  PressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PressCategoryConnectionPageInfo!' }
  },
  PressCategoryToAncestorsPressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryToAncestorsPressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'PressCategoryToAncestorsPressCategoryConnectionPageInfo!' }
  },
  PressCategoryToAncestorsPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PressCategory!' }
  },
  PressCategoryToAncestorsPressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressCategoryToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'PressCategoryToContentNodeConnectionPageInfo!' }
  },
  PressCategoryToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  PressCategoryToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressCategoryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypesOfPressCategoryEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'PressCategoryToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PressCategoryToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'PressCategoryToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PressCategoryToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PressCategoryToContentNodeConnectionWhereArgsTaxArray]' }
  },
  PressCategoryToParentPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PressCategory!' }
  },
  PressCategoryToPressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryToPressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'PressCategoryToPressCategoryConnectionPageInfo!' }
  },
  PressCategoryToPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PressCategory!' }
  },
  PressCategoryToPressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressCategoryToPressCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PressCategoryToPressConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryToPressConnectionEdge!]!' },
    nodes: { __type: '[Press!]!' },
    pageInfo: { __type: 'PressCategoryToPressConnectionPageInfo!' }
  },
  PressCategoryToPressConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Press!' }
  },
  PressCategoryToPressConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressCategoryToPressConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'PressCategoryToPressConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PressCategoryToPressConnectionWhereArgsTaxArray: {
    field: { __type: 'PressCategoryToPressConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PressCategoryToPressConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PressCategoryToPressConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PressCategoryToPressConnectionWhereArgsTaxArray]' }
  },
  PressCategoryToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  PressCategoryToTeamMemberConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressCategoryToTeamMemberConnectionEdge!]!' },
    nodes: { __type: '[TeamMember!]!' },
    pageInfo: { __type: 'PressCategoryToTeamMemberConnectionPageInfo!' }
  },
  PressCategoryToTeamMemberConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TeamMember!' }
  },
  PressCategoryToTeamMemberConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressCategoryToTeamMemberConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'PressCategoryToTeamMemberConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  PressCategoryToTeamMemberConnectionWhereArgsTaxArray: {
    field: { __type: 'PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  PressCategoryToTeamMemberConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[PressCategoryToTeamMemberConnectionWhereArgsTaxArray]' }
  },
  PressConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressConnectionEdge!]!' },
    nodes: { __type: '[Press!]!' },
    pageInfo: { __type: 'PressConnectionPageInfo!' },
    $on: { __type: '$PressConnection!' }
  },
  PressConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Press!' },
    $on: { __type: '$PressConnectionEdge!' }
  },
  PressConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$PressConnectionPageInfo!' }
  },
  PressPressCategoriesInput: {
    append: { __type: 'Boolean' },
    nodes: { __type: '[PressPressCategoriesNodeInput]' }
  },
  PressPressCategoriesNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  PressToPressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressToPressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'PressToPressCategoryConnectionPageInfo!' }
  },
  PressToPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'PressCategory!' }
  },
  PressToPressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressToPressCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  PressToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Press!' }
  },
  PressToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[PressToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'PressToTermNodeConnectionPageInfo!' }
  },
  PressToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  PressToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  PressToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    taxonomies: { __type: '[TaxonomyEnum]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  Press_Acfpress: {
    __typename: { __type: 'String!' },
    externalLink: { __type: 'String' },
    fieldGroupName: { __type: 'String' },
    source: { __type: 'String' },
    type: { __type: 'String' }
  },
  Previewable: {
    __typename: { __type: 'String!' },
    isPreview: { __type: 'Boolean' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    $on: { __type: '$Previewable!' }
  },
  ReadingSettings: {
    __typename: { __type: 'String!' },
    pageForPosts: { __type: 'Int' },
    pageOnFront: { __type: 'Int' },
    postsPerPage: { __type: 'Int' },
    showOnFront: { __type: 'String' }
  },
  RegisterUserInput: {
    aim: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    displayName: { __type: 'String' },
    email: { __type: 'String' },
    firstName: { __type: 'String' },
    jabber: { __type: 'String' },
    lastName: { __type: 'String' },
    locale: { __type: 'String' },
    nicename: { __type: 'String' },
    nickname: { __type: 'String' },
    password: { __type: 'String' },
    registered: { __type: 'String' },
    richEditing: { __type: 'String' },
    username: { __type: 'String!' },
    websiteUrl: { __type: 'String' },
    yim: { __type: 'String' }
  },
  RegisterUserPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    user: { __type: 'User' }
  },
  ResetUserPasswordInput: {
    clientMutationId: { __type: 'String' },
    key: { __type: 'String' },
    login: { __type: 'String' },
    password: { __type: 'String' }
  },
  ResetUserPasswordPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    user: { __type: 'User' }
  },
  RestoreCommentInput: { clientMutationId: { __type: 'String' }, id: { __type: 'ID!' } },
  RestoreCommentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    comment: { __type: 'Comment' },
    restoredId: { __type: 'ID' }
  },
  ReusableBlock: {
    __typename: { __type: 'String!' },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isRevision: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    preview: { __type: 'ReusableBlockToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksFrom: { __type: '[Block!]', __args: { databaseId: 'Int!' } },
    previewBlocksFromJSON: { __type: 'String', __args: { databaseId: 'Int!' } },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    reusableBlockId: { __type: 'Int!' },
    revisionOf: { __type: 'NodeWithRevisionsToContentNodeConnectionEdge' },
    revisions: {
      __type: 'ReusableBlockToRevisionConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'ReusableBlockToRevisionConnectionWhereArgs'
      }
    },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  ReusableBlockConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ReusableBlockConnectionEdge!]!' },
    nodes: { __type: '[ReusableBlock!]!' },
    pageInfo: { __type: 'ReusableBlockConnectionPageInfo!' },
    $on: { __type: '$ReusableBlockConnection!' }
  },
  ReusableBlockConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ReusableBlock!' },
    $on: { __type: '$ReusableBlockConnectionEdge!' }
  },
  ReusableBlockConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$ReusableBlockConnectionPageInfo!' }
  },
  ReusableBlockToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ReusableBlock!' }
  },
  ReusableBlockToRevisionConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ReusableBlockToRevisionConnectionEdge!]!' },
    nodes: { __type: '[ReusableBlock!]!' },
    pageInfo: { __type: 'ReusableBlockToRevisionConnectionPageInfo!' }
  },
  ReusableBlockToRevisionConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ReusableBlock!' }
  },
  ReusableBlockToRevisionConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  ReusableBlockToRevisionConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'ReusableBlockToRevisionConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  ReusableBlockToRevisionConnectionWhereArgsTaxArray: {
    field: { __type: 'ReusableBlockToRevisionConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  ReusableBlockToRevisionConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[ReusableBlockToRevisionConnectionWhereArgsTaxArray]' }
  },
  RootQueryToBlockEditorPreviewConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToBlockEditorPreviewConnectionEdge!]!' },
    nodes: { __type: '[BlockEditorPreview!]!' },
    pageInfo: { __type: 'RootQueryToBlockEditorPreviewConnectionPageInfo!' }
  },
  RootQueryToBlockEditorPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'BlockEditorPreview!' }
  },
  RootQueryToBlockEditorPreviewConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToBlockEditorPreviewConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    previewedDatabaseId: { __type: 'Int' },
    previewedParentDatabaseId: { __type: 'Int' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToBlockEditorPreviewConnectionWhereArgsTaxArray]' }
  },
  RootQueryToCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToCategoryConnectionEdge!]!' },
    nodes: { __type: '[Category!]!' },
    pageInfo: { __type: 'RootQueryToCategoryConnectionPageInfo!' }
  },
  RootQueryToCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Category!' }
  },
  RootQueryToCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'RootQueryToCommentConnectionPageInfo!' }
  },
  RootQueryToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  RootQueryToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  RootQueryToCompanyCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToCompanyCategoryConnectionEdge!]!' },
    nodes: { __type: '[CompanyCategory!]!' },
    pageInfo: { __type: 'RootQueryToCompanyCategoryConnectionPageInfo!' }
  },
  RootQueryToCompanyCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'CompanyCategory!' }
  },
  RootQueryToCompanyCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToCompanyCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToCompanyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToCompanyConnectionEdge!]!' },
    nodes: { __type: '[Company!]!' },
    pageInfo: { __type: 'RootQueryToCompanyConnectionPageInfo!' }
  },
  RootQueryToCompanyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Company!' }
  },
  RootQueryToCompanyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToCompanyConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToCompanyConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToCompanyConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToCompanyConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToCompanyConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToCompanyConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToCompanyConnectionWhereArgsTaxArray]' }
  },
  RootQueryToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'RootQueryToContentNodeConnectionPageInfo!' }
  },
  RootQueryToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  RootQueryToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToContentNodeConnectionWhereArgsTaxArray]' }
  },
  RootQueryToContentTypeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToContentTypeConnectionEdge!]!' },
    nodes: { __type: '[ContentType!]!' },
    pageInfo: { __type: 'RootQueryToContentTypeConnectionPageInfo!' }
  },
  RootQueryToContentTypeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentType!' }
  },
  RootQueryToContentTypeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToEnqueuedScriptConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToEnqueuedScriptConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedScript!]!' },
    pageInfo: { __type: 'RootQueryToEnqueuedScriptConnectionPageInfo!' }
  },
  RootQueryToEnqueuedScriptConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedScript!' }
  },
  RootQueryToEnqueuedScriptConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToEnqueuedStylesheetConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToEnqueuedStylesheetConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedStylesheet!]!' },
    pageInfo: { __type: 'RootQueryToEnqueuedStylesheetConnectionPageInfo!' }
  },
  RootQueryToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedStylesheet!' }
  },
  RootQueryToEnqueuedStylesheetConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToGraphqlDocumentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToGraphqlDocumentConnectionEdge!]!' },
    nodes: { __type: '[GraphqlDocument!]!' },
    pageInfo: { __type: 'RootQueryToGraphqlDocumentConnectionPageInfo!' }
  },
  RootQueryToGraphqlDocumentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'GraphqlDocument!' }
  },
  RootQueryToGraphqlDocumentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToGraphqlDocumentConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToGraphqlDocumentConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToGraphqlDocumentConnectionWhereArgsTaxArray]' }
  },
  RootQueryToMediaItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToMediaItemConnectionEdge!]!' },
    nodes: { __type: '[MediaItem!]!' },
    pageInfo: { __type: 'RootQueryToMediaItemConnectionPageInfo!' }
  },
  RootQueryToMediaItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MediaItem!' }
  },
  RootQueryToMediaItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToMediaItemConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToMediaItemConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToMediaItemConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToMediaItemConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToMediaItemConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToMediaItemConnectionWhereArgsTaxArray]' }
  },
  RootQueryToMenuConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToMenuConnectionEdge!]!' },
    nodes: { __type: '[Menu!]!' },
    pageInfo: { __type: 'RootQueryToMenuConnectionPageInfo!' }
  },
  RootQueryToMenuConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Menu!' }
  },
  RootQueryToMenuConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToMenuConnectionWhereArgs: {
    id: { __type: 'Int' },
    location: { __type: 'MenuLocationEnum' },
    slug: { __type: 'String' }
  },
  RootQueryToMenuItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToMenuItemConnectionEdge!]!' },
    nodes: { __type: '[MenuItem!]!' },
    pageInfo: { __type: 'RootQueryToMenuItemConnectionPageInfo!' }
  },
  RootQueryToMenuItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MenuItem!' }
  },
  RootQueryToMenuItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToMenuItemConnectionWhereArgs: {
    id: { __type: 'Int' },
    location: { __type: 'MenuLocationEnum' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' }
  },
  RootQueryToPageConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPageConnectionEdge!]!' },
    nodes: { __type: '[Page!]!' },
    pageInfo: { __type: 'RootQueryToPageConnectionPageInfo!' }
  },
  RootQueryToPageConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Page!' }
  },
  RootQueryToPageConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPageConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToPageConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToPageConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToPageConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToPageConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToPageConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToPageConnectionWhereArgsTaxArray]' }
  },
  RootQueryToPluginConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPluginConnectionEdge!]!' },
    nodes: { __type: '[Plugin!]!' },
    pageInfo: { __type: 'RootQueryToPluginConnectionPageInfo!' }
  },
  RootQueryToPluginConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Plugin!' }
  },
  RootQueryToPluginConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPluginConnectionWhereArgs: {
    search: { __type: 'String' },
    stati: { __type: '[PluginStatusEnum]' },
    status: { __type: 'PluginStatusEnum' }
  },
  RootQueryToPostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'RootQueryToPostConnectionPageInfo!' }
  },
  RootQueryToPostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  RootQueryToPostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPostConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'RootQueryToPostConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToPostConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToPostConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToPostConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToPostConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToPostConnectionWhereArgsTaxArray]' }
  },
  RootQueryToPostFormatConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPostFormatConnectionEdge!]!' },
    nodes: { __type: '[PostFormat!]!' },
    pageInfo: { __type: 'RootQueryToPostFormatConnectionPageInfo!' }
  },
  RootQueryToPostFormatConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PostFormat!' }
  },
  RootQueryToPostFormatConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPostFormatConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToPressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'RootQueryToPressCategoryConnectionPageInfo!' }
  },
  RootQueryToPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'PressCategory!' }
  },
  RootQueryToPressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPressCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToPressConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToPressConnectionEdge!]!' },
    nodes: { __type: '[Press!]!' },
    pageInfo: { __type: 'RootQueryToPressConnectionPageInfo!' }
  },
  RootQueryToPressConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Press!' }
  },
  RootQueryToPressConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToPressConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToPressConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToPressConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToPressConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToPressConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToPressConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToPressConnectionWhereArgsTaxArray]' }
  },
  RootQueryToReusableBlockConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToReusableBlockConnectionEdge!]!' },
    nodes: { __type: '[ReusableBlock!]!' },
    pageInfo: { __type: 'RootQueryToReusableBlockConnectionPageInfo!' }
  },
  RootQueryToReusableBlockConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ReusableBlock!' }
  },
  RootQueryToReusableBlockConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToReusableBlockConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToReusableBlockConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToReusableBlockConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToReusableBlockConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToReusableBlockConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToReusableBlockConnectionWhereArgsTaxArray]' }
  },
  RootQueryToRevisionsConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToRevisionsConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'RootQueryToRevisionsConnectionPageInfo!' }
  },
  RootQueryToRevisionsConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  RootQueryToRevisionsConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToRevisionsConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToRevisionsConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToRevisionsConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToRevisionsConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToRevisionsConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToRevisionsConnectionWhereArgsTaxArray]' }
  },
  RootQueryToTagConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToTagConnectionEdge!]!' },
    nodes: { __type: '[Tag!]!' },
    pageInfo: { __type: 'RootQueryToTagConnectionPageInfo!' }
  },
  RootQueryToTagConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Tag!' }
  },
  RootQueryToTagConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToTagConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToTaxonomyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToTaxonomyConnectionEdge!]!' },
    nodes: { __type: '[Taxonomy!]!' },
    pageInfo: { __type: 'RootQueryToTaxonomyConnectionPageInfo!' }
  },
  RootQueryToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  RootQueryToTaxonomyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToTeamMemberConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToTeamMemberConnectionEdge!]!' },
    nodes: { __type: '[TeamMember!]!' },
    pageInfo: { __type: 'RootQueryToTeamMemberConnectionPageInfo!' }
  },
  RootQueryToTeamMemberConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TeamMember!' }
  },
  RootQueryToTeamMemberConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToTeamMemberConnectionWhereArgs: {
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'RootQueryToTeamMemberConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  RootQueryToTeamMemberConnectionWhereArgsTaxArray: {
    field: { __type: 'RootQueryToTeamMemberConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  RootQueryToTeamMemberConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[RootQueryToTeamMemberConnectionWhereArgsTaxArray]' }
  },
  RootQueryToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'RootQueryToTermNodeConnectionPageInfo!' }
  },
  RootQueryToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  RootQueryToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    taxonomies: { __type: '[TaxonomyEnum]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  RootQueryToThemeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToThemeConnectionEdge!]!' },
    nodes: { __type: '[Theme!]!' },
    pageInfo: { __type: 'RootQueryToThemeConnectionPageInfo!' }
  },
  RootQueryToThemeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Theme!' }
  },
  RootQueryToThemeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToUserConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToUserConnectionEdge!]!' },
    nodes: { __type: '[User!]!' },
    pageInfo: { __type: 'RootQueryToUserConnectionPageInfo!' }
  },
  RootQueryToUserConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'User!' }
  },
  RootQueryToUserConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  RootQueryToUserConnectionWhereArgs: {
    exclude: { __type: '[Int]' },
    hasPublishedPosts: { __type: '[ContentTypeEnum]' },
    include: { __type: '[Int]' },
    login: { __type: 'String' },
    loginIn: { __type: '[String]' },
    loginNotIn: { __type: '[String]' },
    nicename: { __type: 'String' },
    nicenameIn: { __type: '[String]' },
    nicenameNotIn: { __type: '[String]' },
    offsetPagination: { __type: 'OffsetPagination' },
    orderby: { __type: '[UsersConnectionOrderbyInput]' },
    role: { __type: 'UserRoleEnum' },
    roleIn: { __type: '[UserRoleEnum]' },
    roleNotIn: { __type: '[UserRoleEnum]' },
    search: { __type: 'String' },
    searchColumns: { __type: '[UsersConnectionSearchColumnEnum]' }
  },
  RootQueryToUserRoleConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[RootQueryToUserRoleConnectionEdge!]!' },
    nodes: { __type: '[UserRole!]!' },
    pageInfo: { __type: 'RootQueryToUserRoleConnectionPageInfo!' }
  },
  RootQueryToUserRoleConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'UserRole!' }
  },
  RootQueryToUserRoleConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  SEOBreadcrumbs: {
    __typename: { __type: 'String!' },
    archivePrefix: { __type: 'String' },
    boldLast: { __type: 'Boolean' },
    enabled: { __type: 'Boolean' },
    homeText: { __type: 'String' },
    notFoundText: { __type: 'String' },
    prefix: { __type: 'String' },
    searchPrefix: { __type: 'String' },
    separator: { __type: 'String' },
    showBlogPage: { __type: 'Boolean' }
  },
  SEOConfig: {
    __typename: { __type: 'String!' },
    breadcrumbs: { __type: 'SEOBreadcrumbs' },
    contentTypes: { __type: 'SEOContentTypes' },
    openGraph: { __type: 'SEOOpenGraph' },
    redirects: { __type: '[SEORedirect]' },
    schema: { __type: 'SEOSchema' },
    social: { __type: 'SEOSocial' },
    webmaster: { __type: 'SEOWebmaster' }
  },
  SEOContentType: {
    __typename: { __type: 'String!' },
    archive: { __type: 'SEOContentTypeArchive' },
    metaDesc: { __type: 'String' },
    metaRobotsNoindex: { __type: 'Boolean' },
    schema: { __type: 'SEOPageInfoSchema' },
    schemaType: { __type: 'String' },
    title: { __type: 'String' }
  },
  SEOContentTypeArchive: {
    __typename: { __type: 'String!' },
    archiveLink: { __type: 'String' },
    breadcrumbTitle: { __type: 'String' },
    fullHead: { __type: 'String' },
    hasArchive: { __type: 'Boolean' },
    metaDesc: { __type: 'String' },
    metaRobotsNoindex: { __type: 'Boolean' },
    title: { __type: 'String' }
  },
  SEOContentTypes: {
    __typename: { __type: 'String!' },
    blockEditorPreview: { __type: 'SEOContentType' },
    company: { __type: 'SEOContentType' },
    graphqlDocument: { __type: 'SEOContentType' },
    mediaItem: { __type: 'SEOContentType' },
    page: { __type: 'SEOContentType' },
    post: { __type: 'SEOContentType' },
    press: { __type: 'SEOContentType' },
    reusableBlock: { __type: 'SEOContentType' },
    teamMember: { __type: 'SEOContentType' }
  },
  SEOOpenGraph: {
    __typename: { __type: 'String!' },
    defaultImage: { __type: 'MediaItem' },
    frontPage: { __type: 'SEOOpenGraphFrontPage' }
  },
  SEOOpenGraphFrontPage: {
    __typename: { __type: 'String!' },
    description: { __type: 'String' },
    image: { __type: 'MediaItem' },
    title: { __type: 'String' }
  },
  SEOPageInfoSchema: { __typename: { __type: 'String!' }, raw: { __type: 'String' } },
  SEOPostTypeBreadcrumbs: {
    __typename: { __type: 'String!' },
    text: { __type: 'String' },
    url: { __type: 'String' }
  },
  SEOPostTypePageInfo: {
    __typename: { __type: 'String!' },
    schema: { __type: 'SEOPageInfoSchema' }
  },
  SEOPostTypeSchema: {
    __typename: { __type: 'String!' },
    articleType: { __type: '[String]' },
    pageType: { __type: '[String]' },
    raw: { __type: 'String' }
  },
  SEORedirect: {
    __typename: { __type: 'String!' },
    format: { __type: 'String' },
    origin: { __type: 'String' },
    target: { __type: 'String' },
    type: { __type: 'Int' }
  },
  SEOSchema: {
    __typename: { __type: 'String!' },
    companyLogo: { __type: 'MediaItem' },
    companyName: { __type: 'String' },
    companyOrPerson: { __type: 'String' },
    homeUrl: { __type: 'String' },
    inLanguage: { __type: 'String' },
    logo: { __type: 'MediaItem' },
    personLogo: { __type: 'MediaItem' },
    personName: { __type: 'String' },
    siteName: { __type: 'String' },
    siteUrl: { __type: 'String' },
    wordpressSiteName: { __type: 'String' }
  },
  SEOSocial: {
    __typename: { __type: 'String!' },
    facebook: { __type: 'SEOSocialFacebook' },
    instagram: { __type: 'SEOSocialInstagram' },
    linkedIn: { __type: 'SEOSocialLinkedIn' },
    mySpace: { __type: 'SEOSocialMySpace' },
    otherSocials: { __type: '[String]' },
    pinterest: { __type: 'SEOSocialPinterest' },
    twitter: { __type: 'SEOSocialTwitter' },
    wikipedia: { __type: 'SEOSocialWikipedia' },
    youTube: { __type: 'SEOSocialYoutube' }
  },
  SEOSocialFacebook: {
    __typename: { __type: 'String!' },
    defaultImage: { __type: 'MediaItem' },
    url: { __type: 'String' }
  },
  SEOSocialInstagram: { __typename: { __type: 'String!' }, url: { __type: 'String' } },
  SEOSocialLinkedIn: { __typename: { __type: 'String!' }, url: { __type: 'String' } },
  SEOSocialMySpace: { __typename: { __type: 'String!' }, url: { __type: 'String' } },
  SEOSocialPinterest: {
    __typename: { __type: 'String!' },
    metaTag: { __type: 'String' },
    url: { __type: 'String' }
  },
  SEOSocialTwitter: {
    __typename: { __type: 'String!' },
    cardType: { __type: 'SEOCardType' },
    username: { __type: 'String' }
  },
  SEOSocialWikipedia: { __typename: { __type: 'String!' }, url: { __type: 'String' } },
  SEOSocialYoutube: { __typename: { __type: 'String!' }, url: { __type: 'String' } },
  SEOTaxonomySchema: { __typename: { __type: 'String!' }, raw: { __type: 'String' } },
  SEOUser: {
    __typename: { __type: 'String!' },
    breadcrumbTitle: { __type: 'String' },
    canonical: { __type: 'String' },
    fullHead: { __type: 'String' },
    language: { __type: 'String' },
    metaDesc: { __type: 'String' },
    metaRobotsNofollow: { __type: 'String' },
    metaRobotsNoindex: { __type: 'String' },
    opengraphDescription: { __type: 'String' },
    opengraphImage: { __type: 'MediaItem' },
    opengraphTitle: { __type: 'String' },
    region: { __type: 'String' },
    schema: { __type: 'SEOUserSchema' },
    social: { __type: 'SEOUserSocial' },
    title: { __type: 'String' },
    twitterDescription: { __type: 'String' },
    twitterImage: { __type: 'MediaItem' },
    twitterTitle: { __type: 'String' }
  },
  SEOUserSchema: {
    __typename: { __type: 'String!' },
    articleType: { __type: '[String]' },
    pageType: { __type: '[String]' },
    raw: { __type: 'String' }
  },
  SEOUserSocial: {
    __typename: { __type: 'String!' },
    facebook: { __type: 'String' },
    instagram: { __type: 'String' },
    linkedIn: { __type: 'String' },
    mySpace: { __type: 'String' },
    pinterest: { __type: 'String' },
    soundCloud: { __type: 'String' },
    twitter: { __type: 'String' },
    wikipedia: { __type: 'String' },
    youTube: { __type: 'String' }
  },
  SEOWebmaster: {
    __typename: { __type: 'String!' },
    baiduVerify: { __type: 'String' },
    googleVerify: { __type: 'String' },
    msVerify: { __type: 'String' },
    yandexVerify: { __type: 'String' }
  },
  SendPasswordResetEmailInput: {
    clientMutationId: { __type: 'String' },
    username: { __type: 'String!' }
  },
  SendPasswordResetEmailPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    success: { __type: 'Boolean' },
    user: { __type: 'User' }
  },
  Settings: {
    __typename: { __type: 'String!' },
    discussionSettingsDefaultCommentStatus: { __type: 'String' },
    discussionSettingsDefaultPingStatus: { __type: 'String' },
    generalSettingsDateFormat: { __type: 'String' },
    generalSettingsDescription: { __type: 'String' },
    generalSettingsEmail: { __type: 'String' },
    generalSettingsLanguage: { __type: 'String' },
    generalSettingsStartOfWeek: { __type: 'Int' },
    generalSettingsTimeFormat: { __type: 'String' },
    generalSettingsTimezone: { __type: 'String' },
    generalSettingsTitle: { __type: 'String' },
    generalSettingsUrl: { __type: 'String' },
    readingSettingsPageForPosts: { __type: 'Int' },
    readingSettingsPageOnFront: { __type: 'Int' },
    readingSettingsPostsPerPage: { __type: 'Int' },
    readingSettingsShowOnFront: { __type: 'String' },
    writingSettingsDefaultCategory: { __type: 'Int' },
    writingSettingsDefaultPostFormat: { __type: 'String' },
    writingSettingsUseSmilies: { __type: 'Boolean' }
  },
  Tag: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    contentNodes: {
      __type: 'TagToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'TagToContentNodeConnectionWhereArgs'
      }
    },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    posts: {
      __type: 'TagToPostConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'TagToPostConnectionWhereArgs'
      }
    },
    seo: { __type: 'TaxonomySEO' },
    slug: { __type: 'String' },
    tagId: { __type: 'Int' },
    taxonomy: { __type: 'TagToTaxonomyConnectionEdge' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' }
  },
  TagConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TagConnectionEdge!]!' },
    nodes: { __type: '[Tag!]!' },
    pageInfo: { __type: 'TagConnectionPageInfo!' },
    $on: { __type: '$TagConnection!' }
  },
  TagConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Tag!' },
    $on: { __type: '$TagConnectionEdge!' }
  },
  TagConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$TagConnectionPageInfo!' }
  },
  TagToContentNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TagToContentNodeConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'TagToContentNodeConnectionPageInfo!' }
  },
  TagToContentNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  TagToContentNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TagToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypesOfTagEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'TagToContentNodeConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  TagToContentNodeConnectionWhereArgsTaxArray: {
    field: { __type: 'TagToContentNodeConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'TagToContentNodeConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  TagToContentNodeConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[TagToContentNodeConnectionWhereArgsTaxArray]' }
  },
  TagToPostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TagToPostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'TagToPostConnectionPageInfo!' }
  },
  TagToPostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  TagToPostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TagToPostConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'TagToPostConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  TagToPostConnectionWhereArgsTaxArray: {
    field: { __type: 'TagToPostConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'TagToPostConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  TagToPostConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[TagToPostConnectionWhereArgsTaxArray]' }
  },
  TagToTaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' }
  },
  Taxonomy: {
    __typename: { __type: 'String!' },
    connectedContentTypes: {
      __type: 'TaxonomyToContentTypeConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    connectedTerms: {
      __type: 'TaxonomyToTermNodeConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    description: { __type: 'String' },
    graphqlPluralName: { __type: 'String' },
    graphqlSingleName: { __type: 'String' },
    hierarchical: { __type: 'Boolean' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    label: { __type: 'String' },
    name: { __type: 'String' },
    public: { __type: 'Boolean' },
    restBase: { __type: 'String' },
    restControllerClass: { __type: 'String' },
    showCloud: { __type: 'Boolean' },
    showInAdminColumn: { __type: 'Boolean' },
    showInGraphql: { __type: 'Boolean' },
    showInMenu: { __type: 'Boolean' },
    showInNavMenus: { __type: 'Boolean' },
    showInQuickEdit: { __type: 'Boolean' },
    showInRest: { __type: 'Boolean' },
    showUi: { __type: 'Boolean' }
  },
  TaxonomyConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TaxonomyConnectionEdge!]!' },
    nodes: { __type: '[Taxonomy!]!' },
    pageInfo: { __type: 'TaxonomyConnectionPageInfo!' },
    $on: { __type: '$TaxonomyConnection!' }
  },
  TaxonomyConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Taxonomy!' },
    $on: { __type: '$TaxonomyConnectionEdge!' }
  },
  TaxonomyConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$TaxonomyConnectionPageInfo!' }
  },
  TaxonomySEO: {
    __typename: { __type: 'String!' },
    breadcrumbs: { __type: '[SEOPostTypeBreadcrumbs]' },
    canonical: { __type: 'String' },
    cornerstone: { __type: 'Boolean' },
    focuskw: { __type: 'String' },
    fullHead: { __type: 'String' },
    metaDesc: { __type: 'String' },
    metaKeywords: { __type: 'String' },
    metaRobotsNofollow: { __type: 'String' },
    metaRobotsNoindex: { __type: 'String' },
    opengraphAuthor: { __type: 'String' },
    opengraphDescription: { __type: 'String' },
    opengraphImage: { __type: 'MediaItem' },
    opengraphModifiedTime: { __type: 'String' },
    opengraphPublishedTime: { __type: 'String' },
    opengraphPublisher: { __type: 'String' },
    opengraphSiteName: { __type: 'String' },
    opengraphTitle: { __type: 'String' },
    opengraphType: { __type: 'String' },
    opengraphUrl: { __type: 'String' },
    schema: { __type: 'SEOTaxonomySchema' },
    title: { __type: 'String' },
    twitterDescription: { __type: 'String' },
    twitterImage: { __type: 'MediaItem' },
    twitterTitle: { __type: 'String' }
  },
  TaxonomyToContentTypeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TaxonomyToContentTypeConnectionEdge!]!' },
    nodes: { __type: '[ContentType!]!' },
    pageInfo: { __type: 'TaxonomyToContentTypeConnectionPageInfo!' }
  },
  TaxonomyToContentTypeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentType!' }
  },
  TaxonomyToContentTypeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TaxonomyToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TaxonomyToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'TaxonomyToTermNodeConnectionPageInfo!' }
  },
  TaxonomyToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  TaxonomyToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TeamMember: {
    __typename: { __type: 'String!' },
    ancestors: {
      __type: 'HierarchicalContentNodeToContentNodeAncestorsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs'
      }
    },
    blocks: { __type: '[Block!]' },
    blocksJSON: { __type: 'String' },
    children: {
      __type: 'HierarchicalContentNodeToContentNodeChildrenConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    content: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    contentType: { __type: 'ContentNodeToContentTypeConnectionEdge' },
    contentTypeName: { __type: 'String!' },
    databaseId: { __type: 'Int!' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    desiredSlug: { __type: 'String' },
    editingLockedBy: { __type: 'ContentNodeToEditLockConnectionEdge' },
    enclosure: { __type: 'String' },
    enqueuedScripts: {
      __type: 'ContentNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'ContentNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    guid: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isPreview: { __type: 'Boolean' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastEditedBy: { __type: 'ContentNodeToEditLastConnectionEdge' },
    link: { __type: 'String' },
    modified: { __type: 'String' },
    modifiedGmt: { __type: 'String' },
    parent: { __type: 'HierarchicalContentNodeToParentContentNodeConnectionEdge' },
    parentDatabaseId: { __type: 'Int' },
    parentId: { __type: 'ID' },
    pressCategories: {
      __type: 'TeamMemberToPressCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'TeamMemberToPressCategoryConnectionWhereArgs'
      }
    },
    preview: { __type: 'TeamMemberToPreviewConnectionEdge' },
    previewBlocks: { __type: '[Block!]' },
    previewBlocksJSON: { __type: 'String' },
    previewRevisionDatabaseId: { __type: 'Int' },
    previewRevisionId: { __type: 'ID' },
    seo: { __type: 'PostTypeSEO' },
    slug: { __type: 'String' },
    status: { __type: 'String' },
    team: { __type: 'TeamMember_Team' },
    teamInvestments: { __type: 'TeamMember_Teaminvestments' },
    teamMemberId: { __type: 'Int!' },
    template: { __type: 'ContentTemplate' },
    templates: { __type: '[String]' },
    terms: {
      __type: 'TeamMemberToTermNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'TeamMemberToTermNodeConnectionWhereArgs'
      }
    },
    title: { __type: 'String', __args: { format: 'PostObjectFieldFormatEnum' } },
    uri: { __type: 'String' }
  },
  TeamMemberConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TeamMemberConnectionEdge!]!' },
    nodes: { __type: '[TeamMember!]!' },
    pageInfo: { __type: 'TeamMemberConnectionPageInfo!' },
    $on: { __type: '$TeamMemberConnection!' }
  },
  TeamMemberConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TeamMember!' },
    $on: { __type: '$TeamMemberConnectionEdge!' }
  },
  TeamMemberConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$TeamMemberConnectionPageInfo!' }
  },
  TeamMemberPressCategoriesInput: {
    append: { __type: 'Boolean' },
    nodes: { __type: '[TeamMemberPressCategoriesNodeInput]' }
  },
  TeamMemberPressCategoriesNodeInput: {
    description: { __type: 'String' },
    id: { __type: 'ID' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  TeamMemberToPressCategoryConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TeamMemberToPressCategoryConnectionEdge!]!' },
    nodes: { __type: '[PressCategory!]!' },
    pageInfo: { __type: 'TeamMemberToPressCategoryConnectionPageInfo!' }
  },
  TeamMemberToPressCategoryConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    isPrimary: { __type: 'Boolean' },
    node: { __type: 'PressCategory!' }
  },
  TeamMemberToPressCategoryConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TeamMemberToPressCategoryConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  TeamMemberToPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TeamMember!' }
  },
  TeamMemberToTermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TeamMemberToTermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'TeamMemberToTermNodeConnectionPageInfo!' }
  },
  TeamMemberToTermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' }
  },
  TeamMemberToTermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TeamMemberToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: 'String' },
    childOf: { __type: 'Int' },
    childless: { __type: 'Boolean' },
    descriptionLike: { __type: 'String' },
    exclude: { __type: '[ID]' },
    excludeTree: { __type: '[ID]' },
    hideEmpty: { __type: 'Boolean' },
    hierarchical: { __type: 'Boolean' },
    include: { __type: '[ID]' },
    name: { __type: '[String]' },
    nameLike: { __type: 'String' },
    objectIds: { __type: '[ID]' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'TermObjectsConnectionOrderbyEnum' },
    padCounts: { __type: 'Boolean' },
    parent: { __type: 'Int' },
    search: { __type: 'String' },
    slug: { __type: '[String]' },
    taxonomies: { __type: '[TaxonomyEnum]' },
    termTaxonomId: { __type: '[ID]' },
    termTaxonomyId: { __type: '[ID]' },
    updateTermMetaCache: { __type: 'Boolean' }
  },
  TeamMember_Team: {
    __typename: { __type: 'String!' },
    bio: { __type: 'String' },
    bioImage: { __type: 'MediaItem' },
    boardPositions: { __type: '[TeamMember_Team_boardPositions]' },
    email: { __type: 'String' },
    fieldGroupName: { __type: 'String' },
    gridImage: { __type: 'MediaItem' },
    investments: { __type: '[TeamMember_Team_Investments]' },
    linkedinUrl: { __type: 'String' },
    teamType: { __type: 'String' },
    title: { __type: 'String' },
    twitterUrl: { __type: 'String' }
  },
  TeamMember_Team_Investments: {
    __typename: { __type: 'String!' },
    $on: { __type: '$TeamMember_Team_Investments!' }
  },
  TeamMember_Team_boardPositions: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    text: { __type: 'String' }
  },
  TeamMember_Teaminvestments: {
    __typename: { __type: 'String!' },
    fieldGroupName: { __type: 'String' },
    investments: { __type: '[TeamMember_Teaminvestments_Investments]' }
  },
  TeamMember_Teaminvestments_Investments: {
    __typename: { __type: 'String!' },
    $on: { __type: '$TeamMember_Teaminvestments_Investments!' }
  },
  TermNode: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    count: { __type: 'Int' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    enqueuedScripts: {
      __type: 'TermNodeToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'TermNodeToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    link: { __type: 'String' },
    name: { __type: 'String' },
    slug: { __type: 'String' },
    taxonomyName: { __type: 'String' },
    templates: { __type: '[String]' },
    termGroupId: { __type: 'Int' },
    termTaxonomyId: { __type: 'Int' },
    uri: { __type: 'String' },
    $on: { __type: '$TermNode!' }
  },
  TermNodeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TermNodeConnectionEdge!]!' },
    nodes: { __type: '[TermNode!]!' },
    pageInfo: { __type: 'TermNodeConnectionPageInfo!' },
    $on: { __type: '$TermNodeConnection!' }
  },
  TermNodeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'TermNode!' },
    $on: { __type: '$TermNodeConnectionEdge!' }
  },
  TermNodeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$TermNodeConnectionPageInfo!' }
  },
  TermNodeToEnqueuedScriptConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TermNodeToEnqueuedScriptConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedScript!]!' },
    pageInfo: { __type: 'TermNodeToEnqueuedScriptConnectionPageInfo!' }
  },
  TermNodeToEnqueuedScriptConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedScript!' }
  },
  TermNodeToEnqueuedScriptConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  TermNodeToEnqueuedStylesheetConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[TermNodeToEnqueuedStylesheetConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedStylesheet!]!' },
    pageInfo: { __type: 'TermNodeToEnqueuedStylesheetConnectionPageInfo!' }
  },
  TermNodeToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedStylesheet!' }
  },
  TermNodeToEnqueuedStylesheetConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  Theme: {
    __typename: { __type: 'String!' },
    author: { __type: 'String' },
    authorUri: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    name: { __type: 'String' },
    screenshot: { __type: 'String' },
    slug: { __type: 'String' },
    tags: { __type: '[String]' },
    themeUri: { __type: 'String' },
    version: { __type: 'String' }
  },
  ThemeConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[ThemeConnectionEdge!]!' },
    nodes: { __type: '[Theme!]!' },
    pageInfo: { __type: 'ThemeConnectionPageInfo!' },
    $on: { __type: '$ThemeConnection!' }
  },
  ThemeConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Theme!' },
    $on: { __type: '$ThemeConnectionEdge!' }
  },
  ThemeConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$ThemeConnectionPageInfo!' }
  },
  UniformResourceIdentifiable: {
    __typename: { __type: 'String!' },
    conditionalTags: { __type: 'ConditionalTags' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isTermNode: { __type: 'Boolean!' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' },
    $on: { __type: '$UniformResourceIdentifiable!' }
  },
  UpdateBlockEditorPreviewInput: {
    authorId: { __type: 'ID' },
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateBlockEditorPreviewPayload: {
    __typename: { __type: 'String!' },
    blockEditorPreview: { __type: 'BlockEditorPreview' },
    clientMutationId: { __type: 'String' }
  },
  UpdateCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    name: { __type: 'String' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  UpdateCategoryPayload: {
    __typename: { __type: 'String!' },
    category: { __type: 'Category' },
    clientMutationId: { __type: 'String' }
  },
  UpdateCommentInput: {
    approved: { __type: 'String' },
    author: { __type: 'String' },
    authorEmail: { __type: 'String' },
    authorUrl: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    commentOn: { __type: 'Int' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    parent: { __type: 'ID' },
    status: { __type: 'CommentStatusEnum' },
    type: { __type: 'String' }
  },
  UpdateCommentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    comment: { __type: 'Comment' },
    success: { __type: 'Boolean' }
  },
  UpdateCompanyCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    name: { __type: 'String' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  UpdateCompanyCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    companyCategory: { __type: 'CompanyCategory' }
  },
  UpdateCompanyInput: {
    clientMutationId: { __type: 'String' },
    companyCategories: { __type: 'CompanyCompanyCategoriesInput' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    excerpt: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateCompanyPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    company: { __type: 'Company' }
  },
  UpdateGraphqlDocumentInput: {
    alias: { __type: '[String!]' },
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    description: { __type: 'String' },
    grant: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    maxAgeHeader: { __type: 'Int' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateGraphqlDocumentPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    graphqlDocument: { __type: 'GraphqlDocument' }
  },
  UpdateMediaItemInput: {
    altText: { __type: 'String' },
    authorId: { __type: 'ID' },
    caption: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    date: { __type: 'String' },
    dateGmt: { __type: 'String' },
    description: { __type: 'String' },
    filePath: { __type: 'String' },
    fileType: { __type: 'MimeTypeEnum' },
    id: { __type: 'ID!' },
    parentId: { __type: 'ID' },
    pingStatus: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'MediaItemStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateMediaItemPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    mediaItem: { __type: 'MediaItem' }
  },
  UpdatePageInput: {
    authorId: { __type: 'ID' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdatePagePayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    page: { __type: 'Page' }
  },
  UpdatePostFormatInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  UpdatePostFormatPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    postFormat: { __type: 'PostFormat' }
  },
  UpdatePostInput: {
    authorId: { __type: 'ID' },
    categories: { __type: 'PostCategoriesInput' },
    clientMutationId: { __type: 'String' },
    commentStatus: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    excerpt: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    pingStatus: { __type: 'String' },
    pinged: { __type: '[String]' },
    postFormats: { __type: 'PostPostFormatsInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    tags: { __type: 'PostTagsInput' },
    title: { __type: 'String' },
    toPing: { __type: '[String]' }
  },
  UpdatePostPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    post: { __type: 'Post' }
  },
  UpdatePressCategoryInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    name: { __type: 'String' },
    parentId: { __type: 'ID' },
    slug: { __type: 'String' }
  },
  UpdatePressCategoryPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    pressCategory: { __type: 'PressCategory' }
  },
  UpdatePressInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    pressCategories: { __type: 'PressPressCategoriesInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdatePressPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    press: { __type: 'Press' }
  },
  UpdateReusableBlockInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    password: { __type: 'String' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateReusableBlockPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    reusableBlock: { __type: 'ReusableBlock' }
  },
  UpdateSettingsInput: {
    clientMutationId: { __type: 'String' },
    discussionSettingsDefaultCommentStatus: { __type: 'String' },
    discussionSettingsDefaultPingStatus: { __type: 'String' },
    generalSettingsDateFormat: { __type: 'String' },
    generalSettingsDescription: { __type: 'String' },
    generalSettingsEmail: { __type: 'String' },
    generalSettingsLanguage: { __type: 'String' },
    generalSettingsStartOfWeek: { __type: 'Int' },
    generalSettingsTimeFormat: { __type: 'String' },
    generalSettingsTimezone: { __type: 'String' },
    generalSettingsTitle: { __type: 'String' },
    generalSettingsUrl: { __type: 'String' },
    readingSettingsPageForPosts: { __type: 'Int' },
    readingSettingsPageOnFront: { __type: 'Int' },
    readingSettingsPostsPerPage: { __type: 'Int' },
    readingSettingsShowOnFront: { __type: 'String' },
    writingSettingsDefaultCategory: { __type: 'Int' },
    writingSettingsDefaultPostFormat: { __type: 'String' },
    writingSettingsUseSmilies: { __type: 'Boolean' }
  },
  UpdateSettingsPayload: {
    __typename: { __type: 'String!' },
    allSettings: { __type: 'Settings' },
    clientMutationId: { __type: 'String' },
    discussionSettings: { __type: 'DiscussionSettings' },
    generalSettings: { __type: 'GeneralSettings' },
    readingSettings: { __type: 'ReadingSettings' },
    writingSettings: { __type: 'WritingSettings' }
  },
  UpdateTagInput: {
    aliasOf: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    id: { __type: 'ID!' },
    name: { __type: 'String' },
    slug: { __type: 'String' }
  },
  UpdateTagPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    tag: { __type: 'Tag' }
  },
  UpdateTeamMemberInput: {
    clientMutationId: { __type: 'String' },
    content: { __type: 'String' },
    date: { __type: 'String' },
    id: { __type: 'ID!' },
    ignoreEditLock: { __type: 'Boolean' },
    menuOrder: { __type: 'Int' },
    parentId: { __type: 'ID' },
    password: { __type: 'String' },
    pressCategories: { __type: 'TeamMemberPressCategoriesInput' },
    slug: { __type: 'String' },
    status: { __type: 'PostStatusEnum' },
    title: { __type: 'String' }
  },
  UpdateTeamMemberPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    teamMember: { __type: 'TeamMember' }
  },
  UpdateUserInput: {
    aim: { __type: 'String' },
    clientMutationId: { __type: 'String' },
    description: { __type: 'String' },
    displayName: { __type: 'String' },
    email: { __type: 'String' },
    firstName: { __type: 'String' },
    id: { __type: 'ID!' },
    jabber: { __type: 'String' },
    lastName: { __type: 'String' },
    locale: { __type: 'String' },
    nicename: { __type: 'String' },
    nickname: { __type: 'String' },
    password: { __type: 'String' },
    registered: { __type: 'String' },
    richEditing: { __type: 'String' },
    roles: { __type: '[String]' },
    websiteUrl: { __type: 'String' },
    yim: { __type: 'String' }
  },
  UpdateUserPayload: {
    __typename: { __type: 'String!' },
    clientMutationId: { __type: 'String' },
    user: { __type: 'User' }
  },
  User: {
    __typename: { __type: 'String!' },
    avatar: {
      __type: 'Avatar',
      __args: { forceDefault: 'Boolean', rating: 'AvatarRatingEnum', size: 'Int' }
    },
    blockEditorPreviews: {
      __type: 'UserToBlockEditorPreviewConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToBlockEditorPreviewConnectionWhereArgs'
      }
    },
    capKey: { __type: 'String' },
    capabilities: { __type: '[String]' },
    comments: {
      __type: 'UserToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToCommentConnectionWhereArgs'
      }
    },
    conditionalTags: { __type: 'ConditionalTags' },
    databaseId: { __type: 'Int!' },
    description: { __type: 'String' },
    email: { __type: 'String' },
    enqueuedScripts: {
      __type: 'UserToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    enqueuedStylesheets: {
      __type: 'UserToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    extraCapabilities: { __type: '[String]' },
    firstName: { __type: 'String' },
    id: { __type: 'ID!' },
    isContentNode: { __type: 'Boolean!' },
    isRestricted: { __type: 'Boolean' },
    isTermNode: { __type: 'Boolean!' },
    lastName: { __type: 'String' },
    locale: { __type: 'String' },
    mediaItems: {
      __type: 'UserToMediaItemConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToMediaItemConnectionWhereArgs'
      }
    },
    name: { __type: 'String' },
    nicename: { __type: 'String' },
    nickname: { __type: 'String' },
    pages: {
      __type: 'UserToPageConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToPageConnectionWhereArgs'
      }
    },
    posts: {
      __type: 'UserToPostConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToPostConnectionWhereArgs'
      }
    },
    registeredDate: { __type: 'String' },
    revisions: {
      __type: 'UserToRevisionsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'UserToRevisionsConnectionWhereArgs'
      }
    },
    roles: {
      __type: 'UserToUserRoleConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    seo: { __type: 'SEOUser' },
    shouldShowAdminToolbar: { __type: 'Boolean' },
    shouldShowFaustToolbar: { __type: 'Boolean' },
    slug: { __type: 'String' },
    templates: { __type: '[String]' },
    uri: { __type: 'String' },
    url: { __type: 'String' },
    userId: { __type: 'Int' },
    username: { __type: 'String' }
  },
  UserConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserConnectionEdge!]!' },
    nodes: { __type: '[User!]!' },
    pageInfo: { __type: 'UserConnectionPageInfo!' },
    $on: { __type: '$UserConnection!' }
  },
  UserConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'User!' },
    $on: { __type: '$UserConnectionEdge!' }
  },
  UserConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$UserConnectionPageInfo!' }
  },
  UserRole: {
    __typename: { __type: 'String!' },
    capabilities: { __type: '[String]' },
    displayName: { __type: 'String' },
    id: { __type: 'ID!' },
    isRestricted: { __type: 'Boolean' },
    name: { __type: 'String' }
  },
  UserRoleConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserRoleConnectionEdge!]!' },
    nodes: { __type: '[UserRole!]!' },
    pageInfo: { __type: 'UserRoleConnectionPageInfo!' },
    $on: { __type: '$UserRoleConnection!' }
  },
  UserRoleConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'UserRole!' },
    $on: { __type: '$UserRoleConnectionEdge!' }
  },
  UserRoleConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$UserRoleConnectionPageInfo!' }
  },
  UserToBlockEditorPreviewConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToBlockEditorPreviewConnectionEdge!]!' },
    nodes: { __type: '[BlockEditorPreview!]!' },
    pageInfo: { __type: 'UserToBlockEditorPreviewConnectionPageInfo!' }
  },
  UserToBlockEditorPreviewConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'BlockEditorPreview!' }
  },
  UserToBlockEditorPreviewConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToBlockEditorPreviewConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'UserToBlockEditorPreviewConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  UserToBlockEditorPreviewConnectionWhereArgsTaxArray: {
    field: { __type: 'UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  UserToBlockEditorPreviewConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[UserToBlockEditorPreviewConnectionWhereArgsTaxArray]' }
  },
  UserToCommentConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToCommentConnectionEdge!]!' },
    nodes: { __type: '[Comment!]!' },
    pageInfo: { __type: 'UserToCommentConnectionPageInfo!' }
  },
  UserToCommentConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Comment!' }
  },
  UserToCommentConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToCommentConnectionWhereArgs: {
    authorEmail: { __type: 'String' },
    authorIn: { __type: '[ID]' },
    authorNotIn: { __type: '[ID]' },
    authorUrl: { __type: 'String' },
    commentIn: { __type: '[ID]' },
    commentNotIn: { __type: '[ID]' },
    commentType: { __type: 'String' },
    commentTypeIn: { __type: '[String]' },
    commentTypeNotIn: { __type: 'String' },
    contentAuthor: { __type: '[ID]' },
    contentAuthorIn: { __type: '[ID]' },
    contentAuthorNotIn: { __type: '[ID]' },
    contentId: { __type: 'ID' },
    contentIdIn: { __type: '[ID]' },
    contentIdNotIn: { __type: '[ID]' },
    contentName: { __type: 'String' },
    contentParent: { __type: 'Int' },
    contentStatus: { __type: '[PostStatusEnum]' },
    contentType: { __type: '[ContentTypeEnum]' },
    includeUnapproved: { __type: '[ID]' },
    karma: { __type: 'Int' },
    order: { __type: 'OrderEnum' },
    orderby: { __type: 'CommentsConnectionOrderbyEnum' },
    parent: { __type: 'Int' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    search: { __type: 'String' },
    status: { __type: 'String' },
    userId: { __type: 'ID' }
  },
  UserToEnqueuedScriptConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToEnqueuedScriptConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedScript!]!' },
    pageInfo: { __type: 'UserToEnqueuedScriptConnectionPageInfo!' }
  },
  UserToEnqueuedScriptConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedScript!' }
  },
  UserToEnqueuedScriptConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToEnqueuedStylesheetConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToEnqueuedStylesheetConnectionEdge!]!' },
    nodes: { __type: '[EnqueuedStylesheet!]!' },
    pageInfo: { __type: 'UserToEnqueuedStylesheetConnectionPageInfo!' }
  },
  UserToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'EnqueuedStylesheet!' }
  },
  UserToEnqueuedStylesheetConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToMediaItemConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToMediaItemConnectionEdge!]!' },
    nodes: { __type: '[MediaItem!]!' },
    pageInfo: { __type: 'UserToMediaItemConnectionPageInfo!' }
  },
  UserToMediaItemConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'MediaItem!' }
  },
  UserToMediaItemConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToMediaItemConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'UserToMediaItemConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  UserToMediaItemConnectionWhereArgsTaxArray: {
    field: { __type: 'UserToMediaItemConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'UserToMediaItemConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  UserToMediaItemConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[UserToMediaItemConnectionWhereArgsTaxArray]' }
  },
  UserToPageConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToPageConnectionEdge!]!' },
    nodes: { __type: '[Page!]!' },
    pageInfo: { __type: 'UserToPageConnectionPageInfo!' }
  },
  UserToPageConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Page!' }
  },
  UserToPageConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToPageConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'UserToPageConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  UserToPageConnectionWhereArgsTaxArray: {
    field: { __type: 'UserToPageConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'UserToPageConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  UserToPageConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[UserToPageConnectionWhereArgsTaxArray]' }
  },
  UserToPostConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToPostConnectionEdge!]!' },
    nodes: { __type: '[Post!]!' },
    pageInfo: { __type: 'UserToPostConnectionPageInfo!' }
  },
  UserToPostConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'Post!' }
  },
  UserToPostConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToPostConnectionWhereArgs: {
    author: { __type: 'Int' },
    authorIn: { __type: '[ID]' },
    authorName: { __type: 'String' },
    authorNotIn: { __type: '[ID]' },
    categoryId: { __type: 'Int' },
    categoryIn: { __type: '[ID]' },
    categoryName: { __type: 'String' },
    categoryNotIn: { __type: '[ID]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    tag: { __type: 'String' },
    tagId: { __type: 'String' },
    tagIn: { __type: '[ID]' },
    tagNotIn: { __type: '[ID]' },
    tagSlugAnd: { __type: '[String]' },
    tagSlugIn: { __type: '[String]' },
    taxQuery: { __type: 'UserToPostConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  UserToPostConnectionWhereArgsTaxArray: {
    field: { __type: 'UserToPostConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'UserToPostConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  UserToPostConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[UserToPostConnectionWhereArgsTaxArray]' }
  },
  UserToRevisionsConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToRevisionsConnectionEdge!]!' },
    nodes: { __type: '[ContentNode!]!' },
    pageInfo: { __type: 'UserToRevisionsConnectionPageInfo!' }
  },
  UserToRevisionsConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'ContentNode!' }
  },
  UserToRevisionsConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UserToRevisionsConnectionWhereArgs: {
    contentTypes: { __type: '[ContentTypeEnum]' },
    dateQuery: { __type: 'DateQueryInput' },
    hasPassword: { __type: 'Boolean' },
    id: { __type: 'Int' },
    in: { __type: '[ID]' },
    mimeType: { __type: 'MimeTypeEnum' },
    name: { __type: 'String' },
    nameIn: { __type: '[String]' },
    notIn: { __type: '[ID]' },
    orderby: { __type: '[PostObjectsConnectionOrderbyInput]' },
    parent: { __type: 'ID' },
    parentIn: { __type: '[ID]' },
    parentNotIn: { __type: '[ID]' },
    password: { __type: 'String' },
    search: { __type: 'String' },
    stati: { __type: '[PostStatusEnum]' },
    status: { __type: 'PostStatusEnum' },
    taxQuery: { __type: 'UserToRevisionsConnectionWhereArgsTaxQuery' },
    title: { __type: 'String' }
  },
  UserToRevisionsConnectionWhereArgsTaxArray: {
    field: { __type: 'UserToRevisionsConnectionWhereArgsTaxQueryField' },
    includeChildren: { __type: 'Boolean' },
    operator: { __type: 'UserToRevisionsConnectionWhereArgsTaxQueryOperator' },
    taxonomy: { __type: 'TaxonomyEnum' },
    terms: { __type: '[String]' }
  },
  UserToRevisionsConnectionWhereArgsTaxQuery: {
    relation: { __type: 'RelationEnum' },
    taxArray: { __type: '[UserToRevisionsConnectionWhereArgsTaxArray]' }
  },
  UserToUserRoleConnection: {
    __typename: { __type: 'String!' },
    edges: { __type: '[UserToUserRoleConnectionEdge!]!' },
    nodes: { __type: '[UserRole!]!' },
    pageInfo: { __type: 'UserToUserRoleConnectionPageInfo!' }
  },
  UserToUserRoleConnectionEdge: {
    __typename: { __type: 'String!' },
    cursor: { __type: 'String' },
    node: { __type: 'UserRole!' }
  },
  UserToUserRoleConnectionPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' }
  },
  UsersConnectionOrderbyInput: {
    field: { __type: 'UsersConnectionOrderbyEnum!' },
    order: { __type: 'OrderEnum' }
  },
  WPPageInfo: {
    __typename: { __type: 'String!' },
    endCursor: { __type: 'String' },
    hasNextPage: { __type: 'Boolean!' },
    hasPreviousPage: { __type: 'Boolean!' },
    offsetPagination: { __type: 'OffsetPaginationPageInfo' },
    seo: { __type: 'SEOPostTypePageInfo' },
    startCursor: { __type: 'String' },
    $on: { __type: '$WPPageInfo!' }
  },
  WritingSettings: {
    __typename: { __type: 'String!' },
    defaultCategory: { __type: 'Int' },
    defaultPostFormat: { __type: 'String' },
    useSmilies: { __type: 'Boolean' }
  },
  YoastFaqBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'YoastFaqBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  YoastFaqBlockAttributes: {
    __typename: { __type: 'String!' },
    additionalListCssClasses: { __type: 'String' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    questions: { __type: 'BlockAttributesArray' }
  },
  YoastFaqBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$YoastFaqBlockAttributesUnion!' }
  },
  YoastFaqBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    additionalListCssClasses: { __type: 'String' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    questions: { __type: 'BlockAttributesArray' }
  },
  YoastHowToBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'YoastHowToBlockAttributesUnion' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  YoastHowToBlockAttributes: {
    __typename: { __type: 'String!' },
    additionalListCssClasses: { __type: 'String' },
    className: { __type: 'String' },
    days: { __type: 'String' },
    defaultDurationText: { __type: 'String' },
    description: { __type: 'BlockAttributesArray' },
    durationText: { __type: 'String' },
    hasDuration: { __type: 'Boolean' },
    hours: { __type: 'String' },
    jsonDescription: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minutes: { __type: 'String' },
    steps: { __type: 'BlockAttributesArray' },
    unorderedList: { __type: 'Boolean' }
  },
  YoastHowToBlockAttributesUnion: {
    __typename: { __type: 'String!' },
    $on: { __type: '$YoastHowToBlockAttributesUnion!' }
  },
  YoastHowToBlockDeprecatedV1Attributes: {
    __typename: { __type: 'String!' },
    additionalListCssClasses: { __type: 'String' },
    className: { __type: 'String' },
    days: { __type: 'String' },
    defaultDurationText: { __type: 'String' },
    description: { __type: 'BlockAttributesArray' },
    durationText: { __type: 'String' },
    hasDuration: { __type: 'Boolean' },
    hours: { __type: 'String' },
    jsonDescription: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minutes: { __type: 'String' },
    steps: { __type: 'BlockAttributesArray' },
    unorderedList: { __type: 'Boolean' }
  },
  YoastHowToBlockDeprecatedV2Attributes: {
    __typename: { __type: 'String!' },
    additionalListCssClasses: { __type: 'String' },
    className: { __type: 'String' },
    days: { __type: 'String' },
    defaultDurationText: { __type: 'String' },
    description: { __type: 'BlockAttributesArray' },
    durationText: { __type: 'String' },
    hasDuration: { __type: 'Boolean' },
    hours: { __type: 'String' },
    jsonDescription: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' },
    minutes: { __type: 'String' },
    steps: { __type: 'BlockAttributesArray' },
    unorderedList: { __type: 'Boolean' }
  },
  YoastSeoBreadcrumbsBlock: {
    __typename: { __type: 'String!' },
    attributes: { __type: 'YoastSeoBreadcrumbsBlockAttributes' },
    attributesJSON: { __type: 'String' },
    dynamicContent: { __type: 'String' },
    innerBlocks: { __type: '[Block!]' },
    isDynamic: { __type: 'Boolean!' },
    name: { __type: 'String!' },
    order: { __type: 'Int!' },
    originalContent: { __type: 'String' },
    parentNode: { __type: 'Node!' },
    parentNodeDatabaseId: { __type: 'Int!' },
    saveContent: { __type: 'String' }
  },
  YoastSeoBreadcrumbsBlockAttributes: {
    __typename: { __type: 'String!' },
    className: { __type: 'String' },
    lock: { __type: 'BlockAttributesObject' },
    metadata: { __type: 'BlockAttributesObject' }
  },
  mutation: {
    __typename: { __type: 'String!' },
    createBlockEditorPreview: {
      __type: 'CreateBlockEditorPreviewPayload',
      __args: { input: 'CreateBlockEditorPreviewInput!' }
    },
    createCategory: { __type: 'CreateCategoryPayload', __args: { input: 'CreateCategoryInput!' } },
    createComment: { __type: 'CreateCommentPayload', __args: { input: 'CreateCommentInput!' } },
    createCompany: { __type: 'CreateCompanyPayload', __args: { input: 'CreateCompanyInput!' } },
    createCompanyCategory: {
      __type: 'CreateCompanyCategoryPayload',
      __args: { input: 'CreateCompanyCategoryInput!' }
    },
    createGraphqlDocument: {
      __type: 'CreateGraphqlDocumentPayload',
      __args: { input: 'CreateGraphqlDocumentInput!' }
    },
    createMediaItem: {
      __type: 'CreateMediaItemPayload',
      __args: { input: 'CreateMediaItemInput!' }
    },
    createPage: { __type: 'CreatePagePayload', __args: { input: 'CreatePageInput!' } },
    createPost: { __type: 'CreatePostPayload', __args: { input: 'CreatePostInput!' } },
    createPostFormat: {
      __type: 'CreatePostFormatPayload',
      __args: { input: 'CreatePostFormatInput!' }
    },
    createPress: { __type: 'CreatePressPayload', __args: { input: 'CreatePressInput!' } },
    createPressCategory: {
      __type: 'CreatePressCategoryPayload',
      __args: { input: 'CreatePressCategoryInput!' }
    },
    createReusableBlock: {
      __type: 'CreateReusableBlockPayload',
      __args: { input: 'CreateReusableBlockInput!' }
    },
    createTag: { __type: 'CreateTagPayload', __args: { input: 'CreateTagInput!' } },
    createTeamMember: {
      __type: 'CreateTeamMemberPayload',
      __args: { input: 'CreateTeamMemberInput!' }
    },
    createUser: { __type: 'CreateUserPayload', __args: { input: 'CreateUserInput!' } },
    deleteBlockEditorPreview: {
      __type: 'DeleteBlockEditorPreviewPayload',
      __args: { input: 'DeleteBlockEditorPreviewInput!' }
    },
    deleteCategory: { __type: 'DeleteCategoryPayload', __args: { input: 'DeleteCategoryInput!' } },
    deleteComment: { __type: 'DeleteCommentPayload', __args: { input: 'DeleteCommentInput!' } },
    deleteCompany: { __type: 'DeleteCompanyPayload', __args: { input: 'DeleteCompanyInput!' } },
    deleteCompanyCategory: {
      __type: 'DeleteCompanyCategoryPayload',
      __args: { input: 'DeleteCompanyCategoryInput!' }
    },
    deleteGraphqlDocument: {
      __type: 'DeleteGraphqlDocumentPayload',
      __args: { input: 'DeleteGraphqlDocumentInput!' }
    },
    deleteMediaItem: {
      __type: 'DeleteMediaItemPayload',
      __args: { input: 'DeleteMediaItemInput!' }
    },
    deletePage: { __type: 'DeletePagePayload', __args: { input: 'DeletePageInput!' } },
    deletePost: { __type: 'DeletePostPayload', __args: { input: 'DeletePostInput!' } },
    deletePostFormat: {
      __type: 'DeletePostFormatPayload',
      __args: { input: 'DeletePostFormatInput!' }
    },
    deletePress: { __type: 'DeletePressPayload', __args: { input: 'DeletePressInput!' } },
    deletePressCategory: {
      __type: 'DeletePressCategoryPayload',
      __args: { input: 'DeletePressCategoryInput!' }
    },
    deleteReusableBlock: {
      __type: 'DeleteReusableBlockPayload',
      __args: { input: 'DeleteReusableBlockInput!' }
    },
    deleteTag: { __type: 'DeleteTagPayload', __args: { input: 'DeleteTagInput!' } },
    deleteTeamMember: {
      __type: 'DeleteTeamMemberPayload',
      __args: { input: 'DeleteTeamMemberInput!' }
    },
    deleteUser: { __type: 'DeleteUserPayload', __args: { input: 'DeleteUserInput!' } },
    generateAuthorizationCode: {
      __type: 'GenerateAuthorizationCodePayload',
      __args: { input: 'GenerateAuthorizationCodeInput!' }
    },
    increaseCount: { __type: 'Int', __args: { count: 'Int' } },
    registerUser: { __type: 'RegisterUserPayload', __args: { input: 'RegisterUserInput!' } },
    resetUserPassword: {
      __type: 'ResetUserPasswordPayload',
      __args: { input: 'ResetUserPasswordInput!' }
    },
    restoreComment: { __type: 'RestoreCommentPayload', __args: { input: 'RestoreCommentInput!' } },
    sendPasswordResetEmail: {
      __type: 'SendPasswordResetEmailPayload',
      __args: { input: 'SendPasswordResetEmailInput!' }
    },
    updateBlockEditorPreview: {
      __type: 'UpdateBlockEditorPreviewPayload',
      __args: { input: 'UpdateBlockEditorPreviewInput!' }
    },
    updateCategory: { __type: 'UpdateCategoryPayload', __args: { input: 'UpdateCategoryInput!' } },
    updateComment: { __type: 'UpdateCommentPayload', __args: { input: 'UpdateCommentInput!' } },
    updateCompany: { __type: 'UpdateCompanyPayload', __args: { input: 'UpdateCompanyInput!' } },
    updateCompanyCategory: {
      __type: 'UpdateCompanyCategoryPayload',
      __args: { input: 'UpdateCompanyCategoryInput!' }
    },
    updateGraphqlDocument: {
      __type: 'UpdateGraphqlDocumentPayload',
      __args: { input: 'UpdateGraphqlDocumentInput!' }
    },
    updateMediaItem: {
      __type: 'UpdateMediaItemPayload',
      __args: { input: 'UpdateMediaItemInput!' }
    },
    updatePage: { __type: 'UpdatePagePayload', __args: { input: 'UpdatePageInput!' } },
    updatePost: { __type: 'UpdatePostPayload', __args: { input: 'UpdatePostInput!' } },
    updatePostFormat: {
      __type: 'UpdatePostFormatPayload',
      __args: { input: 'UpdatePostFormatInput!' }
    },
    updatePress: { __type: 'UpdatePressPayload', __args: { input: 'UpdatePressInput!' } },
    updatePressCategory: {
      __type: 'UpdatePressCategoryPayload',
      __args: { input: 'UpdatePressCategoryInput!' }
    },
    updateReusableBlock: {
      __type: 'UpdateReusableBlockPayload',
      __args: { input: 'UpdateReusableBlockInput!' }
    },
    updateSettings: { __type: 'UpdateSettingsPayload', __args: { input: 'UpdateSettingsInput!' } },
    updateTag: { __type: 'UpdateTagPayload', __args: { input: 'UpdateTagInput!' } },
    updateTeamMember: {
      __type: 'UpdateTeamMemberPayload',
      __args: { input: 'UpdateTeamMemberInput!' }
    },
    updateUser: { __type: 'UpdateUserPayload', __args: { input: 'UpdateUserInput!' } }
  },
  query: {
    __typename: { __type: 'String!' },
    acfOptionsFooter: { __type: 'AcfOptionsFooter' },
    acfOptionsNotifications: { __type: 'AcfOptionsNotifications' },
    allSettings: { __type: 'Settings' },
    blockEditorContentNodes: {
      __type: 'BlockEditorContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'BlockEditorContentNodeConnectionWhereArgs'
      }
    },
    blockEditorPreview: {
      __type: 'BlockEditorPreview',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'BlockEditorPreviewIdType' }
    },
    blockEditorPreviewBy: {
      __type: 'BlockEditorPreview',
      __args: { blockEditorPreviewId: 'Int', id: 'ID', slug: 'String', uri: 'String' }
    },
    blockEditorPreviews: {
      __type: 'RootQueryToBlockEditorPreviewConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToBlockEditorPreviewConnectionWhereArgs'
      }
    },
    categories: {
      __type: 'RootQueryToCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToCategoryConnectionWhereArgs'
      }
    },
    category: { __type: 'Category', __args: { id: 'ID!', idType: 'CategoryIdType' } },
    comment: { __type: 'Comment', __args: { id: 'ID!', idType: 'CommentNodeIdTypeEnum' } },
    comments: {
      __type: 'RootQueryToCommentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToCommentConnectionWhereArgs'
      }
    },
    companies: {
      __type: 'RootQueryToCompanyConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToCompanyConnectionWhereArgs'
      }
    },
    company: {
      __type: 'Company',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'CompanyIdType' }
    },
    companyBy: { __type: 'Company', __args: { companyId: 'Int', id: 'ID', uri: 'String' } },
    companyCategories: {
      __type: 'RootQueryToCompanyCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToCompanyCategoryConnectionWhereArgs'
      }
    },
    companyCategory: {
      __type: 'CompanyCategory',
      __args: { id: 'ID!', idType: 'CompanyCategoryIdType' }
    },
    contentNode: {
      __type: 'ContentNode',
      __args: {
        asPreview: 'Boolean',
        contentType: 'ContentTypeEnum',
        id: 'ID!',
        idType: 'ContentNodeIdTypeEnum'
      }
    },
    contentNodes: {
      __type: 'RootQueryToContentNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToContentNodeConnectionWhereArgs'
      }
    },
    contentType: { __type: 'ContentType', __args: { id: 'ID!', idType: 'ContentTypeIdTypeEnum' } },
    contentTypes: {
      __type: 'RootQueryToContentTypeConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    discussionSettings: { __type: 'DiscussionSettings' },
    generalSettings: { __type: 'GeneralSettings' },
    globalStylesheet: { __type: 'String', __args: { types: '[GlobalStylesheetTypesEnum]' } },
    graphqlDocument: {
      __type: 'GraphqlDocument',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'GraphqlDocumentIdType' }
    },
    graphqlDocumentBy: {
      __type: 'GraphqlDocument',
      __args: { graphqlDocumentId: 'Int', id: 'ID', slug: 'String', uri: 'String' }
    },
    graphqlDocuments: {
      __type: 'RootQueryToGraphqlDocumentConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToGraphqlDocumentConnectionWhereArgs'
      }
    },
    mediaItem: {
      __type: 'MediaItem',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'MediaItemIdType' }
    },
    mediaItemBy: {
      __type: 'MediaItem',
      __args: { id: 'ID', mediaItemId: 'Int', slug: 'String', uri: 'String' }
    },
    mediaItems: {
      __type: 'RootQueryToMediaItemConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToMediaItemConnectionWhereArgs'
      }
    },
    menu: { __type: 'Menu', __args: { id: 'ID!', idType: 'MenuNodeIdTypeEnum' } },
    menuItem: { __type: 'MenuItem', __args: { id: 'ID!', idType: 'MenuItemNodeIdTypeEnum' } },
    menuItems: {
      __type: 'RootQueryToMenuItemConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToMenuItemConnectionWhereArgs'
      }
    },
    menus: {
      __type: 'RootQueryToMenuConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToMenuConnectionWhereArgs'
      }
    },
    node: { __type: 'Node', __args: { id: 'ID' } },
    nodeByUri: { __type: 'UniformResourceIdentifiable', __args: { uri: 'String!' } },
    page: { __type: 'Page', __args: { asPreview: 'Boolean', id: 'ID!', idType: 'PageIdType' } },
    pageBy: { __type: 'Page', __args: { id: 'ID', pageId: 'Int', uri: 'String' } },
    pages: {
      __type: 'RootQueryToPageConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPageConnectionWhereArgs'
      }
    },
    plugin: { __type: 'Plugin', __args: { id: 'ID!' } },
    plugins: {
      __type: 'RootQueryToPluginConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPluginConnectionWhereArgs'
      }
    },
    post: { __type: 'Post', __args: { asPreview: 'Boolean', id: 'ID!', idType: 'PostIdType' } },
    postBy: { __type: 'Post', __args: { id: 'ID', postId: 'Int', slug: 'String', uri: 'String' } },
    postFormat: { __type: 'PostFormat', __args: { id: 'ID!', idType: 'PostFormatIdType' } },
    postFormats: {
      __type: 'RootQueryToPostFormatConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPostFormatConnectionWhereArgs'
      }
    },
    posts: {
      __type: 'RootQueryToPostConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPostConnectionWhereArgs'
      }
    },
    press: { __type: 'Press', __args: { asPreview: 'Boolean', id: 'ID!', idType: 'PressIdType' } },
    pressBy: {
      __type: 'Press',
      __args: { id: 'ID', pressId: 'Int', slug: 'String', uri: 'String' }
    },
    pressCategories: {
      __type: 'RootQueryToPressCategoryConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPressCategoryConnectionWhereArgs'
      }
    },
    pressCategory: {
      __type: 'PressCategory',
      __args: { id: 'ID!', idType: 'PressCategoryIdType' }
    },
    pressPosts: {
      __type: 'RootQueryToPressConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToPressConnectionWhereArgs'
      }
    },
    readingSettings: { __type: 'ReadingSettings' },
    registeredScripts: {
      __type: 'RootQueryToEnqueuedScriptConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    registeredStylesheets: {
      __type: 'RootQueryToEnqueuedStylesheetConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    reusableBlock: {
      __type: 'ReusableBlock',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'ReusableBlockIdType' }
    },
    reusableBlockBy: {
      __type: 'ReusableBlock',
      __args: { id: 'ID', reusableBlockId: 'Int', slug: 'String', uri: 'String' }
    },
    reusableBlocks: {
      __type: 'RootQueryToReusableBlockConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToReusableBlockConnectionWhereArgs'
      }
    },
    revisions: {
      __type: 'RootQueryToRevisionsConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToRevisionsConnectionWhereArgs'
      }
    },
    seo: { __type: 'SEOConfig' },
    tag: { __type: 'Tag', __args: { id: 'ID!', idType: 'TagIdType' } },
    tags: {
      __type: 'RootQueryToTagConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToTagConnectionWhereArgs'
      }
    },
    taxonomies: {
      __type: 'RootQueryToTaxonomyConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    taxonomy: { __type: 'Taxonomy', __args: { id: 'ID!', idType: 'TaxonomyIdTypeEnum' } },
    teamMember: {
      __type: 'TeamMember',
      __args: { asPreview: 'Boolean', id: 'ID!', idType: 'TeamMemberIdType' }
    },
    teamMemberBy: {
      __type: 'TeamMember',
      __args: { id: 'ID', teamMemberId: 'Int', uri: 'String' }
    },
    teamMembers: {
      __type: 'RootQueryToTeamMemberConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToTeamMemberConnectionWhereArgs'
      }
    },
    termNode: {
      __type: 'TermNode',
      __args: { id: 'ID!', idType: 'TermNodeIdTypeEnum', taxonomy: 'TaxonomyEnum' }
    },
    terms: {
      __type: 'RootQueryToTermNodeConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToTermNodeConnectionWhereArgs'
      }
    },
    theme: { __type: 'Theme', __args: { id: 'ID!' } },
    themes: {
      __type: 'RootQueryToThemeConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    user: { __type: 'User', __args: { id: 'ID!', idType: 'UserNodeIdTypeEnum' } },
    userRole: { __type: 'UserRole', __args: { id: 'ID!' } },
    userRoles: {
      __type: 'RootQueryToUserRoleConnection',
      __args: { after: 'String', before: 'String', first: 'Int', last: 'Int' }
    },
    users: {
      __type: 'RootQueryToUserConnection',
      __args: {
        after: 'String',
        before: 'String',
        first: 'Int',
        last: 'Int',
        where: 'RootQueryToUserConnectionWhereArgs'
      }
    },
    viewer: { __type: 'User' },
    writingSettings: { __type: 'WritingSettings' }
  },
  subscription: {},
  [SchemaUnionsKey]: {
    AcfFieldGroup: [
      'AcfOptionsFooter_Footersettings',
      'AcfOptionsFooter_Footersettings_externalLinks',
      'AcfOptionsFooter_Footersettings_links',
      'AcfOptionsFooter_Footersettings_socialMedia',
      'AcfOptionsNotifications_Websitenotificationbanner',
      'Company_Companies',
      'Company_Companies_info',
      'Post_Blogpost',
      'Press_Acfpress',
      'TeamMember_Team',
      'TeamMember_Team_boardPositions',
      'TeamMember_Teaminvestments'
    ],
    ContentNode: [
      'BlockEditorPreview',
      'Company',
      'GraphqlDocument',
      'MediaItem',
      'Page',
      'Post',
      'Press',
      'ReusableBlock',
      'TeamMember'
    ],
    DatabaseIdentifier: [
      'BlockEditorPreview',
      'Category',
      'Comment',
      'CommentAuthor',
      'Company',
      'CompanyCategory',
      'GraphqlDocument',
      'MediaItem',
      'Menu',
      'MenuItem',
      'Page',
      'Post',
      'PostFormat',
      'Press',
      'PressCategory',
      'ReusableBlock',
      'Tag',
      'TeamMember',
      'User'
    ],
    Node: [
      'BlockEditorPreview',
      'Category',
      'Comment',
      'CommentAuthor',
      'Company',
      'CompanyCategory',
      'ContentType',
      'EnqueuedScript',
      'EnqueuedStylesheet',
      'GraphqlDocument',
      'MediaItem',
      'Menu',
      'MenuItem',
      'Page',
      'Plugin',
      'Post',
      'PostFormat',
      'Press',
      'PressCategory',
      'ReusableBlock',
      'Tag',
      'Taxonomy',
      'TeamMember',
      'Theme',
      'User',
      'UserRole'
    ],
    NodeWithAuthor: ['BlockEditorPreview', 'MediaItem', 'Page', 'Post'],
    NodeWithContentEditor: [
      'BlockEditorPreview',
      'Company',
      'GraphqlDocument',
      'Page',
      'Post',
      'Press',
      'ReusableBlock',
      'TeamMember'
    ],
    NodeWithTemplate: [
      'BlockEditorPreview',
      'Company',
      'GraphqlDocument',
      'MediaItem',
      'Page',
      'Post',
      'Press',
      'ReusableBlock',
      'TeamMember'
    ],
    NodeWithTitle: [
      'BlockEditorPreview',
      'Company',
      'GraphqlDocument',
      'MediaItem',
      'Page',
      'Post',
      'Press',
      'ReusableBlock',
      'TeamMember'
    ],
    UniformResourceIdentifiable: [
      'BlockEditorPreview',
      'Category',
      'Company',
      'CompanyCategory',
      'ContentType',
      'GraphqlDocument',
      'MediaItem',
      'Page',
      'Post',
      'PostFormat',
      'Press',
      'PressCategory',
      'ReusableBlock',
      'Tag',
      'TeamMember',
      'User'
    ],
    BlockEditorPreviewConnectionEdge: [
      'BlockEditorPreviewToPreviewConnectionEdge',
      'RootQueryToBlockEditorPreviewConnectionEdge',
      'UserToBlockEditorPreviewConnectionEdge'
    ],
    Edge: [
      'BlockEditorPreviewToPreviewConnectionEdge',
      'CategoryToAncestorsCategoryConnectionEdge',
      'CategoryToCategoryConnectionEdge',
      'CategoryToContentNodeConnectionEdge',
      'CategoryToParentCategoryConnectionEdge',
      'CategoryToPostConnectionEdge',
      'CategoryToTaxonomyConnectionEdge',
      'CommentToCommentConnectionEdge',
      'CommentToCommenterConnectionEdge',
      'CommentToContentNodeConnectionEdge',
      'CommentToParentCommentConnectionEdge',
      'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge',
      'CompanyCategoryToCompanyCategoryConnectionEdge',
      'CompanyCategoryToCompanyConnectionEdge',
      'CompanyCategoryToContentNodeConnectionEdge',
      'CompanyCategoryToParentCompanyCategoryConnectionEdge',
      'CompanyCategoryToTaxonomyConnectionEdge',
      'CompanyToCompanyCategoryConnectionEdge',
      'CompanyToPreviewConnectionEdge',
      'CompanyToTermNodeConnectionEdge',
      'ContentNodeToContentTypeConnectionEdge',
      'ContentNodeToEditLastConnectionEdge',
      'ContentNodeToEditLockConnectionEdge',
      'ContentNodeToEnqueuedScriptConnectionEdge',
      'ContentNodeToEnqueuedStylesheetConnectionEdge',
      'ContentTypeToContentNodeConnectionEdge',
      'ContentTypeToTaxonomyConnectionEdge',
      'CoreImageBlockToMediaItemConnectionEdge',
      'GraphqlDocumentToPreviewConnectionEdge',
      'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge',
      'HierarchicalContentNodeToContentNodeChildrenConnectionEdge',
      'HierarchicalContentNodeToParentContentNodeConnectionEdge',
      'MediaItemToCommentConnectionEdge',
      'MenuItemToMenuConnectionEdge',
      'MenuItemToMenuItemConnectionEdge',
      'MenuItemToMenuItemLinkableConnectionEdge',
      'MenuToMenuItemConnectionEdge',
      'NodeWithAuthorToUserConnectionEdge',
      'NodeWithFeaturedImageToMediaItemConnectionEdge',
      'NodeWithRevisionsToContentNodeConnectionEdge',
      'PageToCommentConnectionEdge',
      'PageToPreviewConnectionEdge',
      'PageToRevisionConnectionEdge',
      'PostFormatToContentNodeConnectionEdge',
      'PostFormatToPostConnectionEdge',
      'PostFormatToTaxonomyConnectionEdge',
      'PostToCategoryConnectionEdge',
      'PostToCommentConnectionEdge',
      'PostToPostFormatConnectionEdge',
      'PostToPreviewConnectionEdge',
      'PostToRevisionConnectionEdge',
      'PostToTagConnectionEdge',
      'PostToTermNodeConnectionEdge',
      'PressCategoryToAncestorsPressCategoryConnectionEdge',
      'PressCategoryToContentNodeConnectionEdge',
      'PressCategoryToParentPressCategoryConnectionEdge',
      'PressCategoryToPressCategoryConnectionEdge',
      'PressCategoryToPressConnectionEdge',
      'PressCategoryToTaxonomyConnectionEdge',
      'PressCategoryToTeamMemberConnectionEdge',
      'PressToPressCategoryConnectionEdge',
      'PressToPreviewConnectionEdge',
      'PressToTermNodeConnectionEdge',
      'ReusableBlockToPreviewConnectionEdge',
      'ReusableBlockToRevisionConnectionEdge',
      'RootQueryToBlockEditorPreviewConnectionEdge',
      'RootQueryToCategoryConnectionEdge',
      'RootQueryToCommentConnectionEdge',
      'RootQueryToCompanyCategoryConnectionEdge',
      'RootQueryToCompanyConnectionEdge',
      'RootQueryToContentNodeConnectionEdge',
      'RootQueryToContentTypeConnectionEdge',
      'RootQueryToEnqueuedScriptConnectionEdge',
      'RootQueryToEnqueuedStylesheetConnectionEdge',
      'RootQueryToGraphqlDocumentConnectionEdge',
      'RootQueryToMediaItemConnectionEdge',
      'RootQueryToMenuConnectionEdge',
      'RootQueryToMenuItemConnectionEdge',
      'RootQueryToPageConnectionEdge',
      'RootQueryToPluginConnectionEdge',
      'RootQueryToPostConnectionEdge',
      'RootQueryToPostFormatConnectionEdge',
      'RootQueryToPressCategoryConnectionEdge',
      'RootQueryToPressConnectionEdge',
      'RootQueryToReusableBlockConnectionEdge',
      'RootQueryToRevisionsConnectionEdge',
      'RootQueryToTagConnectionEdge',
      'RootQueryToTaxonomyConnectionEdge',
      'RootQueryToTeamMemberConnectionEdge',
      'RootQueryToTermNodeConnectionEdge',
      'RootQueryToThemeConnectionEdge',
      'RootQueryToUserConnectionEdge',
      'RootQueryToUserRoleConnectionEdge',
      'TagToContentNodeConnectionEdge',
      'TagToPostConnectionEdge',
      'TagToTaxonomyConnectionEdge',
      'TaxonomyToContentTypeConnectionEdge',
      'TaxonomyToTermNodeConnectionEdge',
      'TeamMemberToPressCategoryConnectionEdge',
      'TeamMemberToPreviewConnectionEdge',
      'TeamMemberToTermNodeConnectionEdge',
      'TermNodeToEnqueuedScriptConnectionEdge',
      'TermNodeToEnqueuedStylesheetConnectionEdge',
      'UserToBlockEditorPreviewConnectionEdge',
      'UserToCommentConnectionEdge',
      'UserToEnqueuedScriptConnectionEdge',
      'UserToEnqueuedStylesheetConnectionEdge',
      'UserToMediaItemConnectionEdge',
      'UserToPageConnectionEdge',
      'UserToPostConnectionEdge',
      'UserToRevisionsConnectionEdge',
      'UserToUserRoleConnectionEdge'
    ],
    OneToOneConnection: [
      'BlockEditorPreviewToPreviewConnectionEdge',
      'CategoryToParentCategoryConnectionEdge',
      'CategoryToTaxonomyConnectionEdge',
      'CommentToCommenterConnectionEdge',
      'CommentToContentNodeConnectionEdge',
      'CommentToParentCommentConnectionEdge',
      'CompanyCategoryToParentCompanyCategoryConnectionEdge',
      'CompanyCategoryToTaxonomyConnectionEdge',
      'CompanyToPreviewConnectionEdge',
      'ContentNodeToContentTypeConnectionEdge',
      'ContentNodeToEditLastConnectionEdge',
      'ContentNodeToEditLockConnectionEdge',
      'CoreImageBlockToMediaItemConnectionEdge',
      'GraphqlDocumentToPreviewConnectionEdge',
      'HierarchicalContentNodeToParentContentNodeConnectionEdge',
      'MenuItemToMenuConnectionEdge',
      'MenuItemToMenuItemLinkableConnectionEdge',
      'NodeWithAuthorToUserConnectionEdge',
      'NodeWithFeaturedImageToMediaItemConnectionEdge',
      'NodeWithRevisionsToContentNodeConnectionEdge',
      'PageToPreviewConnectionEdge',
      'PostFormatToTaxonomyConnectionEdge',
      'PostToPreviewConnectionEdge',
      'PressCategoryToParentPressCategoryConnectionEdge',
      'PressCategoryToTaxonomyConnectionEdge',
      'PressToPreviewConnectionEdge',
      'ReusableBlockToPreviewConnectionEdge',
      'TagToTaxonomyConnectionEdge',
      'TeamMemberToPreviewConnectionEdge'
    ],
    HierarchicalNode: [
      'Category',
      'Company',
      'CompanyCategory',
      'MediaItem',
      'Page',
      'PressCategory',
      'TeamMember'
    ],
    HierarchicalTermNode: ['Category', 'CompanyCategory', 'PressCategory'],
    MenuItemLinkable: [
      'Category',
      'Company',
      'CompanyCategory',
      'Page',
      'Post',
      'Press',
      'PressCategory',
      'Tag',
      'TeamMember'
    ],
    TermNode: ['Category', 'CompanyCategory', 'PostFormat', 'PressCategory', 'Tag'],
    CategoryConnection: [
      'CategoryToAncestorsCategoryConnection',
      'CategoryToCategoryConnection',
      'PostToCategoryConnection',
      'RootQueryToCategoryConnection'
    ],
    Connection: [
      'CategoryToAncestorsCategoryConnection',
      'CategoryToCategoryConnection',
      'CategoryToContentNodeConnection',
      'CategoryToPostConnection',
      'CommentToCommentConnection',
      'CompanyCategoryToAncestorsCompanyCategoryConnection',
      'CompanyCategoryToCompanyCategoryConnection',
      'CompanyCategoryToCompanyConnection',
      'CompanyCategoryToContentNodeConnection',
      'CompanyToCompanyCategoryConnection',
      'CompanyToTermNodeConnection',
      'ContentNodeToEnqueuedScriptConnection',
      'ContentNodeToEnqueuedStylesheetConnection',
      'ContentTypeToContentNodeConnection',
      'ContentTypeToTaxonomyConnection',
      'HierarchicalContentNodeToContentNodeAncestorsConnection',
      'HierarchicalContentNodeToContentNodeChildrenConnection',
      'MediaItemToCommentConnection',
      'MenuItemToMenuItemConnection',
      'MenuToMenuItemConnection',
      'PageToCommentConnection',
      'PageToRevisionConnection',
      'PostFormatToContentNodeConnection',
      'PostFormatToPostConnection',
      'PostToCategoryConnection',
      'PostToCommentConnection',
      'PostToPostFormatConnection',
      'PostToRevisionConnection',
      'PostToTagConnection',
      'PostToTermNodeConnection',
      'PressCategoryToAncestorsPressCategoryConnection',
      'PressCategoryToContentNodeConnection',
      'PressCategoryToPressCategoryConnection',
      'PressCategoryToPressConnection',
      'PressCategoryToTeamMemberConnection',
      'PressToPressCategoryConnection',
      'PressToTermNodeConnection',
      'ReusableBlockToRevisionConnection',
      'RootQueryToBlockEditorPreviewConnection',
      'RootQueryToCategoryConnection',
      'RootQueryToCommentConnection',
      'RootQueryToCompanyCategoryConnection',
      'RootQueryToCompanyConnection',
      'RootQueryToContentNodeConnection',
      'RootQueryToContentTypeConnection',
      'RootQueryToEnqueuedScriptConnection',
      'RootQueryToEnqueuedStylesheetConnection',
      'RootQueryToGraphqlDocumentConnection',
      'RootQueryToMediaItemConnection',
      'RootQueryToMenuConnection',
      'RootQueryToMenuItemConnection',
      'RootQueryToPageConnection',
      'RootQueryToPluginConnection',
      'RootQueryToPostConnection',
      'RootQueryToPostFormatConnection',
      'RootQueryToPressCategoryConnection',
      'RootQueryToPressConnection',
      'RootQueryToReusableBlockConnection',
      'RootQueryToRevisionsConnection',
      'RootQueryToTagConnection',
      'RootQueryToTaxonomyConnection',
      'RootQueryToTeamMemberConnection',
      'RootQueryToTermNodeConnection',
      'RootQueryToThemeConnection',
      'RootQueryToUserConnection',
      'RootQueryToUserRoleConnection',
      'TagToContentNodeConnection',
      'TagToPostConnection',
      'TaxonomyToContentTypeConnection',
      'TaxonomyToTermNodeConnection',
      'TeamMemberToPressCategoryConnection',
      'TeamMemberToTermNodeConnection',
      'TermNodeToEnqueuedScriptConnection',
      'TermNodeToEnqueuedStylesheetConnection',
      'UserToBlockEditorPreviewConnection',
      'UserToCommentConnection',
      'UserToEnqueuedScriptConnection',
      'UserToEnqueuedStylesheetConnection',
      'UserToMediaItemConnection',
      'UserToPageConnection',
      'UserToPostConnection',
      'UserToRevisionsConnection',
      'UserToUserRoleConnection'
    ],
    CategoryConnectionEdge: [
      'CategoryToAncestorsCategoryConnectionEdge',
      'CategoryToCategoryConnectionEdge',
      'CategoryToParentCategoryConnectionEdge',
      'PostToCategoryConnectionEdge',
      'RootQueryToCategoryConnectionEdge'
    ],
    CategoryConnectionPageInfo: [
      'CategoryToAncestorsCategoryConnectionPageInfo',
      'CategoryToCategoryConnectionPageInfo',
      'PostToCategoryConnectionPageInfo',
      'RootQueryToCategoryConnectionPageInfo'
    ],
    PageInfo: [
      'CategoryToAncestorsCategoryConnectionPageInfo',
      'CategoryToCategoryConnectionPageInfo',
      'CategoryToContentNodeConnectionPageInfo',
      'CategoryToPostConnectionPageInfo',
      'CommentToCommentConnectionPageInfo',
      'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo',
      'CompanyCategoryToCompanyCategoryConnectionPageInfo',
      'CompanyCategoryToCompanyConnectionPageInfo',
      'CompanyCategoryToContentNodeConnectionPageInfo',
      'CompanyToCompanyCategoryConnectionPageInfo',
      'CompanyToTermNodeConnectionPageInfo',
      'ContentNodeToEnqueuedScriptConnectionPageInfo',
      'ContentNodeToEnqueuedStylesheetConnectionPageInfo',
      'ContentTypeToContentNodeConnectionPageInfo',
      'ContentTypeToTaxonomyConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo',
      'MediaItemToCommentConnectionPageInfo',
      'MenuItemToMenuItemConnectionPageInfo',
      'MenuToMenuItemConnectionPageInfo',
      'PageToCommentConnectionPageInfo',
      'PageToRevisionConnectionPageInfo',
      'PostFormatToContentNodeConnectionPageInfo',
      'PostFormatToPostConnectionPageInfo',
      'PostToCategoryConnectionPageInfo',
      'PostToCommentConnectionPageInfo',
      'PostToPostFormatConnectionPageInfo',
      'PostToRevisionConnectionPageInfo',
      'PostToTagConnectionPageInfo',
      'PostToTermNodeConnectionPageInfo',
      'PressCategoryToAncestorsPressCategoryConnectionPageInfo',
      'PressCategoryToContentNodeConnectionPageInfo',
      'PressCategoryToPressCategoryConnectionPageInfo',
      'PressCategoryToPressConnectionPageInfo',
      'PressCategoryToTeamMemberConnectionPageInfo',
      'PressToPressCategoryConnectionPageInfo',
      'PressToTermNodeConnectionPageInfo',
      'ReusableBlockToRevisionConnectionPageInfo',
      'RootQueryToBlockEditorPreviewConnectionPageInfo',
      'RootQueryToCategoryConnectionPageInfo',
      'RootQueryToCommentConnectionPageInfo',
      'RootQueryToCompanyCategoryConnectionPageInfo',
      'RootQueryToCompanyConnectionPageInfo',
      'RootQueryToContentNodeConnectionPageInfo',
      'RootQueryToContentTypeConnectionPageInfo',
      'RootQueryToEnqueuedScriptConnectionPageInfo',
      'RootQueryToEnqueuedStylesheetConnectionPageInfo',
      'RootQueryToGraphqlDocumentConnectionPageInfo',
      'RootQueryToMediaItemConnectionPageInfo',
      'RootQueryToMenuConnectionPageInfo',
      'RootQueryToMenuItemConnectionPageInfo',
      'RootQueryToPageConnectionPageInfo',
      'RootQueryToPluginConnectionPageInfo',
      'RootQueryToPostConnectionPageInfo',
      'RootQueryToPostFormatConnectionPageInfo',
      'RootQueryToPressCategoryConnectionPageInfo',
      'RootQueryToPressConnectionPageInfo',
      'RootQueryToReusableBlockConnectionPageInfo',
      'RootQueryToRevisionsConnectionPageInfo',
      'RootQueryToTagConnectionPageInfo',
      'RootQueryToTaxonomyConnectionPageInfo',
      'RootQueryToTeamMemberConnectionPageInfo',
      'RootQueryToTermNodeConnectionPageInfo',
      'RootQueryToThemeConnectionPageInfo',
      'RootQueryToUserConnectionPageInfo',
      'RootQueryToUserRoleConnectionPageInfo',
      'TagToContentNodeConnectionPageInfo',
      'TagToPostConnectionPageInfo',
      'TaxonomyToContentTypeConnectionPageInfo',
      'TaxonomyToTermNodeConnectionPageInfo',
      'TeamMemberToPressCategoryConnectionPageInfo',
      'TeamMemberToTermNodeConnectionPageInfo',
      'TermNodeToEnqueuedScriptConnectionPageInfo',
      'TermNodeToEnqueuedStylesheetConnectionPageInfo',
      'UserToBlockEditorPreviewConnectionPageInfo',
      'UserToCommentConnectionPageInfo',
      'UserToEnqueuedScriptConnectionPageInfo',
      'UserToEnqueuedStylesheetConnectionPageInfo',
      'UserToMediaItemConnectionPageInfo',
      'UserToPageConnectionPageInfo',
      'UserToPostConnectionPageInfo',
      'UserToRevisionsConnectionPageInfo',
      'UserToUserRoleConnectionPageInfo'
    ],
    WPPageInfo: [
      'CategoryToAncestorsCategoryConnectionPageInfo',
      'CategoryToCategoryConnectionPageInfo',
      'CategoryToContentNodeConnectionPageInfo',
      'CategoryToPostConnectionPageInfo',
      'CommentToCommentConnectionPageInfo',
      'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo',
      'CompanyCategoryToCompanyCategoryConnectionPageInfo',
      'CompanyCategoryToCompanyConnectionPageInfo',
      'CompanyCategoryToContentNodeConnectionPageInfo',
      'CompanyToCompanyCategoryConnectionPageInfo',
      'CompanyToTermNodeConnectionPageInfo',
      'ContentNodeToEnqueuedScriptConnectionPageInfo',
      'ContentNodeToEnqueuedStylesheetConnectionPageInfo',
      'ContentTypeToContentNodeConnectionPageInfo',
      'ContentTypeToTaxonomyConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo',
      'MediaItemToCommentConnectionPageInfo',
      'MenuItemToMenuItemConnectionPageInfo',
      'MenuToMenuItemConnectionPageInfo',
      'PageToCommentConnectionPageInfo',
      'PageToRevisionConnectionPageInfo',
      'PostFormatToContentNodeConnectionPageInfo',
      'PostFormatToPostConnectionPageInfo',
      'PostToCategoryConnectionPageInfo',
      'PostToCommentConnectionPageInfo',
      'PostToPostFormatConnectionPageInfo',
      'PostToRevisionConnectionPageInfo',
      'PostToTagConnectionPageInfo',
      'PostToTermNodeConnectionPageInfo',
      'PressCategoryToAncestorsPressCategoryConnectionPageInfo',
      'PressCategoryToContentNodeConnectionPageInfo',
      'PressCategoryToPressCategoryConnectionPageInfo',
      'PressCategoryToPressConnectionPageInfo',
      'PressCategoryToTeamMemberConnectionPageInfo',
      'PressToPressCategoryConnectionPageInfo',
      'PressToTermNodeConnectionPageInfo',
      'ReusableBlockToRevisionConnectionPageInfo',
      'RootQueryToBlockEditorPreviewConnectionPageInfo',
      'RootQueryToCategoryConnectionPageInfo',
      'RootQueryToCommentConnectionPageInfo',
      'RootQueryToCompanyCategoryConnectionPageInfo',
      'RootQueryToCompanyConnectionPageInfo',
      'RootQueryToContentNodeConnectionPageInfo',
      'RootQueryToContentTypeConnectionPageInfo',
      'RootQueryToEnqueuedScriptConnectionPageInfo',
      'RootQueryToEnqueuedStylesheetConnectionPageInfo',
      'RootQueryToGraphqlDocumentConnectionPageInfo',
      'RootQueryToMediaItemConnectionPageInfo',
      'RootQueryToMenuConnectionPageInfo',
      'RootQueryToMenuItemConnectionPageInfo',
      'RootQueryToPageConnectionPageInfo',
      'RootQueryToPluginConnectionPageInfo',
      'RootQueryToPostConnectionPageInfo',
      'RootQueryToPostFormatConnectionPageInfo',
      'RootQueryToPressCategoryConnectionPageInfo',
      'RootQueryToPressConnectionPageInfo',
      'RootQueryToReusableBlockConnectionPageInfo',
      'RootQueryToRevisionsConnectionPageInfo',
      'RootQueryToTagConnectionPageInfo',
      'RootQueryToTaxonomyConnectionPageInfo',
      'RootQueryToTeamMemberConnectionPageInfo',
      'RootQueryToTermNodeConnectionPageInfo',
      'RootQueryToThemeConnectionPageInfo',
      'RootQueryToUserConnectionPageInfo',
      'RootQueryToUserRoleConnectionPageInfo',
      'TagToContentNodeConnectionPageInfo',
      'TagToPostConnectionPageInfo',
      'TaxonomyToContentTypeConnectionPageInfo',
      'TaxonomyToTermNodeConnectionPageInfo',
      'TeamMemberToPressCategoryConnectionPageInfo',
      'TeamMemberToTermNodeConnectionPageInfo',
      'TermNodeToEnqueuedScriptConnectionPageInfo',
      'TermNodeToEnqueuedStylesheetConnectionPageInfo',
      'UserToBlockEditorPreviewConnectionPageInfo',
      'UserToCommentConnectionPageInfo',
      'UserToEnqueuedScriptConnectionPageInfo',
      'UserToEnqueuedStylesheetConnectionPageInfo',
      'UserToMediaItemConnectionPageInfo',
      'UserToPageConnectionPageInfo',
      'UserToPostConnectionPageInfo',
      'UserToRevisionsConnectionPageInfo',
      'UserToUserRoleConnectionPageInfo'
    ],
    ContentNodeConnection: [
      'CategoryToContentNodeConnection',
      'CompanyCategoryToContentNodeConnection',
      'ContentTypeToContentNodeConnection',
      'HierarchicalContentNodeToContentNodeAncestorsConnection',
      'HierarchicalContentNodeToContentNodeChildrenConnection',
      'PostFormatToContentNodeConnection',
      'PressCategoryToContentNodeConnection',
      'RootQueryToContentNodeConnection',
      'RootQueryToRevisionsConnection',
      'TagToContentNodeConnection',
      'UserToRevisionsConnection'
    ],
    ContentNodeConnectionEdge: [
      'CategoryToContentNodeConnectionEdge',
      'CommentToContentNodeConnectionEdge',
      'CompanyCategoryToContentNodeConnectionEdge',
      'ContentTypeToContentNodeConnectionEdge',
      'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge',
      'HierarchicalContentNodeToContentNodeChildrenConnectionEdge',
      'HierarchicalContentNodeToParentContentNodeConnectionEdge',
      'NodeWithRevisionsToContentNodeConnectionEdge',
      'PostFormatToContentNodeConnectionEdge',
      'PressCategoryToContentNodeConnectionEdge',
      'RootQueryToContentNodeConnectionEdge',
      'RootQueryToRevisionsConnectionEdge',
      'TagToContentNodeConnectionEdge',
      'UserToRevisionsConnectionEdge'
    ],
    ContentNodeConnectionPageInfo: [
      'CategoryToContentNodeConnectionPageInfo',
      'CompanyCategoryToContentNodeConnectionPageInfo',
      'ContentTypeToContentNodeConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo',
      'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo',
      'PostFormatToContentNodeConnectionPageInfo',
      'PressCategoryToContentNodeConnectionPageInfo',
      'RootQueryToContentNodeConnectionPageInfo',
      'RootQueryToRevisionsConnectionPageInfo',
      'TagToContentNodeConnectionPageInfo',
      'UserToRevisionsConnectionPageInfo'
    ],
    PostConnection: [
      'CategoryToPostConnection',
      'PostFormatToPostConnection',
      'PostToRevisionConnection',
      'RootQueryToPostConnection',
      'TagToPostConnection',
      'UserToPostConnection'
    ],
    PostConnectionEdge: [
      'CategoryToPostConnectionEdge',
      'PostFormatToPostConnectionEdge',
      'PostToPreviewConnectionEdge',
      'PostToRevisionConnectionEdge',
      'RootQueryToPostConnectionEdge',
      'TagToPostConnectionEdge',
      'UserToPostConnectionEdge'
    ],
    PostConnectionPageInfo: [
      'CategoryToPostConnectionPageInfo',
      'PostFormatToPostConnectionPageInfo',
      'PostToRevisionConnectionPageInfo',
      'RootQueryToPostConnectionPageInfo',
      'TagToPostConnectionPageInfo',
      'UserToPostConnectionPageInfo'
    ],
    TaxonomyConnectionEdge: [
      'CategoryToTaxonomyConnectionEdge',
      'CompanyCategoryToTaxonomyConnectionEdge',
      'ContentTypeToTaxonomyConnectionEdge',
      'PostFormatToTaxonomyConnectionEdge',
      'PressCategoryToTaxonomyConnectionEdge',
      'RootQueryToTaxonomyConnectionEdge',
      'TagToTaxonomyConnectionEdge'
    ],
    Commenter: ['CommentAuthor', 'User'],
    CommentConnection: [
      'CommentToCommentConnection',
      'MediaItemToCommentConnection',
      'PageToCommentConnection',
      'PostToCommentConnection',
      'RootQueryToCommentConnection',
      'UserToCommentConnection'
    ],
    CommentConnectionEdge: [
      'CommentToCommentConnectionEdge',
      'CommentToParentCommentConnectionEdge',
      'MediaItemToCommentConnectionEdge',
      'PageToCommentConnectionEdge',
      'PostToCommentConnectionEdge',
      'RootQueryToCommentConnectionEdge',
      'UserToCommentConnectionEdge'
    ],
    CommentConnectionPageInfo: [
      'CommentToCommentConnectionPageInfo',
      'MediaItemToCommentConnectionPageInfo',
      'PageToCommentConnectionPageInfo',
      'PostToCommentConnectionPageInfo',
      'RootQueryToCommentConnectionPageInfo',
      'UserToCommentConnectionPageInfo'
    ],
    CommenterConnectionEdge: ['CommentToCommenterConnectionEdge'],
    BlockEditorContentNode: ['Company', 'Page', 'Post', 'Press', 'ReusableBlock', 'TeamMember'],
    HierarchicalContentNode: ['Company', 'MediaItem', 'Page', 'TeamMember'],
    NodeWithExcerpt: ['Company', 'Post'],
    NodeWithFeaturedImage: ['Company', 'Page', 'Post', 'Press'],
    Previewable: ['Company', 'Page', 'Post', 'Press', 'TeamMember'],
    CompanyCategoryConnection: [
      'CompanyCategoryToAncestorsCompanyCategoryConnection',
      'CompanyCategoryToCompanyCategoryConnection',
      'CompanyToCompanyCategoryConnection',
      'RootQueryToCompanyCategoryConnection'
    ],
    CompanyCategoryConnectionEdge: [
      'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge',
      'CompanyCategoryToCompanyCategoryConnectionEdge',
      'CompanyCategoryToParentCompanyCategoryConnectionEdge',
      'CompanyToCompanyCategoryConnectionEdge',
      'RootQueryToCompanyCategoryConnectionEdge'
    ],
    CompanyCategoryConnectionPageInfo: [
      'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo',
      'CompanyCategoryToCompanyCategoryConnectionPageInfo',
      'CompanyToCompanyCategoryConnectionPageInfo',
      'RootQueryToCompanyCategoryConnectionPageInfo'
    ],
    CompanyConnection: ['CompanyCategoryToCompanyConnection', 'RootQueryToCompanyConnection'],
    CompanyConnectionEdge: [
      'CompanyCategoryToCompanyConnectionEdge',
      'CompanyToPreviewConnectionEdge',
      'RootQueryToCompanyConnectionEdge'
    ],
    CompanyConnectionPageInfo: [
      'CompanyCategoryToCompanyConnectionPageInfo',
      'RootQueryToCompanyConnectionPageInfo'
    ],
    TermNodeConnection: [
      'CompanyToTermNodeConnection',
      'PostToTermNodeConnection',
      'PressToTermNodeConnection',
      'RootQueryToTermNodeConnection',
      'TaxonomyToTermNodeConnection',
      'TeamMemberToTermNodeConnection'
    ],
    TermNodeConnectionEdge: [
      'CompanyToTermNodeConnectionEdge',
      'PostToTermNodeConnectionEdge',
      'PressToTermNodeConnectionEdge',
      'RootQueryToTermNodeConnectionEdge',
      'TaxonomyToTermNodeConnectionEdge',
      'TeamMemberToTermNodeConnectionEdge'
    ],
    TermNodeConnectionPageInfo: [
      'CompanyToTermNodeConnectionPageInfo',
      'PostToTermNodeConnectionPageInfo',
      'PressToTermNodeConnectionPageInfo',
      'RootQueryToTermNodeConnectionPageInfo',
      'TaxonomyToTermNodeConnectionPageInfo',
      'TeamMemberToTermNodeConnectionPageInfo'
    ],
    Company_Companies_Partner: ['TeamMember'],
    ContentTypeConnectionEdge: [
      'ContentNodeToContentTypeConnectionEdge',
      'RootQueryToContentTypeConnectionEdge',
      'TaxonomyToContentTypeConnectionEdge'
    ],
    UserConnectionEdge: [
      'ContentNodeToEditLastConnectionEdge',
      'ContentNodeToEditLockConnectionEdge',
      'NodeWithAuthorToUserConnectionEdge',
      'RootQueryToUserConnectionEdge'
    ],
    EnqueuedScriptConnection: [
      'ContentNodeToEnqueuedScriptConnection',
      'RootQueryToEnqueuedScriptConnection',
      'TermNodeToEnqueuedScriptConnection',
      'UserToEnqueuedScriptConnection'
    ],
    EnqueuedScriptConnectionEdge: [
      'ContentNodeToEnqueuedScriptConnectionEdge',
      'RootQueryToEnqueuedScriptConnectionEdge',
      'TermNodeToEnqueuedScriptConnectionEdge',
      'UserToEnqueuedScriptConnectionEdge'
    ],
    EnqueuedScriptConnectionPageInfo: [
      'ContentNodeToEnqueuedScriptConnectionPageInfo',
      'RootQueryToEnqueuedScriptConnectionPageInfo',
      'TermNodeToEnqueuedScriptConnectionPageInfo',
      'UserToEnqueuedScriptConnectionPageInfo'
    ],
    EnqueuedStylesheetConnection: [
      'ContentNodeToEnqueuedStylesheetConnection',
      'RootQueryToEnqueuedStylesheetConnection',
      'TermNodeToEnqueuedStylesheetConnection',
      'UserToEnqueuedStylesheetConnection'
    ],
    EnqueuedStylesheetConnectionEdge: [
      'ContentNodeToEnqueuedStylesheetConnectionEdge',
      'RootQueryToEnqueuedStylesheetConnectionEdge',
      'TermNodeToEnqueuedStylesheetConnectionEdge',
      'UserToEnqueuedStylesheetConnectionEdge'
    ],
    EnqueuedStylesheetConnectionPageInfo: [
      'ContentNodeToEnqueuedStylesheetConnectionPageInfo',
      'RootQueryToEnqueuedStylesheetConnectionPageInfo',
      'TermNodeToEnqueuedStylesheetConnectionPageInfo',
      'UserToEnqueuedStylesheetConnectionPageInfo'
    ],
    TaxonomyConnection: ['ContentTypeToTaxonomyConnection', 'RootQueryToTaxonomyConnection'],
    TaxonomyConnectionPageInfo: [
      'ContentTypeToTaxonomyConnectionPageInfo',
      'RootQueryToTaxonomyConnectionPageInfo'
    ],
    Block: [
      'CoreArchivesBlock',
      'CoreAudioBlock',
      'CoreAvatarBlock',
      'CoreBlock',
      'CoreButtonBlock',
      'CoreButtonsBlock',
      'CoreCalendarBlock',
      'CoreCategoriesBlock',
      'CoreCodeBlock',
      'CoreColumnBlock',
      'CoreColumnsBlock',
      'CoreCommentAuthorNameBlock',
      'CoreCommentContentBlock',
      'CoreCommentDateBlock',
      'CoreCommentEditLinkBlock',
      'CoreCommentReplyLinkBlock',
      'CoreCommentTemplateBlock',
      'CoreCommentsBlock',
      'CoreCommentsPaginationBlock',
      'CoreCommentsPaginationNextBlock',
      'CoreCommentsPaginationNumbersBlock',
      'CoreCommentsPaginationPreviousBlock',
      'CoreCommentsTitleBlock',
      'CoreCoverBlock',
      'CoreDetailsBlock',
      'CoreEmbedBlock',
      'CoreFileBlock',
      'CoreFootnotesBlock',
      'CoreFreeformBlock',
      'CoreGalleryBlock',
      'CoreGroupBlock',
      'CoreHeadingBlock',
      'CoreHomeLinkBlock',
      'CoreHtmlBlock',
      'CoreImageBlock',
      'CoreLatestCommentsBlock',
      'CoreLatestPostsBlock',
      'CoreLegacyWidgetBlock',
      'CoreListBlock',
      'CoreListItemBlock',
      'CoreLoginoutBlock',
      'CoreMediaTextBlock',
      'CoreMissingBlock',
      'CoreMoreBlock',
      'CoreNavigationBlock',
      'CoreNavigationLinkBlock',
      'CoreNavigationSubmenuBlock',
      'CoreNextpageBlock',
      'CorePageListBlock',
      'CorePageListItemBlock',
      'CoreParagraphBlock',
      'CorePatternBlock',
      'CorePostAuthorBiographyBlock',
      'CorePostAuthorBlock',
      'CorePostAuthorNameBlock',
      'CorePostCommentsFormBlock',
      'CorePostContentBlock',
      'CorePostDateBlock',
      'CorePostExcerptBlock',
      'CorePostFeaturedImageBlock',
      'CorePostNavigationLinkBlock',
      'CorePostTemplateBlock',
      'CorePostTermsBlock',
      'CorePostTitleBlock',
      'CorePreformattedBlock',
      'CorePullquoteBlock',
      'CoreQueryBlock',
      'CoreQueryNoResultsBlock',
      'CoreQueryPaginationBlock',
      'CoreQueryPaginationNextBlock',
      'CoreQueryPaginationNumbersBlock',
      'CoreQueryPaginationPreviousBlock',
      'CoreQueryTitleBlock',
      'CoreQuoteBlock',
      'CoreReadMoreBlock',
      'CoreRssBlock',
      'CoreSearchBlock',
      'CoreSeparatorBlock',
      'CoreShortcodeBlock',
      'CoreSiteLogoBlock',
      'CoreSiteTaglineBlock',
      'CoreSiteTitleBlock',
      'CoreSocialLinkBlock',
      'CoreSocialLinksBlock',
      'CoreSpacerBlock',
      'CoreTableBlock',
      'CoreTagCloudBlock',
      'CoreTemplatePartBlock',
      'CoreTermDescriptionBlock',
      'CoreTextColumnsBlock',
      'CoreVerseBlock',
      'CoreVideoBlock',
      'CoreWidgetGroupBlock',
      'Jam3AccordionBlock',
      'Jam3AccordionItemBlock',
      'Jam3AcfCompanyListingImgBlock',
      'Jam3AcfCompanyLogoBlock',
      'Jam3CarouselBlock',
      'Jam3CarouselItemBlock',
      'Jam3CompanyImageWithLogoBlock',
      'Jam3CompanyInfoBlock',
      'Jam3CompanyInfoItemBlock',
      'Jam3NewsletterBlock',
      'Jam3ReadTimeBlock',
      'Jam3RotateTextBlock',
      'Jam3SocialShareBlock',
      'Jam3TeamListingBlock',
      'MonsterinsightsPopularPostsInlineBlock',
      'MonsterinsightsPopularPostsWidgetBlock',
      'YoastFaqBlock',
      'YoastHowToBlock',
      'YoastSeoBreadcrumbsBlock'
    ],
    CoreAudioBlockAttributesUnion: [
      'CoreAudioBlockAttributes',
      'CoreAudioBlockDeprecatedV1Attributes'
    ],
    CoreBlockAttributesUnion: [
      'CoreBlockAttributes',
      'CoreBlockDeprecatedV1Attributes',
      'CoreBlockDeprecatedV2Attributes'
    ],
    CoreButtonBlockAttributesUnion: [
      'CoreButtonBlockAttributes',
      'CoreButtonBlockDeprecatedV1Attributes',
      'CoreButtonBlockDeprecatedV2Attributes',
      'CoreButtonBlockDeprecatedV3Attributes',
      'CoreButtonBlockDeprecatedV4Attributes',
      'CoreButtonBlockDeprecatedV5Attributes',
      'CoreButtonBlockDeprecatedV6Attributes',
      'CoreButtonBlockDeprecatedV7Attributes',
      'CoreButtonBlockDeprecatedV8Attributes',
      'CoreButtonBlockDeprecatedV9Attributes',
      'CoreButtonBlockDeprecatedV10Attributes',
      'CoreButtonBlockDeprecatedV11Attributes'
    ],
    CoreButtonsBlockAttributesUnion: [
      'CoreButtonsBlockAttributes',
      'CoreButtonsBlockDeprecatedV1Attributes',
      'CoreButtonsBlockDeprecatedV2Attributes'
    ],
    CoreColumnBlockAttributesUnion: [
      'CoreColumnBlockAttributes',
      'CoreColumnBlockDeprecatedV1Attributes'
    ],
    CoreColumnsBlockAttributesUnion: [
      'CoreColumnsBlockAttributes',
      'CoreColumnsBlockDeprecatedV1Attributes',
      'CoreColumnsBlockDeprecatedV2Attributes',
      'CoreColumnsBlockDeprecatedV3Attributes'
    ],
    CoreCommentAuthorNameBlockAttributesUnion: [
      'CoreCommentAuthorNameBlockAttributes',
      'CoreCommentAuthorNameBlockDeprecatedV1Attributes'
    ],
    CoreCommentDateBlockAttributesUnion: [
      'CoreCommentDateBlockAttributes',
      'CoreCommentDateBlockDeprecatedV1Attributes'
    ],
    CoreCommentsBlockAttributesUnion: [
      'CoreCommentsBlockAttributes',
      'CoreCommentsBlockDeprecatedV1Attributes'
    ],
    CoreCommentsTitleBlockAttributesUnion: [
      'CoreCommentsTitleBlockAttributes',
      'CoreCommentsTitleBlockDeprecatedV1Attributes'
    ],
    CoreCoverBlockAttributesUnion: [
      'CoreCoverBlockAttributes',
      'CoreCoverBlockDeprecatedV1Attributes',
      'CoreCoverBlockDeprecatedV2Attributes',
      'CoreCoverBlockDeprecatedV3Attributes',
      'CoreCoverBlockDeprecatedV4Attributes',
      'CoreCoverBlockDeprecatedV5Attributes',
      'CoreCoverBlockDeprecatedV6Attributes',
      'CoreCoverBlockDeprecatedV7Attributes',
      'CoreCoverBlockDeprecatedV8Attributes',
      'CoreCoverBlockDeprecatedV9Attributes',
      'CoreCoverBlockDeprecatedV10Attributes',
      'CoreCoverBlockDeprecatedV11Attributes',
      'CoreCoverBlockDeprecatedV12Attributes',
      'CoreCoverBlockDeprecatedV13Attributes'
    ],
    CoreEmbedBlockAttributesUnion: [
      'CoreEmbedBlockAttributes',
      'CoreEmbedBlockDeprecatedV1Attributes',
      'CoreEmbedBlockDeprecatedV2Attributes'
    ],
    CoreFileBlockAttributesUnion: [
      'CoreFileBlockAttributes',
      'CoreFileBlockDeprecatedV1Attributes',
      'CoreFileBlockDeprecatedV2Attributes',
      'CoreFileBlockDeprecatedV3Attributes'
    ],
    CoreGalleryBlockAttributesUnion: [
      'CoreGalleryBlockAttributes',
      'CoreGalleryBlockDeprecatedV1Attributes',
      'CoreGalleryBlockDeprecatedV2Attributes',
      'CoreGalleryBlockDeprecatedV3Attributes',
      'CoreGalleryBlockDeprecatedV4Attributes',
      'CoreGalleryBlockDeprecatedV5Attributes',
      'CoreGalleryBlockDeprecatedV6Attributes',
      'CoreGalleryBlockDeprecatedV7Attributes'
    ],
    CoreGroupBlockAttributesUnion: [
      'CoreGroupBlockAttributes',
      'CoreGroupBlockDeprecatedV1Attributes',
      'CoreGroupBlockDeprecatedV2Attributes',
      'CoreGroupBlockDeprecatedV3Attributes',
      'CoreGroupBlockDeprecatedV4Attributes',
      'CoreGroupBlockDeprecatedV5Attributes'
    ],
    CoreHeadingBlockAttributesUnion: [
      'CoreHeadingBlockAttributes',
      'CoreHeadingBlockDeprecatedV1Attributes',
      'CoreHeadingBlockDeprecatedV2Attributes',
      'CoreHeadingBlockDeprecatedV3Attributes',
      'CoreHeadingBlockDeprecatedV4Attributes',
      'CoreHeadingBlockDeprecatedV5Attributes'
    ],
    CoreImageBlockAttributesUnion: [
      'CoreImageBlockAttributes',
      'CoreImageBlockDeprecatedV1Attributes',
      'CoreImageBlockDeprecatedV2Attributes',
      'CoreImageBlockDeprecatedV3Attributes',
      'CoreImageBlockDeprecatedV4Attributes',
      'CoreImageBlockDeprecatedV5Attributes',
      'CoreImageBlockDeprecatedV6Attributes',
      'CoreImageBlockDeprecatedV7Attributes',
      'CoreImageBlockDeprecatedV8Attributes'
    ],
    MediaItemConnectionEdge: [
      'CoreImageBlockToMediaItemConnectionEdge',
      'NodeWithFeaturedImageToMediaItemConnectionEdge',
      'RootQueryToMediaItemConnectionEdge',
      'UserToMediaItemConnectionEdge'
    ],
    CoreLatestPostsBlockAttributesUnion: [
      'CoreLatestPostsBlockAttributes',
      'CoreLatestPostsBlockDeprecatedV1Attributes'
    ],
    CoreListBlockAttributesUnion: [
      'CoreListBlockAttributes',
      'CoreListBlockDeprecatedV1Attributes',
      'CoreListBlockDeprecatedV2Attributes',
      'CoreListBlockDeprecatedV3Attributes'
    ],
    CoreMediaTextBlockAttributesUnion: [
      'CoreMediaTextBlockAttributes',
      'CoreMediaTextBlockDeprecatedV1Attributes',
      'CoreMediaTextBlockDeprecatedV2Attributes',
      'CoreMediaTextBlockDeprecatedV3Attributes',
      'CoreMediaTextBlockDeprecatedV4Attributes',
      'CoreMediaTextBlockDeprecatedV5Attributes',
      'CoreMediaTextBlockDeprecatedV6Attributes'
    ],
    CoreNavigationBlockAttributesUnion: [
      'CoreNavigationBlockAttributes',
      'CoreNavigationBlockDeprecatedV1Attributes',
      'CoreNavigationBlockDeprecatedV2Attributes',
      'CoreNavigationBlockDeprecatedV3Attributes',
      'CoreNavigationBlockDeprecatedV4Attributes',
      'CoreNavigationBlockDeprecatedV5Attributes',
      'CoreNavigationBlockDeprecatedV6Attributes'
    ],
    CoreNavigationLinkBlockAttributesUnion: [
      'CoreNavigationLinkBlockAttributes',
      'CoreNavigationLinkBlockDeprecatedV1Attributes'
    ],
    CoreParagraphBlockAttributesUnion: [
      'CoreParagraphBlockAttributes',
      'CoreParagraphBlockDeprecatedV1Attributes',
      'CoreParagraphBlockDeprecatedV2Attributes',
      'CoreParagraphBlockDeprecatedV3Attributes',
      'CoreParagraphBlockDeprecatedV4Attributes',
      'CoreParagraphBlockDeprecatedV5Attributes',
      'CoreParagraphBlockDeprecatedV6Attributes'
    ],
    CorePostDateBlockAttributesUnion: [
      'CorePostDateBlockAttributes',
      'CorePostDateBlockDeprecatedV1Attributes'
    ],
    CorePostTitleBlockAttributesUnion: [
      'CorePostTitleBlockAttributes',
      'CorePostTitleBlockDeprecatedV1Attributes'
    ],
    CorePullquoteBlockAttributesUnion: [
      'CorePullquoteBlockAttributes',
      'CorePullquoteBlockDeprecatedV1Attributes',
      'CorePullquoteBlockDeprecatedV2Attributes',
      'CorePullquoteBlockDeprecatedV3Attributes',
      'CorePullquoteBlockDeprecatedV4Attributes',
      'CorePullquoteBlockDeprecatedV5Attributes',
      'CorePullquoteBlockDeprecatedV6Attributes'
    ],
    CoreQueryBlockAttributesUnion: [
      'CoreQueryBlockAttributes',
      'CoreQueryBlockDeprecatedV1Attributes',
      'CoreQueryBlockDeprecatedV2Attributes',
      'CoreQueryBlockDeprecatedV3Attributes',
      'CoreQueryBlockDeprecatedV4Attributes',
      'CoreQueryBlockDeprecatedV5Attributes'
    ],
    CoreQueryPaginationBlockAttributesUnion: [
      'CoreQueryPaginationBlockAttributes',
      'CoreQueryPaginationBlockDeprecatedV1Attributes'
    ],
    CoreQueryTitleBlockAttributesUnion: [
      'CoreQueryTitleBlockAttributes',
      'CoreQueryTitleBlockDeprecatedV1Attributes'
    ],
    CoreQuoteBlockAttributesUnion: [
      'CoreQuoteBlockAttributes',
      'CoreQuoteBlockDeprecatedV1Attributes',
      'CoreQuoteBlockDeprecatedV2Attributes',
      'CoreQuoteBlockDeprecatedV3Attributes',
      'CoreQuoteBlockDeprecatedV4Attributes'
    ],
    CoreSeparatorBlockAttributesUnion: [
      'CoreSeparatorBlockAttributes',
      'CoreSeparatorBlockDeprecatedV1Attributes'
    ],
    CoreSiteTaglineBlockAttributesUnion: [
      'CoreSiteTaglineBlockAttributes',
      'CoreSiteTaglineBlockDeprecatedV1Attributes'
    ],
    CoreSiteTitleBlockAttributesUnion: [
      'CoreSiteTitleBlockAttributes',
      'CoreSiteTitleBlockDeprecatedV1Attributes'
    ],
    CoreSocialLinksBlockAttributesUnion: [
      'CoreSocialLinksBlockAttributes',
      'CoreSocialLinksBlockDeprecatedV1Attributes'
    ],
    CoreSpacerBlockAttributesUnion: [
      'CoreSpacerBlockAttributes',
      'CoreSpacerBlockDeprecatedV1Attributes'
    ],
    CoreTableBlockAttributesUnion: [
      'CoreTableBlockAttributes',
      'CoreTableBlockDeprecatedV1Attributes',
      'CoreTableBlockDeprecatedV2Attributes',
      'CoreTableBlockDeprecatedV3Attributes'
    ],
    CoreVerseBlockAttributesUnion: [
      'CoreVerseBlockAttributes',
      'CoreVerseBlockDeprecatedV1Attributes',
      'CoreVerseBlockDeprecatedV2Attributes'
    ],
    CoreVideoBlockAttributesUnion: [
      'CoreVideoBlockAttributes',
      'CoreVideoBlockDeprecatedV1Attributes'
    ],
    CoreWidgetGroupBlockAttributesUnion: [
      'CoreWidgetGroupBlockAttributes',
      'CoreWidgetGroupBlockDeprecatedV1Attributes'
    ],
    ContentTemplate: ['DefaultTemplate'],
    EnqueuedAsset: ['EnqueuedScript', 'EnqueuedStylesheet'],
    GraphqlDocumentConnectionEdge: [
      'GraphqlDocumentToPreviewConnectionEdge',
      'RootQueryToGraphqlDocumentConnectionEdge'
    ],
    NodeWithComments: ['MediaItem', 'Page', 'Post'],
    MenuItemObjectUnion: [
      'Category',
      'Company',
      'CompanyCategory',
      'Page',
      'Post',
      'Press',
      'PressCategory',
      'Tag',
      'TeamMember'
    ],
    MenuConnectionEdge: ['MenuItemToMenuConnectionEdge', 'RootQueryToMenuConnectionEdge'],
    MenuItemConnection: [
      'MenuItemToMenuItemConnection',
      'MenuToMenuItemConnection',
      'RootQueryToMenuItemConnection'
    ],
    MenuItemConnectionEdge: [
      'MenuItemToMenuItemConnectionEdge',
      'MenuToMenuItemConnectionEdge',
      'RootQueryToMenuItemConnectionEdge'
    ],
    MenuItemConnectionPageInfo: [
      'MenuItemToMenuItemConnectionPageInfo',
      'MenuToMenuItemConnectionPageInfo',
      'RootQueryToMenuItemConnectionPageInfo'
    ],
    MenuItemLinkableConnectionEdge: ['MenuItemToMenuItemLinkableConnectionEdge'],
    NodeWithPageAttributes: ['Page'],
    NodeWithRevisions: ['Page', 'Post', 'ReusableBlock'],
    PageConnectionEdge: [
      'PageToPreviewConnectionEdge',
      'PageToRevisionConnectionEdge',
      'RootQueryToPageConnectionEdge',
      'UserToPageConnectionEdge'
    ],
    PageConnection: [
      'PageToRevisionConnection',
      'RootQueryToPageConnection',
      'UserToPageConnection'
    ],
    PageConnectionPageInfo: [
      'PageToRevisionConnectionPageInfo',
      'RootQueryToPageConnectionPageInfo',
      'UserToPageConnectionPageInfo'
    ],
    NodeWithTrackbacks: ['Post'],
    PostFormatConnection: ['PostToPostFormatConnection', 'RootQueryToPostFormatConnection'],
    PostFormatConnectionEdge: [
      'PostToPostFormatConnectionEdge',
      'RootQueryToPostFormatConnectionEdge'
    ],
    PostFormatConnectionPageInfo: [
      'PostToPostFormatConnectionPageInfo',
      'RootQueryToPostFormatConnectionPageInfo'
    ],
    TagConnection: ['PostToTagConnection', 'RootQueryToTagConnection'],
    TagConnectionEdge: ['PostToTagConnectionEdge', 'RootQueryToTagConnectionEdge'],
    TagConnectionPageInfo: ['PostToTagConnectionPageInfo', 'RootQueryToTagConnectionPageInfo'],
    PressCategoryConnection: [
      'PressCategoryToAncestorsPressCategoryConnection',
      'PressCategoryToPressCategoryConnection',
      'PressToPressCategoryConnection',
      'RootQueryToPressCategoryConnection',
      'TeamMemberToPressCategoryConnection'
    ],
    PressCategoryConnectionEdge: [
      'PressCategoryToAncestorsPressCategoryConnectionEdge',
      'PressCategoryToParentPressCategoryConnectionEdge',
      'PressCategoryToPressCategoryConnectionEdge',
      'PressToPressCategoryConnectionEdge',
      'RootQueryToPressCategoryConnectionEdge',
      'TeamMemberToPressCategoryConnectionEdge'
    ],
    PressCategoryConnectionPageInfo: [
      'PressCategoryToAncestorsPressCategoryConnectionPageInfo',
      'PressCategoryToPressCategoryConnectionPageInfo',
      'PressToPressCategoryConnectionPageInfo',
      'RootQueryToPressCategoryConnectionPageInfo',
      'TeamMemberToPressCategoryConnectionPageInfo'
    ],
    PressConnection: ['PressCategoryToPressConnection', 'RootQueryToPressConnection'],
    PressConnectionEdge: [
      'PressCategoryToPressConnectionEdge',
      'PressToPreviewConnectionEdge',
      'RootQueryToPressConnectionEdge'
    ],
    PressConnectionPageInfo: [
      'PressCategoryToPressConnectionPageInfo',
      'RootQueryToPressConnectionPageInfo'
    ],
    TeamMemberConnection: [
      'PressCategoryToTeamMemberConnection',
      'RootQueryToTeamMemberConnection'
    ],
    TeamMemberConnectionEdge: [
      'PressCategoryToTeamMemberConnectionEdge',
      'RootQueryToTeamMemberConnectionEdge',
      'TeamMemberToPreviewConnectionEdge'
    ],
    TeamMemberConnectionPageInfo: [
      'PressCategoryToTeamMemberConnectionPageInfo',
      'RootQueryToTeamMemberConnectionPageInfo'
    ],
    ReusableBlockConnectionEdge: [
      'ReusableBlockToPreviewConnectionEdge',
      'ReusableBlockToRevisionConnectionEdge',
      'RootQueryToReusableBlockConnectionEdge'
    ],
    ReusableBlockConnection: [
      'ReusableBlockToRevisionConnection',
      'RootQueryToReusableBlockConnection'
    ],
    ReusableBlockConnectionPageInfo: [
      'ReusableBlockToRevisionConnectionPageInfo',
      'RootQueryToReusableBlockConnectionPageInfo'
    ],
    BlockEditorPreviewConnection: [
      'RootQueryToBlockEditorPreviewConnection',
      'UserToBlockEditorPreviewConnection'
    ],
    BlockEditorPreviewConnectionPageInfo: [
      'RootQueryToBlockEditorPreviewConnectionPageInfo',
      'UserToBlockEditorPreviewConnectionPageInfo'
    ],
    ContentTypeConnection: ['RootQueryToContentTypeConnection', 'TaxonomyToContentTypeConnection'],
    ContentTypeConnectionPageInfo: [
      'RootQueryToContentTypeConnectionPageInfo',
      'TaxonomyToContentTypeConnectionPageInfo'
    ],
    GraphqlDocumentConnection: ['RootQueryToGraphqlDocumentConnection'],
    GraphqlDocumentConnectionPageInfo: ['RootQueryToGraphqlDocumentConnectionPageInfo'],
    MediaItemConnection: ['RootQueryToMediaItemConnection', 'UserToMediaItemConnection'],
    MediaItemConnectionPageInfo: [
      'RootQueryToMediaItemConnectionPageInfo',
      'UserToMediaItemConnectionPageInfo'
    ],
    MenuConnection: ['RootQueryToMenuConnection'],
    MenuConnectionPageInfo: ['RootQueryToMenuConnectionPageInfo'],
    PluginConnection: ['RootQueryToPluginConnection'],
    PluginConnectionEdge: ['RootQueryToPluginConnectionEdge'],
    PluginConnectionPageInfo: ['RootQueryToPluginConnectionPageInfo'],
    ThemeConnection: ['RootQueryToThemeConnection'],
    ThemeConnectionEdge: ['RootQueryToThemeConnectionEdge'],
    ThemeConnectionPageInfo: ['RootQueryToThemeConnectionPageInfo'],
    UserConnection: ['RootQueryToUserConnection'],
    UserConnectionPageInfo: ['RootQueryToUserConnectionPageInfo'],
    UserRoleConnection: ['RootQueryToUserRoleConnection', 'UserToUserRoleConnection'],
    UserRoleConnectionEdge: ['RootQueryToUserRoleConnectionEdge', 'UserToUserRoleConnectionEdge'],
    UserRoleConnectionPageInfo: [
      'RootQueryToUserRoleConnectionPageInfo',
      'UserToUserRoleConnectionPageInfo'
    ],
    TeamMember_Team_Investments: ['Company'],
    TeamMember_Teaminvestments_Investments: ['Company'],
    YoastFaqBlockAttributesUnion: [
      'YoastFaqBlockAttributes',
      'YoastFaqBlockDeprecatedV1Attributes'
    ],
    YoastHowToBlockAttributesUnion: [
      'YoastHowToBlockAttributes',
      'YoastHowToBlockDeprecatedV1Attributes',
      'YoastHowToBlockDeprecatedV2Attributes'
    ]
  }
} as const;

/**
 * A Field Group registered by ACF
 */
export interface AcfFieldGroup {
  __typename?:
    | 'AcfOptionsFooter_Footersettings'
    | 'AcfOptionsFooter_Footersettings_externalLinks'
    | 'AcfOptionsFooter_Footersettings_links'
    | 'AcfOptionsFooter_Footersettings_socialMedia'
    | 'AcfOptionsNotifications_Websitenotificationbanner'
    | 'Company_Companies'
    | 'Company_Companies_info'
    | 'Post_Blogpost'
    | 'Press_Acfpress'
    | 'TeamMember_Team'
    | 'TeamMember_Team_boardPositions'
    | 'TeamMember_Teaminvestments';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  $on: $AcfFieldGroup;
}

/**
 * Footer Settings options.
 */
export interface AcfOptionsFooter {
  __typename?: 'AcfOptionsFooter';
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Footer settings&quot; was set to Show in GraphQL.
   */
  footerSettings?: Maybe<AcfOptionsFooter_Footersettings>;
  pageSlug?: Maybe<ScalarsEnums['String']>;
  pageTitle?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface AcfOptionsFooter_Footersettings {
  __typename?: 'AcfOptionsFooter_Footersettings';
  address?: Maybe<ScalarsEnums['String']>;
  addressLink?: Maybe<ScalarsEnums['String']>;
  email?: Maybe<ScalarsEnums['String']>;
  externalLinks?: Maybe<Array<Maybe<AcfOptionsFooter_Footersettings_externalLinks>>>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  links?: Maybe<Array<Maybe<AcfOptionsFooter_Footersettings_links>>>;
  newsletterTitle?: Maybe<ScalarsEnums['String']>;
  phoneNumber?: Maybe<ScalarsEnums['String']>;
  socialMedia?: Maybe<Array<Maybe<AcfOptionsFooter_Footersettings_socialMedia>>>;
}

/**
 * Field Group
 */
export interface AcfOptionsFooter_Footersettings_externalLinks {
  __typename?: 'AcfOptionsFooter_Footersettings_externalLinks';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface AcfOptionsFooter_Footersettings_links {
  __typename?: 'AcfOptionsFooter_Footersettings_links';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface AcfOptionsFooter_Footersettings_socialMedia {
  __typename?: 'AcfOptionsFooter_Footersettings_socialMedia';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  mediaLabel?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
}

/**
 * Notification Banner Settings options.
 */
export interface AcfOptionsNotifications {
  __typename?: 'AcfOptionsNotifications';
  pageSlug?: Maybe<ScalarsEnums['String']>;
  pageTitle?: Maybe<ScalarsEnums['String']>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Website Notification Banner&quot; was set to Show in GraphQL.
   */
  websiteNotificationBanner?: Maybe<AcfOptionsNotifications_Websitenotificationbanner>;
}

/**
 * Field Group
 */
export interface AcfOptionsNotifications_Websitenotificationbanner {
  __typename?: 'AcfOptionsNotifications_Websitenotificationbanner';
  bannerContent?: Maybe<ScalarsEnums['String']>;
  /**
   * ID of current active banner. DO NOTE CHANGE
   */
  bannerId?: Maybe<ScalarsEnums['String']>;
  enabled?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
}

/**
 * Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from.
 */
export interface Avatar {
  __typename?: 'Avatar';
  /**
   * URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo).
   */
  default?: Maybe<ScalarsEnums['String']>;
  /**
   * HTML attributes to insert in the IMG element. Is not sanitized.
   */
  extraAttr?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether to always show the default image, never the Gravatar.
   */
  forceDefault?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the avatar was successfully found.
   */
  foundAvatar?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Height of the avatar image.
   */
  height?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order.
   */
  rating?: Maybe<ScalarsEnums['String']>;
  /**
   * Type of url scheme to use. Typically HTTP vs. HTTPS.
   */
  scheme?: Maybe<ScalarsEnums['String']>;
  /**
   * The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image.
   */
  size?: Maybe<ScalarsEnums['Int']>;
  /**
   * URL for the gravatar image source.
   */
  url?: Maybe<ScalarsEnums['String']>;
  /**
   * Width of the avatar image.
   */
  width?: Maybe<ScalarsEnums['Int']>;
}

/**
 * Gutenberg block interface
 */
export interface Block {
  __typename?:
    | 'CoreArchivesBlock'
    | 'CoreAudioBlock'
    | 'CoreAvatarBlock'
    | 'CoreBlock'
    | 'CoreButtonBlock'
    | 'CoreButtonsBlock'
    | 'CoreCalendarBlock'
    | 'CoreCategoriesBlock'
    | 'CoreCodeBlock'
    | 'CoreColumnBlock'
    | 'CoreColumnsBlock'
    | 'CoreCommentAuthorNameBlock'
    | 'CoreCommentContentBlock'
    | 'CoreCommentDateBlock'
    | 'CoreCommentEditLinkBlock'
    | 'CoreCommentReplyLinkBlock'
    | 'CoreCommentTemplateBlock'
    | 'CoreCommentsBlock'
    | 'CoreCommentsPaginationBlock'
    | 'CoreCommentsPaginationNextBlock'
    | 'CoreCommentsPaginationNumbersBlock'
    | 'CoreCommentsPaginationPreviousBlock'
    | 'CoreCommentsTitleBlock'
    | 'CoreCoverBlock'
    | 'CoreDetailsBlock'
    | 'CoreEmbedBlock'
    | 'CoreFileBlock'
    | 'CoreFootnotesBlock'
    | 'CoreFreeformBlock'
    | 'CoreGalleryBlock'
    | 'CoreGroupBlock'
    | 'CoreHeadingBlock'
    | 'CoreHomeLinkBlock'
    | 'CoreHtmlBlock'
    | 'CoreImageBlock'
    | 'CoreLatestCommentsBlock'
    | 'CoreLatestPostsBlock'
    | 'CoreLegacyWidgetBlock'
    | 'CoreListBlock'
    | 'CoreListItemBlock'
    | 'CoreLoginoutBlock'
    | 'CoreMediaTextBlock'
    | 'CoreMissingBlock'
    | 'CoreMoreBlock'
    | 'CoreNavigationBlock'
    | 'CoreNavigationLinkBlock'
    | 'CoreNavigationSubmenuBlock'
    | 'CoreNextpageBlock'
    | 'CorePageListBlock'
    | 'CorePageListItemBlock'
    | 'CoreParagraphBlock'
    | 'CorePatternBlock'
    | 'CorePostAuthorBiographyBlock'
    | 'CorePostAuthorBlock'
    | 'CorePostAuthorNameBlock'
    | 'CorePostCommentsFormBlock'
    | 'CorePostContentBlock'
    | 'CorePostDateBlock'
    | 'CorePostExcerptBlock'
    | 'CorePostFeaturedImageBlock'
    | 'CorePostNavigationLinkBlock'
    | 'CorePostTemplateBlock'
    | 'CorePostTermsBlock'
    | 'CorePostTitleBlock'
    | 'CorePreformattedBlock'
    | 'CorePullquoteBlock'
    | 'CoreQueryBlock'
    | 'CoreQueryNoResultsBlock'
    | 'CoreQueryPaginationBlock'
    | 'CoreQueryPaginationNextBlock'
    | 'CoreQueryPaginationNumbersBlock'
    | 'CoreQueryPaginationPreviousBlock'
    | 'CoreQueryTitleBlock'
    | 'CoreQuoteBlock'
    | 'CoreReadMoreBlock'
    | 'CoreRssBlock'
    | 'CoreSearchBlock'
    | 'CoreSeparatorBlock'
    | 'CoreShortcodeBlock'
    | 'CoreSiteLogoBlock'
    | 'CoreSiteTaglineBlock'
    | 'CoreSiteTitleBlock'
    | 'CoreSocialLinkBlock'
    | 'CoreSocialLinksBlock'
    | 'CoreSpacerBlock'
    | 'CoreTableBlock'
    | 'CoreTagCloudBlock'
    | 'CoreTemplatePartBlock'
    | 'CoreTermDescriptionBlock'
    | 'CoreTextColumnsBlock'
    | 'CoreVerseBlock'
    | 'CoreVideoBlock'
    | 'CoreWidgetGroupBlock'
    | 'Jam3AccordionBlock'
    | 'Jam3AccordionItemBlock'
    | 'Jam3AcfCompanyListingImgBlock'
    | 'Jam3AcfCompanyLogoBlock'
    | 'Jam3CarouselBlock'
    | 'Jam3CarouselItemBlock'
    | 'Jam3CompanyImageWithLogoBlock'
    | 'Jam3CompanyInfoBlock'
    | 'Jam3CompanyInfoItemBlock'
    | 'Jam3NewsletterBlock'
    | 'Jam3ReadTimeBlock'
    | 'Jam3RotateTextBlock'
    | 'Jam3SocialShareBlock'
    | 'Jam3TeamListingBlock'
    | 'MonsterinsightsPopularPostsInlineBlock'
    | 'MonsterinsightsPopularPostsWidgetBlock'
    | 'YoastFaqBlock'
    | 'YoastHowToBlock'
    | 'YoastSeoBreadcrumbsBlock';
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
  $on: $Block;
}

/**
 * Gutenberg post interface
 */
export interface BlockEditorContentNode {
  __typename?: 'Company' | 'Page' | 'Post' | 'Press' | 'ReusableBlock' | 'TeamMember';
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  id: ScalarsEnums['ID'];
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  $on: $BlockEditorContentNode;
}

/**
 * Connection to BlockEditorContentNode Nodes
 */
export interface BlockEditorContentNodeConnection {
  __typename?: 'BlockEditorContentNodeConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected BlockEditorContentNode Nodes
   */
  edges: Array<BlockEditorContentNodeConnectionEdge>;
  /**
   * A list of connected BlockEditorContentNode Nodes
   */
  nodes: Array<BlockEditorContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: BlockEditorContentNodeConnectionPageInfo;
}

/**
 * Edge between a Node and a connected BlockEditorContentNode
 */
export interface BlockEditorContentNodeConnectionEdge {
  __typename?: 'BlockEditorContentNodeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected BlockEditorContentNode Node
   */
  node: BlockEditorContentNode;
}

/**
 * Page Info on the connected BlockEditorContentNodeConnectionEdge
 */
export interface BlockEditorContentNodeConnectionPageInfo {
  __typename?: 'BlockEditorContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * The BlockEditorPreview type
 */
export interface BlockEditorPreview {
  __typename?: 'BlockEditorPreview';
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  blockEditorPreviewId: ScalarsEnums['Int'];
  blocks?: Maybe<Array<Block>>;
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the wgg_preview object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  lastUpdateTime?: Maybe<ScalarsEnums['String']>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the BlockEditorPreview type and the BlockEditorPreview type
   * @deprecated The &quot;BlockEditorPreview&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  preview?: Maybe<BlockEditorPreviewToPreviewConnectionEdge>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  previewed?: Maybe<BlockEditorContentNode>;
  previewedDatabaseId?: Maybe<ScalarsEnums['Int']>;
  previewedParentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to BlockEditorPreview Nodes
 */
export interface BlockEditorPreviewConnection {
  __typename?: 'RootQueryToBlockEditorPreviewConnection' | 'UserToBlockEditorPreviewConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected BlockEditorPreview Nodes
   */
  edges: Array<BlockEditorPreviewConnectionEdge>;
  /**
   * A list of connected BlockEditorPreview Nodes
   */
  nodes: Array<BlockEditorPreview>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: BlockEditorPreviewConnectionPageInfo;
  $on: $BlockEditorPreviewConnection;
}

/**
 * Edge between a Node and a connected BlockEditorPreview
 */
export interface BlockEditorPreviewConnectionEdge {
  __typename?:
    | 'BlockEditorPreviewToPreviewConnectionEdge'
    | 'RootQueryToBlockEditorPreviewConnectionEdge'
    | 'UserToBlockEditorPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected BlockEditorPreview Node
   */
  node: BlockEditorPreview;
  $on: $BlockEditorPreviewConnectionEdge;
}

/**
 * Page Info on the connected BlockEditorPreviewConnectionEdge
 */
export interface BlockEditorPreviewConnectionPageInfo {
  __typename?:
    | 'RootQueryToBlockEditorPreviewConnectionPageInfo'
    | 'UserToBlockEditorPreviewConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $BlockEditorPreviewConnectionPageInfo;
}

/**
 * Connection between the BlockEditorPreview type and the BlockEditorPreview type
 */
export interface BlockEditorPreviewToPreviewConnectionEdge {
  __typename?: 'BlockEditorPreviewToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   * @deprecated The &quot;BlockEditorPreview&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  node: BlockEditorPreview;
}

/**
 * The category type
 */
export interface Category {
  __typename?: 'Category';
  /**
   * The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<CategoryToAncestorsCategoryConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  categoryId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the category type and its children categories.
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToCategoryConnectionWhereArgs>;
  }) => Maybe<CategoryToCategoryConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the Category type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToContentNodeConnectionWhereArgs>;
  }) => Maybe<CategoryToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the category type and its parent category.
   */
  parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Connection between the Category type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToPostConnectionWhereArgs>;
  }) => Maybe<CategoryToPostConnection>;
  /**
   * The Yoast SEO data of the Categories taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Category type and the Taxonomy type
   */
  taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to category Nodes
 */
export interface CategoryConnection {
  __typename?:
    | 'CategoryToAncestorsCategoryConnection'
    | 'CategoryToCategoryConnection'
    | 'PostToCategoryConnection'
    | 'RootQueryToCategoryConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected category Nodes
   */
  edges: Array<CategoryConnectionEdge>;
  /**
   * A list of connected category Nodes
   */
  nodes: Array<Category>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CategoryConnectionPageInfo;
  $on: $CategoryConnection;
}

/**
 * Edge between a Node and a connected category
 */
export interface CategoryConnectionEdge {
  __typename?:
    | 'CategoryToAncestorsCategoryConnectionEdge'
    | 'CategoryToCategoryConnectionEdge'
    | 'CategoryToParentCategoryConnectionEdge'
    | 'PostToCategoryConnectionEdge'
    | 'RootQueryToCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected category Node
   */
  node: Category;
  $on: $CategoryConnectionEdge;
}

/**
 * Page Info on the connected CategoryConnectionEdge
 */
export interface CategoryConnectionPageInfo {
  __typename?:
    | 'CategoryToAncestorsCategoryConnectionPageInfo'
    | 'CategoryToCategoryConnectionPageInfo'
    | 'PostToCategoryConnectionPageInfo'
    | 'RootQueryToCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $CategoryConnectionPageInfo;
}

/**
 * Connection between the Category type and the category type
 */
export interface CategoryToAncestorsCategoryConnection {
  __typename?: 'CategoryToAncestorsCategoryConnection';
  /**
   * Edges for the CategoryToAncestorsCategoryConnection connection
   */
  edges: Array<CategoryToAncestorsCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Category>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CategoryToAncestorsCategoryConnectionEdge {
  __typename?: 'CategoryToAncestorsCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Category;
}

/**
 * Page Info on the &quot;CategoryToAncestorsCategoryConnection&quot;
 */
export interface CategoryToAncestorsCategoryConnectionPageInfo {
  __typename?: 'CategoryToAncestorsCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Category type and the category type
 */
export interface CategoryToCategoryConnection {
  __typename?: 'CategoryToCategoryConnection';
  /**
   * Edges for the CategoryToCategoryConnection connection
   */
  edges: Array<CategoryToCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Category>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CategoryToCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CategoryToCategoryConnectionEdge {
  __typename?: 'CategoryToCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Category;
}

/**
 * Page Info on the &quot;CategoryToCategoryConnection&quot;
 */
export interface CategoryToCategoryConnectionPageInfo {
  __typename?: 'CategoryToCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Category type and the ContentNode type
 */
export interface CategoryToContentNodeConnection {
  __typename?: 'CategoryToContentNodeConnection';
  /**
   * Edges for the CategoryToContentNodeConnection connection
   */
  edges: Array<CategoryToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CategoryToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CategoryToContentNodeConnectionEdge {
  __typename?: 'CategoryToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;CategoryToContentNodeConnection&quot;
 */
export interface CategoryToContentNodeConnectionPageInfo {
  __typename?: 'CategoryToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Category type and the category type
 */
export interface CategoryToParentCategoryConnectionEdge {
  __typename?: 'CategoryToParentCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Category;
}

/**
 * Connection between the Category type and the post type
 */
export interface CategoryToPostConnection {
  __typename?: 'CategoryToPostConnection';
  /**
   * Edges for the CategoryToPostConnection connection
   */
  edges: Array<CategoryToPostConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CategoryToPostConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CategoryToPostConnectionEdge {
  __typename?: 'CategoryToPostConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;CategoryToPostConnection&quot;
 */
export interface CategoryToPostConnectionPageInfo {
  __typename?: 'CategoryToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Category type and the Taxonomy type
 */
export interface CategoryToTaxonomyConnectionEdge {
  __typename?: 'CategoryToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Taxonomy;
}

/**
 * A Comment object
 */
export interface Comment {
  __typename?: 'Comment';
  /**
   * User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL.
   */
  agent?: Maybe<ScalarsEnums['String']>;
  /**
   * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
   * @deprecated Deprecated in favor of the `status` field
   */
  approved?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The author of the comment
   */
  author?: Maybe<CommentToCommenterConnectionEdge>;
  /**
   * IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL.
   */
  authorIp?: Maybe<ScalarsEnums['String']>;
  /**
   * ID for the comment, unique among comments.
   * @deprecated Deprecated in favor of databaseId
   */
  commentId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the Comment type and the ContentNode type
   */
  commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
  /**
   * Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier for the comment object
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL.
   */
  karma?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the Comment type and the Comment type
   */
  parent: (args?: {
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CommentToParentCommentConnectionWhereArgs>;
  }) => Maybe<CommentToParentCommentConnectionEdge>;
  /**
   * The database id of the parent comment node or null if it is the root comment
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent comment node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Connection between the Comment type and the Comment type
   */
  replies: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CommentToCommentConnectionWhereArgs>;
  }) => Maybe<CommentToCommentConnection>;
  /**
   * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
   */
  status?: Maybe<ScalarsEnums['CommentStatusEnum']>;
  /**
   * Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL.
   */
  type?: Maybe<ScalarsEnums['String']>;
}

/**
 * A Comment Author object
 */
export interface CommentAuthor {
  __typename?: 'CommentAuthor';
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar: (args?: {
    /**
     * Whether to always show the default image, never the Gravatar. Default false
     */
    forceDefault?: Maybe<Scalars['Boolean']>;
    /**
     * The rating level of the avatar.
     */
    rating?: Maybe<AvatarRatingEnum>;
    /**
     * The size attribute of the avatar field can be used to fetch avatars of different sizes. The value corresponds to the dimension in pixels to fetch. The default is 96 pixels.
     * @defaultValue `96`
     */
    size?: Maybe<Scalars['Int']>;
  }) => Maybe<Avatar>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The email for the comment author
   */
  email?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier for the comment author object
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The name for the comment author.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The url the comment author.
   */
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to Comment Nodes
 */
export interface CommentConnection {
  __typename?:
    | 'CommentToCommentConnection'
    | 'MediaItemToCommentConnection'
    | 'PageToCommentConnection'
    | 'PostToCommentConnection'
    | 'RootQueryToCommentConnection'
    | 'UserToCommentConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Comment Nodes
   */
  edges: Array<CommentConnectionEdge>;
  /**
   * A list of connected Comment Nodes
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CommentConnectionPageInfo;
  $on: $CommentConnection;
}

/**
 * Edge between a Node and a connected Comment
 */
export interface CommentConnectionEdge {
  __typename?:
    | 'CommentToCommentConnectionEdge'
    | 'CommentToParentCommentConnectionEdge'
    | 'MediaItemToCommentConnectionEdge'
    | 'PageToCommentConnectionEdge'
    | 'PostToCommentConnectionEdge'
    | 'RootQueryToCommentConnectionEdge'
    | 'UserToCommentConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Comment Node
   */
  node: Comment;
  $on: $CommentConnectionEdge;
}

/**
 * Page Info on the connected CommentConnectionEdge
 */
export interface CommentConnectionPageInfo {
  __typename?:
    | 'CommentToCommentConnectionPageInfo'
    | 'MediaItemToCommentConnectionPageInfo'
    | 'PageToCommentConnectionPageInfo'
    | 'PostToCommentConnectionPageInfo'
    | 'RootQueryToCommentConnectionPageInfo'
    | 'UserToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $CommentConnectionPageInfo;
}

/**
 * Connection between the Comment type and the Comment type
 */
export interface CommentToCommentConnection {
  __typename?: 'CommentToCommentConnection';
  /**
   * Edges for the CommentToCommentConnection connection
   */
  edges: Array<CommentToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CommentToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CommentToCommentConnectionEdge {
  __typename?: 'CommentToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;CommentToCommentConnection&quot;
 */
export interface CommentToCommentConnectionPageInfo {
  __typename?: 'CommentToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Comment type and the Commenter type
 */
export interface CommentToCommenterConnectionEdge {
  __typename?: 'CommentToCommenterConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Commenter;
}

/**
 * Connection between the Comment type and the ContentNode type
 */
export interface CommentToContentNodeConnectionEdge {
  __typename?: 'CommentToContentNodeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: ContentNode;
}

/**
 * Connection between the Comment type and the Comment type
 */
export interface CommentToParentCommentConnectionEdge {
  __typename?: 'CommentToParentCommentConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Comment;
}

/**
 * The author of a comment
 */
export interface Commenter {
  __typename?: 'CommentAuthor' | 'User';
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar?: Maybe<Avatar>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The email address of the author of a comment.
   */
  email?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier for the comment author.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the author information is considered restricted. (not fully public)
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The name of the author of a comment.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The url of the author of a comment.
   */
  url?: Maybe<ScalarsEnums['String']>;
  $on: $Commenter;
}

/**
 * Edge between a Node and a connected Commenter
 */
export interface CommenterConnectionEdge {
  __typename?: 'CommentToCommenterConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Commenter Node
   */
  node: Commenter;
  $on: $CommenterConnectionEdge;
}

/**
 * The Company type
 */
export interface Company {
  __typename?: 'Company';
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Companies&quot; was set to Show in GraphQL.
   */
  companies?: Maybe<Company_Companies>;
  /**
   * Connection between the Company type and the CompanyCategory type
   */
  companyCategories: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CompanyToCompanyCategoryConnectionWhereArgs>;
  }) => Maybe<CompanyToCompanyCategoryConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  companyId: ScalarsEnums['Int'];
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The excerpt of the post.
   */
  excerpt: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the company object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Connection between the Company type and the Company type
   */
  preview?: Maybe<CompanyToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the Company type and the TermNode type
   */
  terms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CompanyToTermNodeConnectionWhereArgs>;
  }) => Maybe<CompanyToTermNodeConnection>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * The CompanyCategory type
 */
export interface CompanyCategory {
  __typename?: 'CompanyCategory';
  /**
   * The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<CompanyCategoryToAncestorsCompanyCategoryConnection>;
  /**
   * Connection between the CompanyCategory type and its children CompanyCategories.
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CompanyCategoryToCompanyCategoryConnectionWhereArgs>;
  }) => Maybe<CompanyCategoryToCompanyCategoryConnection>;
  /**
   * Connection between the CompanyCategory type and the Company type
   */
  companies: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CompanyCategoryToCompanyConnectionWhereArgs>;
  }) => Maybe<CompanyCategoryToCompanyConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  companyCategoryId?: Maybe<ScalarsEnums['Int']>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the CompanyCategory type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CompanyCategoryToContentNodeConnectionWhereArgs>;
  }) => Maybe<CompanyCategoryToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the CompanyCategory type and its parent CompanyCategory.
   */
  parent?: Maybe<CompanyCategoryToParentCompanyCategoryConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the Company Categories taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the CompanyCategory type and the Taxonomy type
   */
  taxonomy?: Maybe<CompanyCategoryToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to CompanyCategory Nodes
 */
export interface CompanyCategoryConnection {
  __typename?:
    | 'CompanyCategoryToAncestorsCompanyCategoryConnection'
    | 'CompanyCategoryToCompanyCategoryConnection'
    | 'CompanyToCompanyCategoryConnection'
    | 'RootQueryToCompanyCategoryConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected CompanyCategory Nodes
   */
  edges: Array<CompanyCategoryConnectionEdge>;
  /**
   * A list of connected CompanyCategory Nodes
   */
  nodes: Array<CompanyCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyCategoryConnectionPageInfo;
  $on: $CompanyCategoryConnection;
}

/**
 * Edge between a Node and a connected CompanyCategory
 */
export interface CompanyCategoryConnectionEdge {
  __typename?:
    | 'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToParentCompanyCategoryConnectionEdge'
    | 'CompanyToCompanyCategoryConnectionEdge'
    | 'RootQueryToCompanyCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected CompanyCategory Node
   */
  node: CompanyCategory;
  $on: $CompanyCategoryConnectionEdge;
}

/**
 * Page Info on the connected CompanyCategoryConnectionEdge
 */
export interface CompanyCategoryConnectionPageInfo {
  __typename?:
    | 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo'
    | 'CompanyCategoryToCompanyCategoryConnectionPageInfo'
    | 'CompanyToCompanyCategoryConnectionPageInfo'
    | 'RootQueryToCompanyCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $CompanyCategoryConnectionPageInfo;
}

/**
 * Connection between the CompanyCategory type and the CompanyCategory type
 */
export interface CompanyCategoryToAncestorsCompanyCategoryConnection {
  __typename?: 'CompanyCategoryToAncestorsCompanyCategoryConnection';
  /**
   * Edges for the CompanyCategoryToAncestorsCompanyCategoryConnection connection
   */
  edges: Array<CompanyCategoryToAncestorsCompanyCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<CompanyCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyCategoryToAncestorsCompanyCategoryConnectionEdge {
  __typename?: 'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: CompanyCategory;
}

/**
 * Page Info on the &quot;CompanyCategoryToAncestorsCompanyCategoryConnection&quot;
 */
export interface CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo {
  __typename?: 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the CompanyCategory type and the CompanyCategory type
 */
export interface CompanyCategoryToCompanyCategoryConnection {
  __typename?: 'CompanyCategoryToCompanyCategoryConnection';
  /**
   * Edges for the CompanyCategoryToCompanyCategoryConnection connection
   */
  edges: Array<CompanyCategoryToCompanyCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<CompanyCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyCategoryToCompanyCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyCategoryToCompanyCategoryConnectionEdge {
  __typename?: 'CompanyCategoryToCompanyCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: CompanyCategory;
}

/**
 * Page Info on the &quot;CompanyCategoryToCompanyCategoryConnection&quot;
 */
export interface CompanyCategoryToCompanyCategoryConnectionPageInfo {
  __typename?: 'CompanyCategoryToCompanyCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the CompanyCategory type and the Company type
 */
export interface CompanyCategoryToCompanyConnection {
  __typename?: 'CompanyCategoryToCompanyConnection';
  /**
   * Edges for the CompanyCategoryToCompanyConnection connection
   */
  edges: Array<CompanyCategoryToCompanyConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Company>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyCategoryToCompanyConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyCategoryToCompanyConnectionEdge {
  __typename?: 'CompanyCategoryToCompanyConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Company;
}

/**
 * Page Info on the &quot;CompanyCategoryToCompanyConnection&quot;
 */
export interface CompanyCategoryToCompanyConnectionPageInfo {
  __typename?: 'CompanyCategoryToCompanyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the CompanyCategory type and the ContentNode type
 */
export interface CompanyCategoryToContentNodeConnection {
  __typename?: 'CompanyCategoryToContentNodeConnection';
  /**
   * Edges for the CompanyCategoryToContentNodeConnection connection
   */
  edges: Array<CompanyCategoryToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyCategoryToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyCategoryToContentNodeConnectionEdge {
  __typename?: 'CompanyCategoryToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;CompanyCategoryToContentNodeConnection&quot;
 */
export interface CompanyCategoryToContentNodeConnectionPageInfo {
  __typename?: 'CompanyCategoryToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the CompanyCategory type and the CompanyCategory type
 */
export interface CompanyCategoryToParentCompanyCategoryConnectionEdge {
  __typename?: 'CompanyCategoryToParentCompanyCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: CompanyCategory;
}

/**
 * Connection between the CompanyCategory type and the Taxonomy type
 */
export interface CompanyCategoryToTaxonomyConnectionEdge {
  __typename?: 'CompanyCategoryToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Taxonomy;
}

/**
 * Connection to Company Nodes
 */
export interface CompanyConnection {
  __typename?: 'CompanyCategoryToCompanyConnection' | 'RootQueryToCompanyConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Company Nodes
   */
  edges: Array<CompanyConnectionEdge>;
  /**
   * A list of connected Company Nodes
   */
  nodes: Array<Company>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyConnectionPageInfo;
  $on: $CompanyConnection;
}

/**
 * Edge between a Node and a connected Company
 */
export interface CompanyConnectionEdge {
  __typename?:
    | 'CompanyCategoryToCompanyConnectionEdge'
    | 'CompanyToPreviewConnectionEdge'
    | 'RootQueryToCompanyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Company Node
   */
  node: Company;
  $on: $CompanyConnectionEdge;
}

/**
 * Page Info on the connected CompanyConnectionEdge
 */
export interface CompanyConnectionPageInfo {
  __typename?:
    | 'CompanyCategoryToCompanyConnectionPageInfo'
    | 'RootQueryToCompanyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $CompanyConnectionPageInfo;
}

/**
 * Connection between the Company type and the CompanyCategory type
 */
export interface CompanyToCompanyCategoryConnection {
  __typename?: 'CompanyToCompanyCategoryConnection';
  /**
   * Edges for the CompanyToCompanyCategoryConnection connection
   */
  edges: Array<CompanyToCompanyCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<CompanyCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyToCompanyCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyToCompanyCategoryConnectionEdge {
  __typename?: 'CompanyToCompanyCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary company_category
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: CompanyCategory;
}

/**
 * Page Info on the &quot;CompanyToCompanyCategoryConnection&quot;
 */
export interface CompanyToCompanyCategoryConnectionPageInfo {
  __typename?: 'CompanyToCompanyCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Company type and the Company type
 */
export interface CompanyToPreviewConnectionEdge {
  __typename?: 'CompanyToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Company;
}

/**
 * Connection between the Company type and the TermNode type
 */
export interface CompanyToTermNodeConnection {
  __typename?: 'CompanyToTermNodeConnection';
  /**
   * Edges for the CompanyToTermNodeConnection connection
   */
  edges: Array<CompanyToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: CompanyToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface CompanyToTermNodeConnectionEdge {
  __typename?: 'CompanyToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;CompanyToTermNodeConnection&quot;
 */
export interface CompanyToTermNodeConnectionPageInfo {
  __typename?: 'CompanyToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface Company_Companies {
  __typename?: 'Company_Companies';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  info?: Maybe<Array<Maybe<Company_Companies_info>>>;
  /**
   * Full path URL (ex. http://google.com)
   */
  linkedinUrl?: Maybe<ScalarsEnums['String']>;
  listingImage?: Maybe<MediaItem>;
  /**
   * Logo Size: 730 x 730 SVG or transparent PNG24
   */
  logo?: Maybe<MediaItem>;
  partner?: Maybe<Array<Maybe<Company_Companies_Partner>>>;
  status?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  stockTicker?: Maybe<ScalarsEnums['String']>;
  /**
   * Full path URL (ex. http://google.com)
   */
  twitterUrl?: Maybe<ScalarsEnums['String']>;
  /**
   * Full path URL (ex. http://google.com)
   */
  websiteUrl?: Maybe<ScalarsEnums['String']>;
}

export interface Company_Companies_Partner {
  __typename?: 'TeamMember';
  $on: $Company_Companies_Partner;
}

/**
 * Field Group
 */
export interface Company_Companies_info {
  __typename?: 'Company_Companies_info';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  text?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * GraphQL representation of WordPress Conditional Tags.
 */
export interface ConditionalTags {
  __typename?: 'ConditionalTags';
  /**
   * Determines whether the query is for an existing archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isArchive?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing attachment page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isAttachment?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing author archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isAuthor?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing category archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isCategory?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing date archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isDate?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing day archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isDay?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for the front page of the site.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isFrontPage?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for the blog homepage.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isHome?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing month archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isMonth?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether this site has more than one author.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isMultiAuthor?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing single page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPage?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether currently in a page template.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPageTemplate?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing post type archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPostTypeArchive?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for a post or page preview.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for the Privacy Policy page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPrivacyPolicy?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for a search.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSearch?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing single post.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSingle?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing single post of any post type (post, attachment, page, custom post types).
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSingular?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether a post is sticky.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSticky?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing tag archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isTag?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing custom taxonomy archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isTax?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Determines whether the query is for an existing year archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isYear?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * A plural connection from one Node Type in the Graph to another Node Type, with support for relational data via &quot;edges&quot;.
 */
export interface Connection {
  __typename?:
    | 'CategoryToAncestorsCategoryConnection'
    | 'CategoryToCategoryConnection'
    | 'CategoryToContentNodeConnection'
    | 'CategoryToPostConnection'
    | 'CommentToCommentConnection'
    | 'CompanyCategoryToAncestorsCompanyCategoryConnection'
    | 'CompanyCategoryToCompanyCategoryConnection'
    | 'CompanyCategoryToCompanyConnection'
    | 'CompanyCategoryToContentNodeConnection'
    | 'CompanyToCompanyCategoryConnection'
    | 'CompanyToTermNodeConnection'
    | 'ContentNodeToEnqueuedScriptConnection'
    | 'ContentNodeToEnqueuedStylesheetConnection'
    | 'ContentTypeToContentNodeConnection'
    | 'ContentTypeToTaxonomyConnection'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnection'
    | 'HierarchicalContentNodeToContentNodeChildrenConnection'
    | 'MediaItemToCommentConnection'
    | 'MenuItemToMenuItemConnection'
    | 'MenuToMenuItemConnection'
    | 'PageToCommentConnection'
    | 'PageToRevisionConnection'
    | 'PostFormatToContentNodeConnection'
    | 'PostFormatToPostConnection'
    | 'PostToCategoryConnection'
    | 'PostToCommentConnection'
    | 'PostToPostFormatConnection'
    | 'PostToRevisionConnection'
    | 'PostToTagConnection'
    | 'PostToTermNodeConnection'
    | 'PressCategoryToAncestorsPressCategoryConnection'
    | 'PressCategoryToContentNodeConnection'
    | 'PressCategoryToPressCategoryConnection'
    | 'PressCategoryToPressConnection'
    | 'PressCategoryToTeamMemberConnection'
    | 'PressToPressCategoryConnection'
    | 'PressToTermNodeConnection'
    | 'ReusableBlockToRevisionConnection'
    | 'RootQueryToBlockEditorPreviewConnection'
    | 'RootQueryToCategoryConnection'
    | 'RootQueryToCommentConnection'
    | 'RootQueryToCompanyCategoryConnection'
    | 'RootQueryToCompanyConnection'
    | 'RootQueryToContentNodeConnection'
    | 'RootQueryToContentTypeConnection'
    | 'RootQueryToEnqueuedScriptConnection'
    | 'RootQueryToEnqueuedStylesheetConnection'
    | 'RootQueryToGraphqlDocumentConnection'
    | 'RootQueryToMediaItemConnection'
    | 'RootQueryToMenuConnection'
    | 'RootQueryToMenuItemConnection'
    | 'RootQueryToPageConnection'
    | 'RootQueryToPluginConnection'
    | 'RootQueryToPostConnection'
    | 'RootQueryToPostFormatConnection'
    | 'RootQueryToPressCategoryConnection'
    | 'RootQueryToPressConnection'
    | 'RootQueryToReusableBlockConnection'
    | 'RootQueryToRevisionsConnection'
    | 'RootQueryToTagConnection'
    | 'RootQueryToTaxonomyConnection'
    | 'RootQueryToTeamMemberConnection'
    | 'RootQueryToTermNodeConnection'
    | 'RootQueryToThemeConnection'
    | 'RootQueryToUserConnection'
    | 'RootQueryToUserRoleConnection'
    | 'TagToContentNodeConnection'
    | 'TagToPostConnection'
    | 'TaxonomyToContentTypeConnection'
    | 'TaxonomyToTermNodeConnection'
    | 'TeamMemberToPressCategoryConnection'
    | 'TeamMemberToTermNodeConnection'
    | 'TermNodeToEnqueuedScriptConnection'
    | 'TermNodeToEnqueuedStylesheetConnection'
    | 'UserToBlockEditorPreviewConnection'
    | 'UserToCommentConnection'
    | 'UserToEnqueuedScriptConnection'
    | 'UserToEnqueuedStylesheetConnection'
    | 'UserToMediaItemConnection'
    | 'UserToPageConnection'
    | 'UserToPostConnection'
    | 'UserToRevisionsConnection'
    | 'UserToUserRoleConnection';
  /**
   * A list of edges (relational context) between connected nodes
   */
  edges: Array<Edge>;
  /**
   * A list of connected nodes
   */
  nodes: Array<Node>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PageInfo;
  $on: $Connection;
}

/**
 * Nodes used to manage content
 */
export interface ContentNode {
  __typename?:
    | 'BlockEditorPreview'
    | 'Company'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'ReusableBlock'
    | 'TeamMember';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The ID of the node in the database.
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $ContentNode;
}

/**
 * Connection to ContentNode Nodes
 */
export interface ContentNodeConnection {
  __typename?:
    | 'CategoryToContentNodeConnection'
    | 'CompanyCategoryToContentNodeConnection'
    | 'ContentTypeToContentNodeConnection'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnection'
    | 'HierarchicalContentNodeToContentNodeChildrenConnection'
    | 'PostFormatToContentNodeConnection'
    | 'PressCategoryToContentNodeConnection'
    | 'RootQueryToContentNodeConnection'
    | 'RootQueryToRevisionsConnection'
    | 'TagToContentNodeConnection'
    | 'UserToRevisionsConnection';
  /**
   * A list of edges (relational context) between ContentType and connected ContentNode Nodes
   */
  edges: Array<ContentNodeConnectionEdge>;
  /**
   * A list of connected ContentNode Nodes
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentNodeConnectionPageInfo;
  $on: $ContentNodeConnection;
}

/**
 * Edge between a Node and a connected ContentNode
 */
export interface ContentNodeConnectionEdge {
  __typename?:
    | 'CategoryToContentNodeConnectionEdge'
    | 'CommentToContentNodeConnectionEdge'
    | 'CompanyCategoryToContentNodeConnectionEdge'
    | 'ContentTypeToContentNodeConnectionEdge'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge'
    | 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge'
    | 'HierarchicalContentNodeToParentContentNodeConnectionEdge'
    | 'NodeWithRevisionsToContentNodeConnectionEdge'
    | 'PostFormatToContentNodeConnectionEdge'
    | 'PressCategoryToContentNodeConnectionEdge'
    | 'RootQueryToContentNodeConnectionEdge'
    | 'RootQueryToRevisionsConnectionEdge'
    | 'TagToContentNodeConnectionEdge'
    | 'UserToRevisionsConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected ContentNode Node
   */
  node: ContentNode;
  $on: $ContentNodeConnectionEdge;
}

/**
 * Page Info on the connected ContentNodeConnectionEdge
 */
export interface ContentNodeConnectionPageInfo {
  __typename?:
    | 'CategoryToContentNodeConnectionPageInfo'
    | 'CompanyCategoryToContentNodeConnectionPageInfo'
    | 'ContentTypeToContentNodeConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo'
    | 'PostFormatToContentNodeConnectionPageInfo'
    | 'PressCategoryToContentNodeConnectionPageInfo'
    | 'RootQueryToContentNodeConnectionPageInfo'
    | 'RootQueryToRevisionsConnectionPageInfo'
    | 'TagToContentNodeConnectionPageInfo'
    | 'UserToRevisionsConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $ContentNodeConnectionPageInfo;
}

/**
 * Connection between the ContentNode type and the ContentType type
 */
export interface ContentNodeToContentTypeConnectionEdge {
  __typename?: 'ContentNodeToContentTypeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: ContentType;
}

/**
 * Connection between the ContentNode type and the User type
 */
export interface ContentNodeToEditLastConnectionEdge {
  __typename?: 'ContentNodeToEditLastConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: User;
}

/**
 * Connection between the ContentNode type and the User type
 */
export interface ContentNodeToEditLockConnectionEdge {
  __typename?: 'ContentNodeToEditLockConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The timestamp for when the node was last edited
   */
  lockTimestamp?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: User;
}

/**
 * Connection between the ContentNode type and the EnqueuedScript type
 */
export interface ContentNodeToEnqueuedScriptConnection {
  __typename?: 'ContentNodeToEnqueuedScriptConnection';
  /**
   * Edges for the ContentNodeToEnqueuedScriptConnection connection
   */
  edges: Array<ContentNodeToEnqueuedScriptConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedScript>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface ContentNodeToEnqueuedScriptConnectionEdge {
  __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedScript;
}

/**
 * Page Info on the &quot;ContentNodeToEnqueuedScriptConnection&quot;
 */
export interface ContentNodeToEnqueuedScriptConnectionPageInfo {
  __typename?: 'ContentNodeToEnqueuedScriptConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the ContentNode type and the EnqueuedStylesheet type
 */
export interface ContentNodeToEnqueuedStylesheetConnection {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnection';
  /**
   * Edges for the ContentNodeToEnqueuedStylesheetConnection connection
   */
  edges: Array<ContentNodeToEnqueuedStylesheetConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedStylesheet>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface ContentNodeToEnqueuedStylesheetConnectionEdge {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedStylesheet;
}

/**
 * Page Info on the &quot;ContentNodeToEnqueuedStylesheetConnection&quot;
 */
export interface ContentNodeToEnqueuedStylesheetConnectionPageInfo {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * The template assigned to a node of content
 */
export interface ContentTemplate {
  __typename?: 'DefaultTemplate';
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums['String']>;
  $on: $ContentTemplate;
}

/**
 * An Post Type object
 */
export interface ContentType {
  __typename?: 'ContentType';
  /**
   * Whether this content type should can be exported.
   */
  canExport?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentType type and the Taxonomy type
   */
  connectedTaxonomies: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentTypeToTaxonomyConnection>;
  /**
   * Connection between the ContentType type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<ContentTypeToContentNodeConnectionWhereArgs>;
  }) => Maybe<ContentTypeToContentNodeConnection>;
  /**
   * Whether content of this type should be deleted when the author of it is deleted from the system.
   */
  deleteWithUser?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Description of the content type.
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether to exclude nodes of this content type from front end search results.
   */
  excludeFromSearch?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The plural name of the content type within the GraphQL Schema.
   */
  graphqlPluralName?: Maybe<ScalarsEnums['String']>;
  /**
   * The singular name of the content type within the GraphQL Schema.
   */
  graphqlSingleName?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether this content type should have archives. Content archives are generated by type and by date.
   */
  hasArchive?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the content type is hierarchical, for example pages.
   */
  hierarchical?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The globally unique identifier of the post-type object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether this page is set to the static front page.
   */
  isFrontPage: ScalarsEnums['Boolean'];
  /**
   * Whether this page is set to the blog posts page.
   */
  isPostsPage: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * Display name of the content type.
   */
  label?: Maybe<ScalarsEnums['String']>;
  /**
   * Details about the content type labels.
   */
  labels?: Maybe<PostTypeLabelDetails>;
  /**
   * The name of the icon file to display as a menu icon.
   */
  menuIcon?: Maybe<ScalarsEnums['String']>;
  /**
   * The position of this post type in the menu. Only applies if show_in_menu is true.
   */
  menuPosition?: Maybe<ScalarsEnums['Int']>;
  /**
   * The internal name of the post type. This should not be used for display purposes.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention.
   */
  public?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether queries can be performed on the front end for the content type as part of parse_request().
   */
  publiclyQueryable?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Name of content type to display in REST API &quot;wp/v2&quot; namespace.
   */
  restBase?: Maybe<ScalarsEnums['String']>;
  /**
   * The REST Controller class assigned to handling this content type.
   */
  restControllerClass?: Maybe<ScalarsEnums['String']>;
  /**
   * Makes this content type available via the admin bar.
   */
  showInAdminBar?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to add the content type to the GraphQL Schema.
   */
  showInGraphql?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that.
   */
  showInMenu?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Makes this content type available for selection in navigation menus.
   */
  showInNavMenus?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace.
   */
  showInRest?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to generate and allow a UI for managing this content type in the admin.
   */
  showUi?: Maybe<ScalarsEnums['Boolean']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to ContentType Nodes
 */
export interface ContentTypeConnection {
  __typename?: 'RootQueryToContentTypeConnection' | 'TaxonomyToContentTypeConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected ContentType Nodes
   */
  edges: Array<ContentTypeConnectionEdge>;
  /**
   * A list of connected ContentType Nodes
   */
  nodes: Array<ContentType>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentTypeConnectionPageInfo;
  $on: $ContentTypeConnection;
}

/**
 * Edge between a Node and a connected ContentType
 */
export interface ContentTypeConnectionEdge {
  __typename?:
    | 'ContentNodeToContentTypeConnectionEdge'
    | 'RootQueryToContentTypeConnectionEdge'
    | 'TaxonomyToContentTypeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected ContentType Node
   */
  node: ContentType;
  $on: $ContentTypeConnectionEdge;
}

/**
 * Page Info on the connected ContentTypeConnectionEdge
 */
export interface ContentTypeConnectionPageInfo {
  __typename?:
    | 'RootQueryToContentTypeConnectionPageInfo'
    | 'TaxonomyToContentTypeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $ContentTypeConnectionPageInfo;
}

/**
 * Connection between the ContentType type and the ContentNode type
 */
export interface ContentTypeToContentNodeConnection {
  __typename?: 'ContentTypeToContentNodeConnection';
  /**
   * Edges for the ContentTypeToContentNodeConnection connection
   */
  edges: Array<ContentTypeToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentTypeToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface ContentTypeToContentNodeConnectionEdge {
  __typename?: 'ContentTypeToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;ContentTypeToContentNodeConnection&quot;
 */
export interface ContentTypeToContentNodeConnectionPageInfo {
  __typename?: 'ContentTypeToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the ContentType type and the Taxonomy type
 */
export interface ContentTypeToTaxonomyConnection {
  __typename?: 'ContentTypeToTaxonomyConnection';
  /**
   * Edges for the ContentTypeToTaxonomyConnection connection
   */
  edges: Array<ContentTypeToTaxonomyConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Taxonomy>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ContentTypeToTaxonomyConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface ContentTypeToTaxonomyConnectionEdge {
  __typename?: 'ContentTypeToTaxonomyConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Taxonomy;
}

/**
 * Page Info on the &quot;ContentTypeToTaxonomyConnection&quot;
 */
export interface ContentTypeToTaxonomyConnectionPageInfo {
  __typename?: 'ContentTypeToTaxonomyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/archives block
 */
export interface CoreArchivesBlock {
  __typename?: 'CoreArchivesBlock';
  attributes?: Maybe<CoreArchivesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreArchivesBlockAttributes {
  __typename?: 'CoreArchivesBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayAsDropdown: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showLabel: ScalarsEnums['Boolean'];
  showPostCounts: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  type: ScalarsEnums['String'];
}

/**
 * core/audio block
 */
export interface CoreAudioBlock {
  __typename?: 'CoreAudioBlock';
  attributes?: Maybe<CoreAudioBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreAudioBlockAttributes {
  __typename?: 'CoreAudioBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  autoplay?: Maybe<ScalarsEnums['Boolean']>;
  className?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  loop?: Maybe<ScalarsEnums['Boolean']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  preload?: Maybe<ScalarsEnums['String']>;
  src?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

export interface CoreAudioBlockAttributesUnion {
  __typename?: 'CoreAudioBlockAttributes' | 'CoreAudioBlockDeprecatedV1Attributes';
  $on: $CoreAudioBlockAttributesUnion;
}

export interface CoreAudioBlockDeprecatedV1Attributes {
  __typename?: 'CoreAudioBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  autoplay?: Maybe<ScalarsEnums['Boolean']>;
  className?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  loop?: Maybe<ScalarsEnums['Boolean']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  preload?: Maybe<ScalarsEnums['String']>;
  src?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/avatar block
 */
export interface CoreAvatarBlock {
  __typename?: 'CoreAvatarBlock';
  attributes?: Maybe<CoreAvatarBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreAvatarBlockAttributes {
  __typename?: 'CoreAvatarBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  size: ScalarsEnums['Float'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  userId?: Maybe<ScalarsEnums['Float']>;
}

/**
 * core/block block
 */
export interface CoreBlock {
  __typename?: 'CoreBlock';
  attributes?: Maybe<CoreBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  reusableBlock: Node;
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreBlockAttributes {
  __typename?: 'CoreBlockAttributes';
  content?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ref?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreBlockAttributesUnion {
  __typename?:
    | 'CoreBlockAttributes'
    | 'CoreBlockDeprecatedV1Attributes'
    | 'CoreBlockDeprecatedV2Attributes';
  $on: $CoreBlockAttributesUnion;
}

export interface CoreBlockDeprecatedV1Attributes {
  __typename?: 'CoreBlockDeprecatedV1Attributes';
  content?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ref?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreBlockDeprecatedV2Attributes {
  __typename?: 'CoreBlockDeprecatedV2Attributes';
  content?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ref?: Maybe<ScalarsEnums['Float']>;
}

/**
 * core/button block
 */
export interface CoreButtonBlock {
  __typename?: 'CoreButtonBlock';
  attributes?: Maybe<CoreButtonBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreButtonBlockAttributes {
  __typename?: 'CoreButtonBlockAttributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockAttributesUnion {
  __typename?:
    | 'CoreButtonBlockAttributes'
    | 'CoreButtonBlockDeprecatedV1Attributes'
    | 'CoreButtonBlockDeprecatedV2Attributes'
    | 'CoreButtonBlockDeprecatedV3Attributes'
    | 'CoreButtonBlockDeprecatedV4Attributes'
    | 'CoreButtonBlockDeprecatedV5Attributes'
    | 'CoreButtonBlockDeprecatedV6Attributes'
    | 'CoreButtonBlockDeprecatedV7Attributes'
    | 'CoreButtonBlockDeprecatedV8Attributes'
    | 'CoreButtonBlockDeprecatedV9Attributes'
    | 'CoreButtonBlockDeprecatedV10Attributes'
    | 'CoreButtonBlockDeprecatedV11Attributes';
  $on: $CoreButtonBlockAttributesUnion;
}

export interface CoreButtonBlockDeprecatedV10Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV10Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV11Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV11Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV1Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV1Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV2Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV2Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV3Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV3Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV4Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV4Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV5Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV5Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV6Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV6Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV7Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV7Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV8Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV8Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

export interface CoreButtonBlockDeprecatedV9Attributes {
  __typename?: 'CoreButtonBlockDeprecatedV9Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

/**
 * core/buttons block
 */
export interface CoreButtonsBlock {
  __typename?: 'CoreButtonsBlock';
  attributes?: Maybe<CoreButtonsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreButtonsBlockAttributes {
  __typename?: 'CoreButtonsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

export interface CoreButtonsBlockAttributesUnion {
  __typename?:
    | 'CoreButtonsBlockAttributes'
    | 'CoreButtonsBlockDeprecatedV1Attributes'
    | 'CoreButtonsBlockDeprecatedV2Attributes';
  $on: $CoreButtonsBlockAttributesUnion;
}

export interface CoreButtonsBlockDeprecatedV1Attributes {
  __typename?: 'CoreButtonsBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

export interface CoreButtonsBlockDeprecatedV2Attributes {
  __typename?: 'CoreButtonsBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/calendar block
 */
export interface CoreCalendarBlock {
  __typename?: 'CoreCalendarBlock';
  attributes?: Maybe<CoreCalendarBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCalendarBlockAttributes {
  __typename?: 'CoreCalendarBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  month?: Maybe<ScalarsEnums['Int']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  year?: Maybe<ScalarsEnums['Int']>;
}

/**
 * core/categories block
 */
export interface CoreCategoriesBlock {
  __typename?: 'CoreCategoriesBlock';
  attributes?: Maybe<CoreCategoriesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCategoriesBlockAttributes {
  __typename?: 'CoreCategoriesBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayAsDropdown: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showEmpty: ScalarsEnums['Boolean'];
  showHierarchy: ScalarsEnums['Boolean'];
  showOnlyTopLevel: ScalarsEnums['Boolean'];
  showPostCounts: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/code block
 */
export interface CoreCodeBlock {
  __typename?: 'CoreCodeBlock';
  attributes?: Maybe<CoreCodeBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCodeBlockAttributes {
  __typename?: 'CoreCodeBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/column block
 */
export interface CoreColumnBlock {
  __typename?: 'CoreColumnBlock';
  attributes?: Maybe<CoreColumnBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnBlockAttributes {
  __typename?: 'CoreColumnBlockAttributes';
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnBlockAttributesUnion {
  __typename?: 'CoreColumnBlockAttributes' | 'CoreColumnBlockDeprecatedV1Attributes';
  $on: $CoreColumnBlockAttributesUnion;
}

export interface CoreColumnBlockDeprecatedV1Attributes {
  __typename?: 'CoreColumnBlockDeprecatedV1Attributes';
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/columns block
 */
export interface CoreColumnsBlock {
  __typename?: 'CoreColumnsBlock';
  attributes?: Maybe<CoreColumnsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnsBlockAttributes {
  __typename?: 'CoreColumnsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnsBlockAttributesUnion {
  __typename?:
    | 'CoreColumnsBlockAttributes'
    | 'CoreColumnsBlockDeprecatedV1Attributes'
    | 'CoreColumnsBlockDeprecatedV2Attributes'
    | 'CoreColumnsBlockDeprecatedV3Attributes';
  $on: $CoreColumnsBlockAttributesUnion;
}

export interface CoreColumnsBlockDeprecatedV1Attributes {
  __typename?: 'CoreColumnsBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnsBlockDeprecatedV2Attributes {
  __typename?: 'CoreColumnsBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreColumnsBlockDeprecatedV3Attributes {
  __typename?: 'CoreColumnsBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-author-name block
 */
export interface CoreCommentAuthorNameBlock {
  __typename?: 'CoreCommentAuthorNameBlock';
  attributes?: Maybe<CoreCommentAuthorNameBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentAuthorNameBlockAttributes {
  __typename?: 'CoreCommentAuthorNameBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentAuthorNameBlockAttributesUnion {
  __typename?:
    | 'CoreCommentAuthorNameBlockAttributes'
    | 'CoreCommentAuthorNameBlockDeprecatedV1Attributes';
  $on: $CoreCommentAuthorNameBlockAttributesUnion;
}

export interface CoreCommentAuthorNameBlockDeprecatedV1Attributes {
  __typename?: 'CoreCommentAuthorNameBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-content block
 */
export interface CoreCommentContentBlock {
  __typename?: 'CoreCommentContentBlock';
  attributes?: Maybe<CoreCommentContentBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentContentBlockAttributes {
  __typename?: 'CoreCommentContentBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-date block
 */
export interface CoreCommentDateBlock {
  __typename?: 'CoreCommentDateBlock';
  attributes?: Maybe<CoreCommentDateBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentDateBlockAttributes {
  __typename?: 'CoreCommentDateBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  format?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentDateBlockAttributesUnion {
  __typename?: 'CoreCommentDateBlockAttributes' | 'CoreCommentDateBlockDeprecatedV1Attributes';
  $on: $CoreCommentDateBlockAttributesUnion;
}

export interface CoreCommentDateBlockDeprecatedV1Attributes {
  __typename?: 'CoreCommentDateBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  format?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-edit-link block
 */
export interface CoreCommentEditLinkBlock {
  __typename?: 'CoreCommentEditLinkBlock';
  attributes?: Maybe<CoreCommentEditLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentEditLinkBlockAttributes {
  __typename?: 'CoreCommentEditLinkBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-reply-link block
 */
export interface CoreCommentReplyLinkBlock {
  __typename?: 'CoreCommentReplyLinkBlock';
  attributes?: Maybe<CoreCommentReplyLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentReplyLinkBlockAttributes {
  __typename?: 'CoreCommentReplyLinkBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comment-template block
 */
export interface CoreCommentTemplateBlock {
  __typename?: 'CoreCommentTemplateBlock';
  attributes?: Maybe<CoreCommentTemplateBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentTemplateBlockAttributes {
  __typename?: 'CoreCommentTemplateBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/comments block
 */
export interface CoreCommentsBlock {
  __typename?: 'CoreCommentsBlock';
  attributes?: Maybe<CoreCommentsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsBlockAttributes {
  __typename?: 'CoreCommentsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  legacy: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsBlockAttributesUnion {
  __typename?: 'CoreCommentsBlockAttributes' | 'CoreCommentsBlockDeprecatedV1Attributes';
  $on: $CoreCommentsBlockAttributesUnion;
}

export interface CoreCommentsBlockDeprecatedV1Attributes {
  __typename?: 'CoreCommentsBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  legacy: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comments-pagination block
 */
export interface CoreCommentsPaginationBlock {
  __typename?: 'CoreCommentsPaginationBlock';
  attributes?: Maybe<CoreCommentsPaginationBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsPaginationBlockAttributes {
  __typename?: 'CoreCommentsPaginationBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  paginationArrow: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comments-pagination-next block
 */
export interface CoreCommentsPaginationNextBlock {
  __typename?: 'CoreCommentsPaginationNextBlock';
  attributes?: Maybe<CoreCommentsPaginationNextBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsPaginationNextBlockAttributes {
  __typename?: 'CoreCommentsPaginationNextBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comments-pagination-numbers block
 */
export interface CoreCommentsPaginationNumbersBlock {
  __typename?: 'CoreCommentsPaginationNumbersBlock';
  attributes?: Maybe<CoreCommentsPaginationNumbersBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsPaginationNumbersBlockAttributes {
  __typename?: 'CoreCommentsPaginationNumbersBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comments-pagination-previous block
 */
export interface CoreCommentsPaginationPreviousBlock {
  __typename?: 'CoreCommentsPaginationPreviousBlock';
  attributes?: Maybe<CoreCommentsPaginationPreviousBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsPaginationPreviousBlockAttributes {
  __typename?: 'CoreCommentsPaginationPreviousBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/comments-title block
 */
export interface CoreCommentsTitleBlock {
  __typename?: 'CoreCommentsTitleBlock';
  attributes?: Maybe<CoreCommentsTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsTitleBlockAttributes {
  __typename?: 'CoreCommentsTitleBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showCommentsCount: ScalarsEnums['Boolean'];
  showPostTitle: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCommentsTitleBlockAttributesUnion {
  __typename?: 'CoreCommentsTitleBlockAttributes' | 'CoreCommentsTitleBlockDeprecatedV1Attributes';
  $on: $CoreCommentsTitleBlockAttributesUnion;
}

export interface CoreCommentsTitleBlockDeprecatedV1Attributes {
  __typename?: 'CoreCommentsTitleBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showCommentsCount: ScalarsEnums['Boolean'];
  showPostTitle: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/cover block
 */
export interface CoreCoverBlock {
  __typename?: 'CoreCoverBlock';
  attributes?: Maybe<CoreCoverBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreCoverBlockAttributes {
  __typename?: 'CoreCoverBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockAttributesUnion {
  __typename?:
    | 'CoreCoverBlockAttributes'
    | 'CoreCoverBlockDeprecatedV1Attributes'
    | 'CoreCoverBlockDeprecatedV2Attributes'
    | 'CoreCoverBlockDeprecatedV3Attributes'
    | 'CoreCoverBlockDeprecatedV4Attributes'
    | 'CoreCoverBlockDeprecatedV5Attributes'
    | 'CoreCoverBlockDeprecatedV6Attributes'
    | 'CoreCoverBlockDeprecatedV7Attributes'
    | 'CoreCoverBlockDeprecatedV8Attributes'
    | 'CoreCoverBlockDeprecatedV9Attributes'
    | 'CoreCoverBlockDeprecatedV10Attributes'
    | 'CoreCoverBlockDeprecatedV11Attributes'
    | 'CoreCoverBlockDeprecatedV12Attributes'
    | 'CoreCoverBlockDeprecatedV13Attributes';
  $on: $CoreCoverBlockAttributesUnion;
}

export interface CoreCoverBlockDeprecatedV10Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV10Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV11Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV11Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV12Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV12Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV13Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV13Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV1Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV2Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV3Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV4Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV5Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV6Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV7Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV7Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV8Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV8Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

export interface CoreCoverBlockDeprecatedV9Attributes {
  __typename?: 'CoreCoverBlockDeprecatedV9Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  backgroundType: ScalarsEnums['String'];
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  contentPosition?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasParallax: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  isDark: ScalarsEnums['Boolean'];
  isRepeated: ScalarsEnums['Boolean'];
  isUserOverlayColor?: Maybe<ScalarsEnums['Boolean']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minHeight?: Maybe<ScalarsEnums['Float']>;
  minHeightUnit?: Maybe<ScalarsEnums['String']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  useFeaturedImage: ScalarsEnums['Boolean'];
}

/**
 * core/details block
 */
export interface CoreDetailsBlock {
  __typename?: 'CoreDetailsBlock';
  attributes?: Maybe<CoreDetailsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreDetailsBlockAttributes {
  __typename?: 'CoreDetailsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showContent: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/embed block
 */
export interface CoreEmbedBlock {
  __typename?: 'CoreEmbedBlock';
  attributes?: Maybe<CoreEmbedBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreEmbedBlockAttributes {
  __typename?: 'CoreEmbedBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResponsive: ScalarsEnums['Boolean'];
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewable: ScalarsEnums['Boolean'];
  providerNameSlug?: Maybe<ScalarsEnums['String']>;
  responsive: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreEmbedBlockAttributesUnion {
  __typename?:
    | 'CoreEmbedBlockAttributes'
    | 'CoreEmbedBlockDeprecatedV1Attributes'
    | 'CoreEmbedBlockDeprecatedV2Attributes';
  $on: $CoreEmbedBlockAttributesUnion;
}

export interface CoreEmbedBlockDeprecatedV1Attributes {
  __typename?: 'CoreEmbedBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResponsive: ScalarsEnums['Boolean'];
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewable: ScalarsEnums['Boolean'];
  providerNameSlug?: Maybe<ScalarsEnums['String']>;
  responsive: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreEmbedBlockDeprecatedV2Attributes {
  __typename?: 'CoreEmbedBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResponsive: ScalarsEnums['Boolean'];
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewable: ScalarsEnums['Boolean'];
  providerNameSlug?: Maybe<ScalarsEnums['String']>;
  responsive: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/file block
 */
export interface CoreFileBlock {
  __typename?: 'CoreFileBlock';
  attributes?: Maybe<CoreFileBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFileBlockAttributes {
  __typename?: 'CoreFileBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayPreview?: Maybe<ScalarsEnums['Boolean']>;
  fileId?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewHeight: ScalarsEnums['Float'];
  showDownloadButton: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  textLinkHref?: Maybe<ScalarsEnums['String']>;
  textLinkTarget?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFileBlockAttributesUnion {
  __typename?:
    | 'CoreFileBlockAttributes'
    | 'CoreFileBlockDeprecatedV1Attributes'
    | 'CoreFileBlockDeprecatedV2Attributes'
    | 'CoreFileBlockDeprecatedV3Attributes';
  $on: $CoreFileBlockAttributesUnion;
}

export interface CoreFileBlockDeprecatedV1Attributes {
  __typename?: 'CoreFileBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayPreview?: Maybe<ScalarsEnums['Boolean']>;
  fileId?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewHeight: ScalarsEnums['Float'];
  showDownloadButton: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  textLinkHref?: Maybe<ScalarsEnums['String']>;
  textLinkTarget?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFileBlockDeprecatedV2Attributes {
  __typename?: 'CoreFileBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayPreview?: Maybe<ScalarsEnums['Boolean']>;
  fileId?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewHeight: ScalarsEnums['Float'];
  showDownloadButton: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  textLinkHref?: Maybe<ScalarsEnums['String']>;
  textLinkTarget?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFileBlockDeprecatedV3Attributes {
  __typename?: 'CoreFileBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayPreview?: Maybe<ScalarsEnums['Boolean']>;
  fileId?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  previewHeight: ScalarsEnums['Float'];
  showDownloadButton: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  textLinkHref?: Maybe<ScalarsEnums['String']>;
  textLinkTarget?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/footnotes block
 */
export interface CoreFootnotesBlock {
  __typename?: 'CoreFootnotesBlock';
  attributes?: Maybe<CoreFootnotesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFootnotesBlockAttributes {
  __typename?: 'CoreFootnotesBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/freeform block
 */
export interface CoreFreeformBlock {
  __typename?: 'CoreFreeformBlock';
  attributes?: Maybe<CoreFreeformBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreFreeformBlockAttributes {
  __typename?: 'CoreFreeformBlockAttributes';
  content?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/gallery block
 */
export interface CoreGalleryBlock {
  __typename?: 'CoreGalleryBlock';
  attributes?: Maybe<CoreGalleryBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockAttributes {
  __typename?: 'CoreGalleryBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockAttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockAttributesImages {
  __typename?: 'CoreGalleryBlockAttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockAttributesUnion {
  __typename?:
    | 'CoreGalleryBlockAttributes'
    | 'CoreGalleryBlockDeprecatedV1Attributes'
    | 'CoreGalleryBlockDeprecatedV2Attributes'
    | 'CoreGalleryBlockDeprecatedV3Attributes'
    | 'CoreGalleryBlockDeprecatedV4Attributes'
    | 'CoreGalleryBlockDeprecatedV5Attributes'
    | 'CoreGalleryBlockDeprecatedV6Attributes'
    | 'CoreGalleryBlockDeprecatedV7Attributes';
  $on: $CoreGalleryBlockAttributesUnion;
}

export interface CoreGalleryBlockDeprecatedV1Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV1AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV1AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV1AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV2Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV2AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV2AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV2AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV3Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV3AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV3AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV3AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV4Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV4AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV4AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV4AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV5Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV5AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV5AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV5AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV6Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV6AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV6AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV6AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV7Attributes {
  __typename?: 'CoreGalleryBlockDeprecatedV7Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowResize: ScalarsEnums['Boolean'];
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  columns?: Maybe<ScalarsEnums['Float']>;
  fixedHeight: ScalarsEnums['Boolean'];
  gradient?: Maybe<ScalarsEnums['String']>;
  ids: Array<Maybe<ScalarsEnums['Float']>>;
  imageCrop: ScalarsEnums['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV7AttributesImages>>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  linkTo?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  randomOrder: ScalarsEnums['Boolean'];
  shortCodeTransforms: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
  sizeSlug: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGalleryBlockDeprecatedV7AttributesImages {
  __typename?: 'CoreGalleryBlockDeprecatedV7AttributesImages';
  alt: ScalarsEnums['String'];
  fullUrl?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/group block
 */
export interface CoreGroupBlock {
  __typename?: 'CoreGroupBlock';
  attributes?: Maybe<CoreGroupBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockAttributes {
  __typename?: 'CoreGroupBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockAttributesUnion {
  __typename?:
    | 'CoreGroupBlockAttributes'
    | 'CoreGroupBlockDeprecatedV1Attributes'
    | 'CoreGroupBlockDeprecatedV2Attributes'
    | 'CoreGroupBlockDeprecatedV3Attributes'
    | 'CoreGroupBlockDeprecatedV4Attributes'
    | 'CoreGroupBlockDeprecatedV5Attributes';
  $on: $CoreGroupBlockAttributesUnion;
}

export interface CoreGroupBlockDeprecatedV1Attributes {
  __typename?: 'CoreGroupBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockDeprecatedV2Attributes {
  __typename?: 'CoreGroupBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockDeprecatedV3Attributes {
  __typename?: 'CoreGroupBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockDeprecatedV4Attributes {
  __typename?: 'CoreGroupBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreGroupBlockDeprecatedV5Attributes {
  __typename?: 'CoreGroupBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  settings?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tagName: ScalarsEnums['String'];
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/heading block
 */
export interface CoreHeadingBlock {
  __typename?: 'CoreHeadingBlock';
  attributes?: Maybe<CoreHeadingBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockAttributes {
  __typename?: 'CoreHeadingBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockAttributesUnion {
  __typename?:
    | 'CoreHeadingBlockAttributes'
    | 'CoreHeadingBlockDeprecatedV1Attributes'
    | 'CoreHeadingBlockDeprecatedV2Attributes'
    | 'CoreHeadingBlockDeprecatedV3Attributes'
    | 'CoreHeadingBlockDeprecatedV4Attributes'
    | 'CoreHeadingBlockDeprecatedV5Attributes';
  $on: $CoreHeadingBlockAttributesUnion;
}

export interface CoreHeadingBlockDeprecatedV1Attributes {
  __typename?: 'CoreHeadingBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockDeprecatedV2Attributes {
  __typename?: 'CoreHeadingBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockDeprecatedV3Attributes {
  __typename?: 'CoreHeadingBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockDeprecatedV4Attributes {
  __typename?: 'CoreHeadingBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHeadingBlockDeprecatedV5Attributes {
  __typename?: 'CoreHeadingBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/home-link block
 */
export interface CoreHomeLinkBlock {
  __typename?: 'CoreHomeLinkBlock';
  attributes?: Maybe<CoreHomeLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHomeLinkBlockAttributes {
  __typename?: 'CoreHomeLinkBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/html block
 */
export interface CoreHtmlBlock {
  __typename?: 'CoreHtmlBlock';
  attributes?: Maybe<CoreHtmlBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreHtmlBlockAttributes {
  __typename?: 'CoreHtmlBlockAttributes';
  content?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/image block
 */
export interface CoreImageBlock {
  __typename?: 'CoreImageBlock';
  attributes?: Maybe<CoreImageBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Connection between the CoreImageBlock type and the MediaItem type
   */
  mediaItem?: Maybe<CoreImageBlockToMediaItemConnectionEdge>;
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockAttributes {
  __typename?: 'CoreImageBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockAttributesUnion {
  __typename?:
    | 'CoreImageBlockAttributes'
    | 'CoreImageBlockDeprecatedV1Attributes'
    | 'CoreImageBlockDeprecatedV2Attributes'
    | 'CoreImageBlockDeprecatedV3Attributes'
    | 'CoreImageBlockDeprecatedV4Attributes'
    | 'CoreImageBlockDeprecatedV5Attributes'
    | 'CoreImageBlockDeprecatedV6Attributes'
    | 'CoreImageBlockDeprecatedV7Attributes'
    | 'CoreImageBlockDeprecatedV8Attributes';
  $on: $CoreImageBlockAttributesUnion;
}

export interface CoreImageBlockDeprecatedV1Attributes {
  __typename?: 'CoreImageBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV2Attributes {
  __typename?: 'CoreImageBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV3Attributes {
  __typename?: 'CoreImageBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV4Attributes {
  __typename?: 'CoreImageBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV5Attributes {
  __typename?: 'CoreImageBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV6Attributes {
  __typename?: 'CoreImageBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV7Attributes {
  __typename?: 'CoreImageBlockDeprecatedV7Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreImageBlockDeprecatedV8Attributes {
  __typename?: 'CoreImageBlockDeprecatedV8Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  alt: ScalarsEnums['String'];
  anchor?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  lightbox?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  scale?: Maybe<ScalarsEnums['String']>;
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the CoreImageBlock type and the MediaItem type
 */
export interface CoreImageBlockToMediaItemConnectionEdge {
  __typename?: 'CoreImageBlockToMediaItemConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: MediaItem;
}

/**
 * core/latest-comments block
 */
export interface CoreLatestCommentsBlock {
  __typename?: 'CoreLatestCommentsBlock';
  attributes?: Maybe<CoreLatestCommentsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreLatestCommentsBlockAttributes {
  __typename?: 'CoreLatestCommentsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  commentsToShow: ScalarsEnums['Float'];
  displayAvatar: ScalarsEnums['Boolean'];
  displayDate: ScalarsEnums['Boolean'];
  displayExcerpt: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/latest-posts block
 */
export interface CoreLatestPostsBlock {
  __typename?: 'CoreLatestPostsBlock';
  attributes?: Maybe<CoreLatestPostsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreLatestPostsBlockAttributes {
  __typename?: 'CoreLatestPostsBlockAttributes';
  addLinkToFeaturedImage: ScalarsEnums['Boolean'];
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  categories?: Maybe<Array<Maybe<ScalarsEnums['BlockAttributesObject']>>>;
  className?: Maybe<ScalarsEnums['String']>;
  columns: ScalarsEnums['Float'];
  displayAuthor: ScalarsEnums['Boolean'];
  displayFeaturedImage: ScalarsEnums['Boolean'];
  displayPostContent: ScalarsEnums['Boolean'];
  displayPostContentRadio: ScalarsEnums['String'];
  displayPostDate: ScalarsEnums['Boolean'];
  excerptLength: ScalarsEnums['Float'];
  featuredImageAlign?: Maybe<ScalarsEnums['String']>;
  featuredImageSizeHeight?: Maybe<ScalarsEnums['Float']>;
  featuredImageSizeSlug: ScalarsEnums['String'];
  featuredImageSizeWidth?: Maybe<ScalarsEnums['Float']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  order: ScalarsEnums['String'];
  orderBy: ScalarsEnums['String'];
  postLayout: ScalarsEnums['String'];
  postsToShow: ScalarsEnums['Float'];
  selectedAuthor?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreLatestPostsBlockAttributesUnion {
  __typename?: 'CoreLatestPostsBlockAttributes' | 'CoreLatestPostsBlockDeprecatedV1Attributes';
  $on: $CoreLatestPostsBlockAttributesUnion;
}

export interface CoreLatestPostsBlockDeprecatedV1Attributes {
  __typename?: 'CoreLatestPostsBlockDeprecatedV1Attributes';
  addLinkToFeaturedImage: ScalarsEnums['Boolean'];
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  categories?: Maybe<Array<Maybe<ScalarsEnums['BlockAttributesObject']>>>;
  className?: Maybe<ScalarsEnums['String']>;
  columns: ScalarsEnums['Float'];
  displayAuthor: ScalarsEnums['Boolean'];
  displayFeaturedImage: ScalarsEnums['Boolean'];
  displayPostContent: ScalarsEnums['Boolean'];
  displayPostContentRadio: ScalarsEnums['String'];
  displayPostDate: ScalarsEnums['Boolean'];
  excerptLength: ScalarsEnums['Float'];
  featuredImageAlign?: Maybe<ScalarsEnums['String']>;
  featuredImageSizeHeight?: Maybe<ScalarsEnums['Float']>;
  featuredImageSizeSlug: ScalarsEnums['String'];
  featuredImageSizeWidth?: Maybe<ScalarsEnums['Float']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  order: ScalarsEnums['String'];
  orderBy: ScalarsEnums['String'];
  postLayout: ScalarsEnums['String'];
  postsToShow: ScalarsEnums['Float'];
  selectedAuthor?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/legacy-widget block
 */
export interface CoreLegacyWidgetBlock {
  __typename?: 'CoreLegacyWidgetBlock';
  attributes?: Maybe<CoreLegacyWidgetBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreLegacyWidgetBlockAttributes {
  __typename?: 'CoreLegacyWidgetBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['String']>;
  idBase?: Maybe<ScalarsEnums['String']>;
  instance?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/list block
 */
export interface CoreListBlock {
  __typename?: 'CoreListBlock';
  attributes?: Maybe<CoreListBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreListBlockAttributes {
  __typename?: 'CoreListBlockAttributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ordered: ScalarsEnums['Boolean'];
  placeholder?: Maybe<ScalarsEnums['String']>;
  reversed?: Maybe<ScalarsEnums['Boolean']>;
  start?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  values: ScalarsEnums['String'];
}

export interface CoreListBlockAttributesUnion {
  __typename?:
    | 'CoreListBlockAttributes'
    | 'CoreListBlockDeprecatedV1Attributes'
    | 'CoreListBlockDeprecatedV2Attributes'
    | 'CoreListBlockDeprecatedV3Attributes';
  $on: $CoreListBlockAttributesUnion;
}

export interface CoreListBlockDeprecatedV1Attributes {
  __typename?: 'CoreListBlockDeprecatedV1Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ordered: ScalarsEnums['Boolean'];
  placeholder?: Maybe<ScalarsEnums['String']>;
  reversed?: Maybe<ScalarsEnums['Boolean']>;
  start?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  values: ScalarsEnums['String'];
}

export interface CoreListBlockDeprecatedV2Attributes {
  __typename?: 'CoreListBlockDeprecatedV2Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ordered: ScalarsEnums['Boolean'];
  placeholder?: Maybe<ScalarsEnums['String']>;
  reversed?: Maybe<ScalarsEnums['Boolean']>;
  start?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  values: ScalarsEnums['String'];
}

export interface CoreListBlockDeprecatedV3Attributes {
  __typename?: 'CoreListBlockDeprecatedV3Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  ordered: ScalarsEnums['Boolean'];
  placeholder?: Maybe<ScalarsEnums['String']>;
  reversed?: Maybe<ScalarsEnums['Boolean']>;
  start?: Maybe<ScalarsEnums['Float']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  values: ScalarsEnums['String'];
}

/**
 * core/list-item block
 */
export interface CoreListItemBlock {
  __typename?: 'CoreListItemBlock';
  attributes?: Maybe<CoreListItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreListItemBlockAttributes {
  __typename?: 'CoreListItemBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/loginout block
 */
export interface CoreLoginoutBlock {
  __typename?: 'CoreLoginoutBlock';
  attributes?: Maybe<CoreLoginoutBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreLoginoutBlockAttributes {
  __typename?: 'CoreLoginoutBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  displayLoginAsForm: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  redirectToCurrent: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/media-text block
 */
export interface CoreMediaTextBlock {
  __typename?: 'CoreMediaTextBlock';
  attributes?: Maybe<CoreMediaTextBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockAttributes {
  __typename?: 'CoreMediaTextBlockAttributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockAttributesUnion {
  __typename?:
    | 'CoreMediaTextBlockAttributes'
    | 'CoreMediaTextBlockDeprecatedV1Attributes'
    | 'CoreMediaTextBlockDeprecatedV2Attributes'
    | 'CoreMediaTextBlockDeprecatedV3Attributes'
    | 'CoreMediaTextBlockDeprecatedV4Attributes'
    | 'CoreMediaTextBlockDeprecatedV5Attributes'
    | 'CoreMediaTextBlockDeprecatedV6Attributes';
  $on: $CoreMediaTextBlockAttributesUnion;
}

export interface CoreMediaTextBlockDeprecatedV1Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV1Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockDeprecatedV2Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV2Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockDeprecatedV3Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV3Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockDeprecatedV4Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV4Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockDeprecatedV5Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV5Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMediaTextBlockDeprecatedV6Attributes {
  __typename?: 'CoreMediaTextBlockDeprecatedV6Attributes';
  align: ScalarsEnums['String'];
  allowedBlocks?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  focalPoint?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  href?: Maybe<ScalarsEnums['String']>;
  imageFill?: Maybe<ScalarsEnums['Boolean']>;
  isStackedOnMobile: ScalarsEnums['Boolean'];
  linkClass?: Maybe<ScalarsEnums['String']>;
  linkDestination?: Maybe<ScalarsEnums['String']>;
  linkTarget?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  mediaAlt: ScalarsEnums['String'];
  mediaId?: Maybe<ScalarsEnums['Float']>;
  mediaLink?: Maybe<ScalarsEnums['String']>;
  mediaPosition: ScalarsEnums['String'];
  mediaSizeSlug?: Maybe<ScalarsEnums['String']>;
  mediaType?: Maybe<ScalarsEnums['String']>;
  mediaUrl?: Maybe<ScalarsEnums['String']>;
  mediaWidth: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  verticalAlignment?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/missing block
 */
export interface CoreMissingBlock {
  __typename?: 'CoreMissingBlock';
  attributes?: Maybe<CoreMissingBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMissingBlockAttributes {
  __typename?: 'CoreMissingBlockAttributes';
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  originalContent?: Maybe<ScalarsEnums['String']>;
  originalName?: Maybe<ScalarsEnums['String']>;
  originalUndelimitedContent?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/more block
 */
export interface CoreMoreBlock {
  __typename?: 'CoreMoreBlock';
  attributes?: Maybe<CoreMoreBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreMoreBlockAttributes {
  __typename?: 'CoreMoreBlockAttributes';
  customText?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  noTeaser: ScalarsEnums['Boolean'];
}

/**
 * core/navigation block
 */
export interface CoreNavigationBlock {
  __typename?: 'CoreNavigationBlock';
  attributes?: Maybe<CoreNavigationBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockAttributes {
  __typename?: 'CoreNavigationBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockAttributesUnion {
  __typename?:
    | 'CoreNavigationBlockAttributes'
    | 'CoreNavigationBlockDeprecatedV1Attributes'
    | 'CoreNavigationBlockDeprecatedV2Attributes'
    | 'CoreNavigationBlockDeprecatedV3Attributes'
    | 'CoreNavigationBlockDeprecatedV4Attributes'
    | 'CoreNavigationBlockDeprecatedV5Attributes'
    | 'CoreNavigationBlockDeprecatedV6Attributes';
  $on: $CoreNavigationBlockAttributesUnion;
}

export interface CoreNavigationBlockDeprecatedV1Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockDeprecatedV2Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockDeprecatedV3Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockDeprecatedV4Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockDeprecatedV5Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationBlockDeprecatedV6Attributes {
  __typename?: 'CoreNavigationBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  ariaLabel?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customOverlayTextColor?: Maybe<ScalarsEnums['String']>;
  customTextColor?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  hasIcon: ScalarsEnums['Boolean'];
  icon: ScalarsEnums['String'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  maxNestingLevel: ScalarsEnums['Float'];
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openSubmenusOnClick: ScalarsEnums['Boolean'];
  overlayBackgroundColor?: Maybe<ScalarsEnums['String']>;
  overlayMenu: ScalarsEnums['String'];
  overlayTextColor?: Maybe<ScalarsEnums['String']>;
  ref?: Maybe<ScalarsEnums['Float']>;
  rgbBackgroundColor?: Maybe<ScalarsEnums['String']>;
  rgbTextColor?: Maybe<ScalarsEnums['String']>;
  showSubmenuIcon: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  unstableLocation?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/navigation-link block
 */
export interface CoreNavigationLinkBlock {
  __typename?: 'CoreNavigationLinkBlock';
  attributes?: Maybe<CoreNavigationLinkBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationLinkBlockAttributes {
  __typename?: 'CoreNavigationLinkBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  isTopLevelLink?: Maybe<ScalarsEnums['Boolean']>;
  kind?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  opensInNewTab: ScalarsEnums['Boolean'];
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationLinkBlockAttributesUnion {
  __typename?:
    | 'CoreNavigationLinkBlockAttributes'
    | 'CoreNavigationLinkBlockDeprecatedV1Attributes';
  $on: $CoreNavigationLinkBlockAttributesUnion;
}

export interface CoreNavigationLinkBlockDeprecatedV1Attributes {
  __typename?: 'CoreNavigationLinkBlockDeprecatedV1Attributes';
  className?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  isTopLevelLink?: Maybe<ScalarsEnums['Boolean']>;
  kind?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  opensInNewTab: ScalarsEnums['Boolean'];
  rel?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/navigation-submenu block
 */
export interface CoreNavigationSubmenuBlock {
  __typename?: 'CoreNavigationSubmenuBlock';
  attributes?: Maybe<CoreNavigationSubmenuBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNavigationSubmenuBlockAttributes {
  __typename?: 'CoreNavigationSubmenuBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['String']>;
  id?: Maybe<ScalarsEnums['Float']>;
  isTopLevelItem?: Maybe<ScalarsEnums['Boolean']>;
  kind?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  opensInNewTab: ScalarsEnums['Boolean'];
  rel?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/nextpage block
 */
export interface CoreNextpageBlock {
  __typename?: 'CoreNextpageBlock';
  attributes?: Maybe<CoreNextpageBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreNextpageBlockAttributes {
  __typename?: 'CoreNextpageBlockAttributes';
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/page-list block
 */
export interface CorePageListBlock {
  __typename?: 'CorePageListBlock';
  attributes?: Maybe<CorePageListBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePageListBlockAttributes {
  __typename?: 'CorePageListBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  isNested: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  parentPageID: ScalarsEnums['Int'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/page-list-item block
 */
export interface CorePageListItemBlock {
  __typename?: 'CorePageListItemBlock';
  attributes?: Maybe<CorePageListItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePageListItemBlockAttributes {
  __typename?: 'CorePageListItemBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  hasChildren?: Maybe<ScalarsEnums['Boolean']>;
  id?: Maybe<ScalarsEnums['Float']>;
  label?: Maybe<ScalarsEnums['String']>;
  link?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/paragraph block
 */
export interface CoreParagraphBlock {
  __typename?: 'CoreParagraphBlock';
  attributes?: Maybe<CoreParagraphBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockAttributes {
  __typename?: 'CoreParagraphBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockAttributesUnion {
  __typename?:
    | 'CoreParagraphBlockAttributes'
    | 'CoreParagraphBlockDeprecatedV1Attributes'
    | 'CoreParagraphBlockDeprecatedV2Attributes'
    | 'CoreParagraphBlockDeprecatedV3Attributes'
    | 'CoreParagraphBlockDeprecatedV4Attributes'
    | 'CoreParagraphBlockDeprecatedV5Attributes'
    | 'CoreParagraphBlockDeprecatedV6Attributes';
  $on: $CoreParagraphBlockAttributesUnion;
}

export interface CoreParagraphBlockDeprecatedV1Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockDeprecatedV2Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockDeprecatedV3Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockDeprecatedV4Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockDeprecatedV5Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreParagraphBlockDeprecatedV6Attributes {
  __typename?: 'CoreParagraphBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  direction?: Maybe<ScalarsEnums['String']>;
  dropCap: ScalarsEnums['Boolean'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/pattern block
 */
export interface CorePatternBlock {
  __typename?: 'CorePatternBlock';
  attributes?: Maybe<CorePatternBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePatternBlockAttributes {
  __typename?: 'CorePatternBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  slug?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-author-biography block
 */
export interface CorePostAuthorBiographyBlock {
  __typename?: 'CorePostAuthorBiographyBlock';
  attributes?: Maybe<CorePostAuthorBiographyBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostAuthorBiographyBlockAttributes {
  __typename?: 'CorePostAuthorBiographyBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-author block
 */
export interface CorePostAuthorBlock {
  __typename?: 'CorePostAuthorBlock';
  attributes?: Maybe<CorePostAuthorBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostAuthorBlockAttributes {
  __typename?: 'CorePostAuthorBlockAttributes';
  avatarSize: ScalarsEnums['Float'];
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  byline?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showAvatar: ScalarsEnums['Boolean'];
  showBio?: Maybe<ScalarsEnums['Boolean']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-author-name block
 */
export interface CorePostAuthorNameBlock {
  __typename?: 'CorePostAuthorNameBlock';
  attributes?: Maybe<CorePostAuthorNameBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostAuthorNameBlockAttributes {
  __typename?: 'CorePostAuthorNameBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-comments-form block
 */
export interface CorePostCommentsFormBlock {
  __typename?: 'CorePostCommentsFormBlock';
  attributes?: Maybe<CorePostCommentsFormBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostCommentsFormBlockAttributes {
  __typename?: 'CorePostCommentsFormBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-content block
 */
export interface CorePostContentBlock {
  __typename?: 'CorePostContentBlock';
  attributes?: Maybe<CorePostContentBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostContentBlockAttributes {
  __typename?: 'CorePostContentBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-date block
 */
export interface CorePostDateBlock {
  __typename?: 'CorePostDateBlock';
  attributes?: Maybe<CorePostDateBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostDateBlockAttributes {
  __typename?: 'CorePostDateBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayType: ScalarsEnums['String'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  format?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostDateBlockAttributesUnion {
  __typename?: 'CorePostDateBlockAttributes' | 'CorePostDateBlockDeprecatedV1Attributes';
  $on: $CorePostDateBlockAttributesUnion;
}

export interface CorePostDateBlockDeprecatedV1Attributes {
  __typename?: 'CorePostDateBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  displayType: ScalarsEnums['String'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  format?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-excerpt block
 */
export interface CorePostExcerptBlock {
  __typename?: 'CorePostExcerptBlock';
  attributes?: Maybe<CorePostExcerptBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostExcerptBlockAttributes {
  __typename?: 'CorePostExcerptBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  excerptLength: ScalarsEnums['Float'];
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  moreText?: Maybe<ScalarsEnums['String']>;
  showMoreOnNewLine: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-featured-image block
 */
export interface CorePostFeaturedImageBlock {
  __typename?: 'CorePostFeaturedImageBlock';
  attributes?: Maybe<CorePostFeaturedImageBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostFeaturedImageBlockAttributes {
  __typename?: 'CorePostFeaturedImageBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  aspectRatio?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customGradient?: Maybe<ScalarsEnums['String']>;
  customOverlayColor?: Maybe<ScalarsEnums['String']>;
  dimRatio: ScalarsEnums['Float'];
  gradient?: Maybe<ScalarsEnums['String']>;
  height?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  overlayColor?: Maybe<ScalarsEnums['String']>;
  rel: ScalarsEnums['String'];
  scale: ScalarsEnums['String'];
  sizeSlug?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  useFirstImageFromPost: ScalarsEnums['Boolean'];
  width?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-navigation-link block
 */
export interface CorePostNavigationLinkBlock {
  __typename?: 'CorePostNavigationLinkBlock';
  attributes?: Maybe<CorePostNavigationLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostNavigationLinkBlockAttributes {
  __typename?: 'CorePostNavigationLinkBlockAttributes';
  arrow: ScalarsEnums['String'];
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  linkLabel: ScalarsEnums['Boolean'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showTitle: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  taxonomy: ScalarsEnums['String'];
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type: ScalarsEnums['String'];
}

/**
 * core/post-template block
 */
export interface CorePostTemplateBlock {
  __typename?: 'CorePostTemplateBlock';
  attributes?: Maybe<CorePostTemplateBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostTemplateBlockAttributes {
  __typename?: 'CorePostTemplateBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-terms block
 */
export interface CorePostTermsBlock {
  __typename?: 'CorePostTermsBlock';
  attributes?: Maybe<CorePostTermsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostTermsBlockAttributes {
  __typename?: 'CorePostTermsBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  prefix: ScalarsEnums['String'];
  separator: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  suffix: ScalarsEnums['String'];
  term?: Maybe<ScalarsEnums['String']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/post-title block
 */
export interface CorePostTitleBlock {
  __typename?: 'CorePostTitleBlock';
  attributes?: Maybe<CorePostTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostTitleBlockAttributes {
  __typename?: 'CorePostTitleBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  level: ScalarsEnums['Float'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePostTitleBlockAttributesUnion {
  __typename?: 'CorePostTitleBlockAttributes' | 'CorePostTitleBlockDeprecatedV1Attributes';
  $on: $CorePostTitleBlockAttributesUnion;
}

export interface CorePostTitleBlockDeprecatedV1Attributes {
  __typename?: 'CorePostTitleBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  level: ScalarsEnums['Float'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/preformatted block
 */
export interface CorePreformattedBlock {
  __typename?: 'CorePreformattedBlock';
  attributes?: Maybe<CorePreformattedBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePreformattedBlockAttributes {
  __typename?: 'CorePreformattedBlockAttributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/pullquote block
 */
export interface CorePullquoteBlock {
  __typename?: 'CorePullquoteBlock';
  attributes?: Maybe<CorePullquoteBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockAttributes {
  __typename?: 'CorePullquoteBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockAttributesUnion {
  __typename?:
    | 'CorePullquoteBlockAttributes'
    | 'CorePullquoteBlockDeprecatedV1Attributes'
    | 'CorePullquoteBlockDeprecatedV2Attributes'
    | 'CorePullquoteBlockDeprecatedV3Attributes'
    | 'CorePullquoteBlockDeprecatedV4Attributes'
    | 'CorePullquoteBlockDeprecatedV5Attributes'
    | 'CorePullquoteBlockDeprecatedV6Attributes';
  $on: $CorePullquoteBlockAttributesUnion;
}

export interface CorePullquoteBlockDeprecatedV1Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockDeprecatedV2Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockDeprecatedV3Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockDeprecatedV4Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockDeprecatedV5Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CorePullquoteBlockDeprecatedV6Attributes {
  __typename?: 'CorePullquoteBlockDeprecatedV6Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query block
 */
export interface CoreQueryBlock {
  __typename?: 'CoreQueryBlock';
  attributes?: Maybe<CoreQueryBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryBlockAttributes {
  __typename?: 'CoreQueryBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

export interface CoreQueryBlockAttributesUnion {
  __typename?:
    | 'CoreQueryBlockAttributes'
    | 'CoreQueryBlockDeprecatedV1Attributes'
    | 'CoreQueryBlockDeprecatedV2Attributes'
    | 'CoreQueryBlockDeprecatedV3Attributes'
    | 'CoreQueryBlockDeprecatedV4Attributes'
    | 'CoreQueryBlockDeprecatedV5Attributes';
  $on: $CoreQueryBlockAttributesUnion;
}

export interface CoreQueryBlockDeprecatedV1Attributes {
  __typename?: 'CoreQueryBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

export interface CoreQueryBlockDeprecatedV2Attributes {
  __typename?: 'CoreQueryBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

export interface CoreQueryBlockDeprecatedV3Attributes {
  __typename?: 'CoreQueryBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

export interface CoreQueryBlockDeprecatedV4Attributes {
  __typename?: 'CoreQueryBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

export interface CoreQueryBlockDeprecatedV5Attributes {
  __typename?: 'CoreQueryBlockDeprecatedV5Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  enhancedPagination: ScalarsEnums['Boolean'];
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  namespace?: Maybe<ScalarsEnums['String']>;
  query: ScalarsEnums['BlockAttributesObject'];
  queryId?: Maybe<ScalarsEnums['Float']>;
  tagName: ScalarsEnums['String'];
}

/**
 * core/query-no-results block
 */
export interface CoreQueryNoResultsBlock {
  __typename?: 'CoreQueryNoResultsBlock';
  attributes?: Maybe<CoreQueryNoResultsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryNoResultsBlockAttributes {
  __typename?: 'CoreQueryNoResultsBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query-pagination block
 */
export interface CoreQueryPaginationBlock {
  __typename?: 'CoreQueryPaginationBlock';
  attributes?: Maybe<CoreQueryPaginationBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryPaginationBlockAttributes {
  __typename?: 'CoreQueryPaginationBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  paginationArrow: ScalarsEnums['String'];
  showLabel: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryPaginationBlockAttributesUnion {
  __typename?:
    | 'CoreQueryPaginationBlockAttributes'
    | 'CoreQueryPaginationBlockDeprecatedV1Attributes';
  $on: $CoreQueryPaginationBlockAttributesUnion;
}

export interface CoreQueryPaginationBlockDeprecatedV1Attributes {
  __typename?: 'CoreQueryPaginationBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  paginationArrow: ScalarsEnums['String'];
  showLabel: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query-pagination-next block
 */
export interface CoreQueryPaginationNextBlock {
  __typename?: 'CoreQueryPaginationNextBlock';
  attributes?: Maybe<CoreQueryPaginationNextBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryPaginationNextBlockAttributes {
  __typename?: 'CoreQueryPaginationNextBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query-pagination-numbers block
 */
export interface CoreQueryPaginationNumbersBlock {
  __typename?: 'CoreQueryPaginationNumbersBlock';
  attributes?: Maybe<CoreQueryPaginationNumbersBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryPaginationNumbersBlockAttributes {
  __typename?: 'CoreQueryPaginationNumbersBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  midSize: ScalarsEnums['Float'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query-pagination-previous block
 */
export interface CoreQueryPaginationPreviousBlock {
  __typename?: 'CoreQueryPaginationPreviousBlock';
  attributes?: Maybe<CoreQueryPaginationPreviousBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryPaginationPreviousBlockAttributes {
  __typename?: 'CoreQueryPaginationPreviousBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/query-title block
 */
export interface CoreQueryTitleBlock {
  __typename?: 'CoreQueryTitleBlock';
  attributes?: Maybe<CoreQueryTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryTitleBlockAttributes {
  __typename?: 'CoreQueryTitleBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showPrefix: ScalarsEnums['Boolean'];
  showSearchTerm: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQueryTitleBlockAttributesUnion {
  __typename?: 'CoreQueryTitleBlockAttributes' | 'CoreQueryTitleBlockDeprecatedV1Attributes';
  $on: $CoreQueryTitleBlockAttributesUnion;
}

export interface CoreQueryTitleBlockDeprecatedV1Attributes {
  __typename?: 'CoreQueryTitleBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  level: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  showPrefix: ScalarsEnums['Boolean'];
  showSearchTerm: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/quote block
 */
export interface CoreQuoteBlock {
  __typename?: 'CoreQuoteBlock';
  attributes?: Maybe<CoreQuoteBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreQuoteBlockAttributes {
  __typename?: 'CoreQuoteBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  value: ScalarsEnums['String'];
}

export interface CoreQuoteBlockAttributesUnion {
  __typename?:
    | 'CoreQuoteBlockAttributes'
    | 'CoreQuoteBlockDeprecatedV1Attributes'
    | 'CoreQuoteBlockDeprecatedV2Attributes'
    | 'CoreQuoteBlockDeprecatedV3Attributes'
    | 'CoreQuoteBlockDeprecatedV4Attributes';
  $on: $CoreQuoteBlockAttributesUnion;
}

export interface CoreQuoteBlockDeprecatedV1Attributes {
  __typename?: 'CoreQuoteBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  value: ScalarsEnums['String'];
}

export interface CoreQuoteBlockDeprecatedV2Attributes {
  __typename?: 'CoreQuoteBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  value: ScalarsEnums['String'];
}

export interface CoreQuoteBlockDeprecatedV3Attributes {
  __typename?: 'CoreQuoteBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  value: ScalarsEnums['String'];
}

export interface CoreQuoteBlockDeprecatedV4Attributes {
  __typename?: 'CoreQuoteBlockDeprecatedV4Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  value: ScalarsEnums['String'];
}

/**
 * core/read-more block
 */
export interface CoreReadMoreBlock {
  __typename?: 'CoreReadMoreBlock';
  attributes?: Maybe<CoreReadMoreBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreReadMoreBlockAttributes {
  __typename?: 'CoreReadMoreBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  content?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/rss block
 */
export interface CoreRssBlock {
  __typename?: 'CoreRssBlock';
  attributes?: Maybe<CoreRssBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreRssBlockAttributes {
  __typename?: 'CoreRssBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  blockLayout: ScalarsEnums['String'];
  className?: Maybe<ScalarsEnums['String']>;
  columns: ScalarsEnums['Float'];
  displayAuthor: ScalarsEnums['Boolean'];
  displayDate: ScalarsEnums['Boolean'];
  displayExcerpt: ScalarsEnums['Boolean'];
  excerptLength: ScalarsEnums['Float'];
  feedURL: ScalarsEnums['String'];
  itemsToShow: ScalarsEnums['Float'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * core/search block
 */
export interface CoreSearchBlock {
  __typename?: 'CoreSearchBlock';
  attributes?: Maybe<CoreSearchBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSearchBlockAttributes {
  __typename?: 'CoreSearchBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  buttonPosition: ScalarsEnums['String'];
  buttonText?: Maybe<ScalarsEnums['String']>;
  buttonUseIcon: ScalarsEnums['Boolean'];
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isSearchFieldHidden: ScalarsEnums['Boolean'];
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  placeholder: ScalarsEnums['String'];
  query: ScalarsEnums['BlockAttributesObject'];
  showLabel: ScalarsEnums['Boolean'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
  width?: Maybe<ScalarsEnums['Float']>;
  widthUnit?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/separator block
 */
export interface CoreSeparatorBlock {
  __typename?: 'CoreSeparatorBlock';
  attributes?: Maybe<CoreSeparatorBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSeparatorBlockAttributes {
  __typename?: 'CoreSeparatorBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  opacity: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSeparatorBlockAttributesUnion {
  __typename?: 'CoreSeparatorBlockAttributes' | 'CoreSeparatorBlockDeprecatedV1Attributes';
  $on: $CoreSeparatorBlockAttributesUnion;
}

export interface CoreSeparatorBlockDeprecatedV1Attributes {
  __typename?: 'CoreSeparatorBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  opacity: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/shortcode block
 */
export interface CoreShortcodeBlock {
  __typename?: 'CoreShortcodeBlock';
  attributes?: Maybe<CoreShortcodeBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreShortcodeBlockAttributes {
  __typename?: 'CoreShortcodeBlockAttributes';
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  text?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/site-logo block
 */
export interface CoreSiteLogoBlock {
  __typename?: 'CoreSiteLogoBlock';
  attributes?: Maybe<CoreSiteLogoBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSiteLogoBlockAttributes {
  __typename?: 'CoreSiteLogoBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  shouldSyncIcon?: Maybe<ScalarsEnums['Boolean']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  width?: Maybe<ScalarsEnums['Float']>;
}

/**
 * core/site-tagline block
 */
export interface CoreSiteTaglineBlock {
  __typename?: 'CoreSiteTaglineBlock';
  attributes?: Maybe<CoreSiteTaglineBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSiteTaglineBlockAttributes {
  __typename?: 'CoreSiteTaglineBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSiteTaglineBlockAttributesUnion {
  __typename?: 'CoreSiteTaglineBlockAttributes' | 'CoreSiteTaglineBlockDeprecatedV1Attributes';
  $on: $CoreSiteTaglineBlockAttributesUnion;
}

export interface CoreSiteTaglineBlockDeprecatedV1Attributes {
  __typename?: 'CoreSiteTaglineBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/site-title block
 */
export interface CoreSiteTitleBlock {
  __typename?: 'CoreSiteTitleBlock';
  attributes?: Maybe<CoreSiteTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSiteTitleBlockAttributes {
  __typename?: 'CoreSiteTitleBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  level: ScalarsEnums['Float'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSiteTitleBlockAttributesUnion {
  __typename?: 'CoreSiteTitleBlockAttributes' | 'CoreSiteTitleBlockDeprecatedV1Attributes';
  $on: $CoreSiteTitleBlockAttributesUnion;
}

export interface CoreSiteTitleBlockDeprecatedV1Attributes {
  __typename?: 'CoreSiteTitleBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  isLink: ScalarsEnums['Boolean'];
  level: ScalarsEnums['Float'];
  linkTarget: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/social-link block
 */
export interface CoreSocialLinkBlock {
  __typename?: 'CoreSocialLinkBlock';
  attributes?: Maybe<CoreSocialLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSocialLinkBlockAttributes {
  __typename?: 'CoreSocialLinkBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  label?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  rel?: Maybe<ScalarsEnums['String']>;
  service?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/social-links block
 */
export interface CoreSocialLinksBlock {
  __typename?: 'CoreSocialLinksBlock';
  attributes?: Maybe<CoreSocialLinksBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSocialLinksBlockAttributes {
  __typename?: 'CoreSocialLinksBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customIconBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customIconColor?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  iconBackgroundColor?: Maybe<ScalarsEnums['String']>;
  iconBackgroundColorValue?: Maybe<ScalarsEnums['String']>;
  iconColor?: Maybe<ScalarsEnums['String']>;
  iconColorValue?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openInNewTab: ScalarsEnums['Boolean'];
  showLabels: ScalarsEnums['Boolean'];
  size?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSocialLinksBlockAttributesUnion {
  __typename?: 'CoreSocialLinksBlockAttributes' | 'CoreSocialLinksBlockDeprecatedV1Attributes';
  $on: $CoreSocialLinksBlockAttributesUnion;
}

export interface CoreSocialLinksBlockDeprecatedV1Attributes {
  __typename?: 'CoreSocialLinksBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  customIconBackgroundColor?: Maybe<ScalarsEnums['String']>;
  customIconColor?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  iconBackgroundColor?: Maybe<ScalarsEnums['String']>;
  iconBackgroundColorValue?: Maybe<ScalarsEnums['String']>;
  iconColor?: Maybe<ScalarsEnums['String']>;
  iconColorValue?: Maybe<ScalarsEnums['String']>;
  layout?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  openInNewTab: ScalarsEnums['Boolean'];
  showLabels: ScalarsEnums['Boolean'];
  size?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/spacer block
 */
export interface CoreSpacerBlock {
  __typename?: 'CoreSpacerBlock';
  attributes?: Maybe<CoreSpacerBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSpacerBlockAttributes {
  __typename?: 'CoreSpacerBlockAttributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreSpacerBlockAttributesUnion {
  __typename?: 'CoreSpacerBlockAttributes' | 'CoreSpacerBlockDeprecatedV1Attributes';
  $on: $CoreSpacerBlockAttributesUnion;
}

export interface CoreSpacerBlockDeprecatedV1Attributes {
  __typename?: 'CoreSpacerBlockDeprecatedV1Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  height: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  width?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/table block
 */
export interface CoreTableBlock {
  __typename?: 'CoreTableBlock';
  attributes?: Maybe<CoreTableBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTableBlockAttributes {
  __typename?: 'CoreTableBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  body: Array<Maybe<CoreTableBlockAttributesBody>>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  foot: Array<Maybe<CoreTableBlockAttributesFoot>>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasFixedLayout: ScalarsEnums['Boolean'];
  head: Array<Maybe<CoreTableBlockAttributesHead>>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTableBlockAttributesBody {
  __typename?: 'CoreTableBlockAttributesBody';
  cells: Array<Maybe<CoreTableBlockAttributesBodyCells>>;
}

export interface CoreTableBlockAttributesBodyCells {
  __typename?: 'CoreTableBlockAttributesBodyCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockAttributesFoot {
  __typename?: 'CoreTableBlockAttributesFoot';
  cells: Array<Maybe<CoreTableBlockAttributesFootCells>>;
}

export interface CoreTableBlockAttributesFootCells {
  __typename?: 'CoreTableBlockAttributesFootCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockAttributesHead {
  __typename?: 'CoreTableBlockAttributesHead';
  cells: Array<Maybe<CoreTableBlockAttributesHeadCells>>;
}

export interface CoreTableBlockAttributesHeadCells {
  __typename?: 'CoreTableBlockAttributesHeadCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockAttributesUnion {
  __typename?:
    | 'CoreTableBlockAttributes'
    | 'CoreTableBlockDeprecatedV1Attributes'
    | 'CoreTableBlockDeprecatedV2Attributes'
    | 'CoreTableBlockDeprecatedV3Attributes';
  $on: $CoreTableBlockAttributesUnion;
}

export interface CoreTableBlockDeprecatedV1Attributes {
  __typename?: 'CoreTableBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  body: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasFixedLayout: ScalarsEnums['Boolean'];
  head: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHead>>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTableBlockDeprecatedV1AttributesBody {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesBody';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesBodyCells {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesBodyCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV1AttributesFoot {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesFoot';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesFootCells {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesFootCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV1AttributesHead {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesHead';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesHeadCells {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesHeadCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV2Attributes {
  __typename?: 'CoreTableBlockDeprecatedV2Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  body: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasFixedLayout: ScalarsEnums['Boolean'];
  head: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHead>>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTableBlockDeprecatedV2AttributesBody {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesBody';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesBodyCells {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesBodyCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV2AttributesFoot {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesFoot';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesFootCells {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesFootCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV2AttributesHead {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesHead';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesHeadCells {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesHeadCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV3Attributes {
  __typename?: 'CoreTableBlockDeprecatedV3Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  body: Array<Maybe<CoreTableBlockDeprecatedV3AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV3AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums['String']>;
  hasFixedLayout: ScalarsEnums['Boolean'];
  head: Array<Maybe<CoreTableBlockDeprecatedV3AttributesHead>>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTableBlockDeprecatedV3AttributesBody {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesBody';
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesBodyCells {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesBodyCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV3AttributesFoot {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesFoot';
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesFootCells {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesFootCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

export interface CoreTableBlockDeprecatedV3AttributesHead {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesHead';
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesHeadCells {
  __typename?: 'CoreTableBlockDeprecatedV3AttributesHeadCells';
  align?: Maybe<ScalarsEnums['String']>;
  colspan?: Maybe<ScalarsEnums['String']>;
  rowspan?: Maybe<ScalarsEnums['String']>;
  scope?: Maybe<ScalarsEnums['String']>;
  tag: ScalarsEnums['String'];
}

/**
 * core/tag-cloud block
 */
export interface CoreTagCloudBlock {
  __typename?: 'CoreTagCloudBlock';
  attributes?: Maybe<CoreTagCloudBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTagCloudBlockAttributes {
  __typename?: 'CoreTagCloudBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  largestFontSize: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  numberOfTags: ScalarsEnums['Float'];
  showTagCounts: ScalarsEnums['Boolean'];
  smallestFontSize: ScalarsEnums['String'];
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  taxonomy: ScalarsEnums['String'];
}

/**
 * core/template-part block
 */
export interface CoreTemplatePartBlock {
  __typename?: 'CoreTemplatePartBlock';
  attributes?: Maybe<CoreTemplatePartBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTemplatePartBlockAttributes {
  __typename?: 'CoreTemplatePartBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  area?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  slug?: Maybe<ScalarsEnums['String']>;
  tagName?: Maybe<ScalarsEnums['String']>;
  theme?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/term-description block
 */
export interface CoreTermDescriptionBlock {
  __typename?: 'CoreTermDescriptionBlock';
  attributes?: Maybe<CoreTermDescriptionBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTermDescriptionBlockAttributes {
  __typename?: 'CoreTermDescriptionBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/text-columns block
 */
export interface CoreTextColumnsBlock {
  __typename?: 'CoreTextColumnsBlock';
  attributes?: Maybe<CoreTextColumnsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTextColumnsBlockAttributes {
  __typename?: 'CoreTextColumnsBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  columns: ScalarsEnums['Float'];
  content: Array<Maybe<CoreTextColumnsBlockAttributesContent>>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  width?: Maybe<ScalarsEnums['String']>;
}

export interface CoreTextColumnsBlockAttributesContent {
  __typename?: 'CoreTextColumnsBlockAttributesContent';
  children?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/verse block
 */
export interface CoreVerseBlock {
  __typename?: 'CoreVerseBlock';
  attributes?: Maybe<CoreVerseBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreVerseBlockAttributes {
  __typename?: 'CoreVerseBlockAttributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreVerseBlockAttributesUnion {
  __typename?:
    | 'CoreVerseBlockAttributes'
    | 'CoreVerseBlockDeprecatedV1Attributes'
    | 'CoreVerseBlockDeprecatedV2Attributes';
  $on: $CoreVerseBlockAttributesUnion;
}

export interface CoreVerseBlockDeprecatedV1Attributes {
  __typename?: 'CoreVerseBlockDeprecatedV1Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

export interface CoreVerseBlockDeprecatedV2Attributes {
  __typename?: 'CoreVerseBlockDeprecatedV2Attributes';
  anchor?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontFamily?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  gradient?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textAlign?: Maybe<ScalarsEnums['String']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * core/video block
 */
export interface CoreVideoBlock {
  __typename?: 'CoreVideoBlock';
  attributes?: Maybe<CoreVideoBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreVideoBlockAttributes {
  __typename?: 'CoreVideoBlockAttributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  autoplay?: Maybe<ScalarsEnums['Boolean']>;
  className?: Maybe<ScalarsEnums['String']>;
  controls: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  loop?: Maybe<ScalarsEnums['Boolean']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  muted?: Maybe<ScalarsEnums['Boolean']>;
  playsInline?: Maybe<ScalarsEnums['Boolean']>;
  poster?: Maybe<ScalarsEnums['String']>;
  preload: ScalarsEnums['String'];
  src?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tracks: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
}

export interface CoreVideoBlockAttributesUnion {
  __typename?: 'CoreVideoBlockAttributes' | 'CoreVideoBlockDeprecatedV1Attributes';
  $on: $CoreVideoBlockAttributesUnion;
}

export interface CoreVideoBlockDeprecatedV1Attributes {
  __typename?: 'CoreVideoBlockDeprecatedV1Attributes';
  align?: Maybe<ScalarsEnums['String']>;
  anchor?: Maybe<ScalarsEnums['String']>;
  autoplay?: Maybe<ScalarsEnums['Boolean']>;
  className?: Maybe<ScalarsEnums['String']>;
  controls: ScalarsEnums['Boolean'];
  id?: Maybe<ScalarsEnums['Float']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  loop?: Maybe<ScalarsEnums['Boolean']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  muted?: Maybe<ScalarsEnums['Boolean']>;
  playsInline?: Maybe<ScalarsEnums['Boolean']>;
  poster?: Maybe<ScalarsEnums['String']>;
  preload: ScalarsEnums['String'];
  src?: Maybe<ScalarsEnums['String']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  tracks: Array<Maybe<ScalarsEnums['BlockAttributesObject']>>;
}

/**
 * core/widget-group block
 */
export interface CoreWidgetGroupBlock {
  __typename?: 'CoreWidgetGroupBlock';
  attributes?: Maybe<CoreWidgetGroupBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface CoreWidgetGroupBlockAttributes {
  __typename?: 'CoreWidgetGroupBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
}

export interface CoreWidgetGroupBlockAttributesUnion {
  __typename?: 'CoreWidgetGroupBlockAttributes' | 'CoreWidgetGroupBlockDeprecatedV1Attributes';
  $on: $CoreWidgetGroupBlockAttributesUnion;
}

export interface CoreWidgetGroupBlockDeprecatedV1Attributes {
  __typename?: 'CoreWidgetGroupBlockDeprecatedV1Attributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the createBlockEditorPreview mutation.
 */
export interface CreateBlockEditorPreviewPayload {
  __typename?: 'CreateBlockEditorPreviewPayload';
  /**
   * The Post object mutation type.
   */
  blockEditorPreview?: Maybe<BlockEditorPreview>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the createCategory mutation.
 */
export interface CreateCategoryPayload {
  __typename?: 'CreateCategoryPayload';
  /**
   * The created category
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the createComment mutation.
 */
export interface CreateCommentPayload {
  __typename?: 'CreateCommentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The comment that was created
   */
  comment?: Maybe<Comment>;
  /**
   * Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache
   */
  success?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * The payload for the createCompanyCategory mutation.
 */
export interface CreateCompanyCategoryPayload {
  __typename?: 'CreateCompanyCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created company_category
   */
  companyCategory?: Maybe<CompanyCategory>;
}

/**
 * The payload for the createCompany mutation.
 */
export interface CreateCompanyPayload {
  __typename?: 'CreateCompanyPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  company?: Maybe<Company>;
}

/**
 * The payload for the createGraphqlDocument mutation.
 */
export interface CreateGraphqlDocumentPayload {
  __typename?: 'CreateGraphqlDocumentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  graphqlDocument?: Maybe<GraphqlDocument>;
}

/**
 * The payload for the createMediaItem mutation.
 */
export interface CreateMediaItemPayload {
  __typename?: 'CreateMediaItemPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The MediaItem object mutation type.
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the createPage mutation.
 */
export interface CreatePagePayload {
  __typename?: 'CreatePagePayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the createPostFormat mutation.
 */
export interface CreatePostFormatPayload {
  __typename?: 'CreatePostFormatPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created post_format
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the createPost mutation.
 */
export interface CreatePostPayload {
  __typename?: 'CreatePostPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the createPressCategory mutation.
 */
export interface CreatePressCategoryPayload {
  __typename?: 'CreatePressCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created press_categories
   */
  pressCategory?: Maybe<PressCategory>;
}

/**
 * The payload for the createPress mutation.
 */
export interface CreatePressPayload {
  __typename?: 'CreatePressPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  press?: Maybe<Press>;
}

/**
 * The payload for the createReusableBlock mutation.
 */
export interface CreateReusableBlockPayload {
  __typename?: 'CreateReusableBlockPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the createTag mutation.
 */
export interface CreateTagPayload {
  __typename?: 'CreateTagPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created post_tag
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the createTeamMember mutation.
 */
export interface CreateTeamMemberPayload {
  __typename?: 'CreateTeamMemberPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  teamMember?: Maybe<TeamMember>;
}

/**
 * The payload for the createUser mutation.
 */
export interface CreateUserPayload {
  __typename?: 'CreateUserPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * Object that can be identified with a Database ID
 */
export interface DatabaseIdentifier {
  __typename?:
    | 'BlockEditorPreview'
    | 'Category'
    | 'Comment'
    | 'CommentAuthor'
    | 'Company'
    | 'CompanyCategory'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Menu'
    | 'MenuItem'
    | 'Page'
    | 'Post'
    | 'PostFormat'
    | 'Press'
    | 'PressCategory'
    | 'ReusableBlock'
    | 'Tag'
    | 'TeamMember'
    | 'User';
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  $on: $DatabaseIdentifier;
}

/**
 * The template assigned to the node
 */
export interface DefaultTemplate {
  __typename?: 'DefaultTemplate';
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the deleteBlockEditorPreview mutation.
 */
export interface DeleteBlockEditorPreviewPayload {
  __typename?: 'DeleteBlockEditorPreviewPayload';
  /**
   * The object before it was deleted
   */
  blockEditorPreview?: Maybe<BlockEditorPreview>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The payload for the deleteCategory mutation.
 */
export interface DeleteCategoryPayload {
  __typename?: 'DeleteCategoryPayload';
  /**
   * The deleted term object
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The payload for the deleteComment mutation.
 */
export interface DeleteCommentPayload {
  __typename?: 'DeleteCommentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The deleted comment object
   */
  comment?: Maybe<Comment>;
  /**
   * The deleted comment ID
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The payload for the deleteCompanyCategory mutation.
 */
export interface DeleteCompanyCategoryPayload {
  __typename?: 'DeleteCompanyCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The deleted term object
   */
  companyCategory?: Maybe<CompanyCategory>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The payload for the deleteCompany mutation.
 */
export interface DeleteCompanyPayload {
  __typename?: 'DeleteCompanyPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The object before it was deleted
   */
  company?: Maybe<Company>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The payload for the deleteGraphqlDocument mutation.
 */
export interface DeleteGraphqlDocumentPayload {
  __typename?: 'DeleteGraphqlDocumentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  graphqlDocument?: Maybe<GraphqlDocument>;
}

/**
 * The payload for the deleteMediaItem mutation.
 */
export interface DeleteMediaItemPayload {
  __typename?: 'DeleteMediaItemPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted mediaItem
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The mediaItem before it was deleted
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the deletePage mutation.
 */
export interface DeletePagePayload {
  __typename?: 'DeletePagePayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the deletePostFormat mutation.
 */
export interface DeletePostFormatPayload {
  __typename?: 'DeletePostFormatPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The deleted term object
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the deletePost mutation.
 */
export interface DeletePostPayload {
  __typename?: 'DeletePostPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the deletePressCategory mutation.
 */
export interface DeletePressCategoryPayload {
  __typename?: 'DeletePressCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The deleted term object
   */
  pressCategory?: Maybe<PressCategory>;
}

/**
 * The payload for the deletePress mutation.
 */
export interface DeletePressPayload {
  __typename?: 'DeletePressPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  press?: Maybe<Press>;
}

/**
 * The payload for the deleteReusableBlock mutation.
 */
export interface DeleteReusableBlockPayload {
  __typename?: 'DeleteReusableBlockPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the deleteTag mutation.
 */
export interface DeleteTagPayload {
  __typename?: 'DeleteTagPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The deleted term object
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the deleteTeamMember mutation.
 */
export interface DeleteTeamMemberPayload {
  __typename?: 'DeleteTeamMemberPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The object before it was deleted
   */
  teamMember?: Maybe<TeamMember>;
}

/**
 * The payload for the deleteUser mutation.
 */
export interface DeleteUserPayload {
  __typename?: 'DeleteUserPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the user that you just deleted
   */
  deletedId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The deleted user object
   */
  user?: Maybe<User>;
}

/**
 * The discussion setting type
 */
export interface DiscussionSettings {
  __typename?: 'DiscussionSettings';
  /**
   * Allow people to submit comments on new posts.
   */
  defaultCommentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Allow link notifications from other blogs (pingbacks and trackbacks) on new articles.
   */
  defaultPingStatus?: Maybe<ScalarsEnums['String']>;
}

/**
 * Relational context between connected nodes
 */
export interface Edge {
  __typename?:
    | 'BlockEditorPreviewToPreviewConnectionEdge'
    | 'CategoryToAncestorsCategoryConnectionEdge'
    | 'CategoryToCategoryConnectionEdge'
    | 'CategoryToContentNodeConnectionEdge'
    | 'CategoryToParentCategoryConnectionEdge'
    | 'CategoryToPostConnectionEdge'
    | 'CategoryToTaxonomyConnectionEdge'
    | 'CommentToCommentConnectionEdge'
    | 'CommentToCommenterConnectionEdge'
    | 'CommentToContentNodeConnectionEdge'
    | 'CommentToParentCommentConnectionEdge'
    | 'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToCompanyConnectionEdge'
    | 'CompanyCategoryToContentNodeConnectionEdge'
    | 'CompanyCategoryToParentCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToTaxonomyConnectionEdge'
    | 'CompanyToCompanyCategoryConnectionEdge'
    | 'CompanyToPreviewConnectionEdge'
    | 'CompanyToTermNodeConnectionEdge'
    | 'ContentNodeToContentTypeConnectionEdge'
    | 'ContentNodeToEditLastConnectionEdge'
    | 'ContentNodeToEditLockConnectionEdge'
    | 'ContentNodeToEnqueuedScriptConnectionEdge'
    | 'ContentNodeToEnqueuedStylesheetConnectionEdge'
    | 'ContentTypeToContentNodeConnectionEdge'
    | 'ContentTypeToTaxonomyConnectionEdge'
    | 'CoreImageBlockToMediaItemConnectionEdge'
    | 'GraphqlDocumentToPreviewConnectionEdge'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge'
    | 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge'
    | 'HierarchicalContentNodeToParentContentNodeConnectionEdge'
    | 'MediaItemToCommentConnectionEdge'
    | 'MenuItemToMenuConnectionEdge'
    | 'MenuItemToMenuItemConnectionEdge'
    | 'MenuItemToMenuItemLinkableConnectionEdge'
    | 'MenuToMenuItemConnectionEdge'
    | 'NodeWithAuthorToUserConnectionEdge'
    | 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    | 'NodeWithRevisionsToContentNodeConnectionEdge'
    | 'PageToCommentConnectionEdge'
    | 'PageToPreviewConnectionEdge'
    | 'PageToRevisionConnectionEdge'
    | 'PostFormatToContentNodeConnectionEdge'
    | 'PostFormatToPostConnectionEdge'
    | 'PostFormatToTaxonomyConnectionEdge'
    | 'PostToCategoryConnectionEdge'
    | 'PostToCommentConnectionEdge'
    | 'PostToPostFormatConnectionEdge'
    | 'PostToPreviewConnectionEdge'
    | 'PostToRevisionConnectionEdge'
    | 'PostToTagConnectionEdge'
    | 'PostToTermNodeConnectionEdge'
    | 'PressCategoryToAncestorsPressCategoryConnectionEdge'
    | 'PressCategoryToContentNodeConnectionEdge'
    | 'PressCategoryToParentPressCategoryConnectionEdge'
    | 'PressCategoryToPressCategoryConnectionEdge'
    | 'PressCategoryToPressConnectionEdge'
    | 'PressCategoryToTaxonomyConnectionEdge'
    | 'PressCategoryToTeamMemberConnectionEdge'
    | 'PressToPressCategoryConnectionEdge'
    | 'PressToPreviewConnectionEdge'
    | 'PressToTermNodeConnectionEdge'
    | 'ReusableBlockToPreviewConnectionEdge'
    | 'ReusableBlockToRevisionConnectionEdge'
    | 'RootQueryToBlockEditorPreviewConnectionEdge'
    | 'RootQueryToCategoryConnectionEdge'
    | 'RootQueryToCommentConnectionEdge'
    | 'RootQueryToCompanyCategoryConnectionEdge'
    | 'RootQueryToCompanyConnectionEdge'
    | 'RootQueryToContentNodeConnectionEdge'
    | 'RootQueryToContentTypeConnectionEdge'
    | 'RootQueryToEnqueuedScriptConnectionEdge'
    | 'RootQueryToEnqueuedStylesheetConnectionEdge'
    | 'RootQueryToGraphqlDocumentConnectionEdge'
    | 'RootQueryToMediaItemConnectionEdge'
    | 'RootQueryToMenuConnectionEdge'
    | 'RootQueryToMenuItemConnectionEdge'
    | 'RootQueryToPageConnectionEdge'
    | 'RootQueryToPluginConnectionEdge'
    | 'RootQueryToPostConnectionEdge'
    | 'RootQueryToPostFormatConnectionEdge'
    | 'RootQueryToPressCategoryConnectionEdge'
    | 'RootQueryToPressConnectionEdge'
    | 'RootQueryToReusableBlockConnectionEdge'
    | 'RootQueryToRevisionsConnectionEdge'
    | 'RootQueryToTagConnectionEdge'
    | 'RootQueryToTaxonomyConnectionEdge'
    | 'RootQueryToTeamMemberConnectionEdge'
    | 'RootQueryToTermNodeConnectionEdge'
    | 'RootQueryToThemeConnectionEdge'
    | 'RootQueryToUserConnectionEdge'
    | 'RootQueryToUserRoleConnectionEdge'
    | 'TagToContentNodeConnectionEdge'
    | 'TagToPostConnectionEdge'
    | 'TagToTaxonomyConnectionEdge'
    | 'TaxonomyToContentTypeConnectionEdge'
    | 'TaxonomyToTermNodeConnectionEdge'
    | 'TeamMemberToPressCategoryConnectionEdge'
    | 'TeamMemberToPreviewConnectionEdge'
    | 'TeamMemberToTermNodeConnectionEdge'
    | 'TermNodeToEnqueuedScriptConnectionEdge'
    | 'TermNodeToEnqueuedStylesheetConnectionEdge'
    | 'UserToBlockEditorPreviewConnectionEdge'
    | 'UserToCommentConnectionEdge'
    | 'UserToEnqueuedScriptConnectionEdge'
    | 'UserToEnqueuedStylesheetConnectionEdge'
    | 'UserToMediaItemConnectionEdge'
    | 'UserToPageConnectionEdge'
    | 'UserToPostConnectionEdge'
    | 'UserToRevisionsConnectionEdge'
    | 'UserToUserRoleConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected node
   */
  node: Node;
  $on: $Edge;
}

/**
 * Asset enqueued by the CMS
 */
export interface EnqueuedAsset {
  __typename?: 'EnqueuedScript' | 'EnqueuedStylesheet';
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums['String']>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums['ID'];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums['String']>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums['String']>;
  $on: $EnqueuedAsset;
}

/**
 * Script enqueued by the CMS
 */
export interface EnqueuedScript {
  __typename?: 'EnqueuedScript';
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums['String']>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums['ID'];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums['String']>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to EnqueuedScript Nodes
 */
export interface EnqueuedScriptConnection {
  __typename?:
    | 'ContentNodeToEnqueuedScriptConnection'
    | 'RootQueryToEnqueuedScriptConnection'
    | 'TermNodeToEnqueuedScriptConnection'
    | 'UserToEnqueuedScriptConnection';
  /**
   * A list of edges (relational context) between ContentNode and connected EnqueuedScript Nodes
   */
  edges: Array<EnqueuedScriptConnectionEdge>;
  /**
   * A list of connected EnqueuedScript Nodes
   */
  nodes: Array<EnqueuedScript>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: EnqueuedScriptConnectionPageInfo;
  $on: $EnqueuedScriptConnection;
}

/**
 * Edge between a Node and a connected EnqueuedScript
 */
export interface EnqueuedScriptConnectionEdge {
  __typename?:
    | 'ContentNodeToEnqueuedScriptConnectionEdge'
    | 'RootQueryToEnqueuedScriptConnectionEdge'
    | 'TermNodeToEnqueuedScriptConnectionEdge'
    | 'UserToEnqueuedScriptConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected EnqueuedScript Node
   */
  node: EnqueuedScript;
  $on: $EnqueuedScriptConnectionEdge;
}

/**
 * Page Info on the connected EnqueuedScriptConnectionEdge
 */
export interface EnqueuedScriptConnectionPageInfo {
  __typename?:
    | 'ContentNodeToEnqueuedScriptConnectionPageInfo'
    | 'RootQueryToEnqueuedScriptConnectionPageInfo'
    | 'TermNodeToEnqueuedScriptConnectionPageInfo'
    | 'UserToEnqueuedScriptConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $EnqueuedScriptConnectionPageInfo;
}

/**
 * Stylesheet enqueued by the CMS
 */
export interface EnqueuedStylesheet {
  __typename?: 'EnqueuedStylesheet';
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums['String']>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums['String']>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums['ID'];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums['String']>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to EnqueuedStylesheet Nodes
 */
export interface EnqueuedStylesheetConnection {
  __typename?:
    | 'ContentNodeToEnqueuedStylesheetConnection'
    | 'RootQueryToEnqueuedStylesheetConnection'
    | 'TermNodeToEnqueuedStylesheetConnection'
    | 'UserToEnqueuedStylesheetConnection';
  /**
   * A list of edges (relational context) between ContentNode and connected EnqueuedStylesheet Nodes
   */
  edges: Array<EnqueuedStylesheetConnectionEdge>;
  /**
   * A list of connected EnqueuedStylesheet Nodes
   */
  nodes: Array<EnqueuedStylesheet>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: EnqueuedStylesheetConnectionPageInfo;
  $on: $EnqueuedStylesheetConnection;
}

/**
 * Edge between a Node and a connected EnqueuedStylesheet
 */
export interface EnqueuedStylesheetConnectionEdge {
  __typename?:
    | 'ContentNodeToEnqueuedStylesheetConnectionEdge'
    | 'RootQueryToEnqueuedStylesheetConnectionEdge'
    | 'TermNodeToEnqueuedStylesheetConnectionEdge'
    | 'UserToEnqueuedStylesheetConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected EnqueuedStylesheet Node
   */
  node: EnqueuedStylesheet;
  $on: $EnqueuedStylesheetConnectionEdge;
}

/**
 * Page Info on the connected EnqueuedStylesheetConnectionEdge
 */
export interface EnqueuedStylesheetConnectionPageInfo {
  __typename?:
    | 'ContentNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'RootQueryToEnqueuedStylesheetConnectionPageInfo'
    | 'TermNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'UserToEnqueuedStylesheetConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $EnqueuedStylesheetConnectionPageInfo;
}

/**
 * The general setting type
 */
export interface GeneralSettings {
  __typename?: 'GeneralSettings';
  /**
   * A date format for all date strings.
   */
  dateFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * Site tagline.
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * This address is used for admin purposes, like new user notification.
   */
  email?: Maybe<ScalarsEnums['String']>;
  /**
   * WordPress locale code.
   */
  language?: Maybe<ScalarsEnums['String']>;
  /**
   * A day number of the week that the week should start on.
   */
  startOfWeek?: Maybe<ScalarsEnums['Int']>;
  /**
   * A time format for all time strings.
   */
  timeFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * A city in the same timezone as you.
   */
  timezone?: Maybe<ScalarsEnums['String']>;
  /**
   * Site title.
   */
  title?: Maybe<ScalarsEnums['String']>;
  /**
   * Site URL.
   */
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the generateAuthorizationCode mutation.
 */
export interface GenerateAuthorizationCodePayload {
  __typename?: 'GenerateAuthorizationCodePayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * Authorization code used for requesting refresh/access tokens
   */
  code?: Maybe<ScalarsEnums['String']>;
  /**
   * Error encountered during user authentication, if any
   */
  error?: Maybe<ScalarsEnums['String']>;
}

/**
 * The graphqlDocument type
 */
export interface GraphqlDocument {
  __typename?: 'GraphqlDocument';
  /**
   * Alias names for saved GraphQL query documents
   */
  alias?: Maybe<Array<ScalarsEnums['String']>>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Description for the saved GraphQL document
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Allow, deny or default access grant for specific query
   */
  grant?: Maybe<ScalarsEnums['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  graphqlDocumentId: ScalarsEnums['Int'];
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the graphql_document object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * HTTP Access-Control-Max-Age Header for a saved GraphQL document
   */
  maxAgeHeader?: Maybe<ScalarsEnums['Int']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the GraphqlDocument type and the graphqlDocument type
   * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  preview?: Maybe<GraphqlDocumentToPreviewConnectionEdge>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to graphqlDocument Nodes
 */
export interface GraphqlDocumentConnection {
  __typename?: 'RootQueryToGraphqlDocumentConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected graphqlDocument Nodes
   */
  edges: Array<GraphqlDocumentConnectionEdge>;
  /**
   * A list of connected graphqlDocument Nodes
   */
  nodes: Array<GraphqlDocument>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: GraphqlDocumentConnectionPageInfo;
  $on: $GraphqlDocumentConnection;
}

/**
 * Edge between a Node and a connected graphqlDocument
 */
export interface GraphqlDocumentConnectionEdge {
  __typename?:
    | 'GraphqlDocumentToPreviewConnectionEdge'
    | 'RootQueryToGraphqlDocumentConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected graphqlDocument Node
   */
  node: GraphqlDocument;
  $on: $GraphqlDocumentConnectionEdge;
}

/**
 * Page Info on the connected GraphqlDocumentConnectionEdge
 */
export interface GraphqlDocumentConnectionPageInfo {
  __typename?: 'RootQueryToGraphqlDocumentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $GraphqlDocumentConnectionPageInfo;
}

/**
 * Connection between the GraphqlDocument type and the graphqlDocument type
 */
export interface GraphqlDocumentToPreviewConnectionEdge {
  __typename?: 'GraphqlDocumentToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  node: GraphqlDocument;
}

/**
 * Content node with hierarchical (parent/child) relationships
 */
export interface HierarchicalContentNode {
  __typename?: 'Company' | 'MediaItem' | 'Page' | 'TeamMember';
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $HierarchicalContentNode;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToContentNodeAncestorsConnection {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection';
  /**
   * Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection
   */
  edges: Array<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionEdge {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;HierarchicalContentNodeToContentNodeAncestorsConnection&quot;
 */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToContentNodeChildrenConnection {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
  /**
   * Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection
   */
  edges: Array<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionEdge {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;HierarchicalContentNodeToContentNodeChildrenConnection&quot;
 */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToParentContentNodeConnectionEdge {
  __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: ContentNode;
}

/**
 * Node with hierarchical (parent/child) relationships
 */
export interface HierarchicalNode {
  __typename?:
    | 'Category'
    | 'Company'
    | 'CompanyCategory'
    | 'MediaItem'
    | 'Page'
    | 'PressCategory'
    | 'TeamMember';
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  $on: $HierarchicalNode;
}

/**
 * Term node with hierarchical (parent/child) relationships
 */
export interface HierarchicalTermNode {
  __typename?: 'Category' | 'CompanyCategory' | 'PressCategory';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $HierarchicalTermNode;
}

/**
 * jam3/accordion block
 */
export interface Jam3AccordionBlock {
  __typename?: 'Jam3AccordionBlock';
  attributes?: Maybe<Jam3AccordionBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3AccordionBlockAttributes {
  __typename?: 'Jam3AccordionBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/accordion-item block
 */
export interface Jam3AccordionItemBlock {
  __typename?: 'Jam3AccordionItemBlock';
  attributes?: Maybe<Jam3AccordionItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3AccordionItemBlockAttributes {
  __typename?: 'Jam3AccordionItemBlockAttributes';
  body?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['String']>;
  indexItem: ScalarsEnums['String'];
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * jam3/acf-company-listing-img block
 */
export interface Jam3AcfCompanyListingImgBlock {
  __typename?: 'Jam3AcfCompanyListingImgBlock';
  attributes?: Maybe<Jam3AcfCompanyListingImgBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3AcfCompanyListingImgBlockAttributes {
  __typename?: 'Jam3AcfCompanyListingImgBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/acf-company-logo block
 */
export interface Jam3AcfCompanyLogoBlock {
  __typename?: 'Jam3AcfCompanyLogoBlock';
  attributes?: Maybe<Jam3AcfCompanyLogoBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3AcfCompanyLogoBlockAttributes {
  __typename?: 'Jam3AcfCompanyLogoBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/carousel block
 */
export interface Jam3CarouselBlock {
  __typename?: 'Jam3CarouselBlock';
  attributes?: Maybe<Jam3CarouselBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3CarouselBlockAttributes {
  __typename?: 'Jam3CarouselBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/carousel-item block
 */
export interface Jam3CarouselItemBlock {
  __typename?: 'Jam3CarouselItemBlock';
  attributes?: Maybe<Jam3CarouselItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3CarouselItemBlockAttributes {
  __typename?: 'Jam3CarouselItemBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/company-image-with-logo block
 */
export interface Jam3CompanyImageWithLogoBlock {
  __typename?: 'Jam3CompanyImageWithLogoBlock';
  attributes?: Maybe<Jam3CompanyImageWithLogoBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3CompanyImageWithLogoBlockAttributes {
  __typename?: 'Jam3CompanyImageWithLogoBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  logo?: Maybe<ScalarsEnums['String']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/company-info block
 */
export interface Jam3CompanyInfoBlock {
  __typename?: 'Jam3CompanyInfoBlock';
  attributes?: Maybe<Jam3CompanyInfoBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3CompanyInfoBlockAttributes {
  __typename?: 'Jam3CompanyInfoBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/company-info-item block
 */
export interface Jam3CompanyInfoItemBlock {
  __typename?: 'Jam3CompanyInfoItemBlock';
  attributes?: Maybe<Jam3CompanyInfoItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3CompanyInfoItemBlockAttributes {
  __typename?: 'Jam3CompanyInfoItemBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  text?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * jam3/newsletter block
 */
export interface Jam3NewsletterBlock {
  __typename?: 'Jam3NewsletterBlock';
  attributes?: Maybe<Jam3NewsletterBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3NewsletterBlockAttributes {
  __typename?: 'Jam3NewsletterBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/read-time block
 */
export interface Jam3ReadTimeBlock {
  __typename?: 'Jam3ReadTimeBlock';
  attributes?: Maybe<Jam3ReadTimeBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3ReadTimeBlockAttributes {
  __typename?: 'Jam3ReadTimeBlockAttributes';
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  fontSize?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  style?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  textColor?: Maybe<ScalarsEnums['String']>;
}

/**
 * jam3/rotate-text block
 */
export interface Jam3RotateTextBlock {
  __typename?: 'Jam3RotateTextBlock';
  attributes?: Maybe<Jam3RotateTextBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3RotateTextBlockAttributes {
  __typename?: 'Jam3RotateTextBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/social-share block
 */
export interface Jam3SocialShareBlock {
  __typename?: 'Jam3SocialShareBlock';
  attributes?: Maybe<Jam3SocialShareBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3SocialShareBlockAttributes {
  __typename?: 'Jam3SocialShareBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * jam3/team-listing block
 */
export interface Jam3TeamListingBlock {
  __typename?: 'Jam3TeamListingBlock';
  attributes?: Maybe<Jam3TeamListingBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface Jam3TeamListingBlockAttributes {
  __typename?: 'Jam3TeamListingBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

/**
 * File details for a Media Item
 */
export interface MediaDetails {
  __typename?: 'MediaDetails';
  /**
   * The filename of the mediaItem
   */
  file?: Maybe<ScalarsEnums['String']>;
  /**
   * The height of the mediaItem
   */
  height?: Maybe<ScalarsEnums['Int']>;
  /**
   * Meta information associated with the mediaItem
   */
  meta?: Maybe<MediaItemMeta>;
  /**
   * The available sizes of the mediaItem
   */
  sizes: (args?: {
    /**
     * The sizes to exclude. Will take precedence over `include`.
     */
    exclude?: Maybe<Array<Maybe<MediaItemSizeEnum>>>;
    /**
     * The sizes to include. Can be overridden by `exclude`.
     */
    include?: Maybe<Array<Maybe<MediaItemSizeEnum>>>;
  }) => Maybe<Array<Maybe<MediaSize>>>;
  /**
   * The width of the mediaItem
   */
  width?: Maybe<ScalarsEnums['Int']>;
}

/**
 * The mediaItem type
 */
export interface MediaItem {
  __typename?: 'MediaItem';
  /**
   * Alternative text to display when resource is not displayed
   */
  altText?: Maybe<ScalarsEnums['String']>;
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The caption for the resource
   */
  caption: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the MediaItem type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MediaItemToCommentConnectionWhereArgs>;
  }) => Maybe<MediaItemToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Description of the image (stored as post_content)
   */
  description: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The filesize in bytes of the resource
   */
  fileSize: (args?: {
    /**
     * Size of the MediaItem to return
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums['Int']>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the attachment object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * Details about the mediaItem
   */
  mediaDetails?: Maybe<MediaDetails>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  mediaItemId: ScalarsEnums['Int'];
  /**
   * Url of the mediaItem
   */
  mediaItemUrl?: Maybe<ScalarsEnums['String']>;
  /**
   * Type of resource
   */
  mediaType?: Maybe<ScalarsEnums['String']>;
  /**
   * The mime type of the mediaItem
   */
  mimeType?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The sizes attribute value for an image.
   */
  sizes: (args?: {
    /**
     * Size of the MediaItem to calculate sizes with
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * Url of the mediaItem
   */
  sourceUrl: (args?: {
    /**
     * Size of the MediaItem to return
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths.
   */
  srcSet: (args?: {
    /**
     * Size of the MediaItem to calculate srcSet with
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to mediaItem Nodes
 */
export interface MediaItemConnection {
  __typename?: 'RootQueryToMediaItemConnection' | 'UserToMediaItemConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected mediaItem Nodes
   */
  edges: Array<MediaItemConnectionEdge>;
  /**
   * A list of connected mediaItem Nodes
   */
  nodes: Array<MediaItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MediaItemConnectionPageInfo;
  $on: $MediaItemConnection;
}

/**
 * Edge between a Node and a connected mediaItem
 */
export interface MediaItemConnectionEdge {
  __typename?:
    | 'CoreImageBlockToMediaItemConnectionEdge'
    | 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    | 'RootQueryToMediaItemConnectionEdge'
    | 'UserToMediaItemConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected mediaItem Node
   */
  node: MediaItem;
  $on: $MediaItemConnectionEdge;
}

/**
 * Page Info on the connected MediaItemConnectionEdge
 */
export interface MediaItemConnectionPageInfo {
  __typename?: 'RootQueryToMediaItemConnectionPageInfo' | 'UserToMediaItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $MediaItemConnectionPageInfo;
}

/**
 * Meta connected to a MediaItem
 */
export interface MediaItemMeta {
  __typename?: 'MediaItemMeta';
  /**
   * Aperture measurement of the media item.
   */
  aperture?: Maybe<ScalarsEnums['Float']>;
  /**
   * Information about the camera used to create the media item.
   */
  camera?: Maybe<ScalarsEnums['String']>;
  /**
   * The text string description associated with the media item.
   */
  caption?: Maybe<ScalarsEnums['String']>;
  /**
   * Copyright information associated with the media item.
   */
  copyright?: Maybe<ScalarsEnums['String']>;
  /**
   * The date/time when the media was created.
   */
  createdTimestamp?: Maybe<ScalarsEnums['Int']>;
  /**
   * The original creator of the media item.
   */
  credit?: Maybe<ScalarsEnums['String']>;
  /**
   * The focal length value of the media item.
   */
  focalLength?: Maybe<ScalarsEnums['Float']>;
  /**
   * The ISO (International Organization for Standardization) value of the media item.
   */
  iso?: Maybe<ScalarsEnums['Int']>;
  /**
   * List of keywords used to describe or identfy the media item.
   */
  keywords?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The vertical or horizontal aspect of the media item.
   */
  orientation?: Maybe<ScalarsEnums['String']>;
  /**
   * The shutter speed information of the media item.
   */
  shutterSpeed?: Maybe<ScalarsEnums['Float']>;
  /**
   * A useful title for the media item.
   */
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the MediaItem type and the Comment type
 */
export interface MediaItemToCommentConnection {
  __typename?: 'MediaItemToCommentConnection';
  /**
   * Edges for the MediaItemToCommentConnection connection
   */
  edges: Array<MediaItemToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MediaItemToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface MediaItemToCommentConnectionEdge {
  __typename?: 'MediaItemToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;MediaItemToCommentConnection&quot;
 */
export interface MediaItemToCommentConnectionPageInfo {
  __typename?: 'MediaItemToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Details of an available size for a media item
 */
export interface MediaSize {
  __typename?: 'MediaSize';
  /**
   * The filename of the referenced size
   */
  file?: Maybe<ScalarsEnums['String']>;
  /**
   * The filesize of the resource
   */
  fileSize?: Maybe<ScalarsEnums['Int']>;
  /**
   * The height of the referenced size
   */
  height?: Maybe<ScalarsEnums['String']>;
  /**
   * The mime type of the referenced size
   */
  mimeType?: Maybe<ScalarsEnums['String']>;
  /**
   * The referenced size name
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The url of the referenced size
   */
  sourceUrl?: Maybe<ScalarsEnums['String']>;
  /**
   * The width of the referenced size
   */
  width?: Maybe<ScalarsEnums['String']>;
}

/**
 * Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme.
 */
export interface Menu {
  __typename?: 'Menu';
  /**
   * The number of items in the menu
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The globally unique identifier of the nav menu object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The locations a menu is assigned to
   */
  locations?: Maybe<Array<Maybe<ScalarsEnums['MenuLocationEnum']>>>;
  /**
   * WP ID of the nav menu.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the Menu type and the MenuItem type
   */
  menuItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MenuToMenuItemConnectionWhereArgs>;
  }) => Maybe<MenuToMenuItemConnection>;
  /**
   * Display name of the menu. Equivalent to WP_Term-&gt;name.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The url friendly name of the menu. Equivalent to WP_Term-&gt;slug
   */
  slug?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to Menu Nodes
 */
export interface MenuConnection {
  __typename?: 'RootQueryToMenuConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Menu Nodes
   */
  edges: Array<MenuConnectionEdge>;
  /**
   * A list of connected Menu Nodes
   */
  nodes: Array<Menu>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MenuConnectionPageInfo;
  $on: $MenuConnection;
}

/**
 * Edge between a Node and a connected Menu
 */
export interface MenuConnectionEdge {
  __typename?: 'MenuItemToMenuConnectionEdge' | 'RootQueryToMenuConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Menu Node
   */
  node: Menu;
  $on: $MenuConnectionEdge;
}

/**
 * Page Info on the connected MenuConnectionEdge
 */
export interface MenuConnectionPageInfo {
  __typename?: 'RootQueryToMenuConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $MenuConnectionPageInfo;
}

/**
 * Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu.
 */
export interface MenuItem {
  __typename?: 'MenuItem';
  /**
   * Connection between the MenuItem type and the MenuItem type
   */
  childItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MenuItemToMenuItemConnectionWhereArgs>;
  }) => Maybe<MenuItemToMenuItemConnection>;
  /**
   * Connection from MenuItem to it&#039;s connected node
   */
  connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
  /**
   * The object connected to this menu item.
   * @deprecated Deprecated in favor of the connectedNode field
   */
  connectedObject?: Maybe<MenuItemObjectUnion>;
  /**
   * Class attribute for the menu item link
   */
  cssClasses?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Description of the menu item.
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the nav menu item object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Label or title of the menu item.
   */
  label?: Maybe<ScalarsEnums['String']>;
  /**
   * Link relationship (XFN) of the menu item.
   */
  linkRelationship?: Maybe<ScalarsEnums['String']>;
  /**
   * The locations the menu item&#039;s Menu is assigned to
   */
  locations?: Maybe<Array<Maybe<ScalarsEnums['MenuLocationEnum']>>>;
  /**
   * The Menu a MenuItem is part of
   */
  menu?: Maybe<MenuItemToMenuConnectionEdge>;
  /**
   * WP ID of the menu item.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuItemId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Menu item order
   */
  order?: Maybe<ScalarsEnums['Int']>;
  /**
   * The database id of the parent menu item or null if it is the root
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent nav menu item object.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Path for the resource. Relative path for internal resources. Absolute path for external resources.
   */
  path?: Maybe<ScalarsEnums['String']>;
  /**
   * Target attribute for the menu item link.
   */
  target?: Maybe<ScalarsEnums['String']>;
  /**
   * Title attribute for the menu item link
   */
  title?: Maybe<ScalarsEnums['String']>;
  /**
   * The uri of the resource the menu item links to
   */
  uri?: Maybe<ScalarsEnums['String']>;
  /**
   * URL or destination of the menu item.
   */
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to MenuItem Nodes
 */
export interface MenuItemConnection {
  __typename?:
    | 'MenuItemToMenuItemConnection'
    | 'MenuToMenuItemConnection'
    | 'RootQueryToMenuItemConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected MenuItem Nodes
   */
  edges: Array<MenuItemConnectionEdge>;
  /**
   * A list of connected MenuItem Nodes
   */
  nodes: Array<MenuItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MenuItemConnectionPageInfo;
  $on: $MenuItemConnection;
}

/**
 * Edge between a Node and a connected MenuItem
 */
export interface MenuItemConnectionEdge {
  __typename?:
    | 'MenuItemToMenuItemConnectionEdge'
    | 'MenuToMenuItemConnectionEdge'
    | 'RootQueryToMenuItemConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected MenuItem Node
   */
  node: MenuItem;
  $on: $MenuItemConnectionEdge;
}

/**
 * Page Info on the connected MenuItemConnectionEdge
 */
export interface MenuItemConnectionPageInfo {
  __typename?:
    | 'MenuItemToMenuItemConnectionPageInfo'
    | 'MenuToMenuItemConnectionPageInfo'
    | 'RootQueryToMenuItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $MenuItemConnectionPageInfo;
}

/**
 * Nodes that can be linked to as Menu Items
 */
export interface MenuItemLinkable {
  __typename?:
    | 'Category'
    | 'Company'
    | 'CompanyCategory'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'PressCategory'
    | 'Tag'
    | 'TeamMember';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $MenuItemLinkable;
}

/**
 * Edge between a Node and a connected MenuItemLinkable
 */
export interface MenuItemLinkableConnectionEdge {
  __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected MenuItemLinkable Node
   */
  node: MenuItemLinkable;
  $on: $MenuItemLinkableConnectionEdge;
}

/**
 * Deprecated in favor of MenuItemLinkeable Interface
 */
export interface MenuItemObjectUnion {
  __typename?:
    | 'Category'
    | 'Company'
    | 'CompanyCategory'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'PressCategory'
    | 'Tag'
    | 'TeamMember';
  $on: $MenuItemObjectUnion;
}

/**
 * Connection between the MenuItem type and the Menu type
 */
export interface MenuItemToMenuConnectionEdge {
  __typename?: 'MenuItemToMenuConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Menu;
}

/**
 * Connection between the MenuItem type and the MenuItem type
 */
export interface MenuItemToMenuItemConnection {
  __typename?: 'MenuItemToMenuItemConnection';
  /**
   * Edges for the MenuItemToMenuItemConnection connection
   */
  edges: Array<MenuItemToMenuItemConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<MenuItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MenuItemToMenuItemConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface MenuItemToMenuItemConnectionEdge {
  __typename?: 'MenuItemToMenuItemConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: MenuItem;
}

/**
 * Page Info on the &quot;MenuItemToMenuItemConnection&quot;
 */
export interface MenuItemToMenuItemConnectionPageInfo {
  __typename?: 'MenuItemToMenuItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the MenuItem type and the MenuItemLinkable type
 */
export interface MenuItemToMenuItemLinkableConnectionEdge {
  __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: MenuItemLinkable;
}

/**
 * Connection between the Menu type and the MenuItem type
 */
export interface MenuToMenuItemConnection {
  __typename?: 'MenuToMenuItemConnection';
  /**
   * Edges for the MenuToMenuItemConnection connection
   */
  edges: Array<MenuToMenuItemConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<MenuItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: MenuToMenuItemConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface MenuToMenuItemConnectionEdge {
  __typename?: 'MenuToMenuItemConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: MenuItem;
}

/**
 * Page Info on the &quot;MenuToMenuItemConnection&quot;
 */
export interface MenuToMenuItemConnectionPageInfo {
  __typename?: 'MenuToMenuItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * monsterinsights/popular-posts-inline block
 */
export interface MonsterinsightsPopularPostsInlineBlock {
  __typename?: 'MonsterinsightsPopularPostsInlineBlock';
  attributes?: Maybe<MonsterinsightsPopularPostsInlineBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface MonsterinsightsPopularPostsInlineBlockAttributes {
  __typename?: 'MonsterinsightsPopularPostsInlineBlockAttributes';
  backgroundBorder?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  borderColor?: Maybe<ScalarsEnums['String']>;
  borderColor2?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  iconBackground?: Maybe<ScalarsEnums['String']>;
  iconColor?: Maybe<ScalarsEnums['String']>;
  labelBackground?: Maybe<ScalarsEnums['String']>;
  labelColor?: Maybe<ScalarsEnums['String']>;
  labelText?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  theme?: Maybe<ScalarsEnums['String']>;
  titleColor?: Maybe<ScalarsEnums['String']>;
  titleSize?: Maybe<ScalarsEnums['Float']>;
}

/**
 * monsterinsights/popular-posts-widget block
 */
export interface MonsterinsightsPopularPostsWidgetBlock {
  __typename?: 'MonsterinsightsPopularPostsWidgetBlock';
  attributes?: Maybe<MonsterinsightsPopularPostsWidgetBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface MonsterinsightsPopularPostsWidgetBlockAttributes {
  __typename?: 'MonsterinsightsPopularPostsWidgetBlockAttributes';
  backgroundBorder?: Maybe<ScalarsEnums['String']>;
  backgroundColor?: Maybe<ScalarsEnums['String']>;
  categories: ScalarsEnums['BlockAttributesArray'];
  className?: Maybe<ScalarsEnums['String']>;
  columns: ScalarsEnums['Float'];
  commentsColor?: Maybe<ScalarsEnums['String']>;
  labelBackground?: Maybe<ScalarsEnums['String']>;
  labelColor?: Maybe<ScalarsEnums['String']>;
  labelText?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metaAuthor?: Maybe<ScalarsEnums['Boolean']>;
  metaColor?: Maybe<ScalarsEnums['String']>;
  metaComments?: Maybe<ScalarsEnums['Boolean']>;
  metaDate?: Maybe<ScalarsEnums['Boolean']>;
  metaSize?: Maybe<ScalarsEnums['String']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  postCount: ScalarsEnums['Float'];
  theme?: Maybe<ScalarsEnums['String']>;
  titleColor?: Maybe<ScalarsEnums['String']>;
  titleSize?: Maybe<ScalarsEnums['Float']>;
  widgetTitle?: Maybe<ScalarsEnums['Boolean']>;
  widgetTitleText?: Maybe<ScalarsEnums['String']>;
}

/**
 * An object with an ID
 */
export interface Node {
  __typename?:
    | 'BlockEditorPreview'
    | 'Category'
    | 'Comment'
    | 'CommentAuthor'
    | 'Company'
    | 'CompanyCategory'
    | 'ContentType'
    | 'EnqueuedScript'
    | 'EnqueuedStylesheet'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Menu'
    | 'MenuItem'
    | 'Page'
    | 'Plugin'
    | 'Post'
    | 'PostFormat'
    | 'Press'
    | 'PressCategory'
    | 'ReusableBlock'
    | 'Tag'
    | 'Taxonomy'
    | 'TeamMember'
    | 'Theme'
    | 'User'
    | 'UserRole';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $Node;
}

/**
 * A node that can have an author assigned to it
 */
export interface NodeWithAuthor {
  __typename?: 'BlockEditorPreview' | 'MediaItem' | 'Page' | 'Post';
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $NodeWithAuthor;
}

/**
 * Connection between the NodeWithAuthor type and the User type
 */
export interface NodeWithAuthorToUserConnectionEdge {
  __typename?: 'NodeWithAuthorToUserConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: User;
}

/**
 * A node that can have comments associated with it
 */
export interface NodeWithComments {
  __typename?: 'MediaItem' | 'Page' | 'Post';
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $NodeWithComments;
}

/**
 * A node that supports the content editor
 */
export interface NodeWithContentEditor {
  __typename?:
    | 'BlockEditorPreview'
    | 'Company'
    | 'GraphqlDocument'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'ReusableBlock'
    | 'TeamMember';
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $NodeWithContentEditor;
}

/**
 * A node that can have an excerpt
 */
export interface NodeWithExcerpt {
  __typename?: 'Company' | 'Post';
  /**
   * The excerpt of the post.
   */
  excerpt: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $NodeWithExcerpt;
}

/**
 * A node that can have a featured image set
 */
export interface NodeWithFeaturedImage {
  __typename?: 'Company' | 'Page' | 'Post' | 'Press';
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  $on: $NodeWithFeaturedImage;
}

/**
 * Connection between the NodeWithFeaturedImage type and the MediaItem type
 */
export interface NodeWithFeaturedImageToMediaItemConnectionEdge {
  __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: MediaItem;
}

/**
 * A node that can have page attributes
 */
export interface NodeWithPageAttributes {
  __typename?: 'Page';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<ScalarsEnums['Int']>;
  $on: $NodeWithPageAttributes;
}

/**
 * A node that can have revisions
 */
export interface NodeWithRevisions {
  __typename?: 'Page' | 'Post' | 'ReusableBlock';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  $on: $NodeWithRevisions;
}

/**
 * Connection between the NodeWithRevisions type and the ContentNode type
 */
export interface NodeWithRevisionsToContentNodeConnectionEdge {
  __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: ContentNode;
}

/**
 * A node that can have a template associated with it
 */
export interface NodeWithTemplate {
  __typename?:
    | 'BlockEditorPreview'
    | 'Company'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'ReusableBlock'
    | 'TeamMember';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  $on: $NodeWithTemplate;
}

/**
 * A node that NodeWith a title
 */
export interface NodeWithTitle {
  __typename?:
    | 'BlockEditorPreview'
    | 'Company'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Page'
    | 'Post'
    | 'Press'
    | 'ReusableBlock'
    | 'TeamMember';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  $on: $NodeWithTitle;
}

/**
 * A node that can have trackbacks and pingbacks
 */
export interface NodeWithTrackbacks {
  __typename?: 'Post';
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the pings are open or closed for this particular post.
   */
  pingStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * URLs that have been pinged.
   */
  pinged?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * URLs queued to be pinged.
   */
  toPing?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  $on: $NodeWithTrackbacks;
}

/**
 * Get information about the offset pagination state
 */
export interface OffsetPaginationPageInfo {
  __typename?: 'OffsetPaginationPageInfo';
  /**
   * True if there is one or more nodes available in this connection. Eg. you can increase the offset at least by one.
   */
  hasMore?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * True when offset can be decresed eg. offset is 0&lt;
   */
  hasPrevious?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Total amount of nodes in this connection
   */
  total?: Maybe<ScalarsEnums['Int']>;
}

/**
 * A singular connection from one Node to another, with support for relational data on the &quot;edge&quot; of the connection.
 */
export interface OneToOneConnection {
  __typename?:
    | 'BlockEditorPreviewToPreviewConnectionEdge'
    | 'CategoryToParentCategoryConnectionEdge'
    | 'CategoryToTaxonomyConnectionEdge'
    | 'CommentToCommenterConnectionEdge'
    | 'CommentToContentNodeConnectionEdge'
    | 'CommentToParentCommentConnectionEdge'
    | 'CompanyCategoryToParentCompanyCategoryConnectionEdge'
    | 'CompanyCategoryToTaxonomyConnectionEdge'
    | 'CompanyToPreviewConnectionEdge'
    | 'ContentNodeToContentTypeConnectionEdge'
    | 'ContentNodeToEditLastConnectionEdge'
    | 'ContentNodeToEditLockConnectionEdge'
    | 'CoreImageBlockToMediaItemConnectionEdge'
    | 'GraphqlDocumentToPreviewConnectionEdge'
    | 'HierarchicalContentNodeToParentContentNodeConnectionEdge'
    | 'MenuItemToMenuConnectionEdge'
    | 'MenuItemToMenuItemLinkableConnectionEdge'
    | 'NodeWithAuthorToUserConnectionEdge'
    | 'NodeWithFeaturedImageToMediaItemConnectionEdge'
    | 'NodeWithRevisionsToContentNodeConnectionEdge'
    | 'PageToPreviewConnectionEdge'
    | 'PostFormatToTaxonomyConnectionEdge'
    | 'PostToPreviewConnectionEdge'
    | 'PressCategoryToParentPressCategoryConnectionEdge'
    | 'PressCategoryToTaxonomyConnectionEdge'
    | 'PressToPreviewConnectionEdge'
    | 'ReusableBlockToPreviewConnectionEdge'
    | 'TagToTaxonomyConnectionEdge'
    | 'TeamMemberToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected node
   */
  node: Node;
  $on: $OneToOneConnection;
}

/**
 * The page type
 */
export interface Page {
  __typename?: 'Page';
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Page type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PageToCommentConnectionWhereArgs>;
  }) => Maybe<PageToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the page object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether this page is set to the static front page.
   */
  isFrontPage: ScalarsEnums['Boolean'];
  /**
   * Whether this page is set to the blog posts page.
   */
  isPostsPage: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether this page is set to the privacy page.
   */
  isPrivacyPage: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<ScalarsEnums['Int']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  pageId: ScalarsEnums['Int'];
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Connection between the Page type and the page type
   */
  preview?: Maybe<PageToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the Page type and the page type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PageToRevisionConnectionWhereArgs>;
  }) => Maybe<PageToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to page Nodes
 */
export interface PageConnection {
  __typename?: 'PageToRevisionConnection' | 'RootQueryToPageConnection' | 'UserToPageConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected page Nodes
   */
  edges: Array<PageConnectionEdge>;
  /**
   * A list of connected page Nodes
   */
  nodes: Array<Page>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PageConnectionPageInfo;
  $on: $PageConnection;
}

/**
 * Edge between a Node and a connected page
 */
export interface PageConnectionEdge {
  __typename?:
    | 'PageToPreviewConnectionEdge'
    | 'PageToRevisionConnectionEdge'
    | 'RootQueryToPageConnectionEdge'
    | 'UserToPageConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected page Node
   */
  node: Page;
  $on: $PageConnectionEdge;
}

/**
 * Page Info on the connected PageConnectionEdge
 */
export interface PageConnectionPageInfo {
  __typename?:
    | 'PageToRevisionConnectionPageInfo'
    | 'RootQueryToPageConnectionPageInfo'
    | 'UserToPageConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PageConnectionPageInfo;
}

/**
 * Information about pagination in a connection.
 */
export interface PageInfo {
  __typename?:
    | 'CategoryToAncestorsCategoryConnectionPageInfo'
    | 'CategoryToCategoryConnectionPageInfo'
    | 'CategoryToContentNodeConnectionPageInfo'
    | 'CategoryToPostConnectionPageInfo'
    | 'CommentToCommentConnectionPageInfo'
    | 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo'
    | 'CompanyCategoryToCompanyCategoryConnectionPageInfo'
    | 'CompanyCategoryToCompanyConnectionPageInfo'
    | 'CompanyCategoryToContentNodeConnectionPageInfo'
    | 'CompanyToCompanyCategoryConnectionPageInfo'
    | 'CompanyToTermNodeConnectionPageInfo'
    | 'ContentNodeToEnqueuedScriptConnectionPageInfo'
    | 'ContentNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'ContentTypeToContentNodeConnectionPageInfo'
    | 'ContentTypeToTaxonomyConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo'
    | 'MediaItemToCommentConnectionPageInfo'
    | 'MenuItemToMenuItemConnectionPageInfo'
    | 'MenuToMenuItemConnectionPageInfo'
    | 'PageToCommentConnectionPageInfo'
    | 'PageToRevisionConnectionPageInfo'
    | 'PostFormatToContentNodeConnectionPageInfo'
    | 'PostFormatToPostConnectionPageInfo'
    | 'PostToCategoryConnectionPageInfo'
    | 'PostToCommentConnectionPageInfo'
    | 'PostToPostFormatConnectionPageInfo'
    | 'PostToRevisionConnectionPageInfo'
    | 'PostToTagConnectionPageInfo'
    | 'PostToTermNodeConnectionPageInfo'
    | 'PressCategoryToAncestorsPressCategoryConnectionPageInfo'
    | 'PressCategoryToContentNodeConnectionPageInfo'
    | 'PressCategoryToPressCategoryConnectionPageInfo'
    | 'PressCategoryToPressConnectionPageInfo'
    | 'PressCategoryToTeamMemberConnectionPageInfo'
    | 'PressToPressCategoryConnectionPageInfo'
    | 'PressToTermNodeConnectionPageInfo'
    | 'ReusableBlockToRevisionConnectionPageInfo'
    | 'RootQueryToBlockEditorPreviewConnectionPageInfo'
    | 'RootQueryToCategoryConnectionPageInfo'
    | 'RootQueryToCommentConnectionPageInfo'
    | 'RootQueryToCompanyCategoryConnectionPageInfo'
    | 'RootQueryToCompanyConnectionPageInfo'
    | 'RootQueryToContentNodeConnectionPageInfo'
    | 'RootQueryToContentTypeConnectionPageInfo'
    | 'RootQueryToEnqueuedScriptConnectionPageInfo'
    | 'RootQueryToEnqueuedStylesheetConnectionPageInfo'
    | 'RootQueryToGraphqlDocumentConnectionPageInfo'
    | 'RootQueryToMediaItemConnectionPageInfo'
    | 'RootQueryToMenuConnectionPageInfo'
    | 'RootQueryToMenuItemConnectionPageInfo'
    | 'RootQueryToPageConnectionPageInfo'
    | 'RootQueryToPluginConnectionPageInfo'
    | 'RootQueryToPostConnectionPageInfo'
    | 'RootQueryToPostFormatConnectionPageInfo'
    | 'RootQueryToPressCategoryConnectionPageInfo'
    | 'RootQueryToPressConnectionPageInfo'
    | 'RootQueryToReusableBlockConnectionPageInfo'
    | 'RootQueryToRevisionsConnectionPageInfo'
    | 'RootQueryToTagConnectionPageInfo'
    | 'RootQueryToTaxonomyConnectionPageInfo'
    | 'RootQueryToTeamMemberConnectionPageInfo'
    | 'RootQueryToTermNodeConnectionPageInfo'
    | 'RootQueryToThemeConnectionPageInfo'
    | 'RootQueryToUserConnectionPageInfo'
    | 'RootQueryToUserRoleConnectionPageInfo'
    | 'TagToContentNodeConnectionPageInfo'
    | 'TagToPostConnectionPageInfo'
    | 'TaxonomyToContentTypeConnectionPageInfo'
    | 'TaxonomyToTermNodeConnectionPageInfo'
    | 'TeamMemberToPressCategoryConnectionPageInfo'
    | 'TeamMemberToTermNodeConnectionPageInfo'
    | 'TermNodeToEnqueuedScriptConnectionPageInfo'
    | 'TermNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'UserToBlockEditorPreviewConnectionPageInfo'
    | 'UserToCommentConnectionPageInfo'
    | 'UserToEnqueuedScriptConnectionPageInfo'
    | 'UserToEnqueuedStylesheetConnectionPageInfo'
    | 'UserToMediaItemConnectionPageInfo'
    | 'UserToPageConnectionPageInfo'
    | 'UserToPostConnectionPageInfo'
    | 'UserToRevisionsConnectionPageInfo'
    | 'UserToUserRoleConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PageInfo;
}

/**
 * Connection between the Page type and the Comment type
 */
export interface PageToCommentConnection {
  __typename?: 'PageToCommentConnection';
  /**
   * Edges for the PageToCommentConnection connection
   */
  edges: Array<PageToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PageToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PageToCommentConnectionEdge {
  __typename?: 'PageToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;PageToCommentConnection&quot;
 */
export interface PageToCommentConnectionPageInfo {
  __typename?: 'PageToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Page type and the page type
 */
export interface PageToPreviewConnectionEdge {
  __typename?: 'PageToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Page;
}

/**
 * Connection between the Page type and the page type
 */
export interface PageToRevisionConnection {
  __typename?: 'PageToRevisionConnection';
  /**
   * Edges for the PageToRevisionConnection connection
   */
  edges: Array<PageToRevisionConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Page>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PageToRevisionConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PageToRevisionConnectionEdge {
  __typename?: 'PageToRevisionConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Page;
}

/**
 * Page Info on the &quot;PageToRevisionConnection&quot;
 */
export interface PageToRevisionConnectionPageInfo {
  __typename?: 'PageToRevisionConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * An plugin object
 */
export interface Plugin {
  __typename?: 'Plugin';
  /**
   * Name of the plugin author(s), may also be a company name.
   */
  author?: Maybe<ScalarsEnums['String']>;
  /**
   * URI for the related author(s)/company website.
   */
  authorUri?: Maybe<ScalarsEnums['String']>;
  /**
   * Description of the plugin.
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the plugin object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Display name of the plugin.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Plugin path.
   */
  path?: Maybe<ScalarsEnums['String']>;
  /**
   * URI for the plugin website. This is useful for directing users for support requests etc.
   */
  pluginUri?: Maybe<ScalarsEnums['String']>;
  /**
   * Current version of the plugin.
   */
  version?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to Plugin Nodes
 */
export interface PluginConnection {
  __typename?: 'RootQueryToPluginConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Plugin Nodes
   */
  edges: Array<PluginConnectionEdge>;
  /**
   * A list of connected Plugin Nodes
   */
  nodes: Array<Plugin>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PluginConnectionPageInfo;
  $on: $PluginConnection;
}

/**
 * Edge between a Node and a connected Plugin
 */
export interface PluginConnectionEdge {
  __typename?: 'RootQueryToPluginConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Plugin Node
   */
  node: Plugin;
  $on: $PluginConnectionEdge;
}

/**
 * Page Info on the connected PluginConnectionEdge
 */
export interface PluginConnectionPageInfo {
  __typename?: 'RootQueryToPluginConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PluginConnectionPageInfo;
}

/**
 * The post type
 */
export interface Post {
  __typename?: 'Post';
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Blog Post&quot; was set to Show in GraphQL.
   */
  blogPost?: Maybe<Post_Blogpost>;
  /**
   * Connection between the Post type and the category type
   */
  categories: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToCategoryConnectionWhereArgs>;
  }) => Maybe<PostToCategoryConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Post type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToCommentConnectionWhereArgs>;
  }) => Maybe<PostToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The excerpt of the post.
   */
  excerpt: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the post object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether this page is sticky
   */
  isSticky: ScalarsEnums['Boolean'];
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether the pings are open or closed for this particular post.
   */
  pingStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * URLs that have been pinged.
   */
  pinged?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the Post type and the postFormat type
   */
  postFormats: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToPostFormatConnectionWhereArgs>;
  }) => Maybe<PostToPostFormatConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  postId: ScalarsEnums['Int'];
  /**
   * Connection between the Post type and the post type
   */
  preview?: Maybe<PostToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the Post type and the post type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToRevisionConnectionWhereArgs>;
  }) => Maybe<PostToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Post type and the tag type
   */
  tags: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToTagConnectionWhereArgs>;
  }) => Maybe<PostToTagConnection>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the Post type and the TermNode type
   */
  terms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToTermNodeConnectionWhereArgs>;
  }) => Maybe<PostToTermNodeConnection>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * URLs queued to be pinged.
   */
  toPing?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to post Nodes
 */
export interface PostConnection {
  __typename?:
    | 'CategoryToPostConnection'
    | 'PostFormatToPostConnection'
    | 'PostToRevisionConnection'
    | 'RootQueryToPostConnection'
    | 'TagToPostConnection'
    | 'UserToPostConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected post Nodes
   */
  edges: Array<PostConnectionEdge>;
  /**
   * A list of connected post Nodes
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostConnectionPageInfo;
  $on: $PostConnection;
}

/**
 * Edge between a Node and a connected post
 */
export interface PostConnectionEdge {
  __typename?:
    | 'CategoryToPostConnectionEdge'
    | 'PostFormatToPostConnectionEdge'
    | 'PostToPreviewConnectionEdge'
    | 'PostToRevisionConnectionEdge'
    | 'RootQueryToPostConnectionEdge'
    | 'TagToPostConnectionEdge'
    | 'UserToPostConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected post Node
   */
  node: Post;
  $on: $PostConnectionEdge;
}

/**
 * Page Info on the connected PostConnectionEdge
 */
export interface PostConnectionPageInfo {
  __typename?:
    | 'CategoryToPostConnectionPageInfo'
    | 'PostFormatToPostConnectionPageInfo'
    | 'PostToRevisionConnectionPageInfo'
    | 'RootQueryToPostConnectionPageInfo'
    | 'TagToPostConnectionPageInfo'
    | 'UserToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PostConnectionPageInfo;
}

/**
 * The postFormat type
 */
export interface PostFormat {
  __typename?: 'PostFormat';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the PostFormat type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostFormatToContentNodeConnectionWhereArgs>;
  }) => Maybe<PostFormatToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  postFormatId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the PostFormat type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostFormatToPostConnectionWhereArgs>;
  }) => Maybe<PostFormatToPostConnection>;
  /**
   * The Yoast SEO data of the Formats taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the PostFormat type and the Taxonomy type
   */
  taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to postFormat Nodes
 */
export interface PostFormatConnection {
  __typename?: 'PostToPostFormatConnection' | 'RootQueryToPostFormatConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected postFormat Nodes
   */
  edges: Array<PostFormatConnectionEdge>;
  /**
   * A list of connected postFormat Nodes
   */
  nodes: Array<PostFormat>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostFormatConnectionPageInfo;
  $on: $PostFormatConnection;
}

/**
 * Edge between a Node and a connected postFormat
 */
export interface PostFormatConnectionEdge {
  __typename?: 'PostToPostFormatConnectionEdge' | 'RootQueryToPostFormatConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected postFormat Node
   */
  node: PostFormat;
  $on: $PostFormatConnectionEdge;
}

/**
 * Page Info on the connected PostFormatConnectionEdge
 */
export interface PostFormatConnectionPageInfo {
  __typename?: 'PostToPostFormatConnectionPageInfo' | 'RootQueryToPostFormatConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PostFormatConnectionPageInfo;
}

/**
 * Connection between the PostFormat type and the ContentNode type
 */
export interface PostFormatToContentNodeConnection {
  __typename?: 'PostFormatToContentNodeConnection';
  /**
   * Edges for the PostFormatToContentNodeConnection connection
   */
  edges: Array<PostFormatToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostFormatToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostFormatToContentNodeConnectionEdge {
  __typename?: 'PostFormatToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;PostFormatToContentNodeConnection&quot;
 */
export interface PostFormatToContentNodeConnectionPageInfo {
  __typename?: 'PostFormatToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PostFormat type and the post type
 */
export interface PostFormatToPostConnection {
  __typename?: 'PostFormatToPostConnection';
  /**
   * Edges for the PostFormatToPostConnection connection
   */
  edges: Array<PostFormatToPostConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostFormatToPostConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostFormatToPostConnectionEdge {
  __typename?: 'PostFormatToPostConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;PostFormatToPostConnection&quot;
 */
export interface PostFormatToPostConnectionPageInfo {
  __typename?: 'PostFormatToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PostFormat type and the Taxonomy type
 */
export interface PostFormatToTaxonomyConnectionEdge {
  __typename?: 'PostFormatToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Taxonomy;
}

/**
 * Connection between the Post type and the category type
 */
export interface PostToCategoryConnection {
  __typename?: 'PostToCategoryConnection';
  /**
   * Edges for the PostToCategoryConnection connection
   */
  edges: Array<PostToCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Category>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToCategoryConnectionEdge {
  __typename?: 'PostToCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary category
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: Category;
}

/**
 * Page Info on the &quot;PostToCategoryConnection&quot;
 */
export interface PostToCategoryConnectionPageInfo {
  __typename?: 'PostToCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Post type and the Comment type
 */
export interface PostToCommentConnection {
  __typename?: 'PostToCommentConnection';
  /**
   * Edges for the PostToCommentConnection connection
   */
  edges: Array<PostToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToCommentConnectionEdge {
  __typename?: 'PostToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;PostToCommentConnection&quot;
 */
export interface PostToCommentConnectionPageInfo {
  __typename?: 'PostToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Post type and the postFormat type
 */
export interface PostToPostFormatConnection {
  __typename?: 'PostToPostFormatConnection';
  /**
   * Edges for the PostToPostFormatConnection connection
   */
  edges: Array<PostToPostFormatConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PostFormat>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToPostFormatConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToPostFormatConnectionEdge {
  __typename?: 'PostToPostFormatConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary post_format
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: PostFormat;
}

/**
 * Page Info on the &quot;PostToPostFormatConnection&quot;
 */
export interface PostToPostFormatConnectionPageInfo {
  __typename?: 'PostToPostFormatConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Post type and the post type
 */
export interface PostToPreviewConnectionEdge {
  __typename?: 'PostToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Post;
}

/**
 * Connection between the Post type and the post type
 */
export interface PostToRevisionConnection {
  __typename?: 'PostToRevisionConnection';
  /**
   * Edges for the PostToRevisionConnection connection
   */
  edges: Array<PostToRevisionConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToRevisionConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToRevisionConnectionEdge {
  __typename?: 'PostToRevisionConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;PostToRevisionConnection&quot;
 */
export interface PostToRevisionConnectionPageInfo {
  __typename?: 'PostToRevisionConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Post type and the tag type
 */
export interface PostToTagConnection {
  __typename?: 'PostToTagConnection';
  /**
   * Edges for the PostToTagConnection connection
   */
  edges: Array<PostToTagConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Tag>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToTagConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToTagConnectionEdge {
  __typename?: 'PostToTagConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary post_tag
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: Tag;
}

/**
 * Page Info on the &quot;PostToTagConnection&quot;
 */
export interface PostToTagConnectionPageInfo {
  __typename?: 'PostToTagConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Post type and the TermNode type
 */
export interface PostToTermNodeConnection {
  __typename?: 'PostToTermNodeConnection';
  /**
   * Edges for the PostToTermNodeConnection connection
   */
  edges: Array<PostToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PostToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PostToTermNodeConnectionEdge {
  __typename?: 'PostToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;PostToTermNodeConnection&quot;
 */
export interface PostToTermNodeConnectionPageInfo {
  __typename?: 'PostToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Details for labels of the PostType
 */
export interface PostTypeLabelDetails {
  __typename?: 'PostTypeLabelDetails';
  /**
   * Default is ‘Add New’ for both hierarchical and non-hierarchical types.
   */
  addNew?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for adding a new singular item.
   */
  addNewItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label to signify all items in a submenu link.
   */
  allItems?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for archives in nav menus
   */
  archives?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the attributes meta box.
   */
  attributes?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for editing a singular item.
   */
  editItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the Featured Image meta box title.
   */
  featuredImage?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the table views hidden heading.
   */
  filterItemsList?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the media frame button.
   */
  insertIntoItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the table hidden heading.
   */
  itemsList?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the table pagination hidden heading.
   */
  itemsListNavigation?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the menu name.
   */
  menuName?: Maybe<ScalarsEnums['String']>;
  /**
   * General name for the post type, usually plural.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the new item page title.
   */
  newItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label used when no items are found.
   */
  notFound?: Maybe<ScalarsEnums['String']>;
  /**
   * Label used when no items are in the trash.
   */
  notFoundInTrash?: Maybe<ScalarsEnums['String']>;
  /**
   * Label used to prefix parents of hierarchical items.
   */
  parentItemColon?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for removing the featured image.
   */
  removeFeaturedImage?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for searching plural items.
   */
  searchItems?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for setting the featured image.
   */
  setFeaturedImage?: Maybe<ScalarsEnums['String']>;
  /**
   * Name for one object of this post type.
   */
  singularName?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for the media frame filter.
   */
  uploadedToThisItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label in the media frame for using a featured image.
   */
  useFeaturedImage?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for viewing a singular item.
   */
  viewItem?: Maybe<ScalarsEnums['String']>;
  /**
   * Label for viewing post type archives.
   */
  viewItems?: Maybe<ScalarsEnums['String']>;
}

export interface PostTypeSEO {
  __typename?: 'PostTypeSEO';
  breadcrumbs?: Maybe<Array<Maybe<SEOPostTypeBreadcrumbs>>>;
  canonical?: Maybe<ScalarsEnums['String']>;
  cornerstone?: Maybe<ScalarsEnums['Boolean']>;
  focuskw?: Maybe<ScalarsEnums['String']>;
  fullHead?: Maybe<ScalarsEnums['String']>;
  metaDesc?: Maybe<ScalarsEnums['String']>;
  metaKeywords?: Maybe<ScalarsEnums['String']>;
  metaRobotsNofollow?: Maybe<ScalarsEnums['String']>;
  metaRobotsNoindex?: Maybe<ScalarsEnums['String']>;
  opengraphAuthor?: Maybe<ScalarsEnums['String']>;
  opengraphDescription?: Maybe<ScalarsEnums['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<ScalarsEnums['String']>;
  opengraphPublishedTime?: Maybe<ScalarsEnums['String']>;
  opengraphPublisher?: Maybe<ScalarsEnums['String']>;
  opengraphSiteName?: Maybe<ScalarsEnums['String']>;
  opengraphTitle?: Maybe<ScalarsEnums['String']>;
  opengraphType?: Maybe<ScalarsEnums['String']>;
  opengraphUrl?: Maybe<ScalarsEnums['String']>;
  readingTime?: Maybe<ScalarsEnums['Float']>;
  schema?: Maybe<SEOPostTypeSchema>;
  title?: Maybe<ScalarsEnums['String']>;
  twitterDescription?: Maybe<ScalarsEnums['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface Post_Blogpost {
  __typename?: 'Post_Blogpost';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  heroArticleImage?: Maybe<MediaItem>;
}

/**
 * The Press type
 */
export interface Press {
  __typename?: 'Press';
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Press&quot; was set to Show in GraphQL.
   */
  acfPress?: Maybe<Press_Acfpress>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the press object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Press type and the PressCategory type
   */
  pressCategories: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressToPressCategoryConnectionWhereArgs>;
  }) => Maybe<PressToPressCategoryConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  pressId: ScalarsEnums['Int'];
  /**
   * Connection between the Press type and the Press type
   */
  preview?: Maybe<PressToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the Press type and the TermNode type
   */
  terms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressToTermNodeConnectionWhereArgs>;
  }) => Maybe<PressToTermNodeConnection>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * The PressCategory type
 */
export interface PressCategory {
  __typename?: 'PressCategory';
  /**
   * The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<PressCategoryToAncestorsPressCategoryConnection>;
  /**
   * Connection between the PressCategory type and its children PressCategories.
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressCategoryToPressCategoryConnectionWhereArgs>;
  }) => Maybe<PressCategoryToPressCategoryConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the PressCategory type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressCategoryToContentNodeConnectionWhereArgs>;
  }) => Maybe<PressCategoryToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the PressCategory type and its parent PressCategory.
   */
  parent?: Maybe<PressCategoryToParentPressCategoryConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  pressCategoryId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the PressCategory type and the Press type
   */
  pressPosts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressCategoryToPressConnectionWhereArgs>;
  }) => Maybe<PressCategoryToPressConnection>;
  /**
   * The Yoast SEO data of the Press Categories taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the PressCategory type and the Taxonomy type
   */
  taxonomy?: Maybe<PressCategoryToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the PressCategory type and the TeamMember type
   */
  teamMembers: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PressCategoryToTeamMemberConnectionWhereArgs>;
  }) => Maybe<PressCategoryToTeamMemberConnection>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to PressCategory Nodes
 */
export interface PressCategoryConnection {
  __typename?:
    | 'PressCategoryToAncestorsPressCategoryConnection'
    | 'PressCategoryToPressCategoryConnection'
    | 'PressToPressCategoryConnection'
    | 'RootQueryToPressCategoryConnection'
    | 'TeamMemberToPressCategoryConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected PressCategory Nodes
   */
  edges: Array<PressCategoryConnectionEdge>;
  /**
   * A list of connected PressCategory Nodes
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryConnectionPageInfo;
  $on: $PressCategoryConnection;
}

/**
 * Edge between a Node and a connected PressCategory
 */
export interface PressCategoryConnectionEdge {
  __typename?:
    | 'PressCategoryToAncestorsPressCategoryConnectionEdge'
    | 'PressCategoryToParentPressCategoryConnectionEdge'
    | 'PressCategoryToPressCategoryConnectionEdge'
    | 'PressToPressCategoryConnectionEdge'
    | 'RootQueryToPressCategoryConnectionEdge'
    | 'TeamMemberToPressCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected PressCategory Node
   */
  node: PressCategory;
  $on: $PressCategoryConnectionEdge;
}

/**
 * Page Info on the connected PressCategoryConnectionEdge
 */
export interface PressCategoryConnectionPageInfo {
  __typename?:
    | 'PressCategoryToAncestorsPressCategoryConnectionPageInfo'
    | 'PressCategoryToPressCategoryConnectionPageInfo'
    | 'PressToPressCategoryConnectionPageInfo'
    | 'RootQueryToPressCategoryConnectionPageInfo'
    | 'TeamMemberToPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PressCategoryConnectionPageInfo;
}

/**
 * Connection between the PressCategory type and the PressCategory type
 */
export interface PressCategoryToAncestorsPressCategoryConnection {
  __typename?: 'PressCategoryToAncestorsPressCategoryConnection';
  /**
   * Edges for the PressCategoryToAncestorsPressCategoryConnection connection
   */
  edges: Array<PressCategoryToAncestorsPressCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryToAncestorsPressCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressCategoryToAncestorsPressCategoryConnectionEdge {
  __typename?: 'PressCategoryToAncestorsPressCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: PressCategory;
}

/**
 * Page Info on the &quot;PressCategoryToAncestorsPressCategoryConnection&quot;
 */
export interface PressCategoryToAncestorsPressCategoryConnectionPageInfo {
  __typename?: 'PressCategoryToAncestorsPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PressCategory type and the ContentNode type
 */
export interface PressCategoryToContentNodeConnection {
  __typename?: 'PressCategoryToContentNodeConnection';
  /**
   * Edges for the PressCategoryToContentNodeConnection connection
   */
  edges: Array<PressCategoryToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressCategoryToContentNodeConnectionEdge {
  __typename?: 'PressCategoryToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;PressCategoryToContentNodeConnection&quot;
 */
export interface PressCategoryToContentNodeConnectionPageInfo {
  __typename?: 'PressCategoryToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PressCategory type and the PressCategory type
 */
export interface PressCategoryToParentPressCategoryConnectionEdge {
  __typename?: 'PressCategoryToParentPressCategoryConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: PressCategory;
}

/**
 * Connection between the PressCategory type and the PressCategory type
 */
export interface PressCategoryToPressCategoryConnection {
  __typename?: 'PressCategoryToPressCategoryConnection';
  /**
   * Edges for the PressCategoryToPressCategoryConnection connection
   */
  edges: Array<PressCategoryToPressCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryToPressCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressCategoryToPressCategoryConnectionEdge {
  __typename?: 'PressCategoryToPressCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: PressCategory;
}

/**
 * Page Info on the &quot;PressCategoryToPressCategoryConnection&quot;
 */
export interface PressCategoryToPressCategoryConnectionPageInfo {
  __typename?: 'PressCategoryToPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PressCategory type and the Press type
 */
export interface PressCategoryToPressConnection {
  __typename?: 'PressCategoryToPressConnection';
  /**
   * Edges for the PressCategoryToPressConnection connection
   */
  edges: Array<PressCategoryToPressConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Press>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryToPressConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressCategoryToPressConnectionEdge {
  __typename?: 'PressCategoryToPressConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Press;
}

/**
 * Page Info on the &quot;PressCategoryToPressConnection&quot;
 */
export interface PressCategoryToPressConnectionPageInfo {
  __typename?: 'PressCategoryToPressConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the PressCategory type and the Taxonomy type
 */
export interface PressCategoryToTaxonomyConnectionEdge {
  __typename?: 'PressCategoryToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Taxonomy;
}

/**
 * Connection between the PressCategory type and the TeamMember type
 */
export interface PressCategoryToTeamMemberConnection {
  __typename?: 'PressCategoryToTeamMemberConnection';
  /**
   * Edges for the PressCategoryToTeamMemberConnection connection
   */
  edges: Array<PressCategoryToTeamMemberConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TeamMember>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressCategoryToTeamMemberConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressCategoryToTeamMemberConnectionEdge {
  __typename?: 'PressCategoryToTeamMemberConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TeamMember;
}

/**
 * Page Info on the &quot;PressCategoryToTeamMemberConnection&quot;
 */
export interface PressCategoryToTeamMemberConnectionPageInfo {
  __typename?: 'PressCategoryToTeamMemberConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to Press Nodes
 */
export interface PressConnection {
  __typename?: 'PressCategoryToPressConnection' | 'RootQueryToPressConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Press Nodes
   */
  edges: Array<PressConnectionEdge>;
  /**
   * A list of connected Press Nodes
   */
  nodes: Array<Press>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressConnectionPageInfo;
  $on: $PressConnection;
}

/**
 * Edge between a Node and a connected Press
 */
export interface PressConnectionEdge {
  __typename?:
    | 'PressCategoryToPressConnectionEdge'
    | 'PressToPreviewConnectionEdge'
    | 'RootQueryToPressConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Press Node
   */
  node: Press;
  $on: $PressConnectionEdge;
}

/**
 * Page Info on the connected PressConnectionEdge
 */
export interface PressConnectionPageInfo {
  __typename?: 'PressCategoryToPressConnectionPageInfo' | 'RootQueryToPressConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $PressConnectionPageInfo;
}

/**
 * Connection between the Press type and the PressCategory type
 */
export interface PressToPressCategoryConnection {
  __typename?: 'PressToPressCategoryConnection';
  /**
   * Edges for the PressToPressCategoryConnection connection
   */
  edges: Array<PressToPressCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressToPressCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressToPressCategoryConnectionEdge {
  __typename?: 'PressToPressCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary press_categories
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: PressCategory;
}

/**
 * Page Info on the &quot;PressToPressCategoryConnection&quot;
 */
export interface PressToPressCategoryConnectionPageInfo {
  __typename?: 'PressToPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Press type and the Press type
 */
export interface PressToPreviewConnectionEdge {
  __typename?: 'PressToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Press;
}

/**
 * Connection between the Press type and the TermNode type
 */
export interface PressToTermNodeConnection {
  __typename?: 'PressToTermNodeConnection';
  /**
   * Edges for the PressToTermNodeConnection connection
   */
  edges: Array<PressToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: PressToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface PressToTermNodeConnectionEdge {
  __typename?: 'PressToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;PressToTermNodeConnection&quot;
 */
export interface PressToTermNodeConnectionPageInfo {
  __typename?: 'PressToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface Press_Acfpress {
  __typename?: 'Press_Acfpress';
  externalLink?: Maybe<ScalarsEnums['String']>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  source?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['String']>;
}

/**
 * Nodes that can be seen in a preview (unpublished) state.
 */
export interface Previewable {
  __typename?: 'Company' | 'Page' | 'Post' | 'Press' | 'TeamMember';
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  $on: $Previewable;
}

/**
 * The reading setting type
 */
export interface ReadingSettings {
  __typename?: 'ReadingSettings';
  /**
   * The ID of the page that should display the latest posts
   */
  pageForPosts?: Maybe<ScalarsEnums['Int']>;
  /**
   * The ID of the page that should be displayed on the front page
   */
  pageOnFront?: Maybe<ScalarsEnums['Int']>;
  /**
   * Blog pages show at most.
   */
  postsPerPage?: Maybe<ScalarsEnums['Int']>;
  /**
   * What to show on the front page
   */
  showOnFront?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the registerUser mutation.
 */
export interface RegisterUserPayload {
  __typename?: 'RegisterUserPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the resetUserPassword mutation.
 */
export interface ResetUserPasswordPayload {
  __typename?: 'ResetUserPasswordPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the restoreComment mutation.
 */
export interface RestoreCommentPayload {
  __typename?: 'RestoreCommentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The restored comment object
   */
  comment?: Maybe<Comment>;
  /**
   * The ID of the restored comment
   */
  restoredId?: Maybe<ScalarsEnums['ID']>;
}

/**
 * The ReusableBlock type
 */
export interface ReusableBlock {
  __typename?: 'ReusableBlock';
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the wp_block object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ReusableBlock type and the ReusableBlock type
   * @deprecated The &quot;ReusableBlock&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  preview?: Maybe<ReusableBlockToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocksFrom: (args: { databaseId: Scalars['Int'] }) => Maybe<Array<Block>>;
  /**
   * Previewed gutenberg blocks as json string
   */
  previewBlocksFromJSON: (args: { databaseId: Scalars['Int'] }) => Maybe<ScalarsEnums['String']>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  reusableBlockId: ScalarsEnums['Int'];
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the ReusableBlock type and the ReusableBlock type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<ReusableBlockToRevisionConnectionWhereArgs>;
  }) => Maybe<ReusableBlockToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to ReusableBlock Nodes
 */
export interface ReusableBlockConnection {
  __typename?: 'ReusableBlockToRevisionConnection' | 'RootQueryToReusableBlockConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected ReusableBlock Nodes
   */
  edges: Array<ReusableBlockConnectionEdge>;
  /**
   * A list of connected ReusableBlock Nodes
   */
  nodes: Array<ReusableBlock>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ReusableBlockConnectionPageInfo;
  $on: $ReusableBlockConnection;
}

/**
 * Edge between a Node and a connected ReusableBlock
 */
export interface ReusableBlockConnectionEdge {
  __typename?:
    | 'ReusableBlockToPreviewConnectionEdge'
    | 'ReusableBlockToRevisionConnectionEdge'
    | 'RootQueryToReusableBlockConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected ReusableBlock Node
   */
  node: ReusableBlock;
  $on: $ReusableBlockConnectionEdge;
}

/**
 * Page Info on the connected ReusableBlockConnectionEdge
 */
export interface ReusableBlockConnectionPageInfo {
  __typename?:
    | 'ReusableBlockToRevisionConnectionPageInfo'
    | 'RootQueryToReusableBlockConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $ReusableBlockConnectionPageInfo;
}

/**
 * Connection between the ReusableBlock type and the ReusableBlock type
 */
export interface ReusableBlockToPreviewConnectionEdge {
  __typename?: 'ReusableBlockToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   * @deprecated The &quot;ReusableBlock&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
   */
  node: ReusableBlock;
}

/**
 * Connection between the ReusableBlock type and the ReusableBlock type
 */
export interface ReusableBlockToRevisionConnection {
  __typename?: 'ReusableBlockToRevisionConnection';
  /**
   * Edges for the ReusableBlockToRevisionConnection connection
   */
  edges: Array<ReusableBlockToRevisionConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ReusableBlock>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ReusableBlockToRevisionConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface ReusableBlockToRevisionConnectionEdge {
  __typename?: 'ReusableBlockToRevisionConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ReusableBlock;
}

/**
 * Page Info on the &quot;ReusableBlockToRevisionConnection&quot;
 */
export interface ReusableBlockToRevisionConnectionPageInfo {
  __typename?: 'ReusableBlockToRevisionConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the BlockEditorPreview type
 */
export interface RootQueryToBlockEditorPreviewConnection {
  __typename?: 'RootQueryToBlockEditorPreviewConnection';
  /**
   * Edges for the RootQueryToBlockEditorPreviewConnection connection
   */
  edges: Array<RootQueryToBlockEditorPreviewConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<BlockEditorPreview>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToBlockEditorPreviewConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToBlockEditorPreviewConnectionEdge {
  __typename?: 'RootQueryToBlockEditorPreviewConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: BlockEditorPreview;
}

/**
 * Page Info on the &quot;RootQueryToBlockEditorPreviewConnection&quot;
 */
export interface RootQueryToBlockEditorPreviewConnectionPageInfo {
  __typename?: 'RootQueryToBlockEditorPreviewConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the category type
 */
export interface RootQueryToCategoryConnection {
  __typename?: 'RootQueryToCategoryConnection';
  /**
   * Edges for the RootQueryToCategoryConnection connection
   */
  edges: Array<RootQueryToCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Category>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCategoryConnectionEdge {
  __typename?: 'RootQueryToCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Category;
}

/**
 * Page Info on the &quot;RootQueryToCategoryConnection&quot;
 */
export interface RootQueryToCategoryConnectionPageInfo {
  __typename?: 'RootQueryToCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Comment type
 */
export interface RootQueryToCommentConnection {
  __typename?: 'RootQueryToCommentConnection';
  /**
   * Edges for the RootQueryToCommentConnection connection
   */
  edges: Array<RootQueryToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCommentConnectionEdge {
  __typename?: 'RootQueryToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;RootQueryToCommentConnection&quot;
 */
export interface RootQueryToCommentConnectionPageInfo {
  __typename?: 'RootQueryToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the CompanyCategory type
 */
export interface RootQueryToCompanyCategoryConnection {
  __typename?: 'RootQueryToCompanyCategoryConnection';
  /**
   * Edges for the RootQueryToCompanyCategoryConnection connection
   */
  edges: Array<RootQueryToCompanyCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<CompanyCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToCompanyCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCompanyCategoryConnectionEdge {
  __typename?: 'RootQueryToCompanyCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: CompanyCategory;
}

/**
 * Page Info on the &quot;RootQueryToCompanyCategoryConnection&quot;
 */
export interface RootQueryToCompanyCategoryConnectionPageInfo {
  __typename?: 'RootQueryToCompanyCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Company type
 */
export interface RootQueryToCompanyConnection {
  __typename?: 'RootQueryToCompanyConnection';
  /**
   * Edges for the RootQueryToCompanyConnection connection
   */
  edges: Array<RootQueryToCompanyConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Company>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToCompanyConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCompanyConnectionEdge {
  __typename?: 'RootQueryToCompanyConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Company;
}

/**
 * Page Info on the &quot;RootQueryToCompanyConnection&quot;
 */
export interface RootQueryToCompanyConnectionPageInfo {
  __typename?: 'RootQueryToCompanyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the ContentNode type
 */
export interface RootQueryToContentNodeConnection {
  __typename?: 'RootQueryToContentNodeConnection';
  /**
   * Edges for the RootQueryToContentNodeConnection connection
   */
  edges: Array<RootQueryToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToContentNodeConnectionEdge {
  __typename?: 'RootQueryToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;RootQueryToContentNodeConnection&quot;
 */
export interface RootQueryToContentNodeConnectionPageInfo {
  __typename?: 'RootQueryToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the ContentType type
 */
export interface RootQueryToContentTypeConnection {
  __typename?: 'RootQueryToContentTypeConnection';
  /**
   * Edges for the RootQueryToContentTypeConnection connection
   */
  edges: Array<RootQueryToContentTypeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentType>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToContentTypeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToContentTypeConnectionEdge {
  __typename?: 'RootQueryToContentTypeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentType;
}

/**
 * Page Info on the &quot;RootQueryToContentTypeConnection&quot;
 */
export interface RootQueryToContentTypeConnectionPageInfo {
  __typename?: 'RootQueryToContentTypeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the EnqueuedScript type
 */
export interface RootQueryToEnqueuedScriptConnection {
  __typename?: 'RootQueryToEnqueuedScriptConnection';
  /**
   * Edges for the RootQueryToEnqueuedScriptConnection connection
   */
  edges: Array<RootQueryToEnqueuedScriptConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedScript>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToEnqueuedScriptConnectionEdge {
  __typename?: 'RootQueryToEnqueuedScriptConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedScript;
}

/**
 * Page Info on the &quot;RootQueryToEnqueuedScriptConnection&quot;
 */
export interface RootQueryToEnqueuedScriptConnectionPageInfo {
  __typename?: 'RootQueryToEnqueuedScriptConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the EnqueuedStylesheet type
 */
export interface RootQueryToEnqueuedStylesheetConnection {
  __typename?: 'RootQueryToEnqueuedStylesheetConnection';
  /**
   * Edges for the RootQueryToEnqueuedStylesheetConnection connection
   */
  edges: Array<RootQueryToEnqueuedStylesheetConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedStylesheet>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToEnqueuedStylesheetConnectionEdge {
  __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedStylesheet;
}

/**
 * Page Info on the &quot;RootQueryToEnqueuedStylesheetConnection&quot;
 */
export interface RootQueryToEnqueuedStylesheetConnectionPageInfo {
  __typename?: 'RootQueryToEnqueuedStylesheetConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the graphqlDocument type
 */
export interface RootQueryToGraphqlDocumentConnection {
  __typename?: 'RootQueryToGraphqlDocumentConnection';
  /**
   * Edges for the RootQueryToGraphqlDocumentConnection connection
   */
  edges: Array<RootQueryToGraphqlDocumentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<GraphqlDocument>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToGraphqlDocumentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToGraphqlDocumentConnectionEdge {
  __typename?: 'RootQueryToGraphqlDocumentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: GraphqlDocument;
}

/**
 * Page Info on the &quot;RootQueryToGraphqlDocumentConnection&quot;
 */
export interface RootQueryToGraphqlDocumentConnectionPageInfo {
  __typename?: 'RootQueryToGraphqlDocumentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the mediaItem type
 */
export interface RootQueryToMediaItemConnection {
  __typename?: 'RootQueryToMediaItemConnection';
  /**
   * Edges for the RootQueryToMediaItemConnection connection
   */
  edges: Array<RootQueryToMediaItemConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<MediaItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToMediaItemConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMediaItemConnectionEdge {
  __typename?: 'RootQueryToMediaItemConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: MediaItem;
}

/**
 * Page Info on the &quot;RootQueryToMediaItemConnection&quot;
 */
export interface RootQueryToMediaItemConnectionPageInfo {
  __typename?: 'RootQueryToMediaItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Menu type
 */
export interface RootQueryToMenuConnection {
  __typename?: 'RootQueryToMenuConnection';
  /**
   * Edges for the RootQueryToMenuConnection connection
   */
  edges: Array<RootQueryToMenuConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Menu>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToMenuConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMenuConnectionEdge {
  __typename?: 'RootQueryToMenuConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Menu;
}

/**
 * Page Info on the &quot;RootQueryToMenuConnection&quot;
 */
export interface RootQueryToMenuConnectionPageInfo {
  __typename?: 'RootQueryToMenuConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the MenuItem type
 */
export interface RootQueryToMenuItemConnection {
  __typename?: 'RootQueryToMenuItemConnection';
  /**
   * Edges for the RootQueryToMenuItemConnection connection
   */
  edges: Array<RootQueryToMenuItemConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<MenuItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToMenuItemConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMenuItemConnectionEdge {
  __typename?: 'RootQueryToMenuItemConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: MenuItem;
}

/**
 * Page Info on the &quot;RootQueryToMenuItemConnection&quot;
 */
export interface RootQueryToMenuItemConnectionPageInfo {
  __typename?: 'RootQueryToMenuItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the page type
 */
export interface RootQueryToPageConnection {
  __typename?: 'RootQueryToPageConnection';
  /**
   * Edges for the RootQueryToPageConnection connection
   */
  edges: Array<RootQueryToPageConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Page>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPageConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPageConnectionEdge {
  __typename?: 'RootQueryToPageConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Page;
}

/**
 * Page Info on the &quot;RootQueryToPageConnection&quot;
 */
export interface RootQueryToPageConnectionPageInfo {
  __typename?: 'RootQueryToPageConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Plugin type
 */
export interface RootQueryToPluginConnection {
  __typename?: 'RootQueryToPluginConnection';
  /**
   * Edges for the RootQueryToPluginConnection connection
   */
  edges: Array<RootQueryToPluginConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Plugin>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPluginConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPluginConnectionEdge {
  __typename?: 'RootQueryToPluginConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Plugin;
}

/**
 * Page Info on the &quot;RootQueryToPluginConnection&quot;
 */
export interface RootQueryToPluginConnectionPageInfo {
  __typename?: 'RootQueryToPluginConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the post type
 */
export interface RootQueryToPostConnection {
  __typename?: 'RootQueryToPostConnection';
  /**
   * Edges for the RootQueryToPostConnection connection
   */
  edges: Array<RootQueryToPostConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPostConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPostConnectionEdge {
  __typename?: 'RootQueryToPostConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;RootQueryToPostConnection&quot;
 */
export interface RootQueryToPostConnectionPageInfo {
  __typename?: 'RootQueryToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the postFormat type
 */
export interface RootQueryToPostFormatConnection {
  __typename?: 'RootQueryToPostFormatConnection';
  /**
   * Edges for the RootQueryToPostFormatConnection connection
   */
  edges: Array<RootQueryToPostFormatConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PostFormat>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPostFormatConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPostFormatConnectionEdge {
  __typename?: 'RootQueryToPostFormatConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: PostFormat;
}

/**
 * Page Info on the &quot;RootQueryToPostFormatConnection&quot;
 */
export interface RootQueryToPostFormatConnectionPageInfo {
  __typename?: 'RootQueryToPostFormatConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the PressCategory type
 */
export interface RootQueryToPressCategoryConnection {
  __typename?: 'RootQueryToPressCategoryConnection';
  /**
   * Edges for the RootQueryToPressCategoryConnection connection
   */
  edges: Array<RootQueryToPressCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPressCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPressCategoryConnectionEdge {
  __typename?: 'RootQueryToPressCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: PressCategory;
}

/**
 * Page Info on the &quot;RootQueryToPressCategoryConnection&quot;
 */
export interface RootQueryToPressCategoryConnectionPageInfo {
  __typename?: 'RootQueryToPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Press type
 */
export interface RootQueryToPressConnection {
  __typename?: 'RootQueryToPressConnection';
  /**
   * Edges for the RootQueryToPressConnection connection
   */
  edges: Array<RootQueryToPressConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Press>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToPressConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPressConnectionEdge {
  __typename?: 'RootQueryToPressConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Press;
}

/**
 * Page Info on the &quot;RootQueryToPressConnection&quot;
 */
export interface RootQueryToPressConnectionPageInfo {
  __typename?: 'RootQueryToPressConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the ReusableBlock type
 */
export interface RootQueryToReusableBlockConnection {
  __typename?: 'RootQueryToReusableBlockConnection';
  /**
   * Edges for the RootQueryToReusableBlockConnection connection
   */
  edges: Array<RootQueryToReusableBlockConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ReusableBlock>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToReusableBlockConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToReusableBlockConnectionEdge {
  __typename?: 'RootQueryToReusableBlockConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ReusableBlock;
}

/**
 * Page Info on the &quot;RootQueryToReusableBlockConnection&quot;
 */
export interface RootQueryToReusableBlockConnectionPageInfo {
  __typename?: 'RootQueryToReusableBlockConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the ContentNode type
 */
export interface RootQueryToRevisionsConnection {
  __typename?: 'RootQueryToRevisionsConnection';
  /**
   * Edges for the RootQueryToRevisionsConnection connection
   */
  edges: Array<RootQueryToRevisionsConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToRevisionsConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToRevisionsConnectionEdge {
  __typename?: 'RootQueryToRevisionsConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;RootQueryToRevisionsConnection&quot;
 */
export interface RootQueryToRevisionsConnectionPageInfo {
  __typename?: 'RootQueryToRevisionsConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the tag type
 */
export interface RootQueryToTagConnection {
  __typename?: 'RootQueryToTagConnection';
  /**
   * Edges for the RootQueryToTagConnection connection
   */
  edges: Array<RootQueryToTagConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Tag>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToTagConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTagConnectionEdge {
  __typename?: 'RootQueryToTagConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Tag;
}

/**
 * Page Info on the &quot;RootQueryToTagConnection&quot;
 */
export interface RootQueryToTagConnectionPageInfo {
  __typename?: 'RootQueryToTagConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Taxonomy type
 */
export interface RootQueryToTaxonomyConnection {
  __typename?: 'RootQueryToTaxonomyConnection';
  /**
   * Edges for the RootQueryToTaxonomyConnection connection
   */
  edges: Array<RootQueryToTaxonomyConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Taxonomy>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToTaxonomyConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTaxonomyConnectionEdge {
  __typename?: 'RootQueryToTaxonomyConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Taxonomy;
}

/**
 * Page Info on the &quot;RootQueryToTaxonomyConnection&quot;
 */
export interface RootQueryToTaxonomyConnectionPageInfo {
  __typename?: 'RootQueryToTaxonomyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the TeamMember type
 */
export interface RootQueryToTeamMemberConnection {
  __typename?: 'RootQueryToTeamMemberConnection';
  /**
   * Edges for the RootQueryToTeamMemberConnection connection
   */
  edges: Array<RootQueryToTeamMemberConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TeamMember>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToTeamMemberConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTeamMemberConnectionEdge {
  __typename?: 'RootQueryToTeamMemberConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TeamMember;
}

/**
 * Page Info on the &quot;RootQueryToTeamMemberConnection&quot;
 */
export interface RootQueryToTeamMemberConnectionPageInfo {
  __typename?: 'RootQueryToTeamMemberConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the TermNode type
 */
export interface RootQueryToTermNodeConnection {
  __typename?: 'RootQueryToTermNodeConnection';
  /**
   * Edges for the RootQueryToTermNodeConnection connection
   */
  edges: Array<RootQueryToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTermNodeConnectionEdge {
  __typename?: 'RootQueryToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;RootQueryToTermNodeConnection&quot;
 */
export interface RootQueryToTermNodeConnectionPageInfo {
  __typename?: 'RootQueryToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the Theme type
 */
export interface RootQueryToThemeConnection {
  __typename?: 'RootQueryToThemeConnection';
  /**
   * Edges for the RootQueryToThemeConnection connection
   */
  edges: Array<RootQueryToThemeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Theme>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToThemeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToThemeConnectionEdge {
  __typename?: 'RootQueryToThemeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Theme;
}

/**
 * Page Info on the &quot;RootQueryToThemeConnection&quot;
 */
export interface RootQueryToThemeConnectionPageInfo {
  __typename?: 'RootQueryToThemeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the User type
 */
export interface RootQueryToUserConnection {
  __typename?: 'RootQueryToUserConnection';
  /**
   * Edges for the RootQueryToUserConnection connection
   */
  edges: Array<RootQueryToUserConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<User>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToUserConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToUserConnectionEdge {
  __typename?: 'RootQueryToUserConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: User;
}

/**
 * Page Info on the &quot;RootQueryToUserConnection&quot;
 */
export interface RootQueryToUserConnectionPageInfo {
  __typename?: 'RootQueryToUserConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the RootQuery type and the UserRole type
 */
export interface RootQueryToUserRoleConnection {
  __typename?: 'RootQueryToUserRoleConnection';
  /**
   * Edges for the RootQueryToUserRoleConnection connection
   */
  edges: Array<RootQueryToUserRoleConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<UserRole>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: RootQueryToUserRoleConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface RootQueryToUserRoleConnectionEdge {
  __typename?: 'RootQueryToUserRoleConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: UserRole;
}

/**
 * Page Info on the &quot;RootQueryToUserRoleConnection&quot;
 */
export interface RootQueryToUserRoleConnectionPageInfo {
  __typename?: 'RootQueryToUserRoleConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Yoast SEO breadcrumb config
 */
export interface SEOBreadcrumbs {
  __typename?: 'SEOBreadcrumbs';
  archivePrefix?: Maybe<ScalarsEnums['String']>;
  boldLast?: Maybe<ScalarsEnums['Boolean']>;
  enabled?: Maybe<ScalarsEnums['Boolean']>;
  homeText?: Maybe<ScalarsEnums['String']>;
  notFoundText?: Maybe<ScalarsEnums['String']>;
  prefix?: Maybe<ScalarsEnums['String']>;
  searchPrefix?: Maybe<ScalarsEnums['String']>;
  separator?: Maybe<ScalarsEnums['String']>;
  showBlogPage?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * The Yoast SEO site level configuration data
 */
export interface SEOConfig {
  __typename?: 'SEOConfig';
  breadcrumbs?: Maybe<SEOBreadcrumbs>;
  contentTypes?: Maybe<SEOContentTypes>;
  openGraph?: Maybe<SEOOpenGraph>;
  redirects?: Maybe<Array<Maybe<SEORedirect>>>;
  schema?: Maybe<SEOSchema>;
  social?: Maybe<SEOSocial>;
  webmaster?: Maybe<SEOWebmaster>;
}

/**
 * The Yoast SEO search appearance content types fields
 */
export interface SEOContentType {
  __typename?: 'SEOContentType';
  archive?: Maybe<SEOContentTypeArchive>;
  metaDesc?: Maybe<ScalarsEnums['String']>;
  metaRobotsNoindex?: Maybe<ScalarsEnums['Boolean']>;
  schema?: Maybe<SEOPageInfoSchema>;
  schemaType?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * he Yoast SEO search appearance content types fields
 */
export interface SEOContentTypeArchive {
  __typename?: 'SEOContentTypeArchive';
  archiveLink?: Maybe<ScalarsEnums['String']>;
  breadcrumbTitle?: Maybe<ScalarsEnums['String']>;
  fullHead?: Maybe<ScalarsEnums['String']>;
  hasArchive?: Maybe<ScalarsEnums['Boolean']>;
  metaDesc?: Maybe<ScalarsEnums['String']>;
  metaRobotsNoindex?: Maybe<ScalarsEnums['Boolean']>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Yoast SEO search appearance content types
 */
export interface SEOContentTypes {
  __typename?: 'SEOContentTypes';
  blockEditorPreview?: Maybe<SEOContentType>;
  company?: Maybe<SEOContentType>;
  graphqlDocument?: Maybe<SEOContentType>;
  mediaItem?: Maybe<SEOContentType>;
  page?: Maybe<SEOContentType>;
  post?: Maybe<SEOContentType>;
  press?: Maybe<SEOContentType>;
  reusableBlock?: Maybe<SEOContentType>;
  teamMember?: Maybe<SEOContentType>;
}

/**
 * The Open Graph data
 */
export interface SEOOpenGraph {
  __typename?: 'SEOOpenGraph';
  defaultImage?: Maybe<MediaItem>;
  frontPage?: Maybe<SEOOpenGraphFrontPage>;
}

/**
 * The Open Graph Front page data
 */
export interface SEOOpenGraphFrontPage {
  __typename?: 'SEOOpenGraphFrontPage';
  description?: Maybe<ScalarsEnums['String']>;
  image?: Maybe<MediaItem>;
  title?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Schema for post type
 */
export interface SEOPageInfoSchema {
  __typename?: 'SEOPageInfoSchema';
  raw?: Maybe<ScalarsEnums['String']>;
}

export interface SEOPostTypeBreadcrumbs {
  __typename?: 'SEOPostTypeBreadcrumbs';
  text?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * The page info SEO details
 */
export interface SEOPostTypePageInfo {
  __typename?: 'SEOPostTypePageInfo';
  schema?: Maybe<SEOPageInfoSchema>;
}

/**
 * The Schema types
 */
export interface SEOPostTypeSchema {
  __typename?: 'SEOPostTypeSchema';
  articleType?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  pageType?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  raw?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Yoast redirect data  (Yoast Premium only)
 */
export interface SEORedirect {
  __typename?: 'SEORedirect';
  format?: Maybe<ScalarsEnums['String']>;
  origin?: Maybe<ScalarsEnums['String']>;
  target?: Maybe<ScalarsEnums['String']>;
  type?: Maybe<ScalarsEnums['Int']>;
}

/**
 * The Yoast SEO schema data
 */
export interface SEOSchema {
  __typename?: 'SEOSchema';
  companyLogo?: Maybe<MediaItem>;
  companyName?: Maybe<ScalarsEnums['String']>;
  companyOrPerson?: Maybe<ScalarsEnums['String']>;
  homeUrl?: Maybe<ScalarsEnums['String']>;
  inLanguage?: Maybe<ScalarsEnums['String']>;
  logo?: Maybe<MediaItem>;
  personLogo?: Maybe<MediaItem>;
  personName?: Maybe<ScalarsEnums['String']>;
  siteName?: Maybe<ScalarsEnums['String']>;
  siteUrl?: Maybe<ScalarsEnums['String']>;
  wordpressSiteName?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Yoast SEO Social media links
 */
export interface SEOSocial {
  __typename?: 'SEOSocial';
  facebook?: Maybe<SEOSocialFacebook>;
  instagram?: Maybe<SEOSocialInstagram>;
  linkedIn?: Maybe<SEOSocialLinkedIn>;
  mySpace?: Maybe<SEOSocialMySpace>;
  otherSocials?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  pinterest?: Maybe<SEOSocialPinterest>;
  twitter?: Maybe<SEOSocialTwitter>;
  wikipedia?: Maybe<SEOSocialWikipedia>;
  youTube?: Maybe<SEOSocialYoutube>;
}

export interface SEOSocialFacebook {
  __typename?: 'SEOSocialFacebook';
  defaultImage?: Maybe<MediaItem>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialInstagram {
  __typename?: 'SEOSocialInstagram';
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialLinkedIn {
  __typename?: 'SEOSocialLinkedIn';
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialMySpace {
  __typename?: 'SEOSocialMySpace';
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialPinterest {
  __typename?: 'SEOSocialPinterest';
  metaTag?: Maybe<ScalarsEnums['String']>;
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialTwitter {
  __typename?: 'SEOSocialTwitter';
  cardType?: Maybe<ScalarsEnums['SEOCardType']>;
  username?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialWikipedia {
  __typename?: 'SEOSocialWikipedia';
  url?: Maybe<ScalarsEnums['String']>;
}

export interface SEOSocialYoutube {
  __typename?: 'SEOSocialYoutube';
  url?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Schema types for Taxonomy
 */
export interface SEOTaxonomySchema {
  __typename?: 'SEOTaxonomySchema';
  raw?: Maybe<ScalarsEnums['String']>;
}

export interface SEOUser {
  __typename?: 'SEOUser';
  breadcrumbTitle?: Maybe<ScalarsEnums['String']>;
  canonical?: Maybe<ScalarsEnums['String']>;
  fullHead?: Maybe<ScalarsEnums['String']>;
  language?: Maybe<ScalarsEnums['String']>;
  metaDesc?: Maybe<ScalarsEnums['String']>;
  metaRobotsNofollow?: Maybe<ScalarsEnums['String']>;
  metaRobotsNoindex?: Maybe<ScalarsEnums['String']>;
  opengraphDescription?: Maybe<ScalarsEnums['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphTitle?: Maybe<ScalarsEnums['String']>;
  region?: Maybe<ScalarsEnums['String']>;
  schema?: Maybe<SEOUserSchema>;
  social?: Maybe<SEOUserSocial>;
  title?: Maybe<ScalarsEnums['String']>;
  twitterDescription?: Maybe<ScalarsEnums['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Schema types for User
 */
export interface SEOUserSchema {
  __typename?: 'SEOUserSchema';
  articleType?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  pageType?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  raw?: Maybe<ScalarsEnums['String']>;
}

export interface SEOUserSocial {
  __typename?: 'SEOUserSocial';
  facebook?: Maybe<ScalarsEnums['String']>;
  instagram?: Maybe<ScalarsEnums['String']>;
  linkedIn?: Maybe<ScalarsEnums['String']>;
  mySpace?: Maybe<ScalarsEnums['String']>;
  pinterest?: Maybe<ScalarsEnums['String']>;
  soundCloud?: Maybe<ScalarsEnums['String']>;
  twitter?: Maybe<ScalarsEnums['String']>;
  wikipedia?: Maybe<ScalarsEnums['String']>;
  youTube?: Maybe<ScalarsEnums['String']>;
}

/**
 * The Yoast SEO  webmaster fields
 */
export interface SEOWebmaster {
  __typename?: 'SEOWebmaster';
  baiduVerify?: Maybe<ScalarsEnums['String']>;
  googleVerify?: Maybe<ScalarsEnums['String']>;
  msVerify?: Maybe<ScalarsEnums['String']>;
  yandexVerify?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the sendPasswordResetEmail mutation.
 */
export interface SendPasswordResetEmailPayload {
  __typename?: 'SendPasswordResetEmailPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether the mutation completed successfully. This does NOT necessarily mean that an email was sent.
   */
  success?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The user that the password reset email was sent to
   * @deprecated This field will be removed in a future version of WPGraphQL
   */
  user?: Maybe<User>;
}

/**
 * All of the registered settings
 */
export interface Settings {
  __typename?: 'Settings';
  /**
   * Settings of the the string Settings Group
   */
  discussionSettingsDefaultCommentStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  discussionSettingsDefaultPingStatus?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsDateFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsDescription?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsEmail?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsLanguage?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the integer Settings Group
   */
  generalSettingsStartOfWeek?: Maybe<ScalarsEnums['Int']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTimeFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTimezone?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTitle?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsUrl?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPageForPosts?: Maybe<ScalarsEnums['Int']>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPageOnFront?: Maybe<ScalarsEnums['Int']>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPostsPerPage?: Maybe<ScalarsEnums['Int']>;
  /**
   * Settings of the the string Settings Group
   */
  readingSettingsShowOnFront?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the integer Settings Group
   */
  writingSettingsDefaultCategory?: Maybe<ScalarsEnums['Int']>;
  /**
   * Settings of the the string Settings Group
   */
  writingSettingsDefaultPostFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * Settings of the the boolean Settings Group
   */
  writingSettingsUseSmilies?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * The tag type
 */
export interface Tag {
  __typename?: 'Tag';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the Tag type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TagToContentNodeConnectionWhereArgs>;
  }) => Maybe<TagToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the Tag type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TagToPostConnectionWhereArgs>;
  }) => Maybe<TagToPostConnection>;
  /**
   * The Yoast SEO data of the Tags taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  tagId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Connection between the Tag type and the Taxonomy type
   */
  taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to tag Nodes
 */
export interface TagConnection {
  __typename?: 'PostToTagConnection' | 'RootQueryToTagConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected tag Nodes
   */
  edges: Array<TagConnectionEdge>;
  /**
   * A list of connected tag Nodes
   */
  nodes: Array<Tag>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TagConnectionPageInfo;
  $on: $TagConnection;
}

/**
 * Edge between a Node and a connected tag
 */
export interface TagConnectionEdge {
  __typename?: 'PostToTagConnectionEdge' | 'RootQueryToTagConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected tag Node
   */
  node: Tag;
  $on: $TagConnectionEdge;
}

/**
 * Page Info on the connected TagConnectionEdge
 */
export interface TagConnectionPageInfo {
  __typename?: 'PostToTagConnectionPageInfo' | 'RootQueryToTagConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $TagConnectionPageInfo;
}

/**
 * Connection between the Tag type and the ContentNode type
 */
export interface TagToContentNodeConnection {
  __typename?: 'TagToContentNodeConnection';
  /**
   * Edges for the TagToContentNodeConnection connection
   */
  edges: Array<TagToContentNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TagToContentNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TagToContentNodeConnectionEdge {
  __typename?: 'TagToContentNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;TagToContentNodeConnection&quot;
 */
export interface TagToContentNodeConnectionPageInfo {
  __typename?: 'TagToContentNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Tag type and the post type
 */
export interface TagToPostConnection {
  __typename?: 'TagToPostConnection';
  /**
   * Edges for the TagToPostConnection connection
   */
  edges: Array<TagToPostConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TagToPostConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TagToPostConnectionEdge {
  __typename?: 'TagToPostConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;TagToPostConnection&quot;
 */
export interface TagToPostConnectionPageInfo {
  __typename?: 'TagToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Tag type and the Taxonomy type
 */
export interface TagToTaxonomyConnectionEdge {
  __typename?: 'TagToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: Taxonomy;
}

/**
 * A taxonomy object
 */
export interface Taxonomy {
  __typename?: 'Taxonomy';
  /**
   * List of Content Types associated with the Taxonomy
   */
  connectedContentTypes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TaxonomyToContentTypeConnection>;
  /**
   * List of Term Nodes associated with the Taxonomy
   */
  connectedTerms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TaxonomyToTermNodeConnection>;
  /**
   * Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * The plural name of the post type within the GraphQL Schema.
   */
  graphqlPluralName?: Maybe<ScalarsEnums['String']>;
  /**
   * The singular name of the post type within the GraphQL Schema.
   */
  graphqlSingleName?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether the taxonomy is hierarchical
   */
  hierarchical?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The globally unique identifier of the taxonomy object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Name of the taxonomy shown in the menu. Usually plural.
   */
  label?: Maybe<ScalarsEnums['String']>;
  /**
   * The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether the taxonomy is publicly queryable
   */
  public?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Name of content type to display in REST API &quot;wp/v2&quot; namespace.
   */
  restBase?: Maybe<ScalarsEnums['String']>;
  /**
   * The REST Controller class assigned to handling this content type.
   */
  restControllerClass?: Maybe<ScalarsEnums['String']>;
  /**
   * Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud
   */
  showCloud?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to display a column for the taxonomy on its post type listing screens.
   */
  showInAdminColumn?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to add the post type to the GraphQL Schema.
   */
  showInGraphql?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to show the taxonomy in the admin menu
   */
  showInMenu?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the taxonomy is available for selection in navigation menus.
   */
  showInNavMenus?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to show the taxonomy in the quick/bulk edit panel.
   */
  showInQuickEdit?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace.
   */
  showInRest?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether to generate and allow a UI for managing terms in this taxonomy in the admin
   */
  showUi?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * Connection to Taxonomy Nodes
 */
export interface TaxonomyConnection {
  __typename?: 'ContentTypeToTaxonomyConnection' | 'RootQueryToTaxonomyConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Taxonomy Nodes
   */
  edges: Array<TaxonomyConnectionEdge>;
  /**
   * A list of connected Taxonomy Nodes
   */
  nodes: Array<Taxonomy>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TaxonomyConnectionPageInfo;
  $on: $TaxonomyConnection;
}

/**
 * Edge between a Node and a connected Taxonomy
 */
export interface TaxonomyConnectionEdge {
  __typename?:
    | 'CategoryToTaxonomyConnectionEdge'
    | 'CompanyCategoryToTaxonomyConnectionEdge'
    | 'ContentTypeToTaxonomyConnectionEdge'
    | 'PostFormatToTaxonomyConnectionEdge'
    | 'PressCategoryToTaxonomyConnectionEdge'
    | 'RootQueryToTaxonomyConnectionEdge'
    | 'TagToTaxonomyConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Taxonomy Node
   */
  node: Taxonomy;
  $on: $TaxonomyConnectionEdge;
}

/**
 * Page Info on the connected TaxonomyConnectionEdge
 */
export interface TaxonomyConnectionPageInfo {
  __typename?: 'ContentTypeToTaxonomyConnectionPageInfo' | 'RootQueryToTaxonomyConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $TaxonomyConnectionPageInfo;
}

export interface TaxonomySEO {
  __typename?: 'TaxonomySEO';
  breadcrumbs?: Maybe<Array<Maybe<SEOPostTypeBreadcrumbs>>>;
  canonical?: Maybe<ScalarsEnums['String']>;
  cornerstone?: Maybe<ScalarsEnums['Boolean']>;
  focuskw?: Maybe<ScalarsEnums['String']>;
  fullHead?: Maybe<ScalarsEnums['String']>;
  metaDesc?: Maybe<ScalarsEnums['String']>;
  metaKeywords?: Maybe<ScalarsEnums['String']>;
  metaRobotsNofollow?: Maybe<ScalarsEnums['String']>;
  metaRobotsNoindex?: Maybe<ScalarsEnums['String']>;
  opengraphAuthor?: Maybe<ScalarsEnums['String']>;
  opengraphDescription?: Maybe<ScalarsEnums['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<ScalarsEnums['String']>;
  opengraphPublishedTime?: Maybe<ScalarsEnums['String']>;
  opengraphPublisher?: Maybe<ScalarsEnums['String']>;
  opengraphSiteName?: Maybe<ScalarsEnums['String']>;
  opengraphTitle?: Maybe<ScalarsEnums['String']>;
  opengraphType?: Maybe<ScalarsEnums['String']>;
  opengraphUrl?: Maybe<ScalarsEnums['String']>;
  schema?: Maybe<SEOTaxonomySchema>;
  title?: Maybe<ScalarsEnums['String']>;
  twitterDescription?: Maybe<ScalarsEnums['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Taxonomy type and the ContentType type
 */
export interface TaxonomyToContentTypeConnection {
  __typename?: 'TaxonomyToContentTypeConnection';
  /**
   * Edges for the TaxonomyToContentTypeConnection connection
   */
  edges: Array<TaxonomyToContentTypeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentType>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TaxonomyToContentTypeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TaxonomyToContentTypeConnectionEdge {
  __typename?: 'TaxonomyToContentTypeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentType;
}

/**
 * Page Info on the &quot;TaxonomyToContentTypeConnection&quot;
 */
export interface TaxonomyToContentTypeConnectionPageInfo {
  __typename?: 'TaxonomyToContentTypeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the Taxonomy type and the TermNode type
 */
export interface TaxonomyToTermNodeConnection {
  __typename?: 'TaxonomyToTermNodeConnection';
  /**
   * Edges for the TaxonomyToTermNodeConnection connection
   */
  edges: Array<TaxonomyToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TaxonomyToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TaxonomyToTermNodeConnectionEdge {
  __typename?: 'TaxonomyToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;TaxonomyToTermNodeConnection&quot;
 */
export interface TaxonomyToTermNodeConnectionPageInfo {
  __typename?: 'TaxonomyToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * The TeamMember type
 */
export interface TeamMember {
  __typename?: 'TeamMember';
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums['String'];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums['String']>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums['String']>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the team object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums['String']>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums['String']>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums['ID']>;
  /**
   * Connection between the TeamMember type and the PressCategory type
   */
  pressCategories: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TeamMemberToPressCategoryConnectionWhereArgs>;
  }) => Maybe<TeamMemberToPressCategoryConnection>;
  /**
   * Connection between the TeamMember type and the TeamMember type
   */
  preview?: Maybe<TeamMemberToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums['ID']>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums['String']>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Team&quot; was set to Show in GraphQL.
   */
  team?: Maybe<TeamMember_Team>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Team Investments&quot; was set to Show in GraphQL.
   */
  teamInvestments?: Maybe<TeamMember_Teaminvestments>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  teamMemberId: ScalarsEnums['Int'];
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the TeamMember type and the TermNode type
   */
  terms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TeamMemberToTermNodeConnectionWhereArgs>;
  }) => Maybe<TeamMemberToTermNodeConnection>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums['String']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to TeamMember Nodes
 */
export interface TeamMemberConnection {
  __typename?: 'PressCategoryToTeamMemberConnection' | 'RootQueryToTeamMemberConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected TeamMember Nodes
   */
  edges: Array<TeamMemberConnectionEdge>;
  /**
   * A list of connected TeamMember Nodes
   */
  nodes: Array<TeamMember>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TeamMemberConnectionPageInfo;
  $on: $TeamMemberConnection;
}

/**
 * Edge between a Node and a connected TeamMember
 */
export interface TeamMemberConnectionEdge {
  __typename?:
    | 'PressCategoryToTeamMemberConnectionEdge'
    | 'RootQueryToTeamMemberConnectionEdge'
    | 'TeamMemberToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected TeamMember Node
   */
  node: TeamMember;
  $on: $TeamMemberConnectionEdge;
}

/**
 * Page Info on the connected TeamMemberConnectionEdge
 */
export interface TeamMemberConnectionPageInfo {
  __typename?:
    | 'PressCategoryToTeamMemberConnectionPageInfo'
    | 'RootQueryToTeamMemberConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $TeamMemberConnectionPageInfo;
}

/**
 * Connection between the TeamMember type and the PressCategory type
 */
export interface TeamMemberToPressCategoryConnection {
  __typename?: 'TeamMemberToPressCategoryConnection';
  /**
   * Edges for the TeamMemberToPressCategoryConnection connection
   */
  edges: Array<TeamMemberToPressCategoryConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<PressCategory>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TeamMemberToPressCategoryConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TeamMemberToPressCategoryConnectionEdge {
  __typename?: 'TeamMemberToPressCategoryConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The Yoast SEO Primary press_categories
   */
  isPrimary?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The item at the end of the edge
   */
  node: PressCategory;
}

/**
 * Page Info on the &quot;TeamMemberToPressCategoryConnection&quot;
 */
export interface TeamMemberToPressCategoryConnectionPageInfo {
  __typename?: 'TeamMemberToPressCategoryConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the TeamMember type and the TeamMember type
 */
export interface TeamMemberToPreviewConnectionEdge {
  __typename?: 'TeamMemberToPreviewConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The node of the connection, without the edges
   */
  node: TeamMember;
}

/**
 * Connection between the TeamMember type and the TermNode type
 */
export interface TeamMemberToTermNodeConnection {
  __typename?: 'TeamMemberToTermNodeConnection';
  /**
   * Edges for the TeamMemberToTermNodeConnection connection
   */
  edges: Array<TeamMemberToTermNodeConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TeamMemberToTermNodeConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TeamMemberToTermNodeConnectionEdge {
  __typename?: 'TeamMemberToTermNodeConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: TermNode;
}

/**
 * Page Info on the &quot;TeamMemberToTermNodeConnection&quot;
 */
export interface TeamMemberToTermNodeConnectionPageInfo {
  __typename?: 'TeamMemberToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface TeamMember_Team {
  __typename?: 'TeamMember_Team';
  bio?: Maybe<ScalarsEnums['String']>;
  /**
   * Image Width: 805px, Height: 454px
   */
  bioImage?: Maybe<MediaItem>;
  boardPositions?: Maybe<Array<Maybe<TeamMember_Team_boardPositions>>>;
  /**
   * ex: info@website.com
   */
  email?: Maybe<ScalarsEnums['String']>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  /**
   * Image Size: 336 x 336
   */
  gridImage?: Maybe<MediaItem>;
  investments?: Maybe<Array<Maybe<TeamMember_Team_Investments>>>;
  /**
   * Full path URL (ex. http://google.com)
   */
  linkedinUrl?: Maybe<ScalarsEnums['String']>;
  teamType?: Maybe<ScalarsEnums['String']>;
  title?: Maybe<ScalarsEnums['String']>;
  /**
   * Full path URL (ex. http://google.com)
   */
  twitterUrl?: Maybe<ScalarsEnums['String']>;
}

export interface TeamMember_Team_Investments {
  __typename?: 'Company';
  $on: $TeamMember_Team_Investments;
}

/**
 * Field Group
 */
export interface TeamMember_Team_boardPositions {
  __typename?: 'TeamMember_Team_boardPositions';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  text?: Maybe<ScalarsEnums['String']>;
}

/**
 * Field Group
 */
export interface TeamMember_Teaminvestments {
  __typename?: 'TeamMember_Teaminvestments';
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums['String']>;
  investments?: Maybe<Array<Maybe<TeamMember_Teaminvestments_Investments>>>;
}

export interface TeamMember_Teaminvestments_Investments {
  __typename?: 'Company';
  $on: $TeamMember_Teaminvestments_Investments;
}

/**
 * Terms are nodes within a Taxonomy, used to group and relate other nodes.
 */
export interface TermNode {
  __typename?: 'Category' | 'CompanyCategory' | 'PostFormat' | 'PressCategory' | 'Tag';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums['Int']>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums['String']>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums['Int']>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $TermNode;
}

/**
 * Connection to TermNode Nodes
 */
export interface TermNodeConnection {
  __typename?:
    | 'CompanyToTermNodeConnection'
    | 'PostToTermNodeConnection'
    | 'PressToTermNodeConnection'
    | 'RootQueryToTermNodeConnection'
    | 'TaxonomyToTermNodeConnection'
    | 'TeamMemberToTermNodeConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected TermNode Nodes
   */
  edges: Array<TermNodeConnectionEdge>;
  /**
   * A list of connected TermNode Nodes
   */
  nodes: Array<TermNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TermNodeConnectionPageInfo;
  $on: $TermNodeConnection;
}

/**
 * Edge between a Node and a connected TermNode
 */
export interface TermNodeConnectionEdge {
  __typename?:
    | 'CompanyToTermNodeConnectionEdge'
    | 'PostToTermNodeConnectionEdge'
    | 'PressToTermNodeConnectionEdge'
    | 'RootQueryToTermNodeConnectionEdge'
    | 'TaxonomyToTermNodeConnectionEdge'
    | 'TeamMemberToTermNodeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected TermNode Node
   */
  node: TermNode;
  $on: $TermNodeConnectionEdge;
}

/**
 * Page Info on the connected TermNodeConnectionEdge
 */
export interface TermNodeConnectionPageInfo {
  __typename?:
    | 'CompanyToTermNodeConnectionPageInfo'
    | 'PostToTermNodeConnectionPageInfo'
    | 'PressToTermNodeConnectionPageInfo'
    | 'RootQueryToTermNodeConnectionPageInfo'
    | 'TaxonomyToTermNodeConnectionPageInfo'
    | 'TeamMemberToTermNodeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $TermNodeConnectionPageInfo;
}

/**
 * Connection between the TermNode type and the EnqueuedScript type
 */
export interface TermNodeToEnqueuedScriptConnection {
  __typename?: 'TermNodeToEnqueuedScriptConnection';
  /**
   * Edges for the TermNodeToEnqueuedScriptConnection connection
   */
  edges: Array<TermNodeToEnqueuedScriptConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedScript>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TermNodeToEnqueuedScriptConnectionEdge {
  __typename?: 'TermNodeToEnqueuedScriptConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedScript;
}

/**
 * Page Info on the &quot;TermNodeToEnqueuedScriptConnection&quot;
 */
export interface TermNodeToEnqueuedScriptConnectionPageInfo {
  __typename?: 'TermNodeToEnqueuedScriptConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the TermNode type and the EnqueuedStylesheet type
 */
export interface TermNodeToEnqueuedStylesheetConnection {
  __typename?: 'TermNodeToEnqueuedStylesheetConnection';
  /**
   * Edges for the TermNodeToEnqueuedStylesheetConnection connection
   */
  edges: Array<TermNodeToEnqueuedStylesheetConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedStylesheet>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface TermNodeToEnqueuedStylesheetConnectionEdge {
  __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedStylesheet;
}

/**
 * Page Info on the &quot;TermNodeToEnqueuedStylesheetConnection&quot;
 */
export interface TermNodeToEnqueuedStylesheetConnectionPageInfo {
  __typename?: 'TermNodeToEnqueuedStylesheetConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * A theme object
 */
export interface Theme {
  __typename?: 'Theme';
  /**
   * Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ).
   */
  author?: Maybe<ScalarsEnums['String']>;
  /**
   * URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ).
   */
  authorUri?: Maybe<ScalarsEnums['String']>;
  /**
   * The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ).
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier of the theme object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ).
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot().
   */
  screenshot?: Maybe<ScalarsEnums['String']>;
  /**
   * The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet().
   */
  slug?: Maybe<ScalarsEnums['String']>;
  /**
   * URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ).
   */
  tags?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ).
   */
  themeUri?: Maybe<ScalarsEnums['String']>;
  /**
   * The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ).
   */
  version?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to Theme Nodes
 */
export interface ThemeConnection {
  __typename?: 'RootQueryToThemeConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected Theme Nodes
   */
  edges: Array<ThemeConnectionEdge>;
  /**
   * A list of connected Theme Nodes
   */
  nodes: Array<Theme>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: ThemeConnectionPageInfo;
  $on: $ThemeConnection;
}

/**
 * Edge between a Node and a connected Theme
 */
export interface ThemeConnectionEdge {
  __typename?: 'RootQueryToThemeConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected Theme Node
   */
  node: Theme;
  $on: $ThemeConnectionEdge;
}

/**
 * Page Info on the connected ThemeConnectionEdge
 */
export interface ThemeConnectionPageInfo {
  __typename?: 'RootQueryToThemeConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $ThemeConnectionPageInfo;
}

/**
 * Any node that has a URI
 */
export interface UniformResourceIdentifiable {
  __typename?:
    | 'BlockEditorPreview'
    | 'Category'
    | 'Company'
    | 'CompanyCategory'
    | 'ContentType'
    | 'GraphqlDocument'
    | 'MediaItem'
    | 'Page'
    | 'Post'
    | 'PostFormat'
    | 'Press'
    | 'PressCategory'
    | 'ReusableBlock'
    | 'Tag'
    | 'TeamMember'
    | 'User';
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  $on: $UniformResourceIdentifiable;
}

/**
 * The payload for the updateBlockEditorPreview mutation.
 */
export interface UpdateBlockEditorPreviewPayload {
  __typename?: 'UpdateBlockEditorPreviewPayload';
  /**
   * The Post object mutation type.
   */
  blockEditorPreview?: Maybe<BlockEditorPreview>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the updateCategory mutation.
 */
export interface UpdateCategoryPayload {
  __typename?: 'UpdateCategoryPayload';
  /**
   * The created category
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
}

/**
 * The payload for the updateComment mutation.
 */
export interface UpdateCommentPayload {
  __typename?: 'UpdateCommentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The comment that was created
   */
  comment?: Maybe<Comment>;
  /**
   * Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache
   */
  success?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * The payload for the updateCompanyCategory mutation.
 */
export interface UpdateCompanyCategoryPayload {
  __typename?: 'UpdateCompanyCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created company_category
   */
  companyCategory?: Maybe<CompanyCategory>;
}

/**
 * The payload for the updateCompany mutation.
 */
export interface UpdateCompanyPayload {
  __typename?: 'UpdateCompanyPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  company?: Maybe<Company>;
}

/**
 * The payload for the updateGraphqlDocument mutation.
 */
export interface UpdateGraphqlDocumentPayload {
  __typename?: 'UpdateGraphqlDocumentPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  graphqlDocument?: Maybe<GraphqlDocument>;
}

/**
 * The payload for the updateMediaItem mutation.
 */
export interface UpdateMediaItemPayload {
  __typename?: 'UpdateMediaItemPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The MediaItem object mutation type.
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the updatePage mutation.
 */
export interface UpdatePagePayload {
  __typename?: 'UpdatePagePayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the updatePostFormat mutation.
 */
export interface UpdatePostFormatPayload {
  __typename?: 'UpdatePostFormatPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created post_format
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the updatePost mutation.
 */
export interface UpdatePostPayload {
  __typename?: 'UpdatePostPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the updatePressCategory mutation.
 */
export interface UpdatePressCategoryPayload {
  __typename?: 'UpdatePressCategoryPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created press_categories
   */
  pressCategory?: Maybe<PressCategory>;
}

/**
 * The payload for the updatePress mutation.
 */
export interface UpdatePressPayload {
  __typename?: 'UpdatePressPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  press?: Maybe<Press>;
}

/**
 * The payload for the updateReusableBlock mutation.
 */
export interface UpdateReusableBlockPayload {
  __typename?: 'UpdateReusableBlockPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the updateSettings mutation.
 */
export interface UpdateSettingsPayload {
  __typename?: 'UpdateSettingsPayload';
  /**
   * Update all settings.
   */
  allSettings?: Maybe<Settings>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * Update the DiscussionSettings setting.
   */
  discussionSettings?: Maybe<DiscussionSettings>;
  /**
   * Update the GeneralSettings setting.
   */
  generalSettings?: Maybe<GeneralSettings>;
  /**
   * Update the ReadingSettings setting.
   */
  readingSettings?: Maybe<ReadingSettings>;
  /**
   * Update the WritingSettings setting.
   */
  writingSettings?: Maybe<WritingSettings>;
}

/**
 * The payload for the updateTag mutation.
 */
export interface UpdateTagPayload {
  __typename?: 'UpdateTagPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The created post_tag
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the updateTeamMember mutation.
 */
export interface UpdateTeamMemberPayload {
  __typename?: 'UpdateTeamMemberPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The Post object mutation type.
   */
  teamMember?: Maybe<TeamMember>;
}

/**
 * The payload for the updateUser mutation.
 */
export interface UpdateUserPayload {
  __typename?: 'UpdateUserPayload';
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums['String']>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * A User object
 */
export interface User {
  __typename?: 'User';
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar: (args?: {
    /**
     * Whether to always show the default image, never the Gravatar. Default false
     */
    forceDefault?: Maybe<Scalars['Boolean']>;
    /**
     * The rating level of the avatar.
     */
    rating?: Maybe<AvatarRatingEnum>;
    /**
     * The size attribute of the avatar field can be used to fetch avatars of different sizes. The value corresponds to the dimension in pixels to fetch. The default is 96 pixels.
     * @defaultValue `96`
     */
    size?: Maybe<Scalars['Int']>;
  }) => Maybe<Avatar>;
  /**
   * Connection between the User type and the BlockEditorPreview type
   */
  blockEditorPreviews: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToBlockEditorPreviewConnectionWhereArgs>;
  }) => Maybe<UserToBlockEditorPreviewConnection>;
  /**
   * User metadata option name. Usually it will be &quot;wp_capabilities&quot;.
   */
  capKey?: Maybe<ScalarsEnums['String']>;
  /**
   * A list of capabilities (permissions) granted to the user
   */
  capabilities?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * Connection between the User type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToCommentConnectionWhereArgs>;
  }) => Maybe<UserToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums['Int'];
  /**
   * Description of the user.
   */
  description?: Maybe<ScalarsEnums['String']>;
  /**
   * Email address of the user. This is equivalent to the WP_User-&gt;user_email property.
   */
  email?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the User type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<UserToEnqueuedScriptConnection>;
  /**
   * Connection between the User type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<UserToEnqueuedStylesheetConnection>;
  /**
   * A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps.
   */
  extraCapabilities?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * First name of the user. This is equivalent to the WP_User-&gt;user_first_name property.
   */
  firstName?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier for the user object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums['Boolean'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums['Boolean'];
  /**
   * Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property.
   */
  lastName?: Maybe<ScalarsEnums['String']>;
  /**
   * The preferred language locale set for the user. Value derived from get_user_locale().
   */
  locale?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the User type and the mediaItem type
   */
  mediaItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToMediaItemConnectionWhereArgs>;
  }) => Maybe<UserToMediaItemConnection>;
  /**
   * Display name of the user. This is equivalent to the WP_User-&gt;display_name property.
   */
  name?: Maybe<ScalarsEnums['String']>;
  /**
   * The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename
   */
  nicename?: Maybe<ScalarsEnums['String']>;
  /**
   * Nickname of the user.
   */
  nickname?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the User type and the page type
   */
  pages: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToPageConnectionWhereArgs>;
  }) => Maybe<UserToPageConnection>;
  /**
   * Connection between the User type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToPostConnectionWhereArgs>;
  }) => Maybe<UserToPostConnection>;
  /**
   * The date the user registered or was created. The field follows a full ISO8601 date string format.
   */
  registeredDate?: Maybe<ScalarsEnums['String']>;
  /**
   * Connection between the User and Revisions authored by the user
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToRevisionsConnectionWhereArgs>;
  }) => Maybe<UserToRevisionsConnection>;
  /**
   * Connection between the User type and the UserRole type
   */
  roles: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars['String']>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars['String']>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars['Int']>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<UserToUserRoleConnection>;
  /**
   * The Yoast SEO data of a user
   */
  seo?: Maybe<SEOUser>;
  /**
   * Whether the Toolbar should be displayed when the user is viewing the site.
   */
  shouldShowAdminToolbar?: Maybe<ScalarsEnums['Boolean']>;
  shouldShowFaustToolbar?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The slug for the user. This field is equivalent to WP_User-&gt;user_nicename
   */
  slug?: Maybe<ScalarsEnums['String']>;
  templates?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums['String']>;
  /**
   * A website url that is associated with the user.
   */
  url?: Maybe<ScalarsEnums['String']>;
  /**
   * The Id of the user. Equivalent to WP_User-&gt;ID
   * @deprecated Deprecated in favor of the databaseId field
   */
  userId?: Maybe<ScalarsEnums['Int']>;
  /**
   * Username for the user. This field is equivalent to WP_User-&gt;user_login.
   */
  username?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to User Nodes
 */
export interface UserConnection {
  __typename?: 'RootQueryToUserConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected User Nodes
   */
  edges: Array<UserConnectionEdge>;
  /**
   * A list of connected User Nodes
   */
  nodes: Array<User>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserConnectionPageInfo;
  $on: $UserConnection;
}

/**
 * Edge between a Node and a connected User
 */
export interface UserConnectionEdge {
  __typename?:
    | 'ContentNodeToEditLastConnectionEdge'
    | 'ContentNodeToEditLockConnectionEdge'
    | 'NodeWithAuthorToUserConnectionEdge'
    | 'RootQueryToUserConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected User Node
   */
  node: User;
  $on: $UserConnectionEdge;
}

/**
 * Page Info on the connected UserConnectionEdge
 */
export interface UserConnectionPageInfo {
  __typename?: 'RootQueryToUserConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $UserConnectionPageInfo;
}

/**
 * A user role object
 */
export interface UserRole {
  __typename?: 'UserRole';
  /**
   * The capabilities that belong to this role
   */
  capabilities?: Maybe<Array<Maybe<ScalarsEnums['String']>>>;
  /**
   * The display name of the role
   */
  displayName?: Maybe<ScalarsEnums['String']>;
  /**
   * The globally unique identifier for the user role object.
   */
  id: ScalarsEnums['ID'];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums['Boolean']>;
  /**
   * The registered name of the role
   */
  name?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection to UserRole Nodes
 */
export interface UserRoleConnection {
  __typename?: 'RootQueryToUserRoleConnection' | 'UserToUserRoleConnection';
  /**
   * A list of edges (relational context) between RootQuery and connected UserRole Nodes
   */
  edges: Array<UserRoleConnectionEdge>;
  /**
   * A list of connected UserRole Nodes
   */
  nodes: Array<UserRole>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserRoleConnectionPageInfo;
  $on: $UserRoleConnection;
}

/**
 * Edge between a Node and a connected UserRole
 */
export interface UserRoleConnectionEdge {
  __typename?: 'RootQueryToUserRoleConnectionEdge' | 'UserToUserRoleConnectionEdge';
  /**
   * Opaque reference to the nodes position in the connection. Value can be used with pagination args.
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The connected UserRole Node
   */
  node: UserRole;
  $on: $UserRoleConnectionEdge;
}

/**
 * Page Info on the connected UserRoleConnectionEdge
 */
export interface UserRoleConnectionPageInfo {
  __typename?: 'RootQueryToUserRoleConnectionPageInfo' | 'UserToUserRoleConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $UserRoleConnectionPageInfo;
}

/**
 * Connection between the User type and the BlockEditorPreview type
 */
export interface UserToBlockEditorPreviewConnection {
  __typename?: 'UserToBlockEditorPreviewConnection';
  /**
   * Edges for the UserToBlockEditorPreviewConnection connection
   */
  edges: Array<UserToBlockEditorPreviewConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<BlockEditorPreview>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToBlockEditorPreviewConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToBlockEditorPreviewConnectionEdge {
  __typename?: 'UserToBlockEditorPreviewConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: BlockEditorPreview;
}

/**
 * Page Info on the &quot;UserToBlockEditorPreviewConnection&quot;
 */
export interface UserToBlockEditorPreviewConnectionPageInfo {
  __typename?: 'UserToBlockEditorPreviewConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the Comment type
 */
export interface UserToCommentConnection {
  __typename?: 'UserToCommentConnection';
  /**
   * Edges for the UserToCommentConnection connection
   */
  edges: Array<UserToCommentConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Comment>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToCommentConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToCommentConnectionEdge {
  __typename?: 'UserToCommentConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Comment;
}

/**
 * Page Info on the &quot;UserToCommentConnection&quot;
 */
export interface UserToCommentConnectionPageInfo {
  __typename?: 'UserToCommentConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the EnqueuedScript type
 */
export interface UserToEnqueuedScriptConnection {
  __typename?: 'UserToEnqueuedScriptConnection';
  /**
   * Edges for the UserToEnqueuedScriptConnection connection
   */
  edges: Array<UserToEnqueuedScriptConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedScript>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToEnqueuedScriptConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToEnqueuedScriptConnectionEdge {
  __typename?: 'UserToEnqueuedScriptConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedScript;
}

/**
 * Page Info on the &quot;UserToEnqueuedScriptConnection&quot;
 */
export interface UserToEnqueuedScriptConnectionPageInfo {
  __typename?: 'UserToEnqueuedScriptConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the EnqueuedStylesheet type
 */
export interface UserToEnqueuedStylesheetConnection {
  __typename?: 'UserToEnqueuedStylesheetConnection';
  /**
   * Edges for the UserToEnqueuedStylesheetConnection connection
   */
  edges: Array<UserToEnqueuedStylesheetConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<EnqueuedStylesheet>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToEnqueuedStylesheetConnectionEdge {
  __typename?: 'UserToEnqueuedStylesheetConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: EnqueuedStylesheet;
}

/**
 * Page Info on the &quot;UserToEnqueuedStylesheetConnection&quot;
 */
export interface UserToEnqueuedStylesheetConnectionPageInfo {
  __typename?: 'UserToEnqueuedStylesheetConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the mediaItem type
 */
export interface UserToMediaItemConnection {
  __typename?: 'UserToMediaItemConnection';
  /**
   * Edges for the UserToMediaItemConnection connection
   */
  edges: Array<UserToMediaItemConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<MediaItem>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToMediaItemConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToMediaItemConnectionEdge {
  __typename?: 'UserToMediaItemConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: MediaItem;
}

/**
 * Page Info on the &quot;UserToMediaItemConnection&quot;
 */
export interface UserToMediaItemConnectionPageInfo {
  __typename?: 'UserToMediaItemConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the page type
 */
export interface UserToPageConnection {
  __typename?: 'UserToPageConnection';
  /**
   * Edges for the UserToPageConnection connection
   */
  edges: Array<UserToPageConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Page>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToPageConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToPageConnectionEdge {
  __typename?: 'UserToPageConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Page;
}

/**
 * Page Info on the &quot;UserToPageConnection&quot;
 */
export interface UserToPageConnectionPageInfo {
  __typename?: 'UserToPageConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the post type
 */
export interface UserToPostConnection {
  __typename?: 'UserToPostConnection';
  /**
   * Edges for the UserToPostConnection connection
   */
  edges: Array<UserToPostConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<Post>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToPostConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToPostConnectionEdge {
  __typename?: 'UserToPostConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: Post;
}

/**
 * Page Info on the &quot;UserToPostConnection&quot;
 */
export interface UserToPostConnectionPageInfo {
  __typename?: 'UserToPostConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the ContentNode type
 */
export interface UserToRevisionsConnection {
  __typename?: 'UserToRevisionsConnection';
  /**
   * Edges for the UserToRevisionsConnection connection
   */
  edges: Array<UserToRevisionsConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<ContentNode>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToRevisionsConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToRevisionsConnectionEdge {
  __typename?: 'UserToRevisionsConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: ContentNode;
}

/**
 * Page Info on the &quot;UserToRevisionsConnection&quot;
 */
export interface UserToRevisionsConnectionPageInfo {
  __typename?: 'UserToRevisionsConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Connection between the User type and the UserRole type
 */
export interface UserToUserRoleConnection {
  __typename?: 'UserToUserRoleConnection';
  /**
   * Edges for the UserToUserRoleConnection connection
   */
  edges: Array<UserToUserRoleConnectionEdge>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes: Array<UserRole>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo: UserToUserRoleConnectionPageInfo;
}

/**
 * An edge in a connection
 */
export interface UserToUserRoleConnectionEdge {
  __typename?: 'UserToUserRoleConnectionEdge';
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums['String']>;
  /**
   * The item at the end of the edge
   */
  node: UserRole;
}

/**
 * Page Info on the &quot;UserToUserRoleConnection&quot;
 */
export interface UserToUserRoleConnectionPageInfo {
  __typename?: 'UserToUserRoleConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
}

/**
 * Information about pagination in a connection.
 */
export interface WPPageInfo {
  __typename?:
    | 'CategoryToAncestorsCategoryConnectionPageInfo'
    | 'CategoryToCategoryConnectionPageInfo'
    | 'CategoryToContentNodeConnectionPageInfo'
    | 'CategoryToPostConnectionPageInfo'
    | 'CommentToCommentConnectionPageInfo'
    | 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo'
    | 'CompanyCategoryToCompanyCategoryConnectionPageInfo'
    | 'CompanyCategoryToCompanyConnectionPageInfo'
    | 'CompanyCategoryToContentNodeConnectionPageInfo'
    | 'CompanyToCompanyCategoryConnectionPageInfo'
    | 'CompanyToTermNodeConnectionPageInfo'
    | 'ContentNodeToEnqueuedScriptConnectionPageInfo'
    | 'ContentNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'ContentTypeToContentNodeConnectionPageInfo'
    | 'ContentTypeToTaxonomyConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo'
    | 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo'
    | 'MediaItemToCommentConnectionPageInfo'
    | 'MenuItemToMenuItemConnectionPageInfo'
    | 'MenuToMenuItemConnectionPageInfo'
    | 'PageToCommentConnectionPageInfo'
    | 'PageToRevisionConnectionPageInfo'
    | 'PostFormatToContentNodeConnectionPageInfo'
    | 'PostFormatToPostConnectionPageInfo'
    | 'PostToCategoryConnectionPageInfo'
    | 'PostToCommentConnectionPageInfo'
    | 'PostToPostFormatConnectionPageInfo'
    | 'PostToRevisionConnectionPageInfo'
    | 'PostToTagConnectionPageInfo'
    | 'PostToTermNodeConnectionPageInfo'
    | 'PressCategoryToAncestorsPressCategoryConnectionPageInfo'
    | 'PressCategoryToContentNodeConnectionPageInfo'
    | 'PressCategoryToPressCategoryConnectionPageInfo'
    | 'PressCategoryToPressConnectionPageInfo'
    | 'PressCategoryToTeamMemberConnectionPageInfo'
    | 'PressToPressCategoryConnectionPageInfo'
    | 'PressToTermNodeConnectionPageInfo'
    | 'ReusableBlockToRevisionConnectionPageInfo'
    | 'RootQueryToBlockEditorPreviewConnectionPageInfo'
    | 'RootQueryToCategoryConnectionPageInfo'
    | 'RootQueryToCommentConnectionPageInfo'
    | 'RootQueryToCompanyCategoryConnectionPageInfo'
    | 'RootQueryToCompanyConnectionPageInfo'
    | 'RootQueryToContentNodeConnectionPageInfo'
    | 'RootQueryToContentTypeConnectionPageInfo'
    | 'RootQueryToEnqueuedScriptConnectionPageInfo'
    | 'RootQueryToEnqueuedStylesheetConnectionPageInfo'
    | 'RootQueryToGraphqlDocumentConnectionPageInfo'
    | 'RootQueryToMediaItemConnectionPageInfo'
    | 'RootQueryToMenuConnectionPageInfo'
    | 'RootQueryToMenuItemConnectionPageInfo'
    | 'RootQueryToPageConnectionPageInfo'
    | 'RootQueryToPluginConnectionPageInfo'
    | 'RootQueryToPostConnectionPageInfo'
    | 'RootQueryToPostFormatConnectionPageInfo'
    | 'RootQueryToPressCategoryConnectionPageInfo'
    | 'RootQueryToPressConnectionPageInfo'
    | 'RootQueryToReusableBlockConnectionPageInfo'
    | 'RootQueryToRevisionsConnectionPageInfo'
    | 'RootQueryToTagConnectionPageInfo'
    | 'RootQueryToTaxonomyConnectionPageInfo'
    | 'RootQueryToTeamMemberConnectionPageInfo'
    | 'RootQueryToTermNodeConnectionPageInfo'
    | 'RootQueryToThemeConnectionPageInfo'
    | 'RootQueryToUserConnectionPageInfo'
    | 'RootQueryToUserRoleConnectionPageInfo'
    | 'TagToContentNodeConnectionPageInfo'
    | 'TagToPostConnectionPageInfo'
    | 'TaxonomyToContentTypeConnectionPageInfo'
    | 'TaxonomyToTermNodeConnectionPageInfo'
    | 'TeamMemberToPressCategoryConnectionPageInfo'
    | 'TeamMemberToTermNodeConnectionPageInfo'
    | 'TermNodeToEnqueuedScriptConnectionPageInfo'
    | 'TermNodeToEnqueuedStylesheetConnectionPageInfo'
    | 'UserToBlockEditorPreviewConnectionPageInfo'
    | 'UserToCommentConnectionPageInfo'
    | 'UserToEnqueuedScriptConnectionPageInfo'
    | 'UserToEnqueuedStylesheetConnectionPageInfo'
    | 'UserToMediaItemConnectionPageInfo'
    | 'UserToPageConnectionPageInfo'
    | 'UserToPostConnectionPageInfo'
    | 'UserToRevisionsConnectionPageInfo'
    | 'UserToUserRoleConnectionPageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums['String']>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums['Boolean'];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums['Boolean'];
  /**
   * Get information about the offset pagination state in the current connection
   */
  offsetPagination?: Maybe<OffsetPaginationPageInfo>;
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums['String']>;
  $on: $WPPageInfo;
}

/**
 * The writing setting type
 */
export interface WritingSettings {
  __typename?: 'WritingSettings';
  /**
   * Default post category.
   */
  defaultCategory?: Maybe<ScalarsEnums['Int']>;
  /**
   * Default post format.
   */
  defaultPostFormat?: Maybe<ScalarsEnums['String']>;
  /**
   * Convert emoticons like :-) and :-P to graphics on display.
   */
  useSmilies?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * yoast/faq-block block
 */
export interface YoastFaqBlock {
  __typename?: 'YoastFaqBlock';
  attributes?: Maybe<YoastFaqBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface YoastFaqBlockAttributes {
  __typename?: 'YoastFaqBlockAttributes';
  additionalListCssClasses?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  questions?: Maybe<ScalarsEnums['BlockAttributesArray']>;
}

export interface YoastFaqBlockAttributesUnion {
  __typename?: 'YoastFaqBlockAttributes' | 'YoastFaqBlockDeprecatedV1Attributes';
  $on: $YoastFaqBlockAttributesUnion;
}

export interface YoastFaqBlockDeprecatedV1Attributes {
  __typename?: 'YoastFaqBlockDeprecatedV1Attributes';
  additionalListCssClasses?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  questions?: Maybe<ScalarsEnums['BlockAttributesArray']>;
}

/**
 * yoast/how-to-block block
 */
export interface YoastHowToBlock {
  __typename?: 'YoastHowToBlock';
  attributes?: Maybe<YoastHowToBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface YoastHowToBlockAttributes {
  __typename?: 'YoastHowToBlockAttributes';
  additionalListCssClasses?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  days?: Maybe<ScalarsEnums['String']>;
  defaultDurationText?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  durationText?: Maybe<ScalarsEnums['String']>;
  hasDuration?: Maybe<ScalarsEnums['Boolean']>;
  hours?: Maybe<ScalarsEnums['String']>;
  jsonDescription?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minutes?: Maybe<ScalarsEnums['String']>;
  steps?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  unorderedList?: Maybe<ScalarsEnums['Boolean']>;
}

export interface YoastHowToBlockAttributesUnion {
  __typename?:
    | 'YoastHowToBlockAttributes'
    | 'YoastHowToBlockDeprecatedV1Attributes'
    | 'YoastHowToBlockDeprecatedV2Attributes';
  $on: $YoastHowToBlockAttributesUnion;
}

export interface YoastHowToBlockDeprecatedV1Attributes {
  __typename?: 'YoastHowToBlockDeprecatedV1Attributes';
  additionalListCssClasses?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  days?: Maybe<ScalarsEnums['String']>;
  defaultDurationText?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  durationText?: Maybe<ScalarsEnums['String']>;
  hasDuration?: Maybe<ScalarsEnums['Boolean']>;
  hours?: Maybe<ScalarsEnums['String']>;
  jsonDescription?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minutes?: Maybe<ScalarsEnums['String']>;
  steps?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  unorderedList?: Maybe<ScalarsEnums['Boolean']>;
}

export interface YoastHowToBlockDeprecatedV2Attributes {
  __typename?: 'YoastHowToBlockDeprecatedV2Attributes';
  additionalListCssClasses?: Maybe<ScalarsEnums['String']>;
  className?: Maybe<ScalarsEnums['String']>;
  days?: Maybe<ScalarsEnums['String']>;
  defaultDurationText?: Maybe<ScalarsEnums['String']>;
  description?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  durationText?: Maybe<ScalarsEnums['String']>;
  hasDuration?: Maybe<ScalarsEnums['Boolean']>;
  hours?: Maybe<ScalarsEnums['String']>;
  jsonDescription?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  minutes?: Maybe<ScalarsEnums['String']>;
  steps?: Maybe<ScalarsEnums['BlockAttributesArray']>;
  unorderedList?: Maybe<ScalarsEnums['Boolean']>;
}

/**
 * yoast-seo/breadcrumbs block
 */
export interface YoastSeoBreadcrumbsBlock {
  __typename?: 'YoastSeoBreadcrumbsBlock';
  attributes?: Maybe<YoastSeoBreadcrumbsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums['String']>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums['Boolean'];
  /**
   * Name of the block.
   */
  name: ScalarsEnums['String'];
  order: ScalarsEnums['Int'];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums['String']>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums['Int'];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums['String']>;
}

export interface YoastSeoBreadcrumbsBlockAttributes {
  __typename?: 'YoastSeoBreadcrumbsBlockAttributes';
  className?: Maybe<ScalarsEnums['String']>;
  lock?: Maybe<ScalarsEnums['BlockAttributesObject']>;
  metadata?: Maybe<ScalarsEnums['BlockAttributesObject']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  createBlockEditorPreview: (args: {
    input: CreateBlockEditorPreviewInput;
  }) => Maybe<CreateBlockEditorPreviewPayload>;
  createCategory: (args: { input: CreateCategoryInput }) => Maybe<CreateCategoryPayload>;
  createComment: (args: { input: CreateCommentInput }) => Maybe<CreateCommentPayload>;
  createCompany: (args: { input: CreateCompanyInput }) => Maybe<CreateCompanyPayload>;
  createCompanyCategory: (args: {
    input: CreateCompanyCategoryInput;
  }) => Maybe<CreateCompanyCategoryPayload>;
  createGraphqlDocument: (args: {
    input: CreateGraphqlDocumentInput;
  }) => Maybe<CreateGraphqlDocumentPayload>;
  createMediaItem: (args: { input: CreateMediaItemInput }) => Maybe<CreateMediaItemPayload>;
  createPage: (args: { input: CreatePageInput }) => Maybe<CreatePagePayload>;
  createPost: (args: { input: CreatePostInput }) => Maybe<CreatePostPayload>;
  createPostFormat: (args: { input: CreatePostFormatInput }) => Maybe<CreatePostFormatPayload>;
  createPress: (args: { input: CreatePressInput }) => Maybe<CreatePressPayload>;
  createPressCategory: (args: {
    input: CreatePressCategoryInput;
  }) => Maybe<CreatePressCategoryPayload>;
  createReusableBlock: (args: {
    input: CreateReusableBlockInput;
  }) => Maybe<CreateReusableBlockPayload>;
  createTag: (args: { input: CreateTagInput }) => Maybe<CreateTagPayload>;
  createTeamMember: (args: { input: CreateTeamMemberInput }) => Maybe<CreateTeamMemberPayload>;
  createUser: (args: { input: CreateUserInput }) => Maybe<CreateUserPayload>;
  deleteBlockEditorPreview: (args: {
    input: DeleteBlockEditorPreviewInput;
  }) => Maybe<DeleteBlockEditorPreviewPayload>;
  deleteCategory: (args: { input: DeleteCategoryInput }) => Maybe<DeleteCategoryPayload>;
  deleteComment: (args: { input: DeleteCommentInput }) => Maybe<DeleteCommentPayload>;
  deleteCompany: (args: { input: DeleteCompanyInput }) => Maybe<DeleteCompanyPayload>;
  deleteCompanyCategory: (args: {
    input: DeleteCompanyCategoryInput;
  }) => Maybe<DeleteCompanyCategoryPayload>;
  deleteGraphqlDocument: (args: {
    input: DeleteGraphqlDocumentInput;
  }) => Maybe<DeleteGraphqlDocumentPayload>;
  deleteMediaItem: (args: { input: DeleteMediaItemInput }) => Maybe<DeleteMediaItemPayload>;
  deletePage: (args: { input: DeletePageInput }) => Maybe<DeletePagePayload>;
  deletePost: (args: { input: DeletePostInput }) => Maybe<DeletePostPayload>;
  deletePostFormat: (args: { input: DeletePostFormatInput }) => Maybe<DeletePostFormatPayload>;
  deletePress: (args: { input: DeletePressInput }) => Maybe<DeletePressPayload>;
  deletePressCategory: (args: {
    input: DeletePressCategoryInput;
  }) => Maybe<DeletePressCategoryPayload>;
  deleteReusableBlock: (args: {
    input: DeleteReusableBlockInput;
  }) => Maybe<DeleteReusableBlockPayload>;
  deleteTag: (args: { input: DeleteTagInput }) => Maybe<DeleteTagPayload>;
  deleteTeamMember: (args: { input: DeleteTeamMemberInput }) => Maybe<DeleteTeamMemberPayload>;
  deleteUser: (args: { input: DeleteUserInput }) => Maybe<DeleteUserPayload>;
  generateAuthorizationCode: (args: {
    input: GenerateAuthorizationCodeInput;
  }) => Maybe<GenerateAuthorizationCodePayload>;
  increaseCount: (args?: { count?: Maybe<Scalars['Int']> }) => Maybe<ScalarsEnums['Int']>;
  registerUser: (args: { input: RegisterUserInput }) => Maybe<RegisterUserPayload>;
  resetUserPassword: (args: { input: ResetUserPasswordInput }) => Maybe<ResetUserPasswordPayload>;
  restoreComment: (args: { input: RestoreCommentInput }) => Maybe<RestoreCommentPayload>;
  sendPasswordResetEmail: (args: {
    input: SendPasswordResetEmailInput;
  }) => Maybe<SendPasswordResetEmailPayload>;
  updateBlockEditorPreview: (args: {
    input: UpdateBlockEditorPreviewInput;
  }) => Maybe<UpdateBlockEditorPreviewPayload>;
  updateCategory: (args: { input: UpdateCategoryInput }) => Maybe<UpdateCategoryPayload>;
  updateComment: (args: { input: UpdateCommentInput }) => Maybe<UpdateCommentPayload>;
  updateCompany: (args: { input: UpdateCompanyInput }) => Maybe<UpdateCompanyPayload>;
  updateCompanyCategory: (args: {
    input: UpdateCompanyCategoryInput;
  }) => Maybe<UpdateCompanyCategoryPayload>;
  updateGraphqlDocument: (args: {
    input: UpdateGraphqlDocumentInput;
  }) => Maybe<UpdateGraphqlDocumentPayload>;
  updateMediaItem: (args: { input: UpdateMediaItemInput }) => Maybe<UpdateMediaItemPayload>;
  updatePage: (args: { input: UpdatePageInput }) => Maybe<UpdatePagePayload>;
  updatePost: (args: { input: UpdatePostInput }) => Maybe<UpdatePostPayload>;
  updatePostFormat: (args: { input: UpdatePostFormatInput }) => Maybe<UpdatePostFormatPayload>;
  updatePress: (args: { input: UpdatePressInput }) => Maybe<UpdatePressPayload>;
  updatePressCategory: (args: {
    input: UpdatePressCategoryInput;
  }) => Maybe<UpdatePressCategoryPayload>;
  updateReusableBlock: (args: {
    input: UpdateReusableBlockInput;
  }) => Maybe<UpdateReusableBlockPayload>;
  updateSettings: (args: { input: UpdateSettingsInput }) => Maybe<UpdateSettingsPayload>;
  updateTag: (args: { input: UpdateTagInput }) => Maybe<UpdateTagPayload>;
  updateTeamMember: (args: { input: UpdateTeamMemberInput }) => Maybe<UpdateTeamMemberPayload>;
  updateUser: (args: { input: UpdateUserInput }) => Maybe<UpdateUserPayload>;
}

export interface Query {
  __typename?: 'Query';
  acfOptionsFooter?: Maybe<AcfOptionsFooter>;
  acfOptionsNotifications?: Maybe<AcfOptionsNotifications>;
  allSettings?: Maybe<Settings>;
  blockEditorContentNodes: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<BlockEditorContentNodeConnectionWhereArgs>;
  }) => Maybe<BlockEditorContentNodeConnection>;
  blockEditorPreview: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<BlockEditorPreviewIdType>;
  }) => Maybe<BlockEditorPreview>;
  blockEditorPreviewBy: (args?: {
    blockEditorPreviewId?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<BlockEditorPreview>;
  blockEditorPreviews: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToBlockEditorPreviewConnectionWhereArgs>;
  }) => Maybe<RootQueryToBlockEditorPreviewConnection>;
  categories: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToCategoryConnectionWhereArgs>;
  }) => Maybe<RootQueryToCategoryConnection>;
  category: (args: { id: Scalars['ID']; idType?: Maybe<CategoryIdType> }) => Maybe<Category>;
  comment: (args: { id: Scalars['ID']; idType?: Maybe<CommentNodeIdTypeEnum> }) => Maybe<Comment>;
  comments: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToCommentConnectionWhereArgs>;
  }) => Maybe<RootQueryToCommentConnection>;
  companies: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToCompanyConnectionWhereArgs>;
  }) => Maybe<RootQueryToCompanyConnection>;
  company: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<CompanyIdType>;
  }) => Maybe<Company>;
  companyBy: (args?: {
    companyId?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<Company>;
  companyCategories: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToCompanyCategoryConnectionWhereArgs>;
  }) => Maybe<RootQueryToCompanyCategoryConnection>;
  companyCategory: (args: {
    id: Scalars['ID'];
    idType?: Maybe<CompanyCategoryIdType>;
  }) => Maybe<CompanyCategory>;
  contentNode: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    contentType?: Maybe<ContentTypeEnum>;
    id: Scalars['ID'];
    idType?: Maybe<ContentNodeIdTypeEnum>;
  }) => Maybe<ContentNode>;
  contentNodes: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToContentNodeConnectionWhereArgs>;
  }) => Maybe<RootQueryToContentNodeConnection>;
  contentType: (args: {
    id: Scalars['ID'];
    idType?: Maybe<ContentTypeIdTypeEnum>;
  }) => Maybe<ContentType>;
  contentTypes: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToContentTypeConnection>;
  discussionSettings?: Maybe<DiscussionSettings>;
  generalSettings?: Maybe<GeneralSettings>;
  globalStylesheet: (args?: {
    types?: Maybe<Array<Maybe<GlobalStylesheetTypesEnum>>>;
  }) => Maybe<ScalarsEnums['String']>;
  graphqlDocument: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<GraphqlDocumentIdType>;
  }) => Maybe<GraphqlDocument>;
  graphqlDocumentBy: (args?: {
    graphqlDocumentId?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<GraphqlDocument>;
  graphqlDocuments: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToGraphqlDocumentConnectionWhereArgs>;
  }) => Maybe<RootQueryToGraphqlDocumentConnection>;
  mediaItem: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<MediaItemIdType>;
  }) => Maybe<MediaItem>;
  mediaItemBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    mediaItemId?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<MediaItem>;
  mediaItems: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToMediaItemConnectionWhereArgs>;
  }) => Maybe<RootQueryToMediaItemConnection>;
  menu: (args: { id: Scalars['ID']; idType?: Maybe<MenuNodeIdTypeEnum> }) => Maybe<Menu>;
  menuItem: (args: {
    id: Scalars['ID'];
    idType?: Maybe<MenuItemNodeIdTypeEnum>;
  }) => Maybe<MenuItem>;
  menuItems: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToMenuItemConnectionWhereArgs>;
  }) => Maybe<RootQueryToMenuItemConnection>;
  menus: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToMenuConnectionWhereArgs>;
  }) => Maybe<RootQueryToMenuConnection>;
  node: (args?: { id?: Maybe<Scalars['ID']> }) => Maybe<Node>;
  nodeByUri: (args: { uri: Scalars['String'] }) => Maybe<UniformResourceIdentifiable>;
  page: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<PageIdType>;
  }) => Maybe<Page>;
  pageBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    pageId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<Page>;
  pages: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPageConnectionWhereArgs>;
  }) => Maybe<RootQueryToPageConnection>;
  plugin: (args: { id: Scalars['ID'] }) => Maybe<Plugin>;
  plugins: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPluginConnectionWhereArgs>;
  }) => Maybe<RootQueryToPluginConnection>;
  post: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<PostIdType>;
  }) => Maybe<Post>;
  postBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    postId?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<Post>;
  postFormat: (args: { id: Scalars['ID']; idType?: Maybe<PostFormatIdType> }) => Maybe<PostFormat>;
  postFormats: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPostFormatConnectionWhereArgs>;
  }) => Maybe<RootQueryToPostFormatConnection>;
  posts: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPostConnectionWhereArgs>;
  }) => Maybe<RootQueryToPostConnection>;
  press: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<PressIdType>;
  }) => Maybe<Press>;
  pressBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    pressId?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<Press>;
  pressCategories: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPressCategoryConnectionWhereArgs>;
  }) => Maybe<RootQueryToPressCategoryConnection>;
  pressCategory: (args: {
    id: Scalars['ID'];
    idType?: Maybe<PressCategoryIdType>;
  }) => Maybe<PressCategory>;
  pressPosts: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToPressConnectionWhereArgs>;
  }) => Maybe<RootQueryToPressConnection>;
  readingSettings?: Maybe<ReadingSettings>;
  registeredScripts: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToEnqueuedScriptConnection>;
  registeredStylesheets: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToEnqueuedStylesheetConnection>;
  reusableBlock: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<ReusableBlockIdType>;
  }) => Maybe<ReusableBlock>;
  reusableBlockBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    reusableBlockId?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<ReusableBlock>;
  reusableBlocks: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToReusableBlockConnectionWhereArgs>;
  }) => Maybe<RootQueryToReusableBlockConnection>;
  revisions: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToRevisionsConnectionWhereArgs>;
  }) => Maybe<RootQueryToRevisionsConnection>;
  seo?: Maybe<SEOConfig>;
  tag: (args: { id: Scalars['ID']; idType?: Maybe<TagIdType> }) => Maybe<Tag>;
  tags: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToTagConnectionWhereArgs>;
  }) => Maybe<RootQueryToTagConnection>;
  taxonomies: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToTaxonomyConnection>;
  taxonomy: (args: { id: Scalars['ID']; idType?: Maybe<TaxonomyIdTypeEnum> }) => Maybe<Taxonomy>;
  teamMember: (args: {
    asPreview?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    idType?: Maybe<TeamMemberIdType>;
  }) => Maybe<TeamMember>;
  teamMemberBy: (args?: {
    id?: Maybe<Scalars['ID']>;
    teamMemberId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  }) => Maybe<TeamMember>;
  teamMembers: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToTeamMemberConnectionWhereArgs>;
  }) => Maybe<RootQueryToTeamMemberConnection>;
  termNode: (args: {
    id: Scalars['ID'];
    idType?: Maybe<TermNodeIdTypeEnum>;
    taxonomy?: Maybe<TaxonomyEnum>;
  }) => Maybe<TermNode>;
  terms: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToTermNodeConnectionWhereArgs>;
  }) => Maybe<RootQueryToTermNodeConnection>;
  theme: (args: { id: Scalars['ID'] }) => Maybe<Theme>;
  themes: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToThemeConnection>;
  user: (args: { id: Scalars['ID']; idType?: Maybe<UserNodeIdTypeEnum> }) => Maybe<User>;
  userRole: (args: { id: Scalars['ID'] }) => Maybe<UserRole>;
  userRoles: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  }) => Maybe<RootQueryToUserRoleConnection>;
  users: (args?: {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    where?: Maybe<RootQueryToUserConnectionWhereArgs>;
  }) => Maybe<RootQueryToUserConnection>;
  viewer?: Maybe<User>;
  writingSettings?: Maybe<WritingSettings>;
}

export interface Subscription {
  __typename?: 'Subscription';
}

export interface SchemaObjectTypes {
  AcfOptionsFooter: AcfOptionsFooter;
  AcfOptionsFooter_Footersettings: AcfOptionsFooter_Footersettings;
  AcfOptionsFooter_Footersettings_externalLinks: AcfOptionsFooter_Footersettings_externalLinks;
  AcfOptionsFooter_Footersettings_links: AcfOptionsFooter_Footersettings_links;
  AcfOptionsFooter_Footersettings_socialMedia: AcfOptionsFooter_Footersettings_socialMedia;
  AcfOptionsNotifications: AcfOptionsNotifications;
  AcfOptionsNotifications_Websitenotificationbanner: AcfOptionsNotifications_Websitenotificationbanner;
  Avatar: Avatar;
  BlockEditorContentNodeConnection: BlockEditorContentNodeConnection;
  BlockEditorContentNodeConnectionEdge: BlockEditorContentNodeConnectionEdge;
  BlockEditorContentNodeConnectionPageInfo: BlockEditorContentNodeConnectionPageInfo;
  BlockEditorPreview: BlockEditorPreview;
  BlockEditorPreviewToPreviewConnectionEdge: BlockEditorPreviewToPreviewConnectionEdge;
  Category: Category;
  CategoryToAncestorsCategoryConnection: CategoryToAncestorsCategoryConnection;
  CategoryToAncestorsCategoryConnectionEdge: CategoryToAncestorsCategoryConnectionEdge;
  CategoryToAncestorsCategoryConnectionPageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
  CategoryToCategoryConnection: CategoryToCategoryConnection;
  CategoryToCategoryConnectionEdge: CategoryToCategoryConnectionEdge;
  CategoryToCategoryConnectionPageInfo: CategoryToCategoryConnectionPageInfo;
  CategoryToContentNodeConnection: CategoryToContentNodeConnection;
  CategoryToContentNodeConnectionEdge: CategoryToContentNodeConnectionEdge;
  CategoryToContentNodeConnectionPageInfo: CategoryToContentNodeConnectionPageInfo;
  CategoryToParentCategoryConnectionEdge: CategoryToParentCategoryConnectionEdge;
  CategoryToPostConnection: CategoryToPostConnection;
  CategoryToPostConnectionEdge: CategoryToPostConnectionEdge;
  CategoryToPostConnectionPageInfo: CategoryToPostConnectionPageInfo;
  CategoryToTaxonomyConnectionEdge: CategoryToTaxonomyConnectionEdge;
  Comment: Comment;
  CommentAuthor: CommentAuthor;
  CommentToCommentConnection: CommentToCommentConnection;
  CommentToCommentConnectionEdge: CommentToCommentConnectionEdge;
  CommentToCommentConnectionPageInfo: CommentToCommentConnectionPageInfo;
  CommentToCommenterConnectionEdge: CommentToCommenterConnectionEdge;
  CommentToContentNodeConnectionEdge: CommentToContentNodeConnectionEdge;
  CommentToParentCommentConnectionEdge: CommentToParentCommentConnectionEdge;
  Company: Company;
  CompanyCategory: CompanyCategory;
  CompanyCategoryToAncestorsCompanyCategoryConnection: CompanyCategoryToAncestorsCompanyCategoryConnection;
  CompanyCategoryToAncestorsCompanyCategoryConnectionEdge: CompanyCategoryToAncestorsCompanyCategoryConnectionEdge;
  CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo: CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyCategoryConnection: CompanyCategoryToCompanyCategoryConnection;
  CompanyCategoryToCompanyCategoryConnectionEdge: CompanyCategoryToCompanyCategoryConnectionEdge;
  CompanyCategoryToCompanyCategoryConnectionPageInfo: CompanyCategoryToCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyConnection: CompanyCategoryToCompanyConnection;
  CompanyCategoryToCompanyConnectionEdge: CompanyCategoryToCompanyConnectionEdge;
  CompanyCategoryToCompanyConnectionPageInfo: CompanyCategoryToCompanyConnectionPageInfo;
  CompanyCategoryToContentNodeConnection: CompanyCategoryToContentNodeConnection;
  CompanyCategoryToContentNodeConnectionEdge: CompanyCategoryToContentNodeConnectionEdge;
  CompanyCategoryToContentNodeConnectionPageInfo: CompanyCategoryToContentNodeConnectionPageInfo;
  CompanyCategoryToParentCompanyCategoryConnectionEdge: CompanyCategoryToParentCompanyCategoryConnectionEdge;
  CompanyCategoryToTaxonomyConnectionEdge: CompanyCategoryToTaxonomyConnectionEdge;
  CompanyToCompanyCategoryConnection: CompanyToCompanyCategoryConnection;
  CompanyToCompanyCategoryConnectionEdge: CompanyToCompanyCategoryConnectionEdge;
  CompanyToCompanyCategoryConnectionPageInfo: CompanyToCompanyCategoryConnectionPageInfo;
  CompanyToPreviewConnectionEdge: CompanyToPreviewConnectionEdge;
  CompanyToTermNodeConnection: CompanyToTermNodeConnection;
  CompanyToTermNodeConnectionEdge: CompanyToTermNodeConnectionEdge;
  CompanyToTermNodeConnectionPageInfo: CompanyToTermNodeConnectionPageInfo;
  Company_Companies: Company_Companies;
  Company_Companies_info: Company_Companies_info;
  ConditionalTags: ConditionalTags;
  ContentNodeToContentTypeConnectionEdge: ContentNodeToContentTypeConnectionEdge;
  ContentNodeToEditLastConnectionEdge: ContentNodeToEditLastConnectionEdge;
  ContentNodeToEditLockConnectionEdge: ContentNodeToEditLockConnectionEdge;
  ContentNodeToEnqueuedScriptConnection: ContentNodeToEnqueuedScriptConnection;
  ContentNodeToEnqueuedScriptConnectionEdge: ContentNodeToEnqueuedScriptConnectionEdge;
  ContentNodeToEnqueuedScriptConnectionPageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
  ContentNodeToEnqueuedStylesheetConnection: ContentNodeToEnqueuedStylesheetConnection;
  ContentNodeToEnqueuedStylesheetConnectionEdge: ContentNodeToEnqueuedStylesheetConnectionEdge;
  ContentNodeToEnqueuedStylesheetConnectionPageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  ContentType: ContentType;
  ContentTypeToContentNodeConnection: ContentTypeToContentNodeConnection;
  ContentTypeToContentNodeConnectionEdge: ContentTypeToContentNodeConnectionEdge;
  ContentTypeToContentNodeConnectionPageInfo: ContentTypeToContentNodeConnectionPageInfo;
  ContentTypeToTaxonomyConnection: ContentTypeToTaxonomyConnection;
  ContentTypeToTaxonomyConnectionEdge: ContentTypeToTaxonomyConnectionEdge;
  ContentTypeToTaxonomyConnectionPageInfo: ContentTypeToTaxonomyConnectionPageInfo;
  CoreArchivesBlock: CoreArchivesBlock;
  CoreArchivesBlockAttributes: CoreArchivesBlockAttributes;
  CoreAudioBlock: CoreAudioBlock;
  CoreAudioBlockAttributes: CoreAudioBlockAttributes;
  CoreAudioBlockDeprecatedV1Attributes: CoreAudioBlockDeprecatedV1Attributes;
  CoreAvatarBlock: CoreAvatarBlock;
  CoreAvatarBlockAttributes: CoreAvatarBlockAttributes;
  CoreBlock: CoreBlock;
  CoreBlockAttributes: CoreBlockAttributes;
  CoreBlockDeprecatedV1Attributes: CoreBlockDeprecatedV1Attributes;
  CoreBlockDeprecatedV2Attributes: CoreBlockDeprecatedV2Attributes;
  CoreButtonBlock: CoreButtonBlock;
  CoreButtonBlockAttributes: CoreButtonBlockAttributes;
  CoreButtonBlockDeprecatedV10Attributes: CoreButtonBlockDeprecatedV10Attributes;
  CoreButtonBlockDeprecatedV11Attributes: CoreButtonBlockDeprecatedV11Attributes;
  CoreButtonBlockDeprecatedV1Attributes: CoreButtonBlockDeprecatedV1Attributes;
  CoreButtonBlockDeprecatedV2Attributes: CoreButtonBlockDeprecatedV2Attributes;
  CoreButtonBlockDeprecatedV3Attributes: CoreButtonBlockDeprecatedV3Attributes;
  CoreButtonBlockDeprecatedV4Attributes: CoreButtonBlockDeprecatedV4Attributes;
  CoreButtonBlockDeprecatedV5Attributes: CoreButtonBlockDeprecatedV5Attributes;
  CoreButtonBlockDeprecatedV6Attributes: CoreButtonBlockDeprecatedV6Attributes;
  CoreButtonBlockDeprecatedV7Attributes: CoreButtonBlockDeprecatedV7Attributes;
  CoreButtonBlockDeprecatedV8Attributes: CoreButtonBlockDeprecatedV8Attributes;
  CoreButtonBlockDeprecatedV9Attributes: CoreButtonBlockDeprecatedV9Attributes;
  CoreButtonsBlock: CoreButtonsBlock;
  CoreButtonsBlockAttributes: CoreButtonsBlockAttributes;
  CoreButtonsBlockDeprecatedV1Attributes: CoreButtonsBlockDeprecatedV1Attributes;
  CoreButtonsBlockDeprecatedV2Attributes: CoreButtonsBlockDeprecatedV2Attributes;
  CoreCalendarBlock: CoreCalendarBlock;
  CoreCalendarBlockAttributes: CoreCalendarBlockAttributes;
  CoreCategoriesBlock: CoreCategoriesBlock;
  CoreCategoriesBlockAttributes: CoreCategoriesBlockAttributes;
  CoreCodeBlock: CoreCodeBlock;
  CoreCodeBlockAttributes: CoreCodeBlockAttributes;
  CoreColumnBlock: CoreColumnBlock;
  CoreColumnBlockAttributes: CoreColumnBlockAttributes;
  CoreColumnBlockDeprecatedV1Attributes: CoreColumnBlockDeprecatedV1Attributes;
  CoreColumnsBlock: CoreColumnsBlock;
  CoreColumnsBlockAttributes: CoreColumnsBlockAttributes;
  CoreColumnsBlockDeprecatedV1Attributes: CoreColumnsBlockDeprecatedV1Attributes;
  CoreColumnsBlockDeprecatedV2Attributes: CoreColumnsBlockDeprecatedV2Attributes;
  CoreColumnsBlockDeprecatedV3Attributes: CoreColumnsBlockDeprecatedV3Attributes;
  CoreCommentAuthorNameBlock: CoreCommentAuthorNameBlock;
  CoreCommentAuthorNameBlockAttributes: CoreCommentAuthorNameBlockAttributes;
  CoreCommentAuthorNameBlockDeprecatedV1Attributes: CoreCommentAuthorNameBlockDeprecatedV1Attributes;
  CoreCommentContentBlock: CoreCommentContentBlock;
  CoreCommentContentBlockAttributes: CoreCommentContentBlockAttributes;
  CoreCommentDateBlock: CoreCommentDateBlock;
  CoreCommentDateBlockAttributes: CoreCommentDateBlockAttributes;
  CoreCommentDateBlockDeprecatedV1Attributes: CoreCommentDateBlockDeprecatedV1Attributes;
  CoreCommentEditLinkBlock: CoreCommentEditLinkBlock;
  CoreCommentEditLinkBlockAttributes: CoreCommentEditLinkBlockAttributes;
  CoreCommentReplyLinkBlock: CoreCommentReplyLinkBlock;
  CoreCommentReplyLinkBlockAttributes: CoreCommentReplyLinkBlockAttributes;
  CoreCommentTemplateBlock: CoreCommentTemplateBlock;
  CoreCommentTemplateBlockAttributes: CoreCommentTemplateBlockAttributes;
  CoreCommentsBlock: CoreCommentsBlock;
  CoreCommentsBlockAttributes: CoreCommentsBlockAttributes;
  CoreCommentsBlockDeprecatedV1Attributes: CoreCommentsBlockDeprecatedV1Attributes;
  CoreCommentsPaginationBlock: CoreCommentsPaginationBlock;
  CoreCommentsPaginationBlockAttributes: CoreCommentsPaginationBlockAttributes;
  CoreCommentsPaginationNextBlock: CoreCommentsPaginationNextBlock;
  CoreCommentsPaginationNextBlockAttributes: CoreCommentsPaginationNextBlockAttributes;
  CoreCommentsPaginationNumbersBlock: CoreCommentsPaginationNumbersBlock;
  CoreCommentsPaginationNumbersBlockAttributes: CoreCommentsPaginationNumbersBlockAttributes;
  CoreCommentsPaginationPreviousBlock: CoreCommentsPaginationPreviousBlock;
  CoreCommentsPaginationPreviousBlockAttributes: CoreCommentsPaginationPreviousBlockAttributes;
  CoreCommentsTitleBlock: CoreCommentsTitleBlock;
  CoreCommentsTitleBlockAttributes: CoreCommentsTitleBlockAttributes;
  CoreCommentsTitleBlockDeprecatedV1Attributes: CoreCommentsTitleBlockDeprecatedV1Attributes;
  CoreCoverBlock: CoreCoverBlock;
  CoreCoverBlockAttributes: CoreCoverBlockAttributes;
  CoreCoverBlockDeprecatedV10Attributes: CoreCoverBlockDeprecatedV10Attributes;
  CoreCoverBlockDeprecatedV11Attributes: CoreCoverBlockDeprecatedV11Attributes;
  CoreCoverBlockDeprecatedV12Attributes: CoreCoverBlockDeprecatedV12Attributes;
  CoreCoverBlockDeprecatedV13Attributes: CoreCoverBlockDeprecatedV13Attributes;
  CoreCoverBlockDeprecatedV1Attributes: CoreCoverBlockDeprecatedV1Attributes;
  CoreCoverBlockDeprecatedV2Attributes: CoreCoverBlockDeprecatedV2Attributes;
  CoreCoverBlockDeprecatedV3Attributes: CoreCoverBlockDeprecatedV3Attributes;
  CoreCoverBlockDeprecatedV4Attributes: CoreCoverBlockDeprecatedV4Attributes;
  CoreCoverBlockDeprecatedV5Attributes: CoreCoverBlockDeprecatedV5Attributes;
  CoreCoverBlockDeprecatedV6Attributes: CoreCoverBlockDeprecatedV6Attributes;
  CoreCoverBlockDeprecatedV7Attributes: CoreCoverBlockDeprecatedV7Attributes;
  CoreCoverBlockDeprecatedV8Attributes: CoreCoverBlockDeprecatedV8Attributes;
  CoreCoverBlockDeprecatedV9Attributes: CoreCoverBlockDeprecatedV9Attributes;
  CoreDetailsBlock: CoreDetailsBlock;
  CoreDetailsBlockAttributes: CoreDetailsBlockAttributes;
  CoreEmbedBlock: CoreEmbedBlock;
  CoreEmbedBlockAttributes: CoreEmbedBlockAttributes;
  CoreEmbedBlockDeprecatedV1Attributes: CoreEmbedBlockDeprecatedV1Attributes;
  CoreEmbedBlockDeprecatedV2Attributes: CoreEmbedBlockDeprecatedV2Attributes;
  CoreFileBlock: CoreFileBlock;
  CoreFileBlockAttributes: CoreFileBlockAttributes;
  CoreFileBlockDeprecatedV1Attributes: CoreFileBlockDeprecatedV1Attributes;
  CoreFileBlockDeprecatedV2Attributes: CoreFileBlockDeprecatedV2Attributes;
  CoreFileBlockDeprecatedV3Attributes: CoreFileBlockDeprecatedV3Attributes;
  CoreFootnotesBlock: CoreFootnotesBlock;
  CoreFootnotesBlockAttributes: CoreFootnotesBlockAttributes;
  CoreFreeformBlock: CoreFreeformBlock;
  CoreFreeformBlockAttributes: CoreFreeformBlockAttributes;
  CoreGalleryBlock: CoreGalleryBlock;
  CoreGalleryBlockAttributes: CoreGalleryBlockAttributes;
  CoreGalleryBlockAttributesImages: CoreGalleryBlockAttributesImages;
  CoreGalleryBlockDeprecatedV1Attributes: CoreGalleryBlockDeprecatedV1Attributes;
  CoreGalleryBlockDeprecatedV1AttributesImages: CoreGalleryBlockDeprecatedV1AttributesImages;
  CoreGalleryBlockDeprecatedV2Attributes: CoreGalleryBlockDeprecatedV2Attributes;
  CoreGalleryBlockDeprecatedV2AttributesImages: CoreGalleryBlockDeprecatedV2AttributesImages;
  CoreGalleryBlockDeprecatedV3Attributes: CoreGalleryBlockDeprecatedV3Attributes;
  CoreGalleryBlockDeprecatedV3AttributesImages: CoreGalleryBlockDeprecatedV3AttributesImages;
  CoreGalleryBlockDeprecatedV4Attributes: CoreGalleryBlockDeprecatedV4Attributes;
  CoreGalleryBlockDeprecatedV4AttributesImages: CoreGalleryBlockDeprecatedV4AttributesImages;
  CoreGalleryBlockDeprecatedV5Attributes: CoreGalleryBlockDeprecatedV5Attributes;
  CoreGalleryBlockDeprecatedV5AttributesImages: CoreGalleryBlockDeprecatedV5AttributesImages;
  CoreGalleryBlockDeprecatedV6Attributes: CoreGalleryBlockDeprecatedV6Attributes;
  CoreGalleryBlockDeprecatedV6AttributesImages: CoreGalleryBlockDeprecatedV6AttributesImages;
  CoreGalleryBlockDeprecatedV7Attributes: CoreGalleryBlockDeprecatedV7Attributes;
  CoreGalleryBlockDeprecatedV7AttributesImages: CoreGalleryBlockDeprecatedV7AttributesImages;
  CoreGroupBlock: CoreGroupBlock;
  CoreGroupBlockAttributes: CoreGroupBlockAttributes;
  CoreGroupBlockDeprecatedV1Attributes: CoreGroupBlockDeprecatedV1Attributes;
  CoreGroupBlockDeprecatedV2Attributes: CoreGroupBlockDeprecatedV2Attributes;
  CoreGroupBlockDeprecatedV3Attributes: CoreGroupBlockDeprecatedV3Attributes;
  CoreGroupBlockDeprecatedV4Attributes: CoreGroupBlockDeprecatedV4Attributes;
  CoreGroupBlockDeprecatedV5Attributes: CoreGroupBlockDeprecatedV5Attributes;
  CoreHeadingBlock: CoreHeadingBlock;
  CoreHeadingBlockAttributes: CoreHeadingBlockAttributes;
  CoreHeadingBlockDeprecatedV1Attributes: CoreHeadingBlockDeprecatedV1Attributes;
  CoreHeadingBlockDeprecatedV2Attributes: CoreHeadingBlockDeprecatedV2Attributes;
  CoreHeadingBlockDeprecatedV3Attributes: CoreHeadingBlockDeprecatedV3Attributes;
  CoreHeadingBlockDeprecatedV4Attributes: CoreHeadingBlockDeprecatedV4Attributes;
  CoreHeadingBlockDeprecatedV5Attributes: CoreHeadingBlockDeprecatedV5Attributes;
  CoreHomeLinkBlock: CoreHomeLinkBlock;
  CoreHomeLinkBlockAttributes: CoreHomeLinkBlockAttributes;
  CoreHtmlBlock: CoreHtmlBlock;
  CoreHtmlBlockAttributes: CoreHtmlBlockAttributes;
  CoreImageBlock: CoreImageBlock;
  CoreImageBlockAttributes: CoreImageBlockAttributes;
  CoreImageBlockDeprecatedV1Attributes: CoreImageBlockDeprecatedV1Attributes;
  CoreImageBlockDeprecatedV2Attributes: CoreImageBlockDeprecatedV2Attributes;
  CoreImageBlockDeprecatedV3Attributes: CoreImageBlockDeprecatedV3Attributes;
  CoreImageBlockDeprecatedV4Attributes: CoreImageBlockDeprecatedV4Attributes;
  CoreImageBlockDeprecatedV5Attributes: CoreImageBlockDeprecatedV5Attributes;
  CoreImageBlockDeprecatedV6Attributes: CoreImageBlockDeprecatedV6Attributes;
  CoreImageBlockDeprecatedV7Attributes: CoreImageBlockDeprecatedV7Attributes;
  CoreImageBlockDeprecatedV8Attributes: CoreImageBlockDeprecatedV8Attributes;
  CoreImageBlockToMediaItemConnectionEdge: CoreImageBlockToMediaItemConnectionEdge;
  CoreLatestCommentsBlock: CoreLatestCommentsBlock;
  CoreLatestCommentsBlockAttributes: CoreLatestCommentsBlockAttributes;
  CoreLatestPostsBlock: CoreLatestPostsBlock;
  CoreLatestPostsBlockAttributes: CoreLatestPostsBlockAttributes;
  CoreLatestPostsBlockDeprecatedV1Attributes: CoreLatestPostsBlockDeprecatedV1Attributes;
  CoreLegacyWidgetBlock: CoreLegacyWidgetBlock;
  CoreLegacyWidgetBlockAttributes: CoreLegacyWidgetBlockAttributes;
  CoreListBlock: CoreListBlock;
  CoreListBlockAttributes: CoreListBlockAttributes;
  CoreListBlockDeprecatedV1Attributes: CoreListBlockDeprecatedV1Attributes;
  CoreListBlockDeprecatedV2Attributes: CoreListBlockDeprecatedV2Attributes;
  CoreListBlockDeprecatedV3Attributes: CoreListBlockDeprecatedV3Attributes;
  CoreListItemBlock: CoreListItemBlock;
  CoreListItemBlockAttributes: CoreListItemBlockAttributes;
  CoreLoginoutBlock: CoreLoginoutBlock;
  CoreLoginoutBlockAttributes: CoreLoginoutBlockAttributes;
  CoreMediaTextBlock: CoreMediaTextBlock;
  CoreMediaTextBlockAttributes: CoreMediaTextBlockAttributes;
  CoreMediaTextBlockDeprecatedV1Attributes: CoreMediaTextBlockDeprecatedV1Attributes;
  CoreMediaTextBlockDeprecatedV2Attributes: CoreMediaTextBlockDeprecatedV2Attributes;
  CoreMediaTextBlockDeprecatedV3Attributes: CoreMediaTextBlockDeprecatedV3Attributes;
  CoreMediaTextBlockDeprecatedV4Attributes: CoreMediaTextBlockDeprecatedV4Attributes;
  CoreMediaTextBlockDeprecatedV5Attributes: CoreMediaTextBlockDeprecatedV5Attributes;
  CoreMediaTextBlockDeprecatedV6Attributes: CoreMediaTextBlockDeprecatedV6Attributes;
  CoreMissingBlock: CoreMissingBlock;
  CoreMissingBlockAttributes: CoreMissingBlockAttributes;
  CoreMoreBlock: CoreMoreBlock;
  CoreMoreBlockAttributes: CoreMoreBlockAttributes;
  CoreNavigationBlock: CoreNavigationBlock;
  CoreNavigationBlockAttributes: CoreNavigationBlockAttributes;
  CoreNavigationBlockDeprecatedV1Attributes: CoreNavigationBlockDeprecatedV1Attributes;
  CoreNavigationBlockDeprecatedV2Attributes: CoreNavigationBlockDeprecatedV2Attributes;
  CoreNavigationBlockDeprecatedV3Attributes: CoreNavigationBlockDeprecatedV3Attributes;
  CoreNavigationBlockDeprecatedV4Attributes: CoreNavigationBlockDeprecatedV4Attributes;
  CoreNavigationBlockDeprecatedV5Attributes: CoreNavigationBlockDeprecatedV5Attributes;
  CoreNavigationBlockDeprecatedV6Attributes: CoreNavigationBlockDeprecatedV6Attributes;
  CoreNavigationLinkBlock: CoreNavigationLinkBlock;
  CoreNavigationLinkBlockAttributes: CoreNavigationLinkBlockAttributes;
  CoreNavigationLinkBlockDeprecatedV1Attributes: CoreNavigationLinkBlockDeprecatedV1Attributes;
  CoreNavigationSubmenuBlock: CoreNavigationSubmenuBlock;
  CoreNavigationSubmenuBlockAttributes: CoreNavigationSubmenuBlockAttributes;
  CoreNextpageBlock: CoreNextpageBlock;
  CoreNextpageBlockAttributes: CoreNextpageBlockAttributes;
  CorePageListBlock: CorePageListBlock;
  CorePageListBlockAttributes: CorePageListBlockAttributes;
  CorePageListItemBlock: CorePageListItemBlock;
  CorePageListItemBlockAttributes: CorePageListItemBlockAttributes;
  CoreParagraphBlock: CoreParagraphBlock;
  CoreParagraphBlockAttributes: CoreParagraphBlockAttributes;
  CoreParagraphBlockDeprecatedV1Attributes: CoreParagraphBlockDeprecatedV1Attributes;
  CoreParagraphBlockDeprecatedV2Attributes: CoreParagraphBlockDeprecatedV2Attributes;
  CoreParagraphBlockDeprecatedV3Attributes: CoreParagraphBlockDeprecatedV3Attributes;
  CoreParagraphBlockDeprecatedV4Attributes: CoreParagraphBlockDeprecatedV4Attributes;
  CoreParagraphBlockDeprecatedV5Attributes: CoreParagraphBlockDeprecatedV5Attributes;
  CoreParagraphBlockDeprecatedV6Attributes: CoreParagraphBlockDeprecatedV6Attributes;
  CorePatternBlock: CorePatternBlock;
  CorePatternBlockAttributes: CorePatternBlockAttributes;
  CorePostAuthorBiographyBlock: CorePostAuthorBiographyBlock;
  CorePostAuthorBiographyBlockAttributes: CorePostAuthorBiographyBlockAttributes;
  CorePostAuthorBlock: CorePostAuthorBlock;
  CorePostAuthorBlockAttributes: CorePostAuthorBlockAttributes;
  CorePostAuthorNameBlock: CorePostAuthorNameBlock;
  CorePostAuthorNameBlockAttributes: CorePostAuthorNameBlockAttributes;
  CorePostCommentsFormBlock: CorePostCommentsFormBlock;
  CorePostCommentsFormBlockAttributes: CorePostCommentsFormBlockAttributes;
  CorePostContentBlock: CorePostContentBlock;
  CorePostContentBlockAttributes: CorePostContentBlockAttributes;
  CorePostDateBlock: CorePostDateBlock;
  CorePostDateBlockAttributes: CorePostDateBlockAttributes;
  CorePostDateBlockDeprecatedV1Attributes: CorePostDateBlockDeprecatedV1Attributes;
  CorePostExcerptBlock: CorePostExcerptBlock;
  CorePostExcerptBlockAttributes: CorePostExcerptBlockAttributes;
  CorePostFeaturedImageBlock: CorePostFeaturedImageBlock;
  CorePostFeaturedImageBlockAttributes: CorePostFeaturedImageBlockAttributes;
  CorePostNavigationLinkBlock: CorePostNavigationLinkBlock;
  CorePostNavigationLinkBlockAttributes: CorePostNavigationLinkBlockAttributes;
  CorePostTemplateBlock: CorePostTemplateBlock;
  CorePostTemplateBlockAttributes: CorePostTemplateBlockAttributes;
  CorePostTermsBlock: CorePostTermsBlock;
  CorePostTermsBlockAttributes: CorePostTermsBlockAttributes;
  CorePostTitleBlock: CorePostTitleBlock;
  CorePostTitleBlockAttributes: CorePostTitleBlockAttributes;
  CorePostTitleBlockDeprecatedV1Attributes: CorePostTitleBlockDeprecatedV1Attributes;
  CorePreformattedBlock: CorePreformattedBlock;
  CorePreformattedBlockAttributes: CorePreformattedBlockAttributes;
  CorePullquoteBlock: CorePullquoteBlock;
  CorePullquoteBlockAttributes: CorePullquoteBlockAttributes;
  CorePullquoteBlockDeprecatedV1Attributes: CorePullquoteBlockDeprecatedV1Attributes;
  CorePullquoteBlockDeprecatedV2Attributes: CorePullquoteBlockDeprecatedV2Attributes;
  CorePullquoteBlockDeprecatedV3Attributes: CorePullquoteBlockDeprecatedV3Attributes;
  CorePullquoteBlockDeprecatedV4Attributes: CorePullquoteBlockDeprecatedV4Attributes;
  CorePullquoteBlockDeprecatedV5Attributes: CorePullquoteBlockDeprecatedV5Attributes;
  CorePullquoteBlockDeprecatedV6Attributes: CorePullquoteBlockDeprecatedV6Attributes;
  CoreQueryBlock: CoreQueryBlock;
  CoreQueryBlockAttributes: CoreQueryBlockAttributes;
  CoreQueryBlockDeprecatedV1Attributes: CoreQueryBlockDeprecatedV1Attributes;
  CoreQueryBlockDeprecatedV2Attributes: CoreQueryBlockDeprecatedV2Attributes;
  CoreQueryBlockDeprecatedV3Attributes: CoreQueryBlockDeprecatedV3Attributes;
  CoreQueryBlockDeprecatedV4Attributes: CoreQueryBlockDeprecatedV4Attributes;
  CoreQueryBlockDeprecatedV5Attributes: CoreQueryBlockDeprecatedV5Attributes;
  CoreQueryNoResultsBlock: CoreQueryNoResultsBlock;
  CoreQueryNoResultsBlockAttributes: CoreQueryNoResultsBlockAttributes;
  CoreQueryPaginationBlock: CoreQueryPaginationBlock;
  CoreQueryPaginationBlockAttributes: CoreQueryPaginationBlockAttributes;
  CoreQueryPaginationBlockDeprecatedV1Attributes: CoreQueryPaginationBlockDeprecatedV1Attributes;
  CoreQueryPaginationNextBlock: CoreQueryPaginationNextBlock;
  CoreQueryPaginationNextBlockAttributes: CoreQueryPaginationNextBlockAttributes;
  CoreQueryPaginationNumbersBlock: CoreQueryPaginationNumbersBlock;
  CoreQueryPaginationNumbersBlockAttributes: CoreQueryPaginationNumbersBlockAttributes;
  CoreQueryPaginationPreviousBlock: CoreQueryPaginationPreviousBlock;
  CoreQueryPaginationPreviousBlockAttributes: CoreQueryPaginationPreviousBlockAttributes;
  CoreQueryTitleBlock: CoreQueryTitleBlock;
  CoreQueryTitleBlockAttributes: CoreQueryTitleBlockAttributes;
  CoreQueryTitleBlockDeprecatedV1Attributes: CoreQueryTitleBlockDeprecatedV1Attributes;
  CoreQuoteBlock: CoreQuoteBlock;
  CoreQuoteBlockAttributes: CoreQuoteBlockAttributes;
  CoreQuoteBlockDeprecatedV1Attributes: CoreQuoteBlockDeprecatedV1Attributes;
  CoreQuoteBlockDeprecatedV2Attributes: CoreQuoteBlockDeprecatedV2Attributes;
  CoreQuoteBlockDeprecatedV3Attributes: CoreQuoteBlockDeprecatedV3Attributes;
  CoreQuoteBlockDeprecatedV4Attributes: CoreQuoteBlockDeprecatedV4Attributes;
  CoreReadMoreBlock: CoreReadMoreBlock;
  CoreReadMoreBlockAttributes: CoreReadMoreBlockAttributes;
  CoreRssBlock: CoreRssBlock;
  CoreRssBlockAttributes: CoreRssBlockAttributes;
  CoreSearchBlock: CoreSearchBlock;
  CoreSearchBlockAttributes: CoreSearchBlockAttributes;
  CoreSeparatorBlock: CoreSeparatorBlock;
  CoreSeparatorBlockAttributes: CoreSeparatorBlockAttributes;
  CoreSeparatorBlockDeprecatedV1Attributes: CoreSeparatorBlockDeprecatedV1Attributes;
  CoreShortcodeBlock: CoreShortcodeBlock;
  CoreShortcodeBlockAttributes: CoreShortcodeBlockAttributes;
  CoreSiteLogoBlock: CoreSiteLogoBlock;
  CoreSiteLogoBlockAttributes: CoreSiteLogoBlockAttributes;
  CoreSiteTaglineBlock: CoreSiteTaglineBlock;
  CoreSiteTaglineBlockAttributes: CoreSiteTaglineBlockAttributes;
  CoreSiteTaglineBlockDeprecatedV1Attributes: CoreSiteTaglineBlockDeprecatedV1Attributes;
  CoreSiteTitleBlock: CoreSiteTitleBlock;
  CoreSiteTitleBlockAttributes: CoreSiteTitleBlockAttributes;
  CoreSiteTitleBlockDeprecatedV1Attributes: CoreSiteTitleBlockDeprecatedV1Attributes;
  CoreSocialLinkBlock: CoreSocialLinkBlock;
  CoreSocialLinkBlockAttributes: CoreSocialLinkBlockAttributes;
  CoreSocialLinksBlock: CoreSocialLinksBlock;
  CoreSocialLinksBlockAttributes: CoreSocialLinksBlockAttributes;
  CoreSocialLinksBlockDeprecatedV1Attributes: CoreSocialLinksBlockDeprecatedV1Attributes;
  CoreSpacerBlock: CoreSpacerBlock;
  CoreSpacerBlockAttributes: CoreSpacerBlockAttributes;
  CoreSpacerBlockDeprecatedV1Attributes: CoreSpacerBlockDeprecatedV1Attributes;
  CoreTableBlock: CoreTableBlock;
  CoreTableBlockAttributes: CoreTableBlockAttributes;
  CoreTableBlockAttributesBody: CoreTableBlockAttributesBody;
  CoreTableBlockAttributesBodyCells: CoreTableBlockAttributesBodyCells;
  CoreTableBlockAttributesFoot: CoreTableBlockAttributesFoot;
  CoreTableBlockAttributesFootCells: CoreTableBlockAttributesFootCells;
  CoreTableBlockAttributesHead: CoreTableBlockAttributesHead;
  CoreTableBlockAttributesHeadCells: CoreTableBlockAttributesHeadCells;
  CoreTableBlockDeprecatedV1Attributes: CoreTableBlockDeprecatedV1Attributes;
  CoreTableBlockDeprecatedV1AttributesBody: CoreTableBlockDeprecatedV1AttributesBody;
  CoreTableBlockDeprecatedV1AttributesBodyCells: CoreTableBlockDeprecatedV1AttributesBodyCells;
  CoreTableBlockDeprecatedV1AttributesFoot: CoreTableBlockDeprecatedV1AttributesFoot;
  CoreTableBlockDeprecatedV1AttributesFootCells: CoreTableBlockDeprecatedV1AttributesFootCells;
  CoreTableBlockDeprecatedV1AttributesHead: CoreTableBlockDeprecatedV1AttributesHead;
  CoreTableBlockDeprecatedV1AttributesHeadCells: CoreTableBlockDeprecatedV1AttributesHeadCells;
  CoreTableBlockDeprecatedV2Attributes: CoreTableBlockDeprecatedV2Attributes;
  CoreTableBlockDeprecatedV2AttributesBody: CoreTableBlockDeprecatedV2AttributesBody;
  CoreTableBlockDeprecatedV2AttributesBodyCells: CoreTableBlockDeprecatedV2AttributesBodyCells;
  CoreTableBlockDeprecatedV2AttributesFoot: CoreTableBlockDeprecatedV2AttributesFoot;
  CoreTableBlockDeprecatedV2AttributesFootCells: CoreTableBlockDeprecatedV2AttributesFootCells;
  CoreTableBlockDeprecatedV2AttributesHead: CoreTableBlockDeprecatedV2AttributesHead;
  CoreTableBlockDeprecatedV2AttributesHeadCells: CoreTableBlockDeprecatedV2AttributesHeadCells;
  CoreTableBlockDeprecatedV3Attributes: CoreTableBlockDeprecatedV3Attributes;
  CoreTableBlockDeprecatedV3AttributesBody: CoreTableBlockDeprecatedV3AttributesBody;
  CoreTableBlockDeprecatedV3AttributesBodyCells: CoreTableBlockDeprecatedV3AttributesBodyCells;
  CoreTableBlockDeprecatedV3AttributesFoot: CoreTableBlockDeprecatedV3AttributesFoot;
  CoreTableBlockDeprecatedV3AttributesFootCells: CoreTableBlockDeprecatedV3AttributesFootCells;
  CoreTableBlockDeprecatedV3AttributesHead: CoreTableBlockDeprecatedV3AttributesHead;
  CoreTableBlockDeprecatedV3AttributesHeadCells: CoreTableBlockDeprecatedV3AttributesHeadCells;
  CoreTagCloudBlock: CoreTagCloudBlock;
  CoreTagCloudBlockAttributes: CoreTagCloudBlockAttributes;
  CoreTemplatePartBlock: CoreTemplatePartBlock;
  CoreTemplatePartBlockAttributes: CoreTemplatePartBlockAttributes;
  CoreTermDescriptionBlock: CoreTermDescriptionBlock;
  CoreTermDescriptionBlockAttributes: CoreTermDescriptionBlockAttributes;
  CoreTextColumnsBlock: CoreTextColumnsBlock;
  CoreTextColumnsBlockAttributes: CoreTextColumnsBlockAttributes;
  CoreTextColumnsBlockAttributesContent: CoreTextColumnsBlockAttributesContent;
  CoreVerseBlock: CoreVerseBlock;
  CoreVerseBlockAttributes: CoreVerseBlockAttributes;
  CoreVerseBlockDeprecatedV1Attributes: CoreVerseBlockDeprecatedV1Attributes;
  CoreVerseBlockDeprecatedV2Attributes: CoreVerseBlockDeprecatedV2Attributes;
  CoreVideoBlock: CoreVideoBlock;
  CoreVideoBlockAttributes: CoreVideoBlockAttributes;
  CoreVideoBlockDeprecatedV1Attributes: CoreVideoBlockDeprecatedV1Attributes;
  CoreWidgetGroupBlock: CoreWidgetGroupBlock;
  CoreWidgetGroupBlockAttributes: CoreWidgetGroupBlockAttributes;
  CoreWidgetGroupBlockDeprecatedV1Attributes: CoreWidgetGroupBlockDeprecatedV1Attributes;
  CreateBlockEditorPreviewPayload: CreateBlockEditorPreviewPayload;
  CreateCategoryPayload: CreateCategoryPayload;
  CreateCommentPayload: CreateCommentPayload;
  CreateCompanyCategoryPayload: CreateCompanyCategoryPayload;
  CreateCompanyPayload: CreateCompanyPayload;
  CreateGraphqlDocumentPayload: CreateGraphqlDocumentPayload;
  CreateMediaItemPayload: CreateMediaItemPayload;
  CreatePagePayload: CreatePagePayload;
  CreatePostFormatPayload: CreatePostFormatPayload;
  CreatePostPayload: CreatePostPayload;
  CreatePressCategoryPayload: CreatePressCategoryPayload;
  CreatePressPayload: CreatePressPayload;
  CreateReusableBlockPayload: CreateReusableBlockPayload;
  CreateTagPayload: CreateTagPayload;
  CreateTeamMemberPayload: CreateTeamMemberPayload;
  CreateUserPayload: CreateUserPayload;
  DefaultTemplate: DefaultTemplate;
  DeleteBlockEditorPreviewPayload: DeleteBlockEditorPreviewPayload;
  DeleteCategoryPayload: DeleteCategoryPayload;
  DeleteCommentPayload: DeleteCommentPayload;
  DeleteCompanyCategoryPayload: DeleteCompanyCategoryPayload;
  DeleteCompanyPayload: DeleteCompanyPayload;
  DeleteGraphqlDocumentPayload: DeleteGraphqlDocumentPayload;
  DeleteMediaItemPayload: DeleteMediaItemPayload;
  DeletePagePayload: DeletePagePayload;
  DeletePostFormatPayload: DeletePostFormatPayload;
  DeletePostPayload: DeletePostPayload;
  DeletePressCategoryPayload: DeletePressCategoryPayload;
  DeletePressPayload: DeletePressPayload;
  DeleteReusableBlockPayload: DeleteReusableBlockPayload;
  DeleteTagPayload: DeleteTagPayload;
  DeleteTeamMemberPayload: DeleteTeamMemberPayload;
  DeleteUserPayload: DeleteUserPayload;
  DiscussionSettings: DiscussionSettings;
  EnqueuedScript: EnqueuedScript;
  EnqueuedStylesheet: EnqueuedStylesheet;
  GeneralSettings: GeneralSettings;
  GenerateAuthorizationCodePayload: GenerateAuthorizationCodePayload;
  GraphqlDocument: GraphqlDocument;
  GraphqlDocumentToPreviewConnectionEdge: GraphqlDocumentToPreviewConnectionEdge;
  HierarchicalContentNodeToContentNodeAncestorsConnection: HierarchicalContentNodeToContentNodeAncestorsConnection;
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge: HierarchicalContentNodeToContentNodeAncestorsConnectionEdge;
  HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  HierarchicalContentNodeToContentNodeChildrenConnection: HierarchicalContentNodeToContentNodeChildrenConnection;
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge: HierarchicalContentNodeToContentNodeChildrenConnectionEdge;
  HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  HierarchicalContentNodeToParentContentNodeConnectionEdge: HierarchicalContentNodeToParentContentNodeConnectionEdge;
  Jam3AccordionBlock: Jam3AccordionBlock;
  Jam3AccordionBlockAttributes: Jam3AccordionBlockAttributes;
  Jam3AccordionItemBlock: Jam3AccordionItemBlock;
  Jam3AccordionItemBlockAttributes: Jam3AccordionItemBlockAttributes;
  Jam3AcfCompanyListingImgBlock: Jam3AcfCompanyListingImgBlock;
  Jam3AcfCompanyListingImgBlockAttributes: Jam3AcfCompanyListingImgBlockAttributes;
  Jam3AcfCompanyLogoBlock: Jam3AcfCompanyLogoBlock;
  Jam3AcfCompanyLogoBlockAttributes: Jam3AcfCompanyLogoBlockAttributes;
  Jam3CarouselBlock: Jam3CarouselBlock;
  Jam3CarouselBlockAttributes: Jam3CarouselBlockAttributes;
  Jam3CarouselItemBlock: Jam3CarouselItemBlock;
  Jam3CarouselItemBlockAttributes: Jam3CarouselItemBlockAttributes;
  Jam3CompanyImageWithLogoBlock: Jam3CompanyImageWithLogoBlock;
  Jam3CompanyImageWithLogoBlockAttributes: Jam3CompanyImageWithLogoBlockAttributes;
  Jam3CompanyInfoBlock: Jam3CompanyInfoBlock;
  Jam3CompanyInfoBlockAttributes: Jam3CompanyInfoBlockAttributes;
  Jam3CompanyInfoItemBlock: Jam3CompanyInfoItemBlock;
  Jam3CompanyInfoItemBlockAttributes: Jam3CompanyInfoItemBlockAttributes;
  Jam3NewsletterBlock: Jam3NewsletterBlock;
  Jam3NewsletterBlockAttributes: Jam3NewsletterBlockAttributes;
  Jam3ReadTimeBlock: Jam3ReadTimeBlock;
  Jam3ReadTimeBlockAttributes: Jam3ReadTimeBlockAttributes;
  Jam3RotateTextBlock: Jam3RotateTextBlock;
  Jam3RotateTextBlockAttributes: Jam3RotateTextBlockAttributes;
  Jam3SocialShareBlock: Jam3SocialShareBlock;
  Jam3SocialShareBlockAttributes: Jam3SocialShareBlockAttributes;
  Jam3TeamListingBlock: Jam3TeamListingBlock;
  Jam3TeamListingBlockAttributes: Jam3TeamListingBlockAttributes;
  MediaDetails: MediaDetails;
  MediaItem: MediaItem;
  MediaItemMeta: MediaItemMeta;
  MediaItemToCommentConnection: MediaItemToCommentConnection;
  MediaItemToCommentConnectionEdge: MediaItemToCommentConnectionEdge;
  MediaItemToCommentConnectionPageInfo: MediaItemToCommentConnectionPageInfo;
  MediaSize: MediaSize;
  Menu: Menu;
  MenuItem: MenuItem;
  MenuItemToMenuConnectionEdge: MenuItemToMenuConnectionEdge;
  MenuItemToMenuItemConnection: MenuItemToMenuItemConnection;
  MenuItemToMenuItemConnectionEdge: MenuItemToMenuItemConnectionEdge;
  MenuItemToMenuItemConnectionPageInfo: MenuItemToMenuItemConnectionPageInfo;
  MenuItemToMenuItemLinkableConnectionEdge: MenuItemToMenuItemLinkableConnectionEdge;
  MenuToMenuItemConnection: MenuToMenuItemConnection;
  MenuToMenuItemConnectionEdge: MenuToMenuItemConnectionEdge;
  MenuToMenuItemConnectionPageInfo: MenuToMenuItemConnectionPageInfo;
  MonsterinsightsPopularPostsInlineBlock: MonsterinsightsPopularPostsInlineBlock;
  MonsterinsightsPopularPostsInlineBlockAttributes: MonsterinsightsPopularPostsInlineBlockAttributes;
  MonsterinsightsPopularPostsWidgetBlock: MonsterinsightsPopularPostsWidgetBlock;
  MonsterinsightsPopularPostsWidgetBlockAttributes: MonsterinsightsPopularPostsWidgetBlockAttributes;
  Mutation: Mutation;
  NodeWithAuthorToUserConnectionEdge: NodeWithAuthorToUserConnectionEdge;
  NodeWithFeaturedImageToMediaItemConnectionEdge: NodeWithFeaturedImageToMediaItemConnectionEdge;
  NodeWithRevisionsToContentNodeConnectionEdge: NodeWithRevisionsToContentNodeConnectionEdge;
  OffsetPaginationPageInfo: OffsetPaginationPageInfo;
  Page: Page;
  PageToCommentConnection: PageToCommentConnection;
  PageToCommentConnectionEdge: PageToCommentConnectionEdge;
  PageToCommentConnectionPageInfo: PageToCommentConnectionPageInfo;
  PageToPreviewConnectionEdge: PageToPreviewConnectionEdge;
  PageToRevisionConnection: PageToRevisionConnection;
  PageToRevisionConnectionEdge: PageToRevisionConnectionEdge;
  PageToRevisionConnectionPageInfo: PageToRevisionConnectionPageInfo;
  Plugin: Plugin;
  Post: Post;
  PostFormat: PostFormat;
  PostFormatToContentNodeConnection: PostFormatToContentNodeConnection;
  PostFormatToContentNodeConnectionEdge: PostFormatToContentNodeConnectionEdge;
  PostFormatToContentNodeConnectionPageInfo: PostFormatToContentNodeConnectionPageInfo;
  PostFormatToPostConnection: PostFormatToPostConnection;
  PostFormatToPostConnectionEdge: PostFormatToPostConnectionEdge;
  PostFormatToPostConnectionPageInfo: PostFormatToPostConnectionPageInfo;
  PostFormatToTaxonomyConnectionEdge: PostFormatToTaxonomyConnectionEdge;
  PostToCategoryConnection: PostToCategoryConnection;
  PostToCategoryConnectionEdge: PostToCategoryConnectionEdge;
  PostToCategoryConnectionPageInfo: PostToCategoryConnectionPageInfo;
  PostToCommentConnection: PostToCommentConnection;
  PostToCommentConnectionEdge: PostToCommentConnectionEdge;
  PostToCommentConnectionPageInfo: PostToCommentConnectionPageInfo;
  PostToPostFormatConnection: PostToPostFormatConnection;
  PostToPostFormatConnectionEdge: PostToPostFormatConnectionEdge;
  PostToPostFormatConnectionPageInfo: PostToPostFormatConnectionPageInfo;
  PostToPreviewConnectionEdge: PostToPreviewConnectionEdge;
  PostToRevisionConnection: PostToRevisionConnection;
  PostToRevisionConnectionEdge: PostToRevisionConnectionEdge;
  PostToRevisionConnectionPageInfo: PostToRevisionConnectionPageInfo;
  PostToTagConnection: PostToTagConnection;
  PostToTagConnectionEdge: PostToTagConnectionEdge;
  PostToTagConnectionPageInfo: PostToTagConnectionPageInfo;
  PostToTermNodeConnection: PostToTermNodeConnection;
  PostToTermNodeConnectionEdge: PostToTermNodeConnectionEdge;
  PostToTermNodeConnectionPageInfo: PostToTermNodeConnectionPageInfo;
  PostTypeLabelDetails: PostTypeLabelDetails;
  PostTypeSEO: PostTypeSEO;
  Post_Blogpost: Post_Blogpost;
  Press: Press;
  PressCategory: PressCategory;
  PressCategoryToAncestorsPressCategoryConnection: PressCategoryToAncestorsPressCategoryConnection;
  PressCategoryToAncestorsPressCategoryConnectionEdge: PressCategoryToAncestorsPressCategoryConnectionEdge;
  PressCategoryToAncestorsPressCategoryConnectionPageInfo: PressCategoryToAncestorsPressCategoryConnectionPageInfo;
  PressCategoryToContentNodeConnection: PressCategoryToContentNodeConnection;
  PressCategoryToContentNodeConnectionEdge: PressCategoryToContentNodeConnectionEdge;
  PressCategoryToContentNodeConnectionPageInfo: PressCategoryToContentNodeConnectionPageInfo;
  PressCategoryToParentPressCategoryConnectionEdge: PressCategoryToParentPressCategoryConnectionEdge;
  PressCategoryToPressCategoryConnection: PressCategoryToPressCategoryConnection;
  PressCategoryToPressCategoryConnectionEdge: PressCategoryToPressCategoryConnectionEdge;
  PressCategoryToPressCategoryConnectionPageInfo: PressCategoryToPressCategoryConnectionPageInfo;
  PressCategoryToPressConnection: PressCategoryToPressConnection;
  PressCategoryToPressConnectionEdge: PressCategoryToPressConnectionEdge;
  PressCategoryToPressConnectionPageInfo: PressCategoryToPressConnectionPageInfo;
  PressCategoryToTaxonomyConnectionEdge: PressCategoryToTaxonomyConnectionEdge;
  PressCategoryToTeamMemberConnection: PressCategoryToTeamMemberConnection;
  PressCategoryToTeamMemberConnectionEdge: PressCategoryToTeamMemberConnectionEdge;
  PressCategoryToTeamMemberConnectionPageInfo: PressCategoryToTeamMemberConnectionPageInfo;
  PressToPressCategoryConnection: PressToPressCategoryConnection;
  PressToPressCategoryConnectionEdge: PressToPressCategoryConnectionEdge;
  PressToPressCategoryConnectionPageInfo: PressToPressCategoryConnectionPageInfo;
  PressToPreviewConnectionEdge: PressToPreviewConnectionEdge;
  PressToTermNodeConnection: PressToTermNodeConnection;
  PressToTermNodeConnectionEdge: PressToTermNodeConnectionEdge;
  PressToTermNodeConnectionPageInfo: PressToTermNodeConnectionPageInfo;
  Press_Acfpress: Press_Acfpress;
  Query: Query;
  ReadingSettings: ReadingSettings;
  RegisterUserPayload: RegisterUserPayload;
  ResetUserPasswordPayload: ResetUserPasswordPayload;
  RestoreCommentPayload: RestoreCommentPayload;
  ReusableBlock: ReusableBlock;
  ReusableBlockToPreviewConnectionEdge: ReusableBlockToPreviewConnectionEdge;
  ReusableBlockToRevisionConnection: ReusableBlockToRevisionConnection;
  ReusableBlockToRevisionConnectionEdge: ReusableBlockToRevisionConnectionEdge;
  ReusableBlockToRevisionConnectionPageInfo: ReusableBlockToRevisionConnectionPageInfo;
  RootQueryToBlockEditorPreviewConnection: RootQueryToBlockEditorPreviewConnection;
  RootQueryToBlockEditorPreviewConnectionEdge: RootQueryToBlockEditorPreviewConnectionEdge;
  RootQueryToBlockEditorPreviewConnectionPageInfo: RootQueryToBlockEditorPreviewConnectionPageInfo;
  RootQueryToCategoryConnection: RootQueryToCategoryConnection;
  RootQueryToCategoryConnectionEdge: RootQueryToCategoryConnectionEdge;
  RootQueryToCategoryConnectionPageInfo: RootQueryToCategoryConnectionPageInfo;
  RootQueryToCommentConnection: RootQueryToCommentConnection;
  RootQueryToCommentConnectionEdge: RootQueryToCommentConnectionEdge;
  RootQueryToCommentConnectionPageInfo: RootQueryToCommentConnectionPageInfo;
  RootQueryToCompanyCategoryConnection: RootQueryToCompanyCategoryConnection;
  RootQueryToCompanyCategoryConnectionEdge: RootQueryToCompanyCategoryConnectionEdge;
  RootQueryToCompanyCategoryConnectionPageInfo: RootQueryToCompanyCategoryConnectionPageInfo;
  RootQueryToCompanyConnection: RootQueryToCompanyConnection;
  RootQueryToCompanyConnectionEdge: RootQueryToCompanyConnectionEdge;
  RootQueryToCompanyConnectionPageInfo: RootQueryToCompanyConnectionPageInfo;
  RootQueryToContentNodeConnection: RootQueryToContentNodeConnection;
  RootQueryToContentNodeConnectionEdge: RootQueryToContentNodeConnectionEdge;
  RootQueryToContentNodeConnectionPageInfo: RootQueryToContentNodeConnectionPageInfo;
  RootQueryToContentTypeConnection: RootQueryToContentTypeConnection;
  RootQueryToContentTypeConnectionEdge: RootQueryToContentTypeConnectionEdge;
  RootQueryToContentTypeConnectionPageInfo: RootQueryToContentTypeConnectionPageInfo;
  RootQueryToEnqueuedScriptConnection: RootQueryToEnqueuedScriptConnection;
  RootQueryToEnqueuedScriptConnectionEdge: RootQueryToEnqueuedScriptConnectionEdge;
  RootQueryToEnqueuedScriptConnectionPageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
  RootQueryToEnqueuedStylesheetConnection: RootQueryToEnqueuedStylesheetConnection;
  RootQueryToEnqueuedStylesheetConnectionEdge: RootQueryToEnqueuedStylesheetConnectionEdge;
  RootQueryToEnqueuedStylesheetConnectionPageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  RootQueryToGraphqlDocumentConnection: RootQueryToGraphqlDocumentConnection;
  RootQueryToGraphqlDocumentConnectionEdge: RootQueryToGraphqlDocumentConnectionEdge;
  RootQueryToGraphqlDocumentConnectionPageInfo: RootQueryToGraphqlDocumentConnectionPageInfo;
  RootQueryToMediaItemConnection: RootQueryToMediaItemConnection;
  RootQueryToMediaItemConnectionEdge: RootQueryToMediaItemConnectionEdge;
  RootQueryToMediaItemConnectionPageInfo: RootQueryToMediaItemConnectionPageInfo;
  RootQueryToMenuConnection: RootQueryToMenuConnection;
  RootQueryToMenuConnectionEdge: RootQueryToMenuConnectionEdge;
  RootQueryToMenuConnectionPageInfo: RootQueryToMenuConnectionPageInfo;
  RootQueryToMenuItemConnection: RootQueryToMenuItemConnection;
  RootQueryToMenuItemConnectionEdge: RootQueryToMenuItemConnectionEdge;
  RootQueryToMenuItemConnectionPageInfo: RootQueryToMenuItemConnectionPageInfo;
  RootQueryToPageConnection: RootQueryToPageConnection;
  RootQueryToPageConnectionEdge: RootQueryToPageConnectionEdge;
  RootQueryToPageConnectionPageInfo: RootQueryToPageConnectionPageInfo;
  RootQueryToPluginConnection: RootQueryToPluginConnection;
  RootQueryToPluginConnectionEdge: RootQueryToPluginConnectionEdge;
  RootQueryToPluginConnectionPageInfo: RootQueryToPluginConnectionPageInfo;
  RootQueryToPostConnection: RootQueryToPostConnection;
  RootQueryToPostConnectionEdge: RootQueryToPostConnectionEdge;
  RootQueryToPostConnectionPageInfo: RootQueryToPostConnectionPageInfo;
  RootQueryToPostFormatConnection: RootQueryToPostFormatConnection;
  RootQueryToPostFormatConnectionEdge: RootQueryToPostFormatConnectionEdge;
  RootQueryToPostFormatConnectionPageInfo: RootQueryToPostFormatConnectionPageInfo;
  RootQueryToPressCategoryConnection: RootQueryToPressCategoryConnection;
  RootQueryToPressCategoryConnectionEdge: RootQueryToPressCategoryConnectionEdge;
  RootQueryToPressCategoryConnectionPageInfo: RootQueryToPressCategoryConnectionPageInfo;
  RootQueryToPressConnection: RootQueryToPressConnection;
  RootQueryToPressConnectionEdge: RootQueryToPressConnectionEdge;
  RootQueryToPressConnectionPageInfo: RootQueryToPressConnectionPageInfo;
  RootQueryToReusableBlockConnection: RootQueryToReusableBlockConnection;
  RootQueryToReusableBlockConnectionEdge: RootQueryToReusableBlockConnectionEdge;
  RootQueryToReusableBlockConnectionPageInfo: RootQueryToReusableBlockConnectionPageInfo;
  RootQueryToRevisionsConnection: RootQueryToRevisionsConnection;
  RootQueryToRevisionsConnectionEdge: RootQueryToRevisionsConnectionEdge;
  RootQueryToRevisionsConnectionPageInfo: RootQueryToRevisionsConnectionPageInfo;
  RootQueryToTagConnection: RootQueryToTagConnection;
  RootQueryToTagConnectionEdge: RootQueryToTagConnectionEdge;
  RootQueryToTagConnectionPageInfo: RootQueryToTagConnectionPageInfo;
  RootQueryToTaxonomyConnection: RootQueryToTaxonomyConnection;
  RootQueryToTaxonomyConnectionEdge: RootQueryToTaxonomyConnectionEdge;
  RootQueryToTaxonomyConnectionPageInfo: RootQueryToTaxonomyConnectionPageInfo;
  RootQueryToTeamMemberConnection: RootQueryToTeamMemberConnection;
  RootQueryToTeamMemberConnectionEdge: RootQueryToTeamMemberConnectionEdge;
  RootQueryToTeamMemberConnectionPageInfo: RootQueryToTeamMemberConnectionPageInfo;
  RootQueryToTermNodeConnection: RootQueryToTermNodeConnection;
  RootQueryToTermNodeConnectionEdge: RootQueryToTermNodeConnectionEdge;
  RootQueryToTermNodeConnectionPageInfo: RootQueryToTermNodeConnectionPageInfo;
  RootQueryToThemeConnection: RootQueryToThemeConnection;
  RootQueryToThemeConnectionEdge: RootQueryToThemeConnectionEdge;
  RootQueryToThemeConnectionPageInfo: RootQueryToThemeConnectionPageInfo;
  RootQueryToUserConnection: RootQueryToUserConnection;
  RootQueryToUserConnectionEdge: RootQueryToUserConnectionEdge;
  RootQueryToUserConnectionPageInfo: RootQueryToUserConnectionPageInfo;
  RootQueryToUserRoleConnection: RootQueryToUserRoleConnection;
  RootQueryToUserRoleConnectionEdge: RootQueryToUserRoleConnectionEdge;
  RootQueryToUserRoleConnectionPageInfo: RootQueryToUserRoleConnectionPageInfo;
  SEOBreadcrumbs: SEOBreadcrumbs;
  SEOConfig: SEOConfig;
  SEOContentType: SEOContentType;
  SEOContentTypeArchive: SEOContentTypeArchive;
  SEOContentTypes: SEOContentTypes;
  SEOOpenGraph: SEOOpenGraph;
  SEOOpenGraphFrontPage: SEOOpenGraphFrontPage;
  SEOPageInfoSchema: SEOPageInfoSchema;
  SEOPostTypeBreadcrumbs: SEOPostTypeBreadcrumbs;
  SEOPostTypePageInfo: SEOPostTypePageInfo;
  SEOPostTypeSchema: SEOPostTypeSchema;
  SEORedirect: SEORedirect;
  SEOSchema: SEOSchema;
  SEOSocial: SEOSocial;
  SEOSocialFacebook: SEOSocialFacebook;
  SEOSocialInstagram: SEOSocialInstagram;
  SEOSocialLinkedIn: SEOSocialLinkedIn;
  SEOSocialMySpace: SEOSocialMySpace;
  SEOSocialPinterest: SEOSocialPinterest;
  SEOSocialTwitter: SEOSocialTwitter;
  SEOSocialWikipedia: SEOSocialWikipedia;
  SEOSocialYoutube: SEOSocialYoutube;
  SEOTaxonomySchema: SEOTaxonomySchema;
  SEOUser: SEOUser;
  SEOUserSchema: SEOUserSchema;
  SEOUserSocial: SEOUserSocial;
  SEOWebmaster: SEOWebmaster;
  SendPasswordResetEmailPayload: SendPasswordResetEmailPayload;
  Settings: Settings;
  Subscription: Subscription;
  Tag: Tag;
  TagToContentNodeConnection: TagToContentNodeConnection;
  TagToContentNodeConnectionEdge: TagToContentNodeConnectionEdge;
  TagToContentNodeConnectionPageInfo: TagToContentNodeConnectionPageInfo;
  TagToPostConnection: TagToPostConnection;
  TagToPostConnectionEdge: TagToPostConnectionEdge;
  TagToPostConnectionPageInfo: TagToPostConnectionPageInfo;
  TagToTaxonomyConnectionEdge: TagToTaxonomyConnectionEdge;
  Taxonomy: Taxonomy;
  TaxonomySEO: TaxonomySEO;
  TaxonomyToContentTypeConnection: TaxonomyToContentTypeConnection;
  TaxonomyToContentTypeConnectionEdge: TaxonomyToContentTypeConnectionEdge;
  TaxonomyToContentTypeConnectionPageInfo: TaxonomyToContentTypeConnectionPageInfo;
  TaxonomyToTermNodeConnection: TaxonomyToTermNodeConnection;
  TaxonomyToTermNodeConnectionEdge: TaxonomyToTermNodeConnectionEdge;
  TaxonomyToTermNodeConnectionPageInfo: TaxonomyToTermNodeConnectionPageInfo;
  TeamMember: TeamMember;
  TeamMemberToPressCategoryConnection: TeamMemberToPressCategoryConnection;
  TeamMemberToPressCategoryConnectionEdge: TeamMemberToPressCategoryConnectionEdge;
  TeamMemberToPressCategoryConnectionPageInfo: TeamMemberToPressCategoryConnectionPageInfo;
  TeamMemberToPreviewConnectionEdge: TeamMemberToPreviewConnectionEdge;
  TeamMemberToTermNodeConnection: TeamMemberToTermNodeConnection;
  TeamMemberToTermNodeConnectionEdge: TeamMemberToTermNodeConnectionEdge;
  TeamMemberToTermNodeConnectionPageInfo: TeamMemberToTermNodeConnectionPageInfo;
  TeamMember_Team: TeamMember_Team;
  TeamMember_Team_boardPositions: TeamMember_Team_boardPositions;
  TeamMember_Teaminvestments: TeamMember_Teaminvestments;
  TermNodeToEnqueuedScriptConnection: TermNodeToEnqueuedScriptConnection;
  TermNodeToEnqueuedScriptConnectionEdge: TermNodeToEnqueuedScriptConnectionEdge;
  TermNodeToEnqueuedScriptConnectionPageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
  TermNodeToEnqueuedStylesheetConnection: TermNodeToEnqueuedStylesheetConnection;
  TermNodeToEnqueuedStylesheetConnectionEdge: TermNodeToEnqueuedStylesheetConnectionEdge;
  TermNodeToEnqueuedStylesheetConnectionPageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  Theme: Theme;
  UpdateBlockEditorPreviewPayload: UpdateBlockEditorPreviewPayload;
  UpdateCategoryPayload: UpdateCategoryPayload;
  UpdateCommentPayload: UpdateCommentPayload;
  UpdateCompanyCategoryPayload: UpdateCompanyCategoryPayload;
  UpdateCompanyPayload: UpdateCompanyPayload;
  UpdateGraphqlDocumentPayload: UpdateGraphqlDocumentPayload;
  UpdateMediaItemPayload: UpdateMediaItemPayload;
  UpdatePagePayload: UpdatePagePayload;
  UpdatePostFormatPayload: UpdatePostFormatPayload;
  UpdatePostPayload: UpdatePostPayload;
  UpdatePressCategoryPayload: UpdatePressCategoryPayload;
  UpdatePressPayload: UpdatePressPayload;
  UpdateReusableBlockPayload: UpdateReusableBlockPayload;
  UpdateSettingsPayload: UpdateSettingsPayload;
  UpdateTagPayload: UpdateTagPayload;
  UpdateTeamMemberPayload: UpdateTeamMemberPayload;
  UpdateUserPayload: UpdateUserPayload;
  User: User;
  UserRole: UserRole;
  UserToBlockEditorPreviewConnection: UserToBlockEditorPreviewConnection;
  UserToBlockEditorPreviewConnectionEdge: UserToBlockEditorPreviewConnectionEdge;
  UserToBlockEditorPreviewConnectionPageInfo: UserToBlockEditorPreviewConnectionPageInfo;
  UserToCommentConnection: UserToCommentConnection;
  UserToCommentConnectionEdge: UserToCommentConnectionEdge;
  UserToCommentConnectionPageInfo: UserToCommentConnectionPageInfo;
  UserToEnqueuedScriptConnection: UserToEnqueuedScriptConnection;
  UserToEnqueuedScriptConnectionEdge: UserToEnqueuedScriptConnectionEdge;
  UserToEnqueuedScriptConnectionPageInfo: UserToEnqueuedScriptConnectionPageInfo;
  UserToEnqueuedStylesheetConnection: UserToEnqueuedStylesheetConnection;
  UserToEnqueuedStylesheetConnectionEdge: UserToEnqueuedStylesheetConnectionEdge;
  UserToEnqueuedStylesheetConnectionPageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
  UserToMediaItemConnection: UserToMediaItemConnection;
  UserToMediaItemConnectionEdge: UserToMediaItemConnectionEdge;
  UserToMediaItemConnectionPageInfo: UserToMediaItemConnectionPageInfo;
  UserToPageConnection: UserToPageConnection;
  UserToPageConnectionEdge: UserToPageConnectionEdge;
  UserToPageConnectionPageInfo: UserToPageConnectionPageInfo;
  UserToPostConnection: UserToPostConnection;
  UserToPostConnectionEdge: UserToPostConnectionEdge;
  UserToPostConnectionPageInfo: UserToPostConnectionPageInfo;
  UserToRevisionsConnection: UserToRevisionsConnection;
  UserToRevisionsConnectionEdge: UserToRevisionsConnectionEdge;
  UserToRevisionsConnectionPageInfo: UserToRevisionsConnectionPageInfo;
  UserToUserRoleConnection: UserToUserRoleConnection;
  UserToUserRoleConnectionEdge: UserToUserRoleConnectionEdge;
  UserToUserRoleConnectionPageInfo: UserToUserRoleConnectionPageInfo;
  WritingSettings: WritingSettings;
  YoastFaqBlock: YoastFaqBlock;
  YoastFaqBlockAttributes: YoastFaqBlockAttributes;
  YoastFaqBlockDeprecatedV1Attributes: YoastFaqBlockDeprecatedV1Attributes;
  YoastHowToBlock: YoastHowToBlock;
  YoastHowToBlockAttributes: YoastHowToBlockAttributes;
  YoastHowToBlockDeprecatedV1Attributes: YoastHowToBlockDeprecatedV1Attributes;
  YoastHowToBlockDeprecatedV2Attributes: YoastHowToBlockDeprecatedV2Attributes;
  YoastSeoBreadcrumbsBlock: YoastSeoBreadcrumbsBlock;
  YoastSeoBreadcrumbsBlockAttributes: YoastSeoBreadcrumbsBlockAttributes;
}
export type SchemaObjectTypesNames =
  | 'AcfOptionsFooter'
  | 'AcfOptionsFooter_Footersettings'
  | 'AcfOptionsFooter_Footersettings_externalLinks'
  | 'AcfOptionsFooter_Footersettings_links'
  | 'AcfOptionsFooter_Footersettings_socialMedia'
  | 'AcfOptionsNotifications'
  | 'AcfOptionsNotifications_Websitenotificationbanner'
  | 'Avatar'
  | 'BlockEditorContentNodeConnection'
  | 'BlockEditorContentNodeConnectionEdge'
  | 'BlockEditorContentNodeConnectionPageInfo'
  | 'BlockEditorPreview'
  | 'BlockEditorPreviewToPreviewConnectionEdge'
  | 'Category'
  | 'CategoryToAncestorsCategoryConnection'
  | 'CategoryToAncestorsCategoryConnectionEdge'
  | 'CategoryToAncestorsCategoryConnectionPageInfo'
  | 'CategoryToCategoryConnection'
  | 'CategoryToCategoryConnectionEdge'
  | 'CategoryToCategoryConnectionPageInfo'
  | 'CategoryToContentNodeConnection'
  | 'CategoryToContentNodeConnectionEdge'
  | 'CategoryToContentNodeConnectionPageInfo'
  | 'CategoryToParentCategoryConnectionEdge'
  | 'CategoryToPostConnection'
  | 'CategoryToPostConnectionEdge'
  | 'CategoryToPostConnectionPageInfo'
  | 'CategoryToTaxonomyConnectionEdge'
  | 'Comment'
  | 'CommentAuthor'
  | 'CommentToCommentConnection'
  | 'CommentToCommentConnectionEdge'
  | 'CommentToCommentConnectionPageInfo'
  | 'CommentToCommenterConnectionEdge'
  | 'CommentToContentNodeConnectionEdge'
  | 'CommentToParentCommentConnectionEdge'
  | 'Company'
  | 'CompanyCategory'
  | 'CompanyCategoryToAncestorsCompanyCategoryConnection'
  | 'CompanyCategoryToAncestorsCompanyCategoryConnectionEdge'
  | 'CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo'
  | 'CompanyCategoryToCompanyCategoryConnection'
  | 'CompanyCategoryToCompanyCategoryConnectionEdge'
  | 'CompanyCategoryToCompanyCategoryConnectionPageInfo'
  | 'CompanyCategoryToCompanyConnection'
  | 'CompanyCategoryToCompanyConnectionEdge'
  | 'CompanyCategoryToCompanyConnectionPageInfo'
  | 'CompanyCategoryToContentNodeConnection'
  | 'CompanyCategoryToContentNodeConnectionEdge'
  | 'CompanyCategoryToContentNodeConnectionPageInfo'
  | 'CompanyCategoryToParentCompanyCategoryConnectionEdge'
  | 'CompanyCategoryToTaxonomyConnectionEdge'
  | 'CompanyToCompanyCategoryConnection'
  | 'CompanyToCompanyCategoryConnectionEdge'
  | 'CompanyToCompanyCategoryConnectionPageInfo'
  | 'CompanyToPreviewConnectionEdge'
  | 'CompanyToTermNodeConnection'
  | 'CompanyToTermNodeConnectionEdge'
  | 'CompanyToTermNodeConnectionPageInfo'
  | 'Company_Companies'
  | 'Company_Companies_info'
  | 'ConditionalTags'
  | 'ContentNodeToContentTypeConnectionEdge'
  | 'ContentNodeToEditLastConnectionEdge'
  | 'ContentNodeToEditLockConnectionEdge'
  | 'ContentNodeToEnqueuedScriptConnection'
  | 'ContentNodeToEnqueuedScriptConnectionEdge'
  | 'ContentNodeToEnqueuedScriptConnectionPageInfo'
  | 'ContentNodeToEnqueuedStylesheetConnection'
  | 'ContentNodeToEnqueuedStylesheetConnectionEdge'
  | 'ContentNodeToEnqueuedStylesheetConnectionPageInfo'
  | 'ContentType'
  | 'ContentTypeToContentNodeConnection'
  | 'ContentTypeToContentNodeConnectionEdge'
  | 'ContentTypeToContentNodeConnectionPageInfo'
  | 'ContentTypeToTaxonomyConnection'
  | 'ContentTypeToTaxonomyConnectionEdge'
  | 'ContentTypeToTaxonomyConnectionPageInfo'
  | 'CoreArchivesBlock'
  | 'CoreArchivesBlockAttributes'
  | 'CoreAudioBlock'
  | 'CoreAudioBlockAttributes'
  | 'CoreAudioBlockDeprecatedV1Attributes'
  | 'CoreAvatarBlock'
  | 'CoreAvatarBlockAttributes'
  | 'CoreBlock'
  | 'CoreBlockAttributes'
  | 'CoreBlockDeprecatedV1Attributes'
  | 'CoreBlockDeprecatedV2Attributes'
  | 'CoreButtonBlock'
  | 'CoreButtonBlockAttributes'
  | 'CoreButtonBlockDeprecatedV10Attributes'
  | 'CoreButtonBlockDeprecatedV11Attributes'
  | 'CoreButtonBlockDeprecatedV1Attributes'
  | 'CoreButtonBlockDeprecatedV2Attributes'
  | 'CoreButtonBlockDeprecatedV3Attributes'
  | 'CoreButtonBlockDeprecatedV4Attributes'
  | 'CoreButtonBlockDeprecatedV5Attributes'
  | 'CoreButtonBlockDeprecatedV6Attributes'
  | 'CoreButtonBlockDeprecatedV7Attributes'
  | 'CoreButtonBlockDeprecatedV8Attributes'
  | 'CoreButtonBlockDeprecatedV9Attributes'
  | 'CoreButtonsBlock'
  | 'CoreButtonsBlockAttributes'
  | 'CoreButtonsBlockDeprecatedV1Attributes'
  | 'CoreButtonsBlockDeprecatedV2Attributes'
  | 'CoreCalendarBlock'
  | 'CoreCalendarBlockAttributes'
  | 'CoreCategoriesBlock'
  | 'CoreCategoriesBlockAttributes'
  | 'CoreCodeBlock'
  | 'CoreCodeBlockAttributes'
  | 'CoreColumnBlock'
  | 'CoreColumnBlockAttributes'
  | 'CoreColumnBlockDeprecatedV1Attributes'
  | 'CoreColumnsBlock'
  | 'CoreColumnsBlockAttributes'
  | 'CoreColumnsBlockDeprecatedV1Attributes'
  | 'CoreColumnsBlockDeprecatedV2Attributes'
  | 'CoreColumnsBlockDeprecatedV3Attributes'
  | 'CoreCommentAuthorNameBlock'
  | 'CoreCommentAuthorNameBlockAttributes'
  | 'CoreCommentAuthorNameBlockDeprecatedV1Attributes'
  | 'CoreCommentContentBlock'
  | 'CoreCommentContentBlockAttributes'
  | 'CoreCommentDateBlock'
  | 'CoreCommentDateBlockAttributes'
  | 'CoreCommentDateBlockDeprecatedV1Attributes'
  | 'CoreCommentEditLinkBlock'
  | 'CoreCommentEditLinkBlockAttributes'
  | 'CoreCommentReplyLinkBlock'
  | 'CoreCommentReplyLinkBlockAttributes'
  | 'CoreCommentTemplateBlock'
  | 'CoreCommentTemplateBlockAttributes'
  | 'CoreCommentsBlock'
  | 'CoreCommentsBlockAttributes'
  | 'CoreCommentsBlockDeprecatedV1Attributes'
  | 'CoreCommentsPaginationBlock'
  | 'CoreCommentsPaginationBlockAttributes'
  | 'CoreCommentsPaginationNextBlock'
  | 'CoreCommentsPaginationNextBlockAttributes'
  | 'CoreCommentsPaginationNumbersBlock'
  | 'CoreCommentsPaginationNumbersBlockAttributes'
  | 'CoreCommentsPaginationPreviousBlock'
  | 'CoreCommentsPaginationPreviousBlockAttributes'
  | 'CoreCommentsTitleBlock'
  | 'CoreCommentsTitleBlockAttributes'
  | 'CoreCommentsTitleBlockDeprecatedV1Attributes'
  | 'CoreCoverBlock'
  | 'CoreCoverBlockAttributes'
  | 'CoreCoverBlockDeprecatedV10Attributes'
  | 'CoreCoverBlockDeprecatedV11Attributes'
  | 'CoreCoverBlockDeprecatedV12Attributes'
  | 'CoreCoverBlockDeprecatedV13Attributes'
  | 'CoreCoverBlockDeprecatedV1Attributes'
  | 'CoreCoverBlockDeprecatedV2Attributes'
  | 'CoreCoverBlockDeprecatedV3Attributes'
  | 'CoreCoverBlockDeprecatedV4Attributes'
  | 'CoreCoverBlockDeprecatedV5Attributes'
  | 'CoreCoverBlockDeprecatedV6Attributes'
  | 'CoreCoverBlockDeprecatedV7Attributes'
  | 'CoreCoverBlockDeprecatedV8Attributes'
  | 'CoreCoverBlockDeprecatedV9Attributes'
  | 'CoreDetailsBlock'
  | 'CoreDetailsBlockAttributes'
  | 'CoreEmbedBlock'
  | 'CoreEmbedBlockAttributes'
  | 'CoreEmbedBlockDeprecatedV1Attributes'
  | 'CoreEmbedBlockDeprecatedV2Attributes'
  | 'CoreFileBlock'
  | 'CoreFileBlockAttributes'
  | 'CoreFileBlockDeprecatedV1Attributes'
  | 'CoreFileBlockDeprecatedV2Attributes'
  | 'CoreFileBlockDeprecatedV3Attributes'
  | 'CoreFootnotesBlock'
  | 'CoreFootnotesBlockAttributes'
  | 'CoreFreeformBlock'
  | 'CoreFreeformBlockAttributes'
  | 'CoreGalleryBlock'
  | 'CoreGalleryBlockAttributes'
  | 'CoreGalleryBlockAttributesImages'
  | 'CoreGalleryBlockDeprecatedV1Attributes'
  | 'CoreGalleryBlockDeprecatedV1AttributesImages'
  | 'CoreGalleryBlockDeprecatedV2Attributes'
  | 'CoreGalleryBlockDeprecatedV2AttributesImages'
  | 'CoreGalleryBlockDeprecatedV3Attributes'
  | 'CoreGalleryBlockDeprecatedV3AttributesImages'
  | 'CoreGalleryBlockDeprecatedV4Attributes'
  | 'CoreGalleryBlockDeprecatedV4AttributesImages'
  | 'CoreGalleryBlockDeprecatedV5Attributes'
  | 'CoreGalleryBlockDeprecatedV5AttributesImages'
  | 'CoreGalleryBlockDeprecatedV6Attributes'
  | 'CoreGalleryBlockDeprecatedV6AttributesImages'
  | 'CoreGalleryBlockDeprecatedV7Attributes'
  | 'CoreGalleryBlockDeprecatedV7AttributesImages'
  | 'CoreGroupBlock'
  | 'CoreGroupBlockAttributes'
  | 'CoreGroupBlockDeprecatedV1Attributes'
  | 'CoreGroupBlockDeprecatedV2Attributes'
  | 'CoreGroupBlockDeprecatedV3Attributes'
  | 'CoreGroupBlockDeprecatedV4Attributes'
  | 'CoreGroupBlockDeprecatedV5Attributes'
  | 'CoreHeadingBlock'
  | 'CoreHeadingBlockAttributes'
  | 'CoreHeadingBlockDeprecatedV1Attributes'
  | 'CoreHeadingBlockDeprecatedV2Attributes'
  | 'CoreHeadingBlockDeprecatedV3Attributes'
  | 'CoreHeadingBlockDeprecatedV4Attributes'
  | 'CoreHeadingBlockDeprecatedV5Attributes'
  | 'CoreHomeLinkBlock'
  | 'CoreHomeLinkBlockAttributes'
  | 'CoreHtmlBlock'
  | 'CoreHtmlBlockAttributes'
  | 'CoreImageBlock'
  | 'CoreImageBlockAttributes'
  | 'CoreImageBlockDeprecatedV1Attributes'
  | 'CoreImageBlockDeprecatedV2Attributes'
  | 'CoreImageBlockDeprecatedV3Attributes'
  | 'CoreImageBlockDeprecatedV4Attributes'
  | 'CoreImageBlockDeprecatedV5Attributes'
  | 'CoreImageBlockDeprecatedV6Attributes'
  | 'CoreImageBlockDeprecatedV7Attributes'
  | 'CoreImageBlockDeprecatedV8Attributes'
  | 'CoreImageBlockToMediaItemConnectionEdge'
  | 'CoreLatestCommentsBlock'
  | 'CoreLatestCommentsBlockAttributes'
  | 'CoreLatestPostsBlock'
  | 'CoreLatestPostsBlockAttributes'
  | 'CoreLatestPostsBlockDeprecatedV1Attributes'
  | 'CoreLegacyWidgetBlock'
  | 'CoreLegacyWidgetBlockAttributes'
  | 'CoreListBlock'
  | 'CoreListBlockAttributes'
  | 'CoreListBlockDeprecatedV1Attributes'
  | 'CoreListBlockDeprecatedV2Attributes'
  | 'CoreListBlockDeprecatedV3Attributes'
  | 'CoreListItemBlock'
  | 'CoreListItemBlockAttributes'
  | 'CoreLoginoutBlock'
  | 'CoreLoginoutBlockAttributes'
  | 'CoreMediaTextBlock'
  | 'CoreMediaTextBlockAttributes'
  | 'CoreMediaTextBlockDeprecatedV1Attributes'
  | 'CoreMediaTextBlockDeprecatedV2Attributes'
  | 'CoreMediaTextBlockDeprecatedV3Attributes'
  | 'CoreMediaTextBlockDeprecatedV4Attributes'
  | 'CoreMediaTextBlockDeprecatedV5Attributes'
  | 'CoreMediaTextBlockDeprecatedV6Attributes'
  | 'CoreMissingBlock'
  | 'CoreMissingBlockAttributes'
  | 'CoreMoreBlock'
  | 'CoreMoreBlockAttributes'
  | 'CoreNavigationBlock'
  | 'CoreNavigationBlockAttributes'
  | 'CoreNavigationBlockDeprecatedV1Attributes'
  | 'CoreNavigationBlockDeprecatedV2Attributes'
  | 'CoreNavigationBlockDeprecatedV3Attributes'
  | 'CoreNavigationBlockDeprecatedV4Attributes'
  | 'CoreNavigationBlockDeprecatedV5Attributes'
  | 'CoreNavigationBlockDeprecatedV6Attributes'
  | 'CoreNavigationLinkBlock'
  | 'CoreNavigationLinkBlockAttributes'
  | 'CoreNavigationLinkBlockDeprecatedV1Attributes'
  | 'CoreNavigationSubmenuBlock'
  | 'CoreNavigationSubmenuBlockAttributes'
  | 'CoreNextpageBlock'
  | 'CoreNextpageBlockAttributes'
  | 'CorePageListBlock'
  | 'CorePageListBlockAttributes'
  | 'CorePageListItemBlock'
  | 'CorePageListItemBlockAttributes'
  | 'CoreParagraphBlock'
  | 'CoreParagraphBlockAttributes'
  | 'CoreParagraphBlockDeprecatedV1Attributes'
  | 'CoreParagraphBlockDeprecatedV2Attributes'
  | 'CoreParagraphBlockDeprecatedV3Attributes'
  | 'CoreParagraphBlockDeprecatedV4Attributes'
  | 'CoreParagraphBlockDeprecatedV5Attributes'
  | 'CoreParagraphBlockDeprecatedV6Attributes'
  | 'CorePatternBlock'
  | 'CorePatternBlockAttributes'
  | 'CorePostAuthorBiographyBlock'
  | 'CorePostAuthorBiographyBlockAttributes'
  | 'CorePostAuthorBlock'
  | 'CorePostAuthorBlockAttributes'
  | 'CorePostAuthorNameBlock'
  | 'CorePostAuthorNameBlockAttributes'
  | 'CorePostCommentsFormBlock'
  | 'CorePostCommentsFormBlockAttributes'
  | 'CorePostContentBlock'
  | 'CorePostContentBlockAttributes'
  | 'CorePostDateBlock'
  | 'CorePostDateBlockAttributes'
  | 'CorePostDateBlockDeprecatedV1Attributes'
  | 'CorePostExcerptBlock'
  | 'CorePostExcerptBlockAttributes'
  | 'CorePostFeaturedImageBlock'
  | 'CorePostFeaturedImageBlockAttributes'
  | 'CorePostNavigationLinkBlock'
  | 'CorePostNavigationLinkBlockAttributes'
  | 'CorePostTemplateBlock'
  | 'CorePostTemplateBlockAttributes'
  | 'CorePostTermsBlock'
  | 'CorePostTermsBlockAttributes'
  | 'CorePostTitleBlock'
  | 'CorePostTitleBlockAttributes'
  | 'CorePostTitleBlockDeprecatedV1Attributes'
  | 'CorePreformattedBlock'
  | 'CorePreformattedBlockAttributes'
  | 'CorePullquoteBlock'
  | 'CorePullquoteBlockAttributes'
  | 'CorePullquoteBlockDeprecatedV1Attributes'
  | 'CorePullquoteBlockDeprecatedV2Attributes'
  | 'CorePullquoteBlockDeprecatedV3Attributes'
  | 'CorePullquoteBlockDeprecatedV4Attributes'
  | 'CorePullquoteBlockDeprecatedV5Attributes'
  | 'CorePullquoteBlockDeprecatedV6Attributes'
  | 'CoreQueryBlock'
  | 'CoreQueryBlockAttributes'
  | 'CoreQueryBlockDeprecatedV1Attributes'
  | 'CoreQueryBlockDeprecatedV2Attributes'
  | 'CoreQueryBlockDeprecatedV3Attributes'
  | 'CoreQueryBlockDeprecatedV4Attributes'
  | 'CoreQueryBlockDeprecatedV5Attributes'
  | 'CoreQueryNoResultsBlock'
  | 'CoreQueryNoResultsBlockAttributes'
  | 'CoreQueryPaginationBlock'
  | 'CoreQueryPaginationBlockAttributes'
  | 'CoreQueryPaginationBlockDeprecatedV1Attributes'
  | 'CoreQueryPaginationNextBlock'
  | 'CoreQueryPaginationNextBlockAttributes'
  | 'CoreQueryPaginationNumbersBlock'
  | 'CoreQueryPaginationNumbersBlockAttributes'
  | 'CoreQueryPaginationPreviousBlock'
  | 'CoreQueryPaginationPreviousBlockAttributes'
  | 'CoreQueryTitleBlock'
  | 'CoreQueryTitleBlockAttributes'
  | 'CoreQueryTitleBlockDeprecatedV1Attributes'
  | 'CoreQuoteBlock'
  | 'CoreQuoteBlockAttributes'
  | 'CoreQuoteBlockDeprecatedV1Attributes'
  | 'CoreQuoteBlockDeprecatedV2Attributes'
  | 'CoreQuoteBlockDeprecatedV3Attributes'
  | 'CoreQuoteBlockDeprecatedV4Attributes'
  | 'CoreReadMoreBlock'
  | 'CoreReadMoreBlockAttributes'
  | 'CoreRssBlock'
  | 'CoreRssBlockAttributes'
  | 'CoreSearchBlock'
  | 'CoreSearchBlockAttributes'
  | 'CoreSeparatorBlock'
  | 'CoreSeparatorBlockAttributes'
  | 'CoreSeparatorBlockDeprecatedV1Attributes'
  | 'CoreShortcodeBlock'
  | 'CoreShortcodeBlockAttributes'
  | 'CoreSiteLogoBlock'
  | 'CoreSiteLogoBlockAttributes'
  | 'CoreSiteTaglineBlock'
  | 'CoreSiteTaglineBlockAttributes'
  | 'CoreSiteTaglineBlockDeprecatedV1Attributes'
  | 'CoreSiteTitleBlock'
  | 'CoreSiteTitleBlockAttributes'
  | 'CoreSiteTitleBlockDeprecatedV1Attributes'
  | 'CoreSocialLinkBlock'
  | 'CoreSocialLinkBlockAttributes'
  | 'CoreSocialLinksBlock'
  | 'CoreSocialLinksBlockAttributes'
  | 'CoreSocialLinksBlockDeprecatedV1Attributes'
  | 'CoreSpacerBlock'
  | 'CoreSpacerBlockAttributes'
  | 'CoreSpacerBlockDeprecatedV1Attributes'
  | 'CoreTableBlock'
  | 'CoreTableBlockAttributes'
  | 'CoreTableBlockAttributesBody'
  | 'CoreTableBlockAttributesBodyCells'
  | 'CoreTableBlockAttributesFoot'
  | 'CoreTableBlockAttributesFootCells'
  | 'CoreTableBlockAttributesHead'
  | 'CoreTableBlockAttributesHeadCells'
  | 'CoreTableBlockDeprecatedV1Attributes'
  | 'CoreTableBlockDeprecatedV1AttributesBody'
  | 'CoreTableBlockDeprecatedV1AttributesBodyCells'
  | 'CoreTableBlockDeprecatedV1AttributesFoot'
  | 'CoreTableBlockDeprecatedV1AttributesFootCells'
  | 'CoreTableBlockDeprecatedV1AttributesHead'
  | 'CoreTableBlockDeprecatedV1AttributesHeadCells'
  | 'CoreTableBlockDeprecatedV2Attributes'
  | 'CoreTableBlockDeprecatedV2AttributesBody'
  | 'CoreTableBlockDeprecatedV2AttributesBodyCells'
  | 'CoreTableBlockDeprecatedV2AttributesFoot'
  | 'CoreTableBlockDeprecatedV2AttributesFootCells'
  | 'CoreTableBlockDeprecatedV2AttributesHead'
  | 'CoreTableBlockDeprecatedV2AttributesHeadCells'
  | 'CoreTableBlockDeprecatedV3Attributes'
  | 'CoreTableBlockDeprecatedV3AttributesBody'
  | 'CoreTableBlockDeprecatedV3AttributesBodyCells'
  | 'CoreTableBlockDeprecatedV3AttributesFoot'
  | 'CoreTableBlockDeprecatedV3AttributesFootCells'
  | 'CoreTableBlockDeprecatedV3AttributesHead'
  | 'CoreTableBlockDeprecatedV3AttributesHeadCells'
  | 'CoreTagCloudBlock'
  | 'CoreTagCloudBlockAttributes'
  | 'CoreTemplatePartBlock'
  | 'CoreTemplatePartBlockAttributes'
  | 'CoreTermDescriptionBlock'
  | 'CoreTermDescriptionBlockAttributes'
  | 'CoreTextColumnsBlock'
  | 'CoreTextColumnsBlockAttributes'
  | 'CoreTextColumnsBlockAttributesContent'
  | 'CoreVerseBlock'
  | 'CoreVerseBlockAttributes'
  | 'CoreVerseBlockDeprecatedV1Attributes'
  | 'CoreVerseBlockDeprecatedV2Attributes'
  | 'CoreVideoBlock'
  | 'CoreVideoBlockAttributes'
  | 'CoreVideoBlockDeprecatedV1Attributes'
  | 'CoreWidgetGroupBlock'
  | 'CoreWidgetGroupBlockAttributes'
  | 'CoreWidgetGroupBlockDeprecatedV1Attributes'
  | 'CreateBlockEditorPreviewPayload'
  | 'CreateCategoryPayload'
  | 'CreateCommentPayload'
  | 'CreateCompanyCategoryPayload'
  | 'CreateCompanyPayload'
  | 'CreateGraphqlDocumentPayload'
  | 'CreateMediaItemPayload'
  | 'CreatePagePayload'
  | 'CreatePostFormatPayload'
  | 'CreatePostPayload'
  | 'CreatePressCategoryPayload'
  | 'CreatePressPayload'
  | 'CreateReusableBlockPayload'
  | 'CreateTagPayload'
  | 'CreateTeamMemberPayload'
  | 'CreateUserPayload'
  | 'DefaultTemplate'
  | 'DeleteBlockEditorPreviewPayload'
  | 'DeleteCategoryPayload'
  | 'DeleteCommentPayload'
  | 'DeleteCompanyCategoryPayload'
  | 'DeleteCompanyPayload'
  | 'DeleteGraphqlDocumentPayload'
  | 'DeleteMediaItemPayload'
  | 'DeletePagePayload'
  | 'DeletePostFormatPayload'
  | 'DeletePostPayload'
  | 'DeletePressCategoryPayload'
  | 'DeletePressPayload'
  | 'DeleteReusableBlockPayload'
  | 'DeleteTagPayload'
  | 'DeleteTeamMemberPayload'
  | 'DeleteUserPayload'
  | 'DiscussionSettings'
  | 'EnqueuedScript'
  | 'EnqueuedStylesheet'
  | 'GeneralSettings'
  | 'GenerateAuthorizationCodePayload'
  | 'GraphqlDocument'
  | 'GraphqlDocumentToPreviewConnectionEdge'
  | 'HierarchicalContentNodeToContentNodeAncestorsConnection'
  | 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge'
  | 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo'
  | 'HierarchicalContentNodeToContentNodeChildrenConnection'
  | 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge'
  | 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo'
  | 'HierarchicalContentNodeToParentContentNodeConnectionEdge'
  | 'Jam3AccordionBlock'
  | 'Jam3AccordionBlockAttributes'
  | 'Jam3AccordionItemBlock'
  | 'Jam3AccordionItemBlockAttributes'
  | 'Jam3AcfCompanyListingImgBlock'
  | 'Jam3AcfCompanyListingImgBlockAttributes'
  | 'Jam3AcfCompanyLogoBlock'
  | 'Jam3AcfCompanyLogoBlockAttributes'
  | 'Jam3CarouselBlock'
  | 'Jam3CarouselBlockAttributes'
  | 'Jam3CarouselItemBlock'
  | 'Jam3CarouselItemBlockAttributes'
  | 'Jam3CompanyImageWithLogoBlock'
  | 'Jam3CompanyImageWithLogoBlockAttributes'
  | 'Jam3CompanyInfoBlock'
  | 'Jam3CompanyInfoBlockAttributes'
  | 'Jam3CompanyInfoItemBlock'
  | 'Jam3CompanyInfoItemBlockAttributes'
  | 'Jam3NewsletterBlock'
  | 'Jam3NewsletterBlockAttributes'
  | 'Jam3ReadTimeBlock'
  | 'Jam3ReadTimeBlockAttributes'
  | 'Jam3RotateTextBlock'
  | 'Jam3RotateTextBlockAttributes'
  | 'Jam3SocialShareBlock'
  | 'Jam3SocialShareBlockAttributes'
  | 'Jam3TeamListingBlock'
  | 'Jam3TeamListingBlockAttributes'
  | 'MediaDetails'
  | 'MediaItem'
  | 'MediaItemMeta'
  | 'MediaItemToCommentConnection'
  | 'MediaItemToCommentConnectionEdge'
  | 'MediaItemToCommentConnectionPageInfo'
  | 'MediaSize'
  | 'Menu'
  | 'MenuItem'
  | 'MenuItemToMenuConnectionEdge'
  | 'MenuItemToMenuItemConnection'
  | 'MenuItemToMenuItemConnectionEdge'
  | 'MenuItemToMenuItemConnectionPageInfo'
  | 'MenuItemToMenuItemLinkableConnectionEdge'
  | 'MenuToMenuItemConnection'
  | 'MenuToMenuItemConnectionEdge'
  | 'MenuToMenuItemConnectionPageInfo'
  | 'MonsterinsightsPopularPostsInlineBlock'
  | 'MonsterinsightsPopularPostsInlineBlockAttributes'
  | 'MonsterinsightsPopularPostsWidgetBlock'
  | 'MonsterinsightsPopularPostsWidgetBlockAttributes'
  | 'Mutation'
  | 'NodeWithAuthorToUserConnectionEdge'
  | 'NodeWithFeaturedImageToMediaItemConnectionEdge'
  | 'NodeWithRevisionsToContentNodeConnectionEdge'
  | 'OffsetPaginationPageInfo'
  | 'Page'
  | 'PageToCommentConnection'
  | 'PageToCommentConnectionEdge'
  | 'PageToCommentConnectionPageInfo'
  | 'PageToPreviewConnectionEdge'
  | 'PageToRevisionConnection'
  | 'PageToRevisionConnectionEdge'
  | 'PageToRevisionConnectionPageInfo'
  | 'Plugin'
  | 'Post'
  | 'PostFormat'
  | 'PostFormatToContentNodeConnection'
  | 'PostFormatToContentNodeConnectionEdge'
  | 'PostFormatToContentNodeConnectionPageInfo'
  | 'PostFormatToPostConnection'
  | 'PostFormatToPostConnectionEdge'
  | 'PostFormatToPostConnectionPageInfo'
  | 'PostFormatToTaxonomyConnectionEdge'
  | 'PostToCategoryConnection'
  | 'PostToCategoryConnectionEdge'
  | 'PostToCategoryConnectionPageInfo'
  | 'PostToCommentConnection'
  | 'PostToCommentConnectionEdge'
  | 'PostToCommentConnectionPageInfo'
  | 'PostToPostFormatConnection'
  | 'PostToPostFormatConnectionEdge'
  | 'PostToPostFormatConnectionPageInfo'
  | 'PostToPreviewConnectionEdge'
  | 'PostToRevisionConnection'
  | 'PostToRevisionConnectionEdge'
  | 'PostToRevisionConnectionPageInfo'
  | 'PostToTagConnection'
  | 'PostToTagConnectionEdge'
  | 'PostToTagConnectionPageInfo'
  | 'PostToTermNodeConnection'
  | 'PostToTermNodeConnectionEdge'
  | 'PostToTermNodeConnectionPageInfo'
  | 'PostTypeLabelDetails'
  | 'PostTypeSEO'
  | 'Post_Blogpost'
  | 'Press'
  | 'PressCategory'
  | 'PressCategoryToAncestorsPressCategoryConnection'
  | 'PressCategoryToAncestorsPressCategoryConnectionEdge'
  | 'PressCategoryToAncestorsPressCategoryConnectionPageInfo'
  | 'PressCategoryToContentNodeConnection'
  | 'PressCategoryToContentNodeConnectionEdge'
  | 'PressCategoryToContentNodeConnectionPageInfo'
  | 'PressCategoryToParentPressCategoryConnectionEdge'
  | 'PressCategoryToPressCategoryConnection'
  | 'PressCategoryToPressCategoryConnectionEdge'
  | 'PressCategoryToPressCategoryConnectionPageInfo'
  | 'PressCategoryToPressConnection'
  | 'PressCategoryToPressConnectionEdge'
  | 'PressCategoryToPressConnectionPageInfo'
  | 'PressCategoryToTaxonomyConnectionEdge'
  | 'PressCategoryToTeamMemberConnection'
  | 'PressCategoryToTeamMemberConnectionEdge'
  | 'PressCategoryToTeamMemberConnectionPageInfo'
  | 'PressToPressCategoryConnection'
  | 'PressToPressCategoryConnectionEdge'
  | 'PressToPressCategoryConnectionPageInfo'
  | 'PressToPreviewConnectionEdge'
  | 'PressToTermNodeConnection'
  | 'PressToTermNodeConnectionEdge'
  | 'PressToTermNodeConnectionPageInfo'
  | 'Press_Acfpress'
  | 'Query'
  | 'ReadingSettings'
  | 'RegisterUserPayload'
  | 'ResetUserPasswordPayload'
  | 'RestoreCommentPayload'
  | 'ReusableBlock'
  | 'ReusableBlockToPreviewConnectionEdge'
  | 'ReusableBlockToRevisionConnection'
  | 'ReusableBlockToRevisionConnectionEdge'
  | 'ReusableBlockToRevisionConnectionPageInfo'
  | 'RootQueryToBlockEditorPreviewConnection'
  | 'RootQueryToBlockEditorPreviewConnectionEdge'
  | 'RootQueryToBlockEditorPreviewConnectionPageInfo'
  | 'RootQueryToCategoryConnection'
  | 'RootQueryToCategoryConnectionEdge'
  | 'RootQueryToCategoryConnectionPageInfo'
  | 'RootQueryToCommentConnection'
  | 'RootQueryToCommentConnectionEdge'
  | 'RootQueryToCommentConnectionPageInfo'
  | 'RootQueryToCompanyCategoryConnection'
  | 'RootQueryToCompanyCategoryConnectionEdge'
  | 'RootQueryToCompanyCategoryConnectionPageInfo'
  | 'RootQueryToCompanyConnection'
  | 'RootQueryToCompanyConnectionEdge'
  | 'RootQueryToCompanyConnectionPageInfo'
  | 'RootQueryToContentNodeConnection'
  | 'RootQueryToContentNodeConnectionEdge'
  | 'RootQueryToContentNodeConnectionPageInfo'
  | 'RootQueryToContentTypeConnection'
  | 'RootQueryToContentTypeConnectionEdge'
  | 'RootQueryToContentTypeConnectionPageInfo'
  | 'RootQueryToEnqueuedScriptConnection'
  | 'RootQueryToEnqueuedScriptConnectionEdge'
  | 'RootQueryToEnqueuedScriptConnectionPageInfo'
  | 'RootQueryToEnqueuedStylesheetConnection'
  | 'RootQueryToEnqueuedStylesheetConnectionEdge'
  | 'RootQueryToEnqueuedStylesheetConnectionPageInfo'
  | 'RootQueryToGraphqlDocumentConnection'
  | 'RootQueryToGraphqlDocumentConnectionEdge'
  | 'RootQueryToGraphqlDocumentConnectionPageInfo'
  | 'RootQueryToMediaItemConnection'
  | 'RootQueryToMediaItemConnectionEdge'
  | 'RootQueryToMediaItemConnectionPageInfo'
  | 'RootQueryToMenuConnection'
  | 'RootQueryToMenuConnectionEdge'
  | 'RootQueryToMenuConnectionPageInfo'
  | 'RootQueryToMenuItemConnection'
  | 'RootQueryToMenuItemConnectionEdge'
  | 'RootQueryToMenuItemConnectionPageInfo'
  | 'RootQueryToPageConnection'
  | 'RootQueryToPageConnectionEdge'
  | 'RootQueryToPageConnectionPageInfo'
  | 'RootQueryToPluginConnection'
  | 'RootQueryToPluginConnectionEdge'
  | 'RootQueryToPluginConnectionPageInfo'
  | 'RootQueryToPostConnection'
  | 'RootQueryToPostConnectionEdge'
  | 'RootQueryToPostConnectionPageInfo'
  | 'RootQueryToPostFormatConnection'
  | 'RootQueryToPostFormatConnectionEdge'
  | 'RootQueryToPostFormatConnectionPageInfo'
  | 'RootQueryToPressCategoryConnection'
  | 'RootQueryToPressCategoryConnectionEdge'
  | 'RootQueryToPressCategoryConnectionPageInfo'
  | 'RootQueryToPressConnection'
  | 'RootQueryToPressConnectionEdge'
  | 'RootQueryToPressConnectionPageInfo'
  | 'RootQueryToReusableBlockConnection'
  | 'RootQueryToReusableBlockConnectionEdge'
  | 'RootQueryToReusableBlockConnectionPageInfo'
  | 'RootQueryToRevisionsConnection'
  | 'RootQueryToRevisionsConnectionEdge'
  | 'RootQueryToRevisionsConnectionPageInfo'
  | 'RootQueryToTagConnection'
  | 'RootQueryToTagConnectionEdge'
  | 'RootQueryToTagConnectionPageInfo'
  | 'RootQueryToTaxonomyConnection'
  | 'RootQueryToTaxonomyConnectionEdge'
  | 'RootQueryToTaxonomyConnectionPageInfo'
  | 'RootQueryToTeamMemberConnection'
  | 'RootQueryToTeamMemberConnectionEdge'
  | 'RootQueryToTeamMemberConnectionPageInfo'
  | 'RootQueryToTermNodeConnection'
  | 'RootQueryToTermNodeConnectionEdge'
  | 'RootQueryToTermNodeConnectionPageInfo'
  | 'RootQueryToThemeConnection'
  | 'RootQueryToThemeConnectionEdge'
  | 'RootQueryToThemeConnectionPageInfo'
  | 'RootQueryToUserConnection'
  | 'RootQueryToUserConnectionEdge'
  | 'RootQueryToUserConnectionPageInfo'
  | 'RootQueryToUserRoleConnection'
  | 'RootQueryToUserRoleConnectionEdge'
  | 'RootQueryToUserRoleConnectionPageInfo'
  | 'SEOBreadcrumbs'
  | 'SEOConfig'
  | 'SEOContentType'
  | 'SEOContentTypeArchive'
  | 'SEOContentTypes'
  | 'SEOOpenGraph'
  | 'SEOOpenGraphFrontPage'
  | 'SEOPageInfoSchema'
  | 'SEOPostTypeBreadcrumbs'
  | 'SEOPostTypePageInfo'
  | 'SEOPostTypeSchema'
  | 'SEORedirect'
  | 'SEOSchema'
  | 'SEOSocial'
  | 'SEOSocialFacebook'
  | 'SEOSocialInstagram'
  | 'SEOSocialLinkedIn'
  | 'SEOSocialMySpace'
  | 'SEOSocialPinterest'
  | 'SEOSocialTwitter'
  | 'SEOSocialWikipedia'
  | 'SEOSocialYoutube'
  | 'SEOTaxonomySchema'
  | 'SEOUser'
  | 'SEOUserSchema'
  | 'SEOUserSocial'
  | 'SEOWebmaster'
  | 'SendPasswordResetEmailPayload'
  | 'Settings'
  | 'Subscription'
  | 'Tag'
  | 'TagToContentNodeConnection'
  | 'TagToContentNodeConnectionEdge'
  | 'TagToContentNodeConnectionPageInfo'
  | 'TagToPostConnection'
  | 'TagToPostConnectionEdge'
  | 'TagToPostConnectionPageInfo'
  | 'TagToTaxonomyConnectionEdge'
  | 'Taxonomy'
  | 'TaxonomySEO'
  | 'TaxonomyToContentTypeConnection'
  | 'TaxonomyToContentTypeConnectionEdge'
  | 'TaxonomyToContentTypeConnectionPageInfo'
  | 'TaxonomyToTermNodeConnection'
  | 'TaxonomyToTermNodeConnectionEdge'
  | 'TaxonomyToTermNodeConnectionPageInfo'
  | 'TeamMember'
  | 'TeamMemberToPressCategoryConnection'
  | 'TeamMemberToPressCategoryConnectionEdge'
  | 'TeamMemberToPressCategoryConnectionPageInfo'
  | 'TeamMemberToPreviewConnectionEdge'
  | 'TeamMemberToTermNodeConnection'
  | 'TeamMemberToTermNodeConnectionEdge'
  | 'TeamMemberToTermNodeConnectionPageInfo'
  | 'TeamMember_Team'
  | 'TeamMember_Team_boardPositions'
  | 'TeamMember_Teaminvestments'
  | 'TermNodeToEnqueuedScriptConnection'
  | 'TermNodeToEnqueuedScriptConnectionEdge'
  | 'TermNodeToEnqueuedScriptConnectionPageInfo'
  | 'TermNodeToEnqueuedStylesheetConnection'
  | 'TermNodeToEnqueuedStylesheetConnectionEdge'
  | 'TermNodeToEnqueuedStylesheetConnectionPageInfo'
  | 'Theme'
  | 'UpdateBlockEditorPreviewPayload'
  | 'UpdateCategoryPayload'
  | 'UpdateCommentPayload'
  | 'UpdateCompanyCategoryPayload'
  | 'UpdateCompanyPayload'
  | 'UpdateGraphqlDocumentPayload'
  | 'UpdateMediaItemPayload'
  | 'UpdatePagePayload'
  | 'UpdatePostFormatPayload'
  | 'UpdatePostPayload'
  | 'UpdatePressCategoryPayload'
  | 'UpdatePressPayload'
  | 'UpdateReusableBlockPayload'
  | 'UpdateSettingsPayload'
  | 'UpdateTagPayload'
  | 'UpdateTeamMemberPayload'
  | 'UpdateUserPayload'
  | 'User'
  | 'UserRole'
  | 'UserToBlockEditorPreviewConnection'
  | 'UserToBlockEditorPreviewConnectionEdge'
  | 'UserToBlockEditorPreviewConnectionPageInfo'
  | 'UserToCommentConnection'
  | 'UserToCommentConnectionEdge'
  | 'UserToCommentConnectionPageInfo'
  | 'UserToEnqueuedScriptConnection'
  | 'UserToEnqueuedScriptConnectionEdge'
  | 'UserToEnqueuedScriptConnectionPageInfo'
  | 'UserToEnqueuedStylesheetConnection'
  | 'UserToEnqueuedStylesheetConnectionEdge'
  | 'UserToEnqueuedStylesheetConnectionPageInfo'
  | 'UserToMediaItemConnection'
  | 'UserToMediaItemConnectionEdge'
  | 'UserToMediaItemConnectionPageInfo'
  | 'UserToPageConnection'
  | 'UserToPageConnectionEdge'
  | 'UserToPageConnectionPageInfo'
  | 'UserToPostConnection'
  | 'UserToPostConnectionEdge'
  | 'UserToPostConnectionPageInfo'
  | 'UserToRevisionsConnection'
  | 'UserToRevisionsConnectionEdge'
  | 'UserToRevisionsConnectionPageInfo'
  | 'UserToUserRoleConnection'
  | 'UserToUserRoleConnectionEdge'
  | 'UserToUserRoleConnectionPageInfo'
  | 'WritingSettings'
  | 'YoastFaqBlock'
  | 'YoastFaqBlockAttributes'
  | 'YoastFaqBlockDeprecatedV1Attributes'
  | 'YoastHowToBlock'
  | 'YoastHowToBlockAttributes'
  | 'YoastHowToBlockDeprecatedV1Attributes'
  | 'YoastHowToBlockDeprecatedV2Attributes'
  | 'YoastSeoBreadcrumbsBlock'
  | 'YoastSeoBreadcrumbsBlockAttributes';

export interface $AcfFieldGroup {
  AcfOptionsFooter_Footersettings?: AcfOptionsFooter_Footersettings;
  AcfOptionsFooter_Footersettings_externalLinks?: AcfOptionsFooter_Footersettings_externalLinks;
  AcfOptionsFooter_Footersettings_links?: AcfOptionsFooter_Footersettings_links;
  AcfOptionsFooter_Footersettings_socialMedia?: AcfOptionsFooter_Footersettings_socialMedia;
  AcfOptionsNotifications_Websitenotificationbanner?: AcfOptionsNotifications_Websitenotificationbanner;
  Company_Companies?: Company_Companies;
  Company_Companies_info?: Company_Companies_info;
  Post_Blogpost?: Post_Blogpost;
  Press_Acfpress?: Press_Acfpress;
  TeamMember_Team?: TeamMember_Team;
  TeamMember_Team_boardPositions?: TeamMember_Team_boardPositions;
  TeamMember_Teaminvestments?: TeamMember_Teaminvestments;
}

export interface $Block {
  CoreArchivesBlock?: CoreArchivesBlock;
  CoreAudioBlock?: CoreAudioBlock;
  CoreAvatarBlock?: CoreAvatarBlock;
  CoreBlock?: CoreBlock;
  CoreButtonBlock?: CoreButtonBlock;
  CoreButtonsBlock?: CoreButtonsBlock;
  CoreCalendarBlock?: CoreCalendarBlock;
  CoreCategoriesBlock?: CoreCategoriesBlock;
  CoreCodeBlock?: CoreCodeBlock;
  CoreColumnBlock?: CoreColumnBlock;
  CoreColumnsBlock?: CoreColumnsBlock;
  CoreCommentAuthorNameBlock?: CoreCommentAuthorNameBlock;
  CoreCommentContentBlock?: CoreCommentContentBlock;
  CoreCommentDateBlock?: CoreCommentDateBlock;
  CoreCommentEditLinkBlock?: CoreCommentEditLinkBlock;
  CoreCommentReplyLinkBlock?: CoreCommentReplyLinkBlock;
  CoreCommentTemplateBlock?: CoreCommentTemplateBlock;
  CoreCommentsBlock?: CoreCommentsBlock;
  CoreCommentsPaginationBlock?: CoreCommentsPaginationBlock;
  CoreCommentsPaginationNextBlock?: CoreCommentsPaginationNextBlock;
  CoreCommentsPaginationNumbersBlock?: CoreCommentsPaginationNumbersBlock;
  CoreCommentsPaginationPreviousBlock?: CoreCommentsPaginationPreviousBlock;
  CoreCommentsTitleBlock?: CoreCommentsTitleBlock;
  CoreCoverBlock?: CoreCoverBlock;
  CoreDetailsBlock?: CoreDetailsBlock;
  CoreEmbedBlock?: CoreEmbedBlock;
  CoreFileBlock?: CoreFileBlock;
  CoreFootnotesBlock?: CoreFootnotesBlock;
  CoreFreeformBlock?: CoreFreeformBlock;
  CoreGalleryBlock?: CoreGalleryBlock;
  CoreGroupBlock?: CoreGroupBlock;
  CoreHeadingBlock?: CoreHeadingBlock;
  CoreHomeLinkBlock?: CoreHomeLinkBlock;
  CoreHtmlBlock?: CoreHtmlBlock;
  CoreImageBlock?: CoreImageBlock;
  CoreLatestCommentsBlock?: CoreLatestCommentsBlock;
  CoreLatestPostsBlock?: CoreLatestPostsBlock;
  CoreLegacyWidgetBlock?: CoreLegacyWidgetBlock;
  CoreListBlock?: CoreListBlock;
  CoreListItemBlock?: CoreListItemBlock;
  CoreLoginoutBlock?: CoreLoginoutBlock;
  CoreMediaTextBlock?: CoreMediaTextBlock;
  CoreMissingBlock?: CoreMissingBlock;
  CoreMoreBlock?: CoreMoreBlock;
  CoreNavigationBlock?: CoreNavigationBlock;
  CoreNavigationLinkBlock?: CoreNavigationLinkBlock;
  CoreNavigationSubmenuBlock?: CoreNavigationSubmenuBlock;
  CoreNextpageBlock?: CoreNextpageBlock;
  CorePageListBlock?: CorePageListBlock;
  CorePageListItemBlock?: CorePageListItemBlock;
  CoreParagraphBlock?: CoreParagraphBlock;
  CorePatternBlock?: CorePatternBlock;
  CorePostAuthorBiographyBlock?: CorePostAuthorBiographyBlock;
  CorePostAuthorBlock?: CorePostAuthorBlock;
  CorePostAuthorNameBlock?: CorePostAuthorNameBlock;
  CorePostCommentsFormBlock?: CorePostCommentsFormBlock;
  CorePostContentBlock?: CorePostContentBlock;
  CorePostDateBlock?: CorePostDateBlock;
  CorePostExcerptBlock?: CorePostExcerptBlock;
  CorePostFeaturedImageBlock?: CorePostFeaturedImageBlock;
  CorePostNavigationLinkBlock?: CorePostNavigationLinkBlock;
  CorePostTemplateBlock?: CorePostTemplateBlock;
  CorePostTermsBlock?: CorePostTermsBlock;
  CorePostTitleBlock?: CorePostTitleBlock;
  CorePreformattedBlock?: CorePreformattedBlock;
  CorePullquoteBlock?: CorePullquoteBlock;
  CoreQueryBlock?: CoreQueryBlock;
  CoreQueryNoResultsBlock?: CoreQueryNoResultsBlock;
  CoreQueryPaginationBlock?: CoreQueryPaginationBlock;
  CoreQueryPaginationNextBlock?: CoreQueryPaginationNextBlock;
  CoreQueryPaginationNumbersBlock?: CoreQueryPaginationNumbersBlock;
  CoreQueryPaginationPreviousBlock?: CoreQueryPaginationPreviousBlock;
  CoreQueryTitleBlock?: CoreQueryTitleBlock;
  CoreQuoteBlock?: CoreQuoteBlock;
  CoreReadMoreBlock?: CoreReadMoreBlock;
  CoreRssBlock?: CoreRssBlock;
  CoreSearchBlock?: CoreSearchBlock;
  CoreSeparatorBlock?: CoreSeparatorBlock;
  CoreShortcodeBlock?: CoreShortcodeBlock;
  CoreSiteLogoBlock?: CoreSiteLogoBlock;
  CoreSiteTaglineBlock?: CoreSiteTaglineBlock;
  CoreSiteTitleBlock?: CoreSiteTitleBlock;
  CoreSocialLinkBlock?: CoreSocialLinkBlock;
  CoreSocialLinksBlock?: CoreSocialLinksBlock;
  CoreSpacerBlock?: CoreSpacerBlock;
  CoreTableBlock?: CoreTableBlock;
  CoreTagCloudBlock?: CoreTagCloudBlock;
  CoreTemplatePartBlock?: CoreTemplatePartBlock;
  CoreTermDescriptionBlock?: CoreTermDescriptionBlock;
  CoreTextColumnsBlock?: CoreTextColumnsBlock;
  CoreVerseBlock?: CoreVerseBlock;
  CoreVideoBlock?: CoreVideoBlock;
  CoreWidgetGroupBlock?: CoreWidgetGroupBlock;
  Jam3AccordionBlock?: Jam3AccordionBlock;
  Jam3AccordionItemBlock?: Jam3AccordionItemBlock;
  Jam3AcfCompanyListingImgBlock?: Jam3AcfCompanyListingImgBlock;
  Jam3AcfCompanyLogoBlock?: Jam3AcfCompanyLogoBlock;
  Jam3CarouselBlock?: Jam3CarouselBlock;
  Jam3CarouselItemBlock?: Jam3CarouselItemBlock;
  Jam3CompanyImageWithLogoBlock?: Jam3CompanyImageWithLogoBlock;
  Jam3CompanyInfoBlock?: Jam3CompanyInfoBlock;
  Jam3CompanyInfoItemBlock?: Jam3CompanyInfoItemBlock;
  Jam3NewsletterBlock?: Jam3NewsletterBlock;
  Jam3ReadTimeBlock?: Jam3ReadTimeBlock;
  Jam3RotateTextBlock?: Jam3RotateTextBlock;
  Jam3SocialShareBlock?: Jam3SocialShareBlock;
  Jam3TeamListingBlock?: Jam3TeamListingBlock;
  MonsterinsightsPopularPostsInlineBlock?: MonsterinsightsPopularPostsInlineBlock;
  MonsterinsightsPopularPostsWidgetBlock?: MonsterinsightsPopularPostsWidgetBlock;
  YoastFaqBlock?: YoastFaqBlock;
  YoastHowToBlock?: YoastHowToBlock;
  YoastSeoBreadcrumbsBlock?: YoastSeoBreadcrumbsBlock;
}

export interface $BlockEditorContentNode {
  Company?: Company;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  ReusableBlock?: ReusableBlock;
  TeamMember?: TeamMember;
}

export interface $BlockEditorPreviewConnection {
  RootQueryToBlockEditorPreviewConnection?: RootQueryToBlockEditorPreviewConnection;
  UserToBlockEditorPreviewConnection?: UserToBlockEditorPreviewConnection;
}

export interface $BlockEditorPreviewConnectionEdge {
  BlockEditorPreviewToPreviewConnectionEdge?: BlockEditorPreviewToPreviewConnectionEdge;
  RootQueryToBlockEditorPreviewConnectionEdge?: RootQueryToBlockEditorPreviewConnectionEdge;
  UserToBlockEditorPreviewConnectionEdge?: UserToBlockEditorPreviewConnectionEdge;
}

export interface $BlockEditorPreviewConnectionPageInfo {
  RootQueryToBlockEditorPreviewConnectionPageInfo?: RootQueryToBlockEditorPreviewConnectionPageInfo;
  UserToBlockEditorPreviewConnectionPageInfo?: UserToBlockEditorPreviewConnectionPageInfo;
}

export interface $CategoryConnection {
  CategoryToAncestorsCategoryConnection?: CategoryToAncestorsCategoryConnection;
  CategoryToCategoryConnection?: CategoryToCategoryConnection;
  PostToCategoryConnection?: PostToCategoryConnection;
  RootQueryToCategoryConnection?: RootQueryToCategoryConnection;
}

export interface $CategoryConnectionEdge {
  CategoryToAncestorsCategoryConnectionEdge?: CategoryToAncestorsCategoryConnectionEdge;
  CategoryToCategoryConnectionEdge?: CategoryToCategoryConnectionEdge;
  CategoryToParentCategoryConnectionEdge?: CategoryToParentCategoryConnectionEdge;
  PostToCategoryConnectionEdge?: PostToCategoryConnectionEdge;
  RootQueryToCategoryConnectionEdge?: RootQueryToCategoryConnectionEdge;
}

export interface $CategoryConnectionPageInfo {
  CategoryToAncestorsCategoryConnectionPageInfo?: CategoryToAncestorsCategoryConnectionPageInfo;
  CategoryToCategoryConnectionPageInfo?: CategoryToCategoryConnectionPageInfo;
  PostToCategoryConnectionPageInfo?: PostToCategoryConnectionPageInfo;
  RootQueryToCategoryConnectionPageInfo?: RootQueryToCategoryConnectionPageInfo;
}

export interface $CommentConnection {
  CommentToCommentConnection?: CommentToCommentConnection;
  MediaItemToCommentConnection?: MediaItemToCommentConnection;
  PageToCommentConnection?: PageToCommentConnection;
  PostToCommentConnection?: PostToCommentConnection;
  RootQueryToCommentConnection?: RootQueryToCommentConnection;
  UserToCommentConnection?: UserToCommentConnection;
}

export interface $CommentConnectionEdge {
  CommentToCommentConnectionEdge?: CommentToCommentConnectionEdge;
  CommentToParentCommentConnectionEdge?: CommentToParentCommentConnectionEdge;
  MediaItemToCommentConnectionEdge?: MediaItemToCommentConnectionEdge;
  PageToCommentConnectionEdge?: PageToCommentConnectionEdge;
  PostToCommentConnectionEdge?: PostToCommentConnectionEdge;
  RootQueryToCommentConnectionEdge?: RootQueryToCommentConnectionEdge;
  UserToCommentConnectionEdge?: UserToCommentConnectionEdge;
}

export interface $CommentConnectionPageInfo {
  CommentToCommentConnectionPageInfo?: CommentToCommentConnectionPageInfo;
  MediaItemToCommentConnectionPageInfo?: MediaItemToCommentConnectionPageInfo;
  PageToCommentConnectionPageInfo?: PageToCommentConnectionPageInfo;
  PostToCommentConnectionPageInfo?: PostToCommentConnectionPageInfo;
  RootQueryToCommentConnectionPageInfo?: RootQueryToCommentConnectionPageInfo;
  UserToCommentConnectionPageInfo?: UserToCommentConnectionPageInfo;
}

export interface $Commenter {
  CommentAuthor?: CommentAuthor;
  User?: User;
}

export interface $CommenterConnectionEdge {
  CommentToCommenterConnectionEdge?: CommentToCommenterConnectionEdge;
}

export interface $CompanyCategoryConnection {
  CompanyCategoryToAncestorsCompanyCategoryConnection?: CompanyCategoryToAncestorsCompanyCategoryConnection;
  CompanyCategoryToCompanyCategoryConnection?: CompanyCategoryToCompanyCategoryConnection;
  CompanyToCompanyCategoryConnection?: CompanyToCompanyCategoryConnection;
  RootQueryToCompanyCategoryConnection?: RootQueryToCompanyCategoryConnection;
}

export interface $CompanyCategoryConnectionEdge {
  CompanyCategoryToAncestorsCompanyCategoryConnectionEdge?: CompanyCategoryToAncestorsCompanyCategoryConnectionEdge;
  CompanyCategoryToCompanyCategoryConnectionEdge?: CompanyCategoryToCompanyCategoryConnectionEdge;
  CompanyCategoryToParentCompanyCategoryConnectionEdge?: CompanyCategoryToParentCompanyCategoryConnectionEdge;
  CompanyToCompanyCategoryConnectionEdge?: CompanyToCompanyCategoryConnectionEdge;
  RootQueryToCompanyCategoryConnectionEdge?: RootQueryToCompanyCategoryConnectionEdge;
}

export interface $CompanyCategoryConnectionPageInfo {
  CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo?: CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyCategoryConnectionPageInfo?: CompanyCategoryToCompanyCategoryConnectionPageInfo;
  CompanyToCompanyCategoryConnectionPageInfo?: CompanyToCompanyCategoryConnectionPageInfo;
  RootQueryToCompanyCategoryConnectionPageInfo?: RootQueryToCompanyCategoryConnectionPageInfo;
}

export interface $CompanyConnection {
  CompanyCategoryToCompanyConnection?: CompanyCategoryToCompanyConnection;
  RootQueryToCompanyConnection?: RootQueryToCompanyConnection;
}

export interface $CompanyConnectionEdge {
  CompanyCategoryToCompanyConnectionEdge?: CompanyCategoryToCompanyConnectionEdge;
  CompanyToPreviewConnectionEdge?: CompanyToPreviewConnectionEdge;
  RootQueryToCompanyConnectionEdge?: RootQueryToCompanyConnectionEdge;
}

export interface $CompanyConnectionPageInfo {
  CompanyCategoryToCompanyConnectionPageInfo?: CompanyCategoryToCompanyConnectionPageInfo;
  RootQueryToCompanyConnectionPageInfo?: RootQueryToCompanyConnectionPageInfo;
}

export interface $Company_Companies_Partner {
  TeamMember?: TeamMember;
}

export interface $Connection {
  CategoryToAncestorsCategoryConnection?: CategoryToAncestorsCategoryConnection;
  CategoryToCategoryConnection?: CategoryToCategoryConnection;
  CategoryToContentNodeConnection?: CategoryToContentNodeConnection;
  CategoryToPostConnection?: CategoryToPostConnection;
  CommentToCommentConnection?: CommentToCommentConnection;
  CompanyCategoryToAncestorsCompanyCategoryConnection?: CompanyCategoryToAncestorsCompanyCategoryConnection;
  CompanyCategoryToCompanyCategoryConnection?: CompanyCategoryToCompanyCategoryConnection;
  CompanyCategoryToCompanyConnection?: CompanyCategoryToCompanyConnection;
  CompanyCategoryToContentNodeConnection?: CompanyCategoryToContentNodeConnection;
  CompanyToCompanyCategoryConnection?: CompanyToCompanyCategoryConnection;
  CompanyToTermNodeConnection?: CompanyToTermNodeConnection;
  ContentNodeToEnqueuedScriptConnection?: ContentNodeToEnqueuedScriptConnection;
  ContentNodeToEnqueuedStylesheetConnection?: ContentNodeToEnqueuedStylesheetConnection;
  ContentTypeToContentNodeConnection?: ContentTypeToContentNodeConnection;
  ContentTypeToTaxonomyConnection?: ContentTypeToTaxonomyConnection;
  HierarchicalContentNodeToContentNodeAncestorsConnection?: HierarchicalContentNodeToContentNodeAncestorsConnection;
  HierarchicalContentNodeToContentNodeChildrenConnection?: HierarchicalContentNodeToContentNodeChildrenConnection;
  MediaItemToCommentConnection?: MediaItemToCommentConnection;
  MenuItemToMenuItemConnection?: MenuItemToMenuItemConnection;
  MenuToMenuItemConnection?: MenuToMenuItemConnection;
  PageToCommentConnection?: PageToCommentConnection;
  PageToRevisionConnection?: PageToRevisionConnection;
  PostFormatToContentNodeConnection?: PostFormatToContentNodeConnection;
  PostFormatToPostConnection?: PostFormatToPostConnection;
  PostToCategoryConnection?: PostToCategoryConnection;
  PostToCommentConnection?: PostToCommentConnection;
  PostToPostFormatConnection?: PostToPostFormatConnection;
  PostToRevisionConnection?: PostToRevisionConnection;
  PostToTagConnection?: PostToTagConnection;
  PostToTermNodeConnection?: PostToTermNodeConnection;
  PressCategoryToAncestorsPressCategoryConnection?: PressCategoryToAncestorsPressCategoryConnection;
  PressCategoryToContentNodeConnection?: PressCategoryToContentNodeConnection;
  PressCategoryToPressCategoryConnection?: PressCategoryToPressCategoryConnection;
  PressCategoryToPressConnection?: PressCategoryToPressConnection;
  PressCategoryToTeamMemberConnection?: PressCategoryToTeamMemberConnection;
  PressToPressCategoryConnection?: PressToPressCategoryConnection;
  PressToTermNodeConnection?: PressToTermNodeConnection;
  ReusableBlockToRevisionConnection?: ReusableBlockToRevisionConnection;
  RootQueryToBlockEditorPreviewConnection?: RootQueryToBlockEditorPreviewConnection;
  RootQueryToCategoryConnection?: RootQueryToCategoryConnection;
  RootQueryToCommentConnection?: RootQueryToCommentConnection;
  RootQueryToCompanyCategoryConnection?: RootQueryToCompanyCategoryConnection;
  RootQueryToCompanyConnection?: RootQueryToCompanyConnection;
  RootQueryToContentNodeConnection?: RootQueryToContentNodeConnection;
  RootQueryToContentTypeConnection?: RootQueryToContentTypeConnection;
  RootQueryToEnqueuedScriptConnection?: RootQueryToEnqueuedScriptConnection;
  RootQueryToEnqueuedStylesheetConnection?: RootQueryToEnqueuedStylesheetConnection;
  RootQueryToGraphqlDocumentConnection?: RootQueryToGraphqlDocumentConnection;
  RootQueryToMediaItemConnection?: RootQueryToMediaItemConnection;
  RootQueryToMenuConnection?: RootQueryToMenuConnection;
  RootQueryToMenuItemConnection?: RootQueryToMenuItemConnection;
  RootQueryToPageConnection?: RootQueryToPageConnection;
  RootQueryToPluginConnection?: RootQueryToPluginConnection;
  RootQueryToPostConnection?: RootQueryToPostConnection;
  RootQueryToPostFormatConnection?: RootQueryToPostFormatConnection;
  RootQueryToPressCategoryConnection?: RootQueryToPressCategoryConnection;
  RootQueryToPressConnection?: RootQueryToPressConnection;
  RootQueryToReusableBlockConnection?: RootQueryToReusableBlockConnection;
  RootQueryToRevisionsConnection?: RootQueryToRevisionsConnection;
  RootQueryToTagConnection?: RootQueryToTagConnection;
  RootQueryToTaxonomyConnection?: RootQueryToTaxonomyConnection;
  RootQueryToTeamMemberConnection?: RootQueryToTeamMemberConnection;
  RootQueryToTermNodeConnection?: RootQueryToTermNodeConnection;
  RootQueryToThemeConnection?: RootQueryToThemeConnection;
  RootQueryToUserConnection?: RootQueryToUserConnection;
  RootQueryToUserRoleConnection?: RootQueryToUserRoleConnection;
  TagToContentNodeConnection?: TagToContentNodeConnection;
  TagToPostConnection?: TagToPostConnection;
  TaxonomyToContentTypeConnection?: TaxonomyToContentTypeConnection;
  TaxonomyToTermNodeConnection?: TaxonomyToTermNodeConnection;
  TeamMemberToPressCategoryConnection?: TeamMemberToPressCategoryConnection;
  TeamMemberToTermNodeConnection?: TeamMemberToTermNodeConnection;
  TermNodeToEnqueuedScriptConnection?: TermNodeToEnqueuedScriptConnection;
  TermNodeToEnqueuedStylesheetConnection?: TermNodeToEnqueuedStylesheetConnection;
  UserToBlockEditorPreviewConnection?: UserToBlockEditorPreviewConnection;
  UserToCommentConnection?: UserToCommentConnection;
  UserToEnqueuedScriptConnection?: UserToEnqueuedScriptConnection;
  UserToEnqueuedStylesheetConnection?: UserToEnqueuedStylesheetConnection;
  UserToMediaItemConnection?: UserToMediaItemConnection;
  UserToPageConnection?: UserToPageConnection;
  UserToPostConnection?: UserToPostConnection;
  UserToRevisionsConnection?: UserToRevisionsConnection;
  UserToUserRoleConnection?: UserToUserRoleConnection;
}

export interface $ContentNode {
  BlockEditorPreview?: BlockEditorPreview;
  Company?: Company;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  ReusableBlock?: ReusableBlock;
  TeamMember?: TeamMember;
}

export interface $ContentNodeConnection {
  CategoryToContentNodeConnection?: CategoryToContentNodeConnection;
  CompanyCategoryToContentNodeConnection?: CompanyCategoryToContentNodeConnection;
  ContentTypeToContentNodeConnection?: ContentTypeToContentNodeConnection;
  HierarchicalContentNodeToContentNodeAncestorsConnection?: HierarchicalContentNodeToContentNodeAncestorsConnection;
  HierarchicalContentNodeToContentNodeChildrenConnection?: HierarchicalContentNodeToContentNodeChildrenConnection;
  PostFormatToContentNodeConnection?: PostFormatToContentNodeConnection;
  PressCategoryToContentNodeConnection?: PressCategoryToContentNodeConnection;
  RootQueryToContentNodeConnection?: RootQueryToContentNodeConnection;
  RootQueryToRevisionsConnection?: RootQueryToRevisionsConnection;
  TagToContentNodeConnection?: TagToContentNodeConnection;
  UserToRevisionsConnection?: UserToRevisionsConnection;
}

export interface $ContentNodeConnectionEdge {
  CategoryToContentNodeConnectionEdge?: CategoryToContentNodeConnectionEdge;
  CommentToContentNodeConnectionEdge?: CommentToContentNodeConnectionEdge;
  CompanyCategoryToContentNodeConnectionEdge?: CompanyCategoryToContentNodeConnectionEdge;
  ContentTypeToContentNodeConnectionEdge?: ContentTypeToContentNodeConnectionEdge;
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge?: HierarchicalContentNodeToContentNodeAncestorsConnectionEdge;
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge?: HierarchicalContentNodeToContentNodeChildrenConnectionEdge;
  HierarchicalContentNodeToParentContentNodeConnectionEdge?: HierarchicalContentNodeToParentContentNodeConnectionEdge;
  NodeWithRevisionsToContentNodeConnectionEdge?: NodeWithRevisionsToContentNodeConnectionEdge;
  PostFormatToContentNodeConnectionEdge?: PostFormatToContentNodeConnectionEdge;
  PressCategoryToContentNodeConnectionEdge?: PressCategoryToContentNodeConnectionEdge;
  RootQueryToContentNodeConnectionEdge?: RootQueryToContentNodeConnectionEdge;
  RootQueryToRevisionsConnectionEdge?: RootQueryToRevisionsConnectionEdge;
  TagToContentNodeConnectionEdge?: TagToContentNodeConnectionEdge;
  UserToRevisionsConnectionEdge?: UserToRevisionsConnectionEdge;
}

export interface $ContentNodeConnectionPageInfo {
  CategoryToContentNodeConnectionPageInfo?: CategoryToContentNodeConnectionPageInfo;
  CompanyCategoryToContentNodeConnectionPageInfo?: CompanyCategoryToContentNodeConnectionPageInfo;
  ContentTypeToContentNodeConnectionPageInfo?: ContentTypeToContentNodeConnectionPageInfo;
  HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo?: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo?: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  PostFormatToContentNodeConnectionPageInfo?: PostFormatToContentNodeConnectionPageInfo;
  PressCategoryToContentNodeConnectionPageInfo?: PressCategoryToContentNodeConnectionPageInfo;
  RootQueryToContentNodeConnectionPageInfo?: RootQueryToContentNodeConnectionPageInfo;
  RootQueryToRevisionsConnectionPageInfo?: RootQueryToRevisionsConnectionPageInfo;
  TagToContentNodeConnectionPageInfo?: TagToContentNodeConnectionPageInfo;
  UserToRevisionsConnectionPageInfo?: UserToRevisionsConnectionPageInfo;
}

export interface $ContentTemplate {
  DefaultTemplate?: DefaultTemplate;
}

export interface $ContentTypeConnection {
  RootQueryToContentTypeConnection?: RootQueryToContentTypeConnection;
  TaxonomyToContentTypeConnection?: TaxonomyToContentTypeConnection;
}

export interface $ContentTypeConnectionEdge {
  ContentNodeToContentTypeConnectionEdge?: ContentNodeToContentTypeConnectionEdge;
  RootQueryToContentTypeConnectionEdge?: RootQueryToContentTypeConnectionEdge;
  TaxonomyToContentTypeConnectionEdge?: TaxonomyToContentTypeConnectionEdge;
}

export interface $ContentTypeConnectionPageInfo {
  RootQueryToContentTypeConnectionPageInfo?: RootQueryToContentTypeConnectionPageInfo;
  TaxonomyToContentTypeConnectionPageInfo?: TaxonomyToContentTypeConnectionPageInfo;
}

export interface $CoreAudioBlockAttributesUnion {
  CoreAudioBlockAttributes?: CoreAudioBlockAttributes;
  CoreAudioBlockDeprecatedV1Attributes?: CoreAudioBlockDeprecatedV1Attributes;
}

export interface $CoreBlockAttributesUnion {
  CoreBlockAttributes?: CoreBlockAttributes;
  CoreBlockDeprecatedV1Attributes?: CoreBlockDeprecatedV1Attributes;
  CoreBlockDeprecatedV2Attributes?: CoreBlockDeprecatedV2Attributes;
}

export interface $CoreButtonBlockAttributesUnion {
  CoreButtonBlockAttributes?: CoreButtonBlockAttributes;
  CoreButtonBlockDeprecatedV1Attributes?: CoreButtonBlockDeprecatedV1Attributes;
  CoreButtonBlockDeprecatedV2Attributes?: CoreButtonBlockDeprecatedV2Attributes;
  CoreButtonBlockDeprecatedV3Attributes?: CoreButtonBlockDeprecatedV3Attributes;
  CoreButtonBlockDeprecatedV4Attributes?: CoreButtonBlockDeprecatedV4Attributes;
  CoreButtonBlockDeprecatedV5Attributes?: CoreButtonBlockDeprecatedV5Attributes;
  CoreButtonBlockDeprecatedV6Attributes?: CoreButtonBlockDeprecatedV6Attributes;
  CoreButtonBlockDeprecatedV7Attributes?: CoreButtonBlockDeprecatedV7Attributes;
  CoreButtonBlockDeprecatedV8Attributes?: CoreButtonBlockDeprecatedV8Attributes;
  CoreButtonBlockDeprecatedV9Attributes?: CoreButtonBlockDeprecatedV9Attributes;
  CoreButtonBlockDeprecatedV10Attributes?: CoreButtonBlockDeprecatedV10Attributes;
  CoreButtonBlockDeprecatedV11Attributes?: CoreButtonBlockDeprecatedV11Attributes;
}

export interface $CoreButtonsBlockAttributesUnion {
  CoreButtonsBlockAttributes?: CoreButtonsBlockAttributes;
  CoreButtonsBlockDeprecatedV1Attributes?: CoreButtonsBlockDeprecatedV1Attributes;
  CoreButtonsBlockDeprecatedV2Attributes?: CoreButtonsBlockDeprecatedV2Attributes;
}

export interface $CoreColumnBlockAttributesUnion {
  CoreColumnBlockAttributes?: CoreColumnBlockAttributes;
  CoreColumnBlockDeprecatedV1Attributes?: CoreColumnBlockDeprecatedV1Attributes;
}

export interface $CoreColumnsBlockAttributesUnion {
  CoreColumnsBlockAttributes?: CoreColumnsBlockAttributes;
  CoreColumnsBlockDeprecatedV1Attributes?: CoreColumnsBlockDeprecatedV1Attributes;
  CoreColumnsBlockDeprecatedV2Attributes?: CoreColumnsBlockDeprecatedV2Attributes;
  CoreColumnsBlockDeprecatedV3Attributes?: CoreColumnsBlockDeprecatedV3Attributes;
}

export interface $CoreCommentAuthorNameBlockAttributesUnion {
  CoreCommentAuthorNameBlockAttributes?: CoreCommentAuthorNameBlockAttributes;
  CoreCommentAuthorNameBlockDeprecatedV1Attributes?: CoreCommentAuthorNameBlockDeprecatedV1Attributes;
}

export interface $CoreCommentDateBlockAttributesUnion {
  CoreCommentDateBlockAttributes?: CoreCommentDateBlockAttributes;
  CoreCommentDateBlockDeprecatedV1Attributes?: CoreCommentDateBlockDeprecatedV1Attributes;
}

export interface $CoreCommentsBlockAttributesUnion {
  CoreCommentsBlockAttributes?: CoreCommentsBlockAttributes;
  CoreCommentsBlockDeprecatedV1Attributes?: CoreCommentsBlockDeprecatedV1Attributes;
}

export interface $CoreCommentsTitleBlockAttributesUnion {
  CoreCommentsTitleBlockAttributes?: CoreCommentsTitleBlockAttributes;
  CoreCommentsTitleBlockDeprecatedV1Attributes?: CoreCommentsTitleBlockDeprecatedV1Attributes;
}

export interface $CoreCoverBlockAttributesUnion {
  CoreCoverBlockAttributes?: CoreCoverBlockAttributes;
  CoreCoverBlockDeprecatedV1Attributes?: CoreCoverBlockDeprecatedV1Attributes;
  CoreCoverBlockDeprecatedV2Attributes?: CoreCoverBlockDeprecatedV2Attributes;
  CoreCoverBlockDeprecatedV3Attributes?: CoreCoverBlockDeprecatedV3Attributes;
  CoreCoverBlockDeprecatedV4Attributes?: CoreCoverBlockDeprecatedV4Attributes;
  CoreCoverBlockDeprecatedV5Attributes?: CoreCoverBlockDeprecatedV5Attributes;
  CoreCoverBlockDeprecatedV6Attributes?: CoreCoverBlockDeprecatedV6Attributes;
  CoreCoverBlockDeprecatedV7Attributes?: CoreCoverBlockDeprecatedV7Attributes;
  CoreCoverBlockDeprecatedV8Attributes?: CoreCoverBlockDeprecatedV8Attributes;
  CoreCoverBlockDeprecatedV9Attributes?: CoreCoverBlockDeprecatedV9Attributes;
  CoreCoverBlockDeprecatedV10Attributes?: CoreCoverBlockDeprecatedV10Attributes;
  CoreCoverBlockDeprecatedV11Attributes?: CoreCoverBlockDeprecatedV11Attributes;
  CoreCoverBlockDeprecatedV12Attributes?: CoreCoverBlockDeprecatedV12Attributes;
  CoreCoverBlockDeprecatedV13Attributes?: CoreCoverBlockDeprecatedV13Attributes;
}

export interface $CoreEmbedBlockAttributesUnion {
  CoreEmbedBlockAttributes?: CoreEmbedBlockAttributes;
  CoreEmbedBlockDeprecatedV1Attributes?: CoreEmbedBlockDeprecatedV1Attributes;
  CoreEmbedBlockDeprecatedV2Attributes?: CoreEmbedBlockDeprecatedV2Attributes;
}

export interface $CoreFileBlockAttributesUnion {
  CoreFileBlockAttributes?: CoreFileBlockAttributes;
  CoreFileBlockDeprecatedV1Attributes?: CoreFileBlockDeprecatedV1Attributes;
  CoreFileBlockDeprecatedV2Attributes?: CoreFileBlockDeprecatedV2Attributes;
  CoreFileBlockDeprecatedV3Attributes?: CoreFileBlockDeprecatedV3Attributes;
}

export interface $CoreGalleryBlockAttributesUnion {
  CoreGalleryBlockAttributes?: CoreGalleryBlockAttributes;
  CoreGalleryBlockDeprecatedV1Attributes?: CoreGalleryBlockDeprecatedV1Attributes;
  CoreGalleryBlockDeprecatedV2Attributes?: CoreGalleryBlockDeprecatedV2Attributes;
  CoreGalleryBlockDeprecatedV3Attributes?: CoreGalleryBlockDeprecatedV3Attributes;
  CoreGalleryBlockDeprecatedV4Attributes?: CoreGalleryBlockDeprecatedV4Attributes;
  CoreGalleryBlockDeprecatedV5Attributes?: CoreGalleryBlockDeprecatedV5Attributes;
  CoreGalleryBlockDeprecatedV6Attributes?: CoreGalleryBlockDeprecatedV6Attributes;
  CoreGalleryBlockDeprecatedV7Attributes?: CoreGalleryBlockDeprecatedV7Attributes;
}

export interface $CoreGroupBlockAttributesUnion {
  CoreGroupBlockAttributes?: CoreGroupBlockAttributes;
  CoreGroupBlockDeprecatedV1Attributes?: CoreGroupBlockDeprecatedV1Attributes;
  CoreGroupBlockDeprecatedV2Attributes?: CoreGroupBlockDeprecatedV2Attributes;
  CoreGroupBlockDeprecatedV3Attributes?: CoreGroupBlockDeprecatedV3Attributes;
  CoreGroupBlockDeprecatedV4Attributes?: CoreGroupBlockDeprecatedV4Attributes;
  CoreGroupBlockDeprecatedV5Attributes?: CoreGroupBlockDeprecatedV5Attributes;
}

export interface $CoreHeadingBlockAttributesUnion {
  CoreHeadingBlockAttributes?: CoreHeadingBlockAttributes;
  CoreHeadingBlockDeprecatedV1Attributes?: CoreHeadingBlockDeprecatedV1Attributes;
  CoreHeadingBlockDeprecatedV2Attributes?: CoreHeadingBlockDeprecatedV2Attributes;
  CoreHeadingBlockDeprecatedV3Attributes?: CoreHeadingBlockDeprecatedV3Attributes;
  CoreHeadingBlockDeprecatedV4Attributes?: CoreHeadingBlockDeprecatedV4Attributes;
  CoreHeadingBlockDeprecatedV5Attributes?: CoreHeadingBlockDeprecatedV5Attributes;
}

export interface $CoreImageBlockAttributesUnion {
  CoreImageBlockAttributes?: CoreImageBlockAttributes;
  CoreImageBlockDeprecatedV1Attributes?: CoreImageBlockDeprecatedV1Attributes;
  CoreImageBlockDeprecatedV2Attributes?: CoreImageBlockDeprecatedV2Attributes;
  CoreImageBlockDeprecatedV3Attributes?: CoreImageBlockDeprecatedV3Attributes;
  CoreImageBlockDeprecatedV4Attributes?: CoreImageBlockDeprecatedV4Attributes;
  CoreImageBlockDeprecatedV5Attributes?: CoreImageBlockDeprecatedV5Attributes;
  CoreImageBlockDeprecatedV6Attributes?: CoreImageBlockDeprecatedV6Attributes;
  CoreImageBlockDeprecatedV7Attributes?: CoreImageBlockDeprecatedV7Attributes;
  CoreImageBlockDeprecatedV8Attributes?: CoreImageBlockDeprecatedV8Attributes;
}

export interface $CoreLatestPostsBlockAttributesUnion {
  CoreLatestPostsBlockAttributes?: CoreLatestPostsBlockAttributes;
  CoreLatestPostsBlockDeprecatedV1Attributes?: CoreLatestPostsBlockDeprecatedV1Attributes;
}

export interface $CoreListBlockAttributesUnion {
  CoreListBlockAttributes?: CoreListBlockAttributes;
  CoreListBlockDeprecatedV1Attributes?: CoreListBlockDeprecatedV1Attributes;
  CoreListBlockDeprecatedV2Attributes?: CoreListBlockDeprecatedV2Attributes;
  CoreListBlockDeprecatedV3Attributes?: CoreListBlockDeprecatedV3Attributes;
}

export interface $CoreMediaTextBlockAttributesUnion {
  CoreMediaTextBlockAttributes?: CoreMediaTextBlockAttributes;
  CoreMediaTextBlockDeprecatedV1Attributes?: CoreMediaTextBlockDeprecatedV1Attributes;
  CoreMediaTextBlockDeprecatedV2Attributes?: CoreMediaTextBlockDeprecatedV2Attributes;
  CoreMediaTextBlockDeprecatedV3Attributes?: CoreMediaTextBlockDeprecatedV3Attributes;
  CoreMediaTextBlockDeprecatedV4Attributes?: CoreMediaTextBlockDeprecatedV4Attributes;
  CoreMediaTextBlockDeprecatedV5Attributes?: CoreMediaTextBlockDeprecatedV5Attributes;
  CoreMediaTextBlockDeprecatedV6Attributes?: CoreMediaTextBlockDeprecatedV6Attributes;
}

export interface $CoreNavigationBlockAttributesUnion {
  CoreNavigationBlockAttributes?: CoreNavigationBlockAttributes;
  CoreNavigationBlockDeprecatedV1Attributes?: CoreNavigationBlockDeprecatedV1Attributes;
  CoreNavigationBlockDeprecatedV2Attributes?: CoreNavigationBlockDeprecatedV2Attributes;
  CoreNavigationBlockDeprecatedV3Attributes?: CoreNavigationBlockDeprecatedV3Attributes;
  CoreNavigationBlockDeprecatedV4Attributes?: CoreNavigationBlockDeprecatedV4Attributes;
  CoreNavigationBlockDeprecatedV5Attributes?: CoreNavigationBlockDeprecatedV5Attributes;
  CoreNavigationBlockDeprecatedV6Attributes?: CoreNavigationBlockDeprecatedV6Attributes;
}

export interface $CoreNavigationLinkBlockAttributesUnion {
  CoreNavigationLinkBlockAttributes?: CoreNavigationLinkBlockAttributes;
  CoreNavigationLinkBlockDeprecatedV1Attributes?: CoreNavigationLinkBlockDeprecatedV1Attributes;
}

export interface $CoreParagraphBlockAttributesUnion {
  CoreParagraphBlockAttributes?: CoreParagraphBlockAttributes;
  CoreParagraphBlockDeprecatedV1Attributes?: CoreParagraphBlockDeprecatedV1Attributes;
  CoreParagraphBlockDeprecatedV2Attributes?: CoreParagraphBlockDeprecatedV2Attributes;
  CoreParagraphBlockDeprecatedV3Attributes?: CoreParagraphBlockDeprecatedV3Attributes;
  CoreParagraphBlockDeprecatedV4Attributes?: CoreParagraphBlockDeprecatedV4Attributes;
  CoreParagraphBlockDeprecatedV5Attributes?: CoreParagraphBlockDeprecatedV5Attributes;
  CoreParagraphBlockDeprecatedV6Attributes?: CoreParagraphBlockDeprecatedV6Attributes;
}

export interface $CorePostDateBlockAttributesUnion {
  CorePostDateBlockAttributes?: CorePostDateBlockAttributes;
  CorePostDateBlockDeprecatedV1Attributes?: CorePostDateBlockDeprecatedV1Attributes;
}

export interface $CorePostTitleBlockAttributesUnion {
  CorePostTitleBlockAttributes?: CorePostTitleBlockAttributes;
  CorePostTitleBlockDeprecatedV1Attributes?: CorePostTitleBlockDeprecatedV1Attributes;
}

export interface $CorePullquoteBlockAttributesUnion {
  CorePullquoteBlockAttributes?: CorePullquoteBlockAttributes;
  CorePullquoteBlockDeprecatedV1Attributes?: CorePullquoteBlockDeprecatedV1Attributes;
  CorePullquoteBlockDeprecatedV2Attributes?: CorePullquoteBlockDeprecatedV2Attributes;
  CorePullquoteBlockDeprecatedV3Attributes?: CorePullquoteBlockDeprecatedV3Attributes;
  CorePullquoteBlockDeprecatedV4Attributes?: CorePullquoteBlockDeprecatedV4Attributes;
  CorePullquoteBlockDeprecatedV5Attributes?: CorePullquoteBlockDeprecatedV5Attributes;
  CorePullquoteBlockDeprecatedV6Attributes?: CorePullquoteBlockDeprecatedV6Attributes;
}

export interface $CoreQueryBlockAttributesUnion {
  CoreQueryBlockAttributes?: CoreQueryBlockAttributes;
  CoreQueryBlockDeprecatedV1Attributes?: CoreQueryBlockDeprecatedV1Attributes;
  CoreQueryBlockDeprecatedV2Attributes?: CoreQueryBlockDeprecatedV2Attributes;
  CoreQueryBlockDeprecatedV3Attributes?: CoreQueryBlockDeprecatedV3Attributes;
  CoreQueryBlockDeprecatedV4Attributes?: CoreQueryBlockDeprecatedV4Attributes;
  CoreQueryBlockDeprecatedV5Attributes?: CoreQueryBlockDeprecatedV5Attributes;
}

export interface $CoreQueryPaginationBlockAttributesUnion {
  CoreQueryPaginationBlockAttributes?: CoreQueryPaginationBlockAttributes;
  CoreQueryPaginationBlockDeprecatedV1Attributes?: CoreQueryPaginationBlockDeprecatedV1Attributes;
}

export interface $CoreQueryTitleBlockAttributesUnion {
  CoreQueryTitleBlockAttributes?: CoreQueryTitleBlockAttributes;
  CoreQueryTitleBlockDeprecatedV1Attributes?: CoreQueryTitleBlockDeprecatedV1Attributes;
}

export interface $CoreQuoteBlockAttributesUnion {
  CoreQuoteBlockAttributes?: CoreQuoteBlockAttributes;
  CoreQuoteBlockDeprecatedV1Attributes?: CoreQuoteBlockDeprecatedV1Attributes;
  CoreQuoteBlockDeprecatedV2Attributes?: CoreQuoteBlockDeprecatedV2Attributes;
  CoreQuoteBlockDeprecatedV3Attributes?: CoreQuoteBlockDeprecatedV3Attributes;
  CoreQuoteBlockDeprecatedV4Attributes?: CoreQuoteBlockDeprecatedV4Attributes;
}

export interface $CoreSeparatorBlockAttributesUnion {
  CoreSeparatorBlockAttributes?: CoreSeparatorBlockAttributes;
  CoreSeparatorBlockDeprecatedV1Attributes?: CoreSeparatorBlockDeprecatedV1Attributes;
}

export interface $CoreSiteTaglineBlockAttributesUnion {
  CoreSiteTaglineBlockAttributes?: CoreSiteTaglineBlockAttributes;
  CoreSiteTaglineBlockDeprecatedV1Attributes?: CoreSiteTaglineBlockDeprecatedV1Attributes;
}

export interface $CoreSiteTitleBlockAttributesUnion {
  CoreSiteTitleBlockAttributes?: CoreSiteTitleBlockAttributes;
  CoreSiteTitleBlockDeprecatedV1Attributes?: CoreSiteTitleBlockDeprecatedV1Attributes;
}

export interface $CoreSocialLinksBlockAttributesUnion {
  CoreSocialLinksBlockAttributes?: CoreSocialLinksBlockAttributes;
  CoreSocialLinksBlockDeprecatedV1Attributes?: CoreSocialLinksBlockDeprecatedV1Attributes;
}

export interface $CoreSpacerBlockAttributesUnion {
  CoreSpacerBlockAttributes?: CoreSpacerBlockAttributes;
  CoreSpacerBlockDeprecatedV1Attributes?: CoreSpacerBlockDeprecatedV1Attributes;
}

export interface $CoreTableBlockAttributesUnion {
  CoreTableBlockAttributes?: CoreTableBlockAttributes;
  CoreTableBlockDeprecatedV1Attributes?: CoreTableBlockDeprecatedV1Attributes;
  CoreTableBlockDeprecatedV2Attributes?: CoreTableBlockDeprecatedV2Attributes;
  CoreTableBlockDeprecatedV3Attributes?: CoreTableBlockDeprecatedV3Attributes;
}

export interface $CoreVerseBlockAttributesUnion {
  CoreVerseBlockAttributes?: CoreVerseBlockAttributes;
  CoreVerseBlockDeprecatedV1Attributes?: CoreVerseBlockDeprecatedV1Attributes;
  CoreVerseBlockDeprecatedV2Attributes?: CoreVerseBlockDeprecatedV2Attributes;
}

export interface $CoreVideoBlockAttributesUnion {
  CoreVideoBlockAttributes?: CoreVideoBlockAttributes;
  CoreVideoBlockDeprecatedV1Attributes?: CoreVideoBlockDeprecatedV1Attributes;
}

export interface $CoreWidgetGroupBlockAttributesUnion {
  CoreWidgetGroupBlockAttributes?: CoreWidgetGroupBlockAttributes;
  CoreWidgetGroupBlockDeprecatedV1Attributes?: CoreWidgetGroupBlockDeprecatedV1Attributes;
}

export interface $DatabaseIdentifier {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  Comment?: Comment;
  CommentAuthor?: CommentAuthor;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Menu?: Menu;
  MenuItem?: MenuItem;
  Page?: Page;
  Post?: Post;
  PostFormat?: PostFormat;
  Press?: Press;
  PressCategory?: PressCategory;
  ReusableBlock?: ReusableBlock;
  Tag?: Tag;
  TeamMember?: TeamMember;
  User?: User;
}

export interface $Edge {
  BlockEditorPreviewToPreviewConnectionEdge?: BlockEditorPreviewToPreviewConnectionEdge;
  CategoryToAncestorsCategoryConnectionEdge?: CategoryToAncestorsCategoryConnectionEdge;
  CategoryToCategoryConnectionEdge?: CategoryToCategoryConnectionEdge;
  CategoryToContentNodeConnectionEdge?: CategoryToContentNodeConnectionEdge;
  CategoryToParentCategoryConnectionEdge?: CategoryToParentCategoryConnectionEdge;
  CategoryToPostConnectionEdge?: CategoryToPostConnectionEdge;
  CategoryToTaxonomyConnectionEdge?: CategoryToTaxonomyConnectionEdge;
  CommentToCommentConnectionEdge?: CommentToCommentConnectionEdge;
  CommentToCommenterConnectionEdge?: CommentToCommenterConnectionEdge;
  CommentToContentNodeConnectionEdge?: CommentToContentNodeConnectionEdge;
  CommentToParentCommentConnectionEdge?: CommentToParentCommentConnectionEdge;
  CompanyCategoryToAncestorsCompanyCategoryConnectionEdge?: CompanyCategoryToAncestorsCompanyCategoryConnectionEdge;
  CompanyCategoryToCompanyCategoryConnectionEdge?: CompanyCategoryToCompanyCategoryConnectionEdge;
  CompanyCategoryToCompanyConnectionEdge?: CompanyCategoryToCompanyConnectionEdge;
  CompanyCategoryToContentNodeConnectionEdge?: CompanyCategoryToContentNodeConnectionEdge;
  CompanyCategoryToParentCompanyCategoryConnectionEdge?: CompanyCategoryToParentCompanyCategoryConnectionEdge;
  CompanyCategoryToTaxonomyConnectionEdge?: CompanyCategoryToTaxonomyConnectionEdge;
  CompanyToCompanyCategoryConnectionEdge?: CompanyToCompanyCategoryConnectionEdge;
  CompanyToPreviewConnectionEdge?: CompanyToPreviewConnectionEdge;
  CompanyToTermNodeConnectionEdge?: CompanyToTermNodeConnectionEdge;
  ContentNodeToContentTypeConnectionEdge?: ContentNodeToContentTypeConnectionEdge;
  ContentNodeToEditLastConnectionEdge?: ContentNodeToEditLastConnectionEdge;
  ContentNodeToEditLockConnectionEdge?: ContentNodeToEditLockConnectionEdge;
  ContentNodeToEnqueuedScriptConnectionEdge?: ContentNodeToEnqueuedScriptConnectionEdge;
  ContentNodeToEnqueuedStylesheetConnectionEdge?: ContentNodeToEnqueuedStylesheetConnectionEdge;
  ContentTypeToContentNodeConnectionEdge?: ContentTypeToContentNodeConnectionEdge;
  ContentTypeToTaxonomyConnectionEdge?: ContentTypeToTaxonomyConnectionEdge;
  CoreImageBlockToMediaItemConnectionEdge?: CoreImageBlockToMediaItemConnectionEdge;
  GraphqlDocumentToPreviewConnectionEdge?: GraphqlDocumentToPreviewConnectionEdge;
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge?: HierarchicalContentNodeToContentNodeAncestorsConnectionEdge;
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge?: HierarchicalContentNodeToContentNodeChildrenConnectionEdge;
  HierarchicalContentNodeToParentContentNodeConnectionEdge?: HierarchicalContentNodeToParentContentNodeConnectionEdge;
  MediaItemToCommentConnectionEdge?: MediaItemToCommentConnectionEdge;
  MenuItemToMenuConnectionEdge?: MenuItemToMenuConnectionEdge;
  MenuItemToMenuItemConnectionEdge?: MenuItemToMenuItemConnectionEdge;
  MenuItemToMenuItemLinkableConnectionEdge?: MenuItemToMenuItemLinkableConnectionEdge;
  MenuToMenuItemConnectionEdge?: MenuToMenuItemConnectionEdge;
  NodeWithAuthorToUserConnectionEdge?: NodeWithAuthorToUserConnectionEdge;
  NodeWithFeaturedImageToMediaItemConnectionEdge?: NodeWithFeaturedImageToMediaItemConnectionEdge;
  NodeWithRevisionsToContentNodeConnectionEdge?: NodeWithRevisionsToContentNodeConnectionEdge;
  PageToCommentConnectionEdge?: PageToCommentConnectionEdge;
  PageToPreviewConnectionEdge?: PageToPreviewConnectionEdge;
  PageToRevisionConnectionEdge?: PageToRevisionConnectionEdge;
  PostFormatToContentNodeConnectionEdge?: PostFormatToContentNodeConnectionEdge;
  PostFormatToPostConnectionEdge?: PostFormatToPostConnectionEdge;
  PostFormatToTaxonomyConnectionEdge?: PostFormatToTaxonomyConnectionEdge;
  PostToCategoryConnectionEdge?: PostToCategoryConnectionEdge;
  PostToCommentConnectionEdge?: PostToCommentConnectionEdge;
  PostToPostFormatConnectionEdge?: PostToPostFormatConnectionEdge;
  PostToPreviewConnectionEdge?: PostToPreviewConnectionEdge;
  PostToRevisionConnectionEdge?: PostToRevisionConnectionEdge;
  PostToTagConnectionEdge?: PostToTagConnectionEdge;
  PostToTermNodeConnectionEdge?: PostToTermNodeConnectionEdge;
  PressCategoryToAncestorsPressCategoryConnectionEdge?: PressCategoryToAncestorsPressCategoryConnectionEdge;
  PressCategoryToContentNodeConnectionEdge?: PressCategoryToContentNodeConnectionEdge;
  PressCategoryToParentPressCategoryConnectionEdge?: PressCategoryToParentPressCategoryConnectionEdge;
  PressCategoryToPressCategoryConnectionEdge?: PressCategoryToPressCategoryConnectionEdge;
  PressCategoryToPressConnectionEdge?: PressCategoryToPressConnectionEdge;
  PressCategoryToTaxonomyConnectionEdge?: PressCategoryToTaxonomyConnectionEdge;
  PressCategoryToTeamMemberConnectionEdge?: PressCategoryToTeamMemberConnectionEdge;
  PressToPressCategoryConnectionEdge?: PressToPressCategoryConnectionEdge;
  PressToPreviewConnectionEdge?: PressToPreviewConnectionEdge;
  PressToTermNodeConnectionEdge?: PressToTermNodeConnectionEdge;
  ReusableBlockToPreviewConnectionEdge?: ReusableBlockToPreviewConnectionEdge;
  ReusableBlockToRevisionConnectionEdge?: ReusableBlockToRevisionConnectionEdge;
  RootQueryToBlockEditorPreviewConnectionEdge?: RootQueryToBlockEditorPreviewConnectionEdge;
  RootQueryToCategoryConnectionEdge?: RootQueryToCategoryConnectionEdge;
  RootQueryToCommentConnectionEdge?: RootQueryToCommentConnectionEdge;
  RootQueryToCompanyCategoryConnectionEdge?: RootQueryToCompanyCategoryConnectionEdge;
  RootQueryToCompanyConnectionEdge?: RootQueryToCompanyConnectionEdge;
  RootQueryToContentNodeConnectionEdge?: RootQueryToContentNodeConnectionEdge;
  RootQueryToContentTypeConnectionEdge?: RootQueryToContentTypeConnectionEdge;
  RootQueryToEnqueuedScriptConnectionEdge?: RootQueryToEnqueuedScriptConnectionEdge;
  RootQueryToEnqueuedStylesheetConnectionEdge?: RootQueryToEnqueuedStylesheetConnectionEdge;
  RootQueryToGraphqlDocumentConnectionEdge?: RootQueryToGraphqlDocumentConnectionEdge;
  RootQueryToMediaItemConnectionEdge?: RootQueryToMediaItemConnectionEdge;
  RootQueryToMenuConnectionEdge?: RootQueryToMenuConnectionEdge;
  RootQueryToMenuItemConnectionEdge?: RootQueryToMenuItemConnectionEdge;
  RootQueryToPageConnectionEdge?: RootQueryToPageConnectionEdge;
  RootQueryToPluginConnectionEdge?: RootQueryToPluginConnectionEdge;
  RootQueryToPostConnectionEdge?: RootQueryToPostConnectionEdge;
  RootQueryToPostFormatConnectionEdge?: RootQueryToPostFormatConnectionEdge;
  RootQueryToPressCategoryConnectionEdge?: RootQueryToPressCategoryConnectionEdge;
  RootQueryToPressConnectionEdge?: RootQueryToPressConnectionEdge;
  RootQueryToReusableBlockConnectionEdge?: RootQueryToReusableBlockConnectionEdge;
  RootQueryToRevisionsConnectionEdge?: RootQueryToRevisionsConnectionEdge;
  RootQueryToTagConnectionEdge?: RootQueryToTagConnectionEdge;
  RootQueryToTaxonomyConnectionEdge?: RootQueryToTaxonomyConnectionEdge;
  RootQueryToTeamMemberConnectionEdge?: RootQueryToTeamMemberConnectionEdge;
  RootQueryToTermNodeConnectionEdge?: RootQueryToTermNodeConnectionEdge;
  RootQueryToThemeConnectionEdge?: RootQueryToThemeConnectionEdge;
  RootQueryToUserConnectionEdge?: RootQueryToUserConnectionEdge;
  RootQueryToUserRoleConnectionEdge?: RootQueryToUserRoleConnectionEdge;
  TagToContentNodeConnectionEdge?: TagToContentNodeConnectionEdge;
  TagToPostConnectionEdge?: TagToPostConnectionEdge;
  TagToTaxonomyConnectionEdge?: TagToTaxonomyConnectionEdge;
  TaxonomyToContentTypeConnectionEdge?: TaxonomyToContentTypeConnectionEdge;
  TaxonomyToTermNodeConnectionEdge?: TaxonomyToTermNodeConnectionEdge;
  TeamMemberToPressCategoryConnectionEdge?: TeamMemberToPressCategoryConnectionEdge;
  TeamMemberToPreviewConnectionEdge?: TeamMemberToPreviewConnectionEdge;
  TeamMemberToTermNodeConnectionEdge?: TeamMemberToTermNodeConnectionEdge;
  TermNodeToEnqueuedScriptConnectionEdge?: TermNodeToEnqueuedScriptConnectionEdge;
  TermNodeToEnqueuedStylesheetConnectionEdge?: TermNodeToEnqueuedStylesheetConnectionEdge;
  UserToBlockEditorPreviewConnectionEdge?: UserToBlockEditorPreviewConnectionEdge;
  UserToCommentConnectionEdge?: UserToCommentConnectionEdge;
  UserToEnqueuedScriptConnectionEdge?: UserToEnqueuedScriptConnectionEdge;
  UserToEnqueuedStylesheetConnectionEdge?: UserToEnqueuedStylesheetConnectionEdge;
  UserToMediaItemConnectionEdge?: UserToMediaItemConnectionEdge;
  UserToPageConnectionEdge?: UserToPageConnectionEdge;
  UserToPostConnectionEdge?: UserToPostConnectionEdge;
  UserToRevisionsConnectionEdge?: UserToRevisionsConnectionEdge;
  UserToUserRoleConnectionEdge?: UserToUserRoleConnectionEdge;
}

export interface $EnqueuedAsset {
  EnqueuedScript?: EnqueuedScript;
  EnqueuedStylesheet?: EnqueuedStylesheet;
}

export interface $EnqueuedScriptConnection {
  ContentNodeToEnqueuedScriptConnection?: ContentNodeToEnqueuedScriptConnection;
  RootQueryToEnqueuedScriptConnection?: RootQueryToEnqueuedScriptConnection;
  TermNodeToEnqueuedScriptConnection?: TermNodeToEnqueuedScriptConnection;
  UserToEnqueuedScriptConnection?: UserToEnqueuedScriptConnection;
}

export interface $EnqueuedScriptConnectionEdge {
  ContentNodeToEnqueuedScriptConnectionEdge?: ContentNodeToEnqueuedScriptConnectionEdge;
  RootQueryToEnqueuedScriptConnectionEdge?: RootQueryToEnqueuedScriptConnectionEdge;
  TermNodeToEnqueuedScriptConnectionEdge?: TermNodeToEnqueuedScriptConnectionEdge;
  UserToEnqueuedScriptConnectionEdge?: UserToEnqueuedScriptConnectionEdge;
}

export interface $EnqueuedScriptConnectionPageInfo {
  ContentNodeToEnqueuedScriptConnectionPageInfo?: ContentNodeToEnqueuedScriptConnectionPageInfo;
  RootQueryToEnqueuedScriptConnectionPageInfo?: RootQueryToEnqueuedScriptConnectionPageInfo;
  TermNodeToEnqueuedScriptConnectionPageInfo?: TermNodeToEnqueuedScriptConnectionPageInfo;
  UserToEnqueuedScriptConnectionPageInfo?: UserToEnqueuedScriptConnectionPageInfo;
}

export interface $EnqueuedStylesheetConnection {
  ContentNodeToEnqueuedStylesheetConnection?: ContentNodeToEnqueuedStylesheetConnection;
  RootQueryToEnqueuedStylesheetConnection?: RootQueryToEnqueuedStylesheetConnection;
  TermNodeToEnqueuedStylesheetConnection?: TermNodeToEnqueuedStylesheetConnection;
  UserToEnqueuedStylesheetConnection?: UserToEnqueuedStylesheetConnection;
}

export interface $EnqueuedStylesheetConnectionEdge {
  ContentNodeToEnqueuedStylesheetConnectionEdge?: ContentNodeToEnqueuedStylesheetConnectionEdge;
  RootQueryToEnqueuedStylesheetConnectionEdge?: RootQueryToEnqueuedStylesheetConnectionEdge;
  TermNodeToEnqueuedStylesheetConnectionEdge?: TermNodeToEnqueuedStylesheetConnectionEdge;
  UserToEnqueuedStylesheetConnectionEdge?: UserToEnqueuedStylesheetConnectionEdge;
}

export interface $EnqueuedStylesheetConnectionPageInfo {
  ContentNodeToEnqueuedStylesheetConnectionPageInfo?: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  RootQueryToEnqueuedStylesheetConnectionPageInfo?: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  TermNodeToEnqueuedStylesheetConnectionPageInfo?: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  UserToEnqueuedStylesheetConnectionPageInfo?: UserToEnqueuedStylesheetConnectionPageInfo;
}

export interface $GraphqlDocumentConnection {
  RootQueryToGraphqlDocumentConnection?: RootQueryToGraphqlDocumentConnection;
}

export interface $GraphqlDocumentConnectionEdge {
  GraphqlDocumentToPreviewConnectionEdge?: GraphqlDocumentToPreviewConnectionEdge;
  RootQueryToGraphqlDocumentConnectionEdge?: RootQueryToGraphqlDocumentConnectionEdge;
}

export interface $GraphqlDocumentConnectionPageInfo {
  RootQueryToGraphqlDocumentConnectionPageInfo?: RootQueryToGraphqlDocumentConnectionPageInfo;
}

export interface $HierarchicalContentNode {
  Company?: Company;
  MediaItem?: MediaItem;
  Page?: Page;
  TeamMember?: TeamMember;
}

export interface $HierarchicalNode {
  Category?: Category;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  MediaItem?: MediaItem;
  Page?: Page;
  PressCategory?: PressCategory;
  TeamMember?: TeamMember;
}

export interface $HierarchicalTermNode {
  Category?: Category;
  CompanyCategory?: CompanyCategory;
  PressCategory?: PressCategory;
}

export interface $MediaItemConnection {
  RootQueryToMediaItemConnection?: RootQueryToMediaItemConnection;
  UserToMediaItemConnection?: UserToMediaItemConnection;
}

export interface $MediaItemConnectionEdge {
  CoreImageBlockToMediaItemConnectionEdge?: CoreImageBlockToMediaItemConnectionEdge;
  NodeWithFeaturedImageToMediaItemConnectionEdge?: NodeWithFeaturedImageToMediaItemConnectionEdge;
  RootQueryToMediaItemConnectionEdge?: RootQueryToMediaItemConnectionEdge;
  UserToMediaItemConnectionEdge?: UserToMediaItemConnectionEdge;
}

export interface $MediaItemConnectionPageInfo {
  RootQueryToMediaItemConnectionPageInfo?: RootQueryToMediaItemConnectionPageInfo;
  UserToMediaItemConnectionPageInfo?: UserToMediaItemConnectionPageInfo;
}

export interface $MenuConnection {
  RootQueryToMenuConnection?: RootQueryToMenuConnection;
}

export interface $MenuConnectionEdge {
  MenuItemToMenuConnectionEdge?: MenuItemToMenuConnectionEdge;
  RootQueryToMenuConnectionEdge?: RootQueryToMenuConnectionEdge;
}

export interface $MenuConnectionPageInfo {
  RootQueryToMenuConnectionPageInfo?: RootQueryToMenuConnectionPageInfo;
}

export interface $MenuItemConnection {
  MenuItemToMenuItemConnection?: MenuItemToMenuItemConnection;
  MenuToMenuItemConnection?: MenuToMenuItemConnection;
  RootQueryToMenuItemConnection?: RootQueryToMenuItemConnection;
}

export interface $MenuItemConnectionEdge {
  MenuItemToMenuItemConnectionEdge?: MenuItemToMenuItemConnectionEdge;
  MenuToMenuItemConnectionEdge?: MenuToMenuItemConnectionEdge;
  RootQueryToMenuItemConnectionEdge?: RootQueryToMenuItemConnectionEdge;
}

export interface $MenuItemConnectionPageInfo {
  MenuItemToMenuItemConnectionPageInfo?: MenuItemToMenuItemConnectionPageInfo;
  MenuToMenuItemConnectionPageInfo?: MenuToMenuItemConnectionPageInfo;
  RootQueryToMenuItemConnectionPageInfo?: RootQueryToMenuItemConnectionPageInfo;
}

export interface $MenuItemLinkable {
  Category?: Category;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  PressCategory?: PressCategory;
  Tag?: Tag;
  TeamMember?: TeamMember;
}

export interface $MenuItemLinkableConnectionEdge {
  MenuItemToMenuItemLinkableConnectionEdge?: MenuItemToMenuItemLinkableConnectionEdge;
}

export interface $MenuItemObjectUnion {
  Category?: Category;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  PressCategory?: PressCategory;
  Tag?: Tag;
  TeamMember?: TeamMember;
}

export interface $Node {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  Comment?: Comment;
  CommentAuthor?: CommentAuthor;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  ContentType?: ContentType;
  EnqueuedScript?: EnqueuedScript;
  EnqueuedStylesheet?: EnqueuedStylesheet;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Menu?: Menu;
  MenuItem?: MenuItem;
  Page?: Page;
  Plugin?: Plugin;
  Post?: Post;
  PostFormat?: PostFormat;
  Press?: Press;
  PressCategory?: PressCategory;
  ReusableBlock?: ReusableBlock;
  Tag?: Tag;
  Taxonomy?: Taxonomy;
  TeamMember?: TeamMember;
  Theme?: Theme;
  User?: User;
  UserRole?: UserRole;
}

export interface $NodeWithAuthor {
  BlockEditorPreview?: BlockEditorPreview;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
}

export interface $NodeWithComments {
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
}

export interface $NodeWithContentEditor {
  BlockEditorPreview?: BlockEditorPreview;
  Company?: Company;
  GraphqlDocument?: GraphqlDocument;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  ReusableBlock?: ReusableBlock;
  TeamMember?: TeamMember;
}

export interface $NodeWithExcerpt {
  Company?: Company;
  Post?: Post;
}

export interface $NodeWithFeaturedImage {
  Company?: Company;
  Page?: Page;
  Post?: Post;
  Press?: Press;
}

export interface $NodeWithPageAttributes {
  Page?: Page;
}

export interface $NodeWithRevisions {
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
}

export interface $NodeWithTemplate {
  BlockEditorPreview?: BlockEditorPreview;
  Company?: Company;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  ReusableBlock?: ReusableBlock;
  TeamMember?: TeamMember;
}

export interface $NodeWithTitle {
  BlockEditorPreview?: BlockEditorPreview;
  Company?: Company;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  ReusableBlock?: ReusableBlock;
  TeamMember?: TeamMember;
}

export interface $NodeWithTrackbacks {
  Post?: Post;
}

export interface $OneToOneConnection {
  BlockEditorPreviewToPreviewConnectionEdge?: BlockEditorPreviewToPreviewConnectionEdge;
  CategoryToParentCategoryConnectionEdge?: CategoryToParentCategoryConnectionEdge;
  CategoryToTaxonomyConnectionEdge?: CategoryToTaxonomyConnectionEdge;
  CommentToCommenterConnectionEdge?: CommentToCommenterConnectionEdge;
  CommentToContentNodeConnectionEdge?: CommentToContentNodeConnectionEdge;
  CommentToParentCommentConnectionEdge?: CommentToParentCommentConnectionEdge;
  CompanyCategoryToParentCompanyCategoryConnectionEdge?: CompanyCategoryToParentCompanyCategoryConnectionEdge;
  CompanyCategoryToTaxonomyConnectionEdge?: CompanyCategoryToTaxonomyConnectionEdge;
  CompanyToPreviewConnectionEdge?: CompanyToPreviewConnectionEdge;
  ContentNodeToContentTypeConnectionEdge?: ContentNodeToContentTypeConnectionEdge;
  ContentNodeToEditLastConnectionEdge?: ContentNodeToEditLastConnectionEdge;
  ContentNodeToEditLockConnectionEdge?: ContentNodeToEditLockConnectionEdge;
  CoreImageBlockToMediaItemConnectionEdge?: CoreImageBlockToMediaItemConnectionEdge;
  GraphqlDocumentToPreviewConnectionEdge?: GraphqlDocumentToPreviewConnectionEdge;
  HierarchicalContentNodeToParentContentNodeConnectionEdge?: HierarchicalContentNodeToParentContentNodeConnectionEdge;
  MenuItemToMenuConnectionEdge?: MenuItemToMenuConnectionEdge;
  MenuItemToMenuItemLinkableConnectionEdge?: MenuItemToMenuItemLinkableConnectionEdge;
  NodeWithAuthorToUserConnectionEdge?: NodeWithAuthorToUserConnectionEdge;
  NodeWithFeaturedImageToMediaItemConnectionEdge?: NodeWithFeaturedImageToMediaItemConnectionEdge;
  NodeWithRevisionsToContentNodeConnectionEdge?: NodeWithRevisionsToContentNodeConnectionEdge;
  PageToPreviewConnectionEdge?: PageToPreviewConnectionEdge;
  PostFormatToTaxonomyConnectionEdge?: PostFormatToTaxonomyConnectionEdge;
  PostToPreviewConnectionEdge?: PostToPreviewConnectionEdge;
  PressCategoryToParentPressCategoryConnectionEdge?: PressCategoryToParentPressCategoryConnectionEdge;
  PressCategoryToTaxonomyConnectionEdge?: PressCategoryToTaxonomyConnectionEdge;
  PressToPreviewConnectionEdge?: PressToPreviewConnectionEdge;
  ReusableBlockToPreviewConnectionEdge?: ReusableBlockToPreviewConnectionEdge;
  TagToTaxonomyConnectionEdge?: TagToTaxonomyConnectionEdge;
  TeamMemberToPreviewConnectionEdge?: TeamMemberToPreviewConnectionEdge;
}

export interface $PageConnection {
  PageToRevisionConnection?: PageToRevisionConnection;
  RootQueryToPageConnection?: RootQueryToPageConnection;
  UserToPageConnection?: UserToPageConnection;
}

export interface $PageConnectionEdge {
  PageToPreviewConnectionEdge?: PageToPreviewConnectionEdge;
  PageToRevisionConnectionEdge?: PageToRevisionConnectionEdge;
  RootQueryToPageConnectionEdge?: RootQueryToPageConnectionEdge;
  UserToPageConnectionEdge?: UserToPageConnectionEdge;
}

export interface $PageConnectionPageInfo {
  PageToRevisionConnectionPageInfo?: PageToRevisionConnectionPageInfo;
  RootQueryToPageConnectionPageInfo?: RootQueryToPageConnectionPageInfo;
  UserToPageConnectionPageInfo?: UserToPageConnectionPageInfo;
}

export interface $PageInfo {
  CategoryToAncestorsCategoryConnectionPageInfo?: CategoryToAncestorsCategoryConnectionPageInfo;
  CategoryToCategoryConnectionPageInfo?: CategoryToCategoryConnectionPageInfo;
  CategoryToContentNodeConnectionPageInfo?: CategoryToContentNodeConnectionPageInfo;
  CategoryToPostConnectionPageInfo?: CategoryToPostConnectionPageInfo;
  CommentToCommentConnectionPageInfo?: CommentToCommentConnectionPageInfo;
  CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo?: CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyCategoryConnectionPageInfo?: CompanyCategoryToCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyConnectionPageInfo?: CompanyCategoryToCompanyConnectionPageInfo;
  CompanyCategoryToContentNodeConnectionPageInfo?: CompanyCategoryToContentNodeConnectionPageInfo;
  CompanyToCompanyCategoryConnectionPageInfo?: CompanyToCompanyCategoryConnectionPageInfo;
  CompanyToTermNodeConnectionPageInfo?: CompanyToTermNodeConnectionPageInfo;
  ContentNodeToEnqueuedScriptConnectionPageInfo?: ContentNodeToEnqueuedScriptConnectionPageInfo;
  ContentNodeToEnqueuedStylesheetConnectionPageInfo?: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  ContentTypeToContentNodeConnectionPageInfo?: ContentTypeToContentNodeConnectionPageInfo;
  ContentTypeToTaxonomyConnectionPageInfo?: ContentTypeToTaxonomyConnectionPageInfo;
  HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo?: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo?: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  MediaItemToCommentConnectionPageInfo?: MediaItemToCommentConnectionPageInfo;
  MenuItemToMenuItemConnectionPageInfo?: MenuItemToMenuItemConnectionPageInfo;
  MenuToMenuItemConnectionPageInfo?: MenuToMenuItemConnectionPageInfo;
  PageToCommentConnectionPageInfo?: PageToCommentConnectionPageInfo;
  PageToRevisionConnectionPageInfo?: PageToRevisionConnectionPageInfo;
  PostFormatToContentNodeConnectionPageInfo?: PostFormatToContentNodeConnectionPageInfo;
  PostFormatToPostConnectionPageInfo?: PostFormatToPostConnectionPageInfo;
  PostToCategoryConnectionPageInfo?: PostToCategoryConnectionPageInfo;
  PostToCommentConnectionPageInfo?: PostToCommentConnectionPageInfo;
  PostToPostFormatConnectionPageInfo?: PostToPostFormatConnectionPageInfo;
  PostToRevisionConnectionPageInfo?: PostToRevisionConnectionPageInfo;
  PostToTagConnectionPageInfo?: PostToTagConnectionPageInfo;
  PostToTermNodeConnectionPageInfo?: PostToTermNodeConnectionPageInfo;
  PressCategoryToAncestorsPressCategoryConnectionPageInfo?: PressCategoryToAncestorsPressCategoryConnectionPageInfo;
  PressCategoryToContentNodeConnectionPageInfo?: PressCategoryToContentNodeConnectionPageInfo;
  PressCategoryToPressCategoryConnectionPageInfo?: PressCategoryToPressCategoryConnectionPageInfo;
  PressCategoryToPressConnectionPageInfo?: PressCategoryToPressConnectionPageInfo;
  PressCategoryToTeamMemberConnectionPageInfo?: PressCategoryToTeamMemberConnectionPageInfo;
  PressToPressCategoryConnectionPageInfo?: PressToPressCategoryConnectionPageInfo;
  PressToTermNodeConnectionPageInfo?: PressToTermNodeConnectionPageInfo;
  ReusableBlockToRevisionConnectionPageInfo?: ReusableBlockToRevisionConnectionPageInfo;
  RootQueryToBlockEditorPreviewConnectionPageInfo?: RootQueryToBlockEditorPreviewConnectionPageInfo;
  RootQueryToCategoryConnectionPageInfo?: RootQueryToCategoryConnectionPageInfo;
  RootQueryToCommentConnectionPageInfo?: RootQueryToCommentConnectionPageInfo;
  RootQueryToCompanyCategoryConnectionPageInfo?: RootQueryToCompanyCategoryConnectionPageInfo;
  RootQueryToCompanyConnectionPageInfo?: RootQueryToCompanyConnectionPageInfo;
  RootQueryToContentNodeConnectionPageInfo?: RootQueryToContentNodeConnectionPageInfo;
  RootQueryToContentTypeConnectionPageInfo?: RootQueryToContentTypeConnectionPageInfo;
  RootQueryToEnqueuedScriptConnectionPageInfo?: RootQueryToEnqueuedScriptConnectionPageInfo;
  RootQueryToEnqueuedStylesheetConnectionPageInfo?: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  RootQueryToGraphqlDocumentConnectionPageInfo?: RootQueryToGraphqlDocumentConnectionPageInfo;
  RootQueryToMediaItemConnectionPageInfo?: RootQueryToMediaItemConnectionPageInfo;
  RootQueryToMenuConnectionPageInfo?: RootQueryToMenuConnectionPageInfo;
  RootQueryToMenuItemConnectionPageInfo?: RootQueryToMenuItemConnectionPageInfo;
  RootQueryToPageConnectionPageInfo?: RootQueryToPageConnectionPageInfo;
  RootQueryToPluginConnectionPageInfo?: RootQueryToPluginConnectionPageInfo;
  RootQueryToPostConnectionPageInfo?: RootQueryToPostConnectionPageInfo;
  RootQueryToPostFormatConnectionPageInfo?: RootQueryToPostFormatConnectionPageInfo;
  RootQueryToPressCategoryConnectionPageInfo?: RootQueryToPressCategoryConnectionPageInfo;
  RootQueryToPressConnectionPageInfo?: RootQueryToPressConnectionPageInfo;
  RootQueryToReusableBlockConnectionPageInfo?: RootQueryToReusableBlockConnectionPageInfo;
  RootQueryToRevisionsConnectionPageInfo?: RootQueryToRevisionsConnectionPageInfo;
  RootQueryToTagConnectionPageInfo?: RootQueryToTagConnectionPageInfo;
  RootQueryToTaxonomyConnectionPageInfo?: RootQueryToTaxonomyConnectionPageInfo;
  RootQueryToTeamMemberConnectionPageInfo?: RootQueryToTeamMemberConnectionPageInfo;
  RootQueryToTermNodeConnectionPageInfo?: RootQueryToTermNodeConnectionPageInfo;
  RootQueryToThemeConnectionPageInfo?: RootQueryToThemeConnectionPageInfo;
  RootQueryToUserConnectionPageInfo?: RootQueryToUserConnectionPageInfo;
  RootQueryToUserRoleConnectionPageInfo?: RootQueryToUserRoleConnectionPageInfo;
  TagToContentNodeConnectionPageInfo?: TagToContentNodeConnectionPageInfo;
  TagToPostConnectionPageInfo?: TagToPostConnectionPageInfo;
  TaxonomyToContentTypeConnectionPageInfo?: TaxonomyToContentTypeConnectionPageInfo;
  TaxonomyToTermNodeConnectionPageInfo?: TaxonomyToTermNodeConnectionPageInfo;
  TeamMemberToPressCategoryConnectionPageInfo?: TeamMemberToPressCategoryConnectionPageInfo;
  TeamMemberToTermNodeConnectionPageInfo?: TeamMemberToTermNodeConnectionPageInfo;
  TermNodeToEnqueuedScriptConnectionPageInfo?: TermNodeToEnqueuedScriptConnectionPageInfo;
  TermNodeToEnqueuedStylesheetConnectionPageInfo?: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  UserToBlockEditorPreviewConnectionPageInfo?: UserToBlockEditorPreviewConnectionPageInfo;
  UserToCommentConnectionPageInfo?: UserToCommentConnectionPageInfo;
  UserToEnqueuedScriptConnectionPageInfo?: UserToEnqueuedScriptConnectionPageInfo;
  UserToEnqueuedStylesheetConnectionPageInfo?: UserToEnqueuedStylesheetConnectionPageInfo;
  UserToMediaItemConnectionPageInfo?: UserToMediaItemConnectionPageInfo;
  UserToPageConnectionPageInfo?: UserToPageConnectionPageInfo;
  UserToPostConnectionPageInfo?: UserToPostConnectionPageInfo;
  UserToRevisionsConnectionPageInfo?: UserToRevisionsConnectionPageInfo;
  UserToUserRoleConnectionPageInfo?: UserToUserRoleConnectionPageInfo;
}

export interface $PluginConnection {
  RootQueryToPluginConnection?: RootQueryToPluginConnection;
}

export interface $PluginConnectionEdge {
  RootQueryToPluginConnectionEdge?: RootQueryToPluginConnectionEdge;
}

export interface $PluginConnectionPageInfo {
  RootQueryToPluginConnectionPageInfo?: RootQueryToPluginConnectionPageInfo;
}

export interface $PostConnection {
  CategoryToPostConnection?: CategoryToPostConnection;
  PostFormatToPostConnection?: PostFormatToPostConnection;
  PostToRevisionConnection?: PostToRevisionConnection;
  RootQueryToPostConnection?: RootQueryToPostConnection;
  TagToPostConnection?: TagToPostConnection;
  UserToPostConnection?: UserToPostConnection;
}

export interface $PostConnectionEdge {
  CategoryToPostConnectionEdge?: CategoryToPostConnectionEdge;
  PostFormatToPostConnectionEdge?: PostFormatToPostConnectionEdge;
  PostToPreviewConnectionEdge?: PostToPreviewConnectionEdge;
  PostToRevisionConnectionEdge?: PostToRevisionConnectionEdge;
  RootQueryToPostConnectionEdge?: RootQueryToPostConnectionEdge;
  TagToPostConnectionEdge?: TagToPostConnectionEdge;
  UserToPostConnectionEdge?: UserToPostConnectionEdge;
}

export interface $PostConnectionPageInfo {
  CategoryToPostConnectionPageInfo?: CategoryToPostConnectionPageInfo;
  PostFormatToPostConnectionPageInfo?: PostFormatToPostConnectionPageInfo;
  PostToRevisionConnectionPageInfo?: PostToRevisionConnectionPageInfo;
  RootQueryToPostConnectionPageInfo?: RootQueryToPostConnectionPageInfo;
  TagToPostConnectionPageInfo?: TagToPostConnectionPageInfo;
  UserToPostConnectionPageInfo?: UserToPostConnectionPageInfo;
}

export interface $PostFormatConnection {
  PostToPostFormatConnection?: PostToPostFormatConnection;
  RootQueryToPostFormatConnection?: RootQueryToPostFormatConnection;
}

export interface $PostFormatConnectionEdge {
  PostToPostFormatConnectionEdge?: PostToPostFormatConnectionEdge;
  RootQueryToPostFormatConnectionEdge?: RootQueryToPostFormatConnectionEdge;
}

export interface $PostFormatConnectionPageInfo {
  PostToPostFormatConnectionPageInfo?: PostToPostFormatConnectionPageInfo;
  RootQueryToPostFormatConnectionPageInfo?: RootQueryToPostFormatConnectionPageInfo;
}

export interface $PressCategoryConnection {
  PressCategoryToAncestorsPressCategoryConnection?: PressCategoryToAncestorsPressCategoryConnection;
  PressCategoryToPressCategoryConnection?: PressCategoryToPressCategoryConnection;
  PressToPressCategoryConnection?: PressToPressCategoryConnection;
  RootQueryToPressCategoryConnection?: RootQueryToPressCategoryConnection;
  TeamMemberToPressCategoryConnection?: TeamMemberToPressCategoryConnection;
}

export interface $PressCategoryConnectionEdge {
  PressCategoryToAncestorsPressCategoryConnectionEdge?: PressCategoryToAncestorsPressCategoryConnectionEdge;
  PressCategoryToParentPressCategoryConnectionEdge?: PressCategoryToParentPressCategoryConnectionEdge;
  PressCategoryToPressCategoryConnectionEdge?: PressCategoryToPressCategoryConnectionEdge;
  PressToPressCategoryConnectionEdge?: PressToPressCategoryConnectionEdge;
  RootQueryToPressCategoryConnectionEdge?: RootQueryToPressCategoryConnectionEdge;
  TeamMemberToPressCategoryConnectionEdge?: TeamMemberToPressCategoryConnectionEdge;
}

export interface $PressCategoryConnectionPageInfo {
  PressCategoryToAncestorsPressCategoryConnectionPageInfo?: PressCategoryToAncestorsPressCategoryConnectionPageInfo;
  PressCategoryToPressCategoryConnectionPageInfo?: PressCategoryToPressCategoryConnectionPageInfo;
  PressToPressCategoryConnectionPageInfo?: PressToPressCategoryConnectionPageInfo;
  RootQueryToPressCategoryConnectionPageInfo?: RootQueryToPressCategoryConnectionPageInfo;
  TeamMemberToPressCategoryConnectionPageInfo?: TeamMemberToPressCategoryConnectionPageInfo;
}

export interface $PressConnection {
  PressCategoryToPressConnection?: PressCategoryToPressConnection;
  RootQueryToPressConnection?: RootQueryToPressConnection;
}

export interface $PressConnectionEdge {
  PressCategoryToPressConnectionEdge?: PressCategoryToPressConnectionEdge;
  PressToPreviewConnectionEdge?: PressToPreviewConnectionEdge;
  RootQueryToPressConnectionEdge?: RootQueryToPressConnectionEdge;
}

export interface $PressConnectionPageInfo {
  PressCategoryToPressConnectionPageInfo?: PressCategoryToPressConnectionPageInfo;
  RootQueryToPressConnectionPageInfo?: RootQueryToPressConnectionPageInfo;
}

export interface $Previewable {
  Company?: Company;
  Page?: Page;
  Post?: Post;
  Press?: Press;
  TeamMember?: TeamMember;
}

export interface $ReusableBlockConnection {
  ReusableBlockToRevisionConnection?: ReusableBlockToRevisionConnection;
  RootQueryToReusableBlockConnection?: RootQueryToReusableBlockConnection;
}

export interface $ReusableBlockConnectionEdge {
  ReusableBlockToPreviewConnectionEdge?: ReusableBlockToPreviewConnectionEdge;
  ReusableBlockToRevisionConnectionEdge?: ReusableBlockToRevisionConnectionEdge;
  RootQueryToReusableBlockConnectionEdge?: RootQueryToReusableBlockConnectionEdge;
}

export interface $ReusableBlockConnectionPageInfo {
  ReusableBlockToRevisionConnectionPageInfo?: ReusableBlockToRevisionConnectionPageInfo;
  RootQueryToReusableBlockConnectionPageInfo?: RootQueryToReusableBlockConnectionPageInfo;
}

export interface $TagConnection {
  PostToTagConnection?: PostToTagConnection;
  RootQueryToTagConnection?: RootQueryToTagConnection;
}

export interface $TagConnectionEdge {
  PostToTagConnectionEdge?: PostToTagConnectionEdge;
  RootQueryToTagConnectionEdge?: RootQueryToTagConnectionEdge;
}

export interface $TagConnectionPageInfo {
  PostToTagConnectionPageInfo?: PostToTagConnectionPageInfo;
  RootQueryToTagConnectionPageInfo?: RootQueryToTagConnectionPageInfo;
}

export interface $TaxonomyConnection {
  ContentTypeToTaxonomyConnection?: ContentTypeToTaxonomyConnection;
  RootQueryToTaxonomyConnection?: RootQueryToTaxonomyConnection;
}

export interface $TaxonomyConnectionEdge {
  CategoryToTaxonomyConnectionEdge?: CategoryToTaxonomyConnectionEdge;
  CompanyCategoryToTaxonomyConnectionEdge?: CompanyCategoryToTaxonomyConnectionEdge;
  ContentTypeToTaxonomyConnectionEdge?: ContentTypeToTaxonomyConnectionEdge;
  PostFormatToTaxonomyConnectionEdge?: PostFormatToTaxonomyConnectionEdge;
  PressCategoryToTaxonomyConnectionEdge?: PressCategoryToTaxonomyConnectionEdge;
  RootQueryToTaxonomyConnectionEdge?: RootQueryToTaxonomyConnectionEdge;
  TagToTaxonomyConnectionEdge?: TagToTaxonomyConnectionEdge;
}

export interface $TaxonomyConnectionPageInfo {
  ContentTypeToTaxonomyConnectionPageInfo?: ContentTypeToTaxonomyConnectionPageInfo;
  RootQueryToTaxonomyConnectionPageInfo?: RootQueryToTaxonomyConnectionPageInfo;
}

export interface $TeamMemberConnection {
  PressCategoryToTeamMemberConnection?: PressCategoryToTeamMemberConnection;
  RootQueryToTeamMemberConnection?: RootQueryToTeamMemberConnection;
}

export interface $TeamMemberConnectionEdge {
  PressCategoryToTeamMemberConnectionEdge?: PressCategoryToTeamMemberConnectionEdge;
  RootQueryToTeamMemberConnectionEdge?: RootQueryToTeamMemberConnectionEdge;
  TeamMemberToPreviewConnectionEdge?: TeamMemberToPreviewConnectionEdge;
}

export interface $TeamMemberConnectionPageInfo {
  PressCategoryToTeamMemberConnectionPageInfo?: PressCategoryToTeamMemberConnectionPageInfo;
  RootQueryToTeamMemberConnectionPageInfo?: RootQueryToTeamMemberConnectionPageInfo;
}

export interface $TeamMember_Team_Investments {
  Company?: Company;
}

export interface $TeamMember_Teaminvestments_Investments {
  Company?: Company;
}

export interface $TermNode {
  Category?: Category;
  CompanyCategory?: CompanyCategory;
  PostFormat?: PostFormat;
  PressCategory?: PressCategory;
  Tag?: Tag;
}

export interface $TermNodeConnection {
  CompanyToTermNodeConnection?: CompanyToTermNodeConnection;
  PostToTermNodeConnection?: PostToTermNodeConnection;
  PressToTermNodeConnection?: PressToTermNodeConnection;
  RootQueryToTermNodeConnection?: RootQueryToTermNodeConnection;
  TaxonomyToTermNodeConnection?: TaxonomyToTermNodeConnection;
  TeamMemberToTermNodeConnection?: TeamMemberToTermNodeConnection;
}

export interface $TermNodeConnectionEdge {
  CompanyToTermNodeConnectionEdge?: CompanyToTermNodeConnectionEdge;
  PostToTermNodeConnectionEdge?: PostToTermNodeConnectionEdge;
  PressToTermNodeConnectionEdge?: PressToTermNodeConnectionEdge;
  RootQueryToTermNodeConnectionEdge?: RootQueryToTermNodeConnectionEdge;
  TaxonomyToTermNodeConnectionEdge?: TaxonomyToTermNodeConnectionEdge;
  TeamMemberToTermNodeConnectionEdge?: TeamMemberToTermNodeConnectionEdge;
}

export interface $TermNodeConnectionPageInfo {
  CompanyToTermNodeConnectionPageInfo?: CompanyToTermNodeConnectionPageInfo;
  PostToTermNodeConnectionPageInfo?: PostToTermNodeConnectionPageInfo;
  PressToTermNodeConnectionPageInfo?: PressToTermNodeConnectionPageInfo;
  RootQueryToTermNodeConnectionPageInfo?: RootQueryToTermNodeConnectionPageInfo;
  TaxonomyToTermNodeConnectionPageInfo?: TaxonomyToTermNodeConnectionPageInfo;
  TeamMemberToTermNodeConnectionPageInfo?: TeamMemberToTermNodeConnectionPageInfo;
}

export interface $ThemeConnection {
  RootQueryToThemeConnection?: RootQueryToThemeConnection;
}

export interface $ThemeConnectionEdge {
  RootQueryToThemeConnectionEdge?: RootQueryToThemeConnectionEdge;
}

export interface $ThemeConnectionPageInfo {
  RootQueryToThemeConnectionPageInfo?: RootQueryToThemeConnectionPageInfo;
}

export interface $UniformResourceIdentifiable {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  Company?: Company;
  CompanyCategory?: CompanyCategory;
  ContentType?: ContentType;
  GraphqlDocument?: GraphqlDocument;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  PostFormat?: PostFormat;
  Press?: Press;
  PressCategory?: PressCategory;
  ReusableBlock?: ReusableBlock;
  Tag?: Tag;
  TeamMember?: TeamMember;
  User?: User;
}

export interface $UserConnection {
  RootQueryToUserConnection?: RootQueryToUserConnection;
}

export interface $UserConnectionEdge {
  ContentNodeToEditLastConnectionEdge?: ContentNodeToEditLastConnectionEdge;
  ContentNodeToEditLockConnectionEdge?: ContentNodeToEditLockConnectionEdge;
  NodeWithAuthorToUserConnectionEdge?: NodeWithAuthorToUserConnectionEdge;
  RootQueryToUserConnectionEdge?: RootQueryToUserConnectionEdge;
}

export interface $UserConnectionPageInfo {
  RootQueryToUserConnectionPageInfo?: RootQueryToUserConnectionPageInfo;
}

export interface $UserRoleConnection {
  RootQueryToUserRoleConnection?: RootQueryToUserRoleConnection;
  UserToUserRoleConnection?: UserToUserRoleConnection;
}

export interface $UserRoleConnectionEdge {
  RootQueryToUserRoleConnectionEdge?: RootQueryToUserRoleConnectionEdge;
  UserToUserRoleConnectionEdge?: UserToUserRoleConnectionEdge;
}

export interface $UserRoleConnectionPageInfo {
  RootQueryToUserRoleConnectionPageInfo?: RootQueryToUserRoleConnectionPageInfo;
  UserToUserRoleConnectionPageInfo?: UserToUserRoleConnectionPageInfo;
}

export interface $WPPageInfo {
  CategoryToAncestorsCategoryConnectionPageInfo?: CategoryToAncestorsCategoryConnectionPageInfo;
  CategoryToCategoryConnectionPageInfo?: CategoryToCategoryConnectionPageInfo;
  CategoryToContentNodeConnectionPageInfo?: CategoryToContentNodeConnectionPageInfo;
  CategoryToPostConnectionPageInfo?: CategoryToPostConnectionPageInfo;
  CommentToCommentConnectionPageInfo?: CommentToCommentConnectionPageInfo;
  CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo?: CompanyCategoryToAncestorsCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyCategoryConnectionPageInfo?: CompanyCategoryToCompanyCategoryConnectionPageInfo;
  CompanyCategoryToCompanyConnectionPageInfo?: CompanyCategoryToCompanyConnectionPageInfo;
  CompanyCategoryToContentNodeConnectionPageInfo?: CompanyCategoryToContentNodeConnectionPageInfo;
  CompanyToCompanyCategoryConnectionPageInfo?: CompanyToCompanyCategoryConnectionPageInfo;
  CompanyToTermNodeConnectionPageInfo?: CompanyToTermNodeConnectionPageInfo;
  ContentNodeToEnqueuedScriptConnectionPageInfo?: ContentNodeToEnqueuedScriptConnectionPageInfo;
  ContentNodeToEnqueuedStylesheetConnectionPageInfo?: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  ContentTypeToContentNodeConnectionPageInfo?: ContentTypeToContentNodeConnectionPageInfo;
  ContentTypeToTaxonomyConnectionPageInfo?: ContentTypeToTaxonomyConnectionPageInfo;
  HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo?: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo?: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  MediaItemToCommentConnectionPageInfo?: MediaItemToCommentConnectionPageInfo;
  MenuItemToMenuItemConnectionPageInfo?: MenuItemToMenuItemConnectionPageInfo;
  MenuToMenuItemConnectionPageInfo?: MenuToMenuItemConnectionPageInfo;
  PageToCommentConnectionPageInfo?: PageToCommentConnectionPageInfo;
  PageToRevisionConnectionPageInfo?: PageToRevisionConnectionPageInfo;
  PostFormatToContentNodeConnectionPageInfo?: PostFormatToContentNodeConnectionPageInfo;
  PostFormatToPostConnectionPageInfo?: PostFormatToPostConnectionPageInfo;
  PostToCategoryConnectionPageInfo?: PostToCategoryConnectionPageInfo;
  PostToCommentConnectionPageInfo?: PostToCommentConnectionPageInfo;
  PostToPostFormatConnectionPageInfo?: PostToPostFormatConnectionPageInfo;
  PostToRevisionConnectionPageInfo?: PostToRevisionConnectionPageInfo;
  PostToTagConnectionPageInfo?: PostToTagConnectionPageInfo;
  PostToTermNodeConnectionPageInfo?: PostToTermNodeConnectionPageInfo;
  PressCategoryToAncestorsPressCategoryConnectionPageInfo?: PressCategoryToAncestorsPressCategoryConnectionPageInfo;
  PressCategoryToContentNodeConnectionPageInfo?: PressCategoryToContentNodeConnectionPageInfo;
  PressCategoryToPressCategoryConnectionPageInfo?: PressCategoryToPressCategoryConnectionPageInfo;
  PressCategoryToPressConnectionPageInfo?: PressCategoryToPressConnectionPageInfo;
  PressCategoryToTeamMemberConnectionPageInfo?: PressCategoryToTeamMemberConnectionPageInfo;
  PressToPressCategoryConnectionPageInfo?: PressToPressCategoryConnectionPageInfo;
  PressToTermNodeConnectionPageInfo?: PressToTermNodeConnectionPageInfo;
  ReusableBlockToRevisionConnectionPageInfo?: ReusableBlockToRevisionConnectionPageInfo;
  RootQueryToBlockEditorPreviewConnectionPageInfo?: RootQueryToBlockEditorPreviewConnectionPageInfo;
  RootQueryToCategoryConnectionPageInfo?: RootQueryToCategoryConnectionPageInfo;
  RootQueryToCommentConnectionPageInfo?: RootQueryToCommentConnectionPageInfo;
  RootQueryToCompanyCategoryConnectionPageInfo?: RootQueryToCompanyCategoryConnectionPageInfo;
  RootQueryToCompanyConnectionPageInfo?: RootQueryToCompanyConnectionPageInfo;
  RootQueryToContentNodeConnectionPageInfo?: RootQueryToContentNodeConnectionPageInfo;
  RootQueryToContentTypeConnectionPageInfo?: RootQueryToContentTypeConnectionPageInfo;
  RootQueryToEnqueuedScriptConnectionPageInfo?: RootQueryToEnqueuedScriptConnectionPageInfo;
  RootQueryToEnqueuedStylesheetConnectionPageInfo?: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  RootQueryToGraphqlDocumentConnectionPageInfo?: RootQueryToGraphqlDocumentConnectionPageInfo;
  RootQueryToMediaItemConnectionPageInfo?: RootQueryToMediaItemConnectionPageInfo;
  RootQueryToMenuConnectionPageInfo?: RootQueryToMenuConnectionPageInfo;
  RootQueryToMenuItemConnectionPageInfo?: RootQueryToMenuItemConnectionPageInfo;
  RootQueryToPageConnectionPageInfo?: RootQueryToPageConnectionPageInfo;
  RootQueryToPluginConnectionPageInfo?: RootQueryToPluginConnectionPageInfo;
  RootQueryToPostConnectionPageInfo?: RootQueryToPostConnectionPageInfo;
  RootQueryToPostFormatConnectionPageInfo?: RootQueryToPostFormatConnectionPageInfo;
  RootQueryToPressCategoryConnectionPageInfo?: RootQueryToPressCategoryConnectionPageInfo;
  RootQueryToPressConnectionPageInfo?: RootQueryToPressConnectionPageInfo;
  RootQueryToReusableBlockConnectionPageInfo?: RootQueryToReusableBlockConnectionPageInfo;
  RootQueryToRevisionsConnectionPageInfo?: RootQueryToRevisionsConnectionPageInfo;
  RootQueryToTagConnectionPageInfo?: RootQueryToTagConnectionPageInfo;
  RootQueryToTaxonomyConnectionPageInfo?: RootQueryToTaxonomyConnectionPageInfo;
  RootQueryToTeamMemberConnectionPageInfo?: RootQueryToTeamMemberConnectionPageInfo;
  RootQueryToTermNodeConnectionPageInfo?: RootQueryToTermNodeConnectionPageInfo;
  RootQueryToThemeConnectionPageInfo?: RootQueryToThemeConnectionPageInfo;
  RootQueryToUserConnectionPageInfo?: RootQueryToUserConnectionPageInfo;
  RootQueryToUserRoleConnectionPageInfo?: RootQueryToUserRoleConnectionPageInfo;
  TagToContentNodeConnectionPageInfo?: TagToContentNodeConnectionPageInfo;
  TagToPostConnectionPageInfo?: TagToPostConnectionPageInfo;
  TaxonomyToContentTypeConnectionPageInfo?: TaxonomyToContentTypeConnectionPageInfo;
  TaxonomyToTermNodeConnectionPageInfo?: TaxonomyToTermNodeConnectionPageInfo;
  TeamMemberToPressCategoryConnectionPageInfo?: TeamMemberToPressCategoryConnectionPageInfo;
  TeamMemberToTermNodeConnectionPageInfo?: TeamMemberToTermNodeConnectionPageInfo;
  TermNodeToEnqueuedScriptConnectionPageInfo?: TermNodeToEnqueuedScriptConnectionPageInfo;
  TermNodeToEnqueuedStylesheetConnectionPageInfo?: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  UserToBlockEditorPreviewConnectionPageInfo?: UserToBlockEditorPreviewConnectionPageInfo;
  UserToCommentConnectionPageInfo?: UserToCommentConnectionPageInfo;
  UserToEnqueuedScriptConnectionPageInfo?: UserToEnqueuedScriptConnectionPageInfo;
  UserToEnqueuedStylesheetConnectionPageInfo?: UserToEnqueuedStylesheetConnectionPageInfo;
  UserToMediaItemConnectionPageInfo?: UserToMediaItemConnectionPageInfo;
  UserToPageConnectionPageInfo?: UserToPageConnectionPageInfo;
  UserToPostConnectionPageInfo?: UserToPostConnectionPageInfo;
  UserToRevisionsConnectionPageInfo?: UserToRevisionsConnectionPageInfo;
  UserToUserRoleConnectionPageInfo?: UserToUserRoleConnectionPageInfo;
}

export interface $YoastFaqBlockAttributesUnion {
  YoastFaqBlockAttributes?: YoastFaqBlockAttributes;
  YoastFaqBlockDeprecatedV1Attributes?: YoastFaqBlockDeprecatedV1Attributes;
}

export interface $YoastHowToBlockAttributesUnion {
  YoastHowToBlockAttributes?: YoastHowToBlockAttributes;
  YoastHowToBlockDeprecatedV1Attributes?: YoastHowToBlockDeprecatedV1Attributes;
  YoastHowToBlockDeprecatedV2Attributes?: YoastHowToBlockDeprecatedV2Attributes;
}

export interface GeneratedSchema {
  query: Query;
  mutation: Mutation;
  subscription: Subscription;
}

export type MakeNullable<T> = {
  [K in keyof T]: T[K] | undefined;
};

export interface ScalarsEnums extends MakeNullable<Scalars> {
  AvatarRatingEnum: AvatarRatingEnum | undefined;
  BlockEditorPreviewIdType: BlockEditorPreviewIdType | undefined;
  CategoryIdType: CategoryIdType | undefined;
  CategoryToContentNodeConnectionWhereArgsTaxQueryField:
    | CategoryToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  CategoryToContentNodeConnectionWhereArgsTaxQueryOperator:
    | CategoryToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  CategoryToPostConnectionWhereArgsTaxQueryField:
    | CategoryToPostConnectionWhereArgsTaxQueryField
    | undefined;
  CategoryToPostConnectionWhereArgsTaxQueryOperator:
    | CategoryToPostConnectionWhereArgsTaxQueryOperator
    | undefined;
  CommentNodeIdTypeEnum: CommentNodeIdTypeEnum | undefined;
  CommentStatusEnum: CommentStatusEnum | undefined;
  CommentsConnectionOrderbyEnum: CommentsConnectionOrderbyEnum | undefined;
  CompanyCategoryIdType: CompanyCategoryIdType | undefined;
  CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField:
    | CompanyCategoryToCompanyConnectionWhereArgsTaxQueryField
    | undefined;
  CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator:
    | CompanyCategoryToCompanyConnectionWhereArgsTaxQueryOperator
    | undefined;
  CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField:
    | CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator:
    | CompanyCategoryToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  CompanyIdType: CompanyIdType | undefined;
  ContentNodeIdTypeEnum: ContentNodeIdTypeEnum | undefined;
  ContentTypeEnum: ContentTypeEnum | undefined;
  ContentTypeIdTypeEnum: ContentTypeIdTypeEnum | undefined;
  ContentTypeToContentNodeConnectionWhereArgsTaxQueryField:
    | ContentTypeToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator:
    | ContentTypeToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  ContentTypesOfCategoryEnum: ContentTypesOfCategoryEnum | undefined;
  ContentTypesOfCompanyCategoryEnum: ContentTypesOfCompanyCategoryEnum | undefined;
  ContentTypesOfPostFormatEnum: ContentTypesOfPostFormatEnum | undefined;
  ContentTypesOfPressCategoryEnum: ContentTypesOfPressCategoryEnum | undefined;
  ContentTypesOfTagEnum: ContentTypesOfTagEnum | undefined;
  GlobalStylesheetTypesEnum: GlobalStylesheetTypesEnum | undefined;
  GraphqlDocumentIdType: GraphqlDocumentIdType | undefined;
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField:
    | HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryField
    | undefined;
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator:
    | HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgsTaxQueryOperator
    | undefined;
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField:
    | HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryField
    | undefined;
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator:
    | HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgsTaxQueryOperator
    | undefined;
  MediaItemIdType: MediaItemIdType | undefined;
  MediaItemSizeEnum: MediaItemSizeEnum | undefined;
  MediaItemStatusEnum: MediaItemStatusEnum | undefined;
  MenuItemNodeIdTypeEnum: MenuItemNodeIdTypeEnum | undefined;
  MenuLocationEnum: MenuLocationEnum | undefined;
  MenuNodeIdTypeEnum: MenuNodeIdTypeEnum | undefined;
  MimeTypeEnum: MimeTypeEnum | undefined;
  OrderEnum: OrderEnum | undefined;
  PageIdType: PageIdType | undefined;
  PageToRevisionConnectionWhereArgsTaxQueryField:
    | PageToRevisionConnectionWhereArgsTaxQueryField
    | undefined;
  PageToRevisionConnectionWhereArgsTaxQueryOperator:
    | PageToRevisionConnectionWhereArgsTaxQueryOperator
    | undefined;
  PluginStatusEnum: PluginStatusEnum | undefined;
  PostFormatIdType: PostFormatIdType | undefined;
  PostFormatToContentNodeConnectionWhereArgsTaxQueryField:
    | PostFormatToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator:
    | PostFormatToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  PostFormatToPostConnectionWhereArgsTaxQueryField:
    | PostFormatToPostConnectionWhereArgsTaxQueryField
    | undefined;
  PostFormatToPostConnectionWhereArgsTaxQueryOperator:
    | PostFormatToPostConnectionWhereArgsTaxQueryOperator
    | undefined;
  PostIdType: PostIdType | undefined;
  PostObjectFieldFormatEnum: PostObjectFieldFormatEnum | undefined;
  PostObjectsConnectionDateColumnEnum: PostObjectsConnectionDateColumnEnum | undefined;
  PostObjectsConnectionOrderbyEnum: PostObjectsConnectionOrderbyEnum | undefined;
  PostStatusEnum: PostStatusEnum | undefined;
  PostToRevisionConnectionWhereArgsTaxQueryField:
    | PostToRevisionConnectionWhereArgsTaxQueryField
    | undefined;
  PostToRevisionConnectionWhereArgsTaxQueryOperator:
    | PostToRevisionConnectionWhereArgsTaxQueryOperator
    | undefined;
  PressCategoryIdType: PressCategoryIdType | undefined;
  PressCategoryToContentNodeConnectionWhereArgsTaxQueryField:
    | PressCategoryToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator:
    | PressCategoryToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  PressCategoryToPressConnectionWhereArgsTaxQueryField:
    | PressCategoryToPressConnectionWhereArgsTaxQueryField
    | undefined;
  PressCategoryToPressConnectionWhereArgsTaxQueryOperator:
    | PressCategoryToPressConnectionWhereArgsTaxQueryOperator
    | undefined;
  PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField:
    | PressCategoryToTeamMemberConnectionWhereArgsTaxQueryField
    | undefined;
  PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator:
    | PressCategoryToTeamMemberConnectionWhereArgsTaxQueryOperator
    | undefined;
  PressIdType: PressIdType | undefined;
  RelationEnum: RelationEnum | undefined;
  ReusableBlockIdType: ReusableBlockIdType | undefined;
  ReusableBlockToRevisionConnectionWhereArgsTaxQueryField:
    | ReusableBlockToRevisionConnectionWhereArgsTaxQueryField
    | undefined;
  ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator:
    | ReusableBlockToRevisionConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField:
    | RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator:
    | RootQueryToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToCompanyConnectionWhereArgsTaxQueryField:
    | RootQueryToCompanyConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToCompanyConnectionWhereArgsTaxQueryOperator:
    | RootQueryToCompanyConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToContentNodeConnectionWhereArgsTaxQueryField:
    | RootQueryToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator:
    | RootQueryToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField:
    | RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator:
    | RootQueryToGraphqlDocumentConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToMediaItemConnectionWhereArgsTaxQueryField:
    | RootQueryToMediaItemConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator:
    | RootQueryToMediaItemConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToPageConnectionWhereArgsTaxQueryField:
    | RootQueryToPageConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToPageConnectionWhereArgsTaxQueryOperator:
    | RootQueryToPageConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToPostConnectionWhereArgsTaxQueryField:
    | RootQueryToPostConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToPostConnectionWhereArgsTaxQueryOperator:
    | RootQueryToPostConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToPressConnectionWhereArgsTaxQueryField:
    | RootQueryToPressConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToPressConnectionWhereArgsTaxQueryOperator:
    | RootQueryToPressConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToReusableBlockConnectionWhereArgsTaxQueryField:
    | RootQueryToReusableBlockConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator:
    | RootQueryToReusableBlockConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToRevisionsConnectionWhereArgsTaxQueryField:
    | RootQueryToRevisionsConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator:
    | RootQueryToRevisionsConnectionWhereArgsTaxQueryOperator
    | undefined;
  RootQueryToTeamMemberConnectionWhereArgsTaxQueryField:
    | RootQueryToTeamMemberConnectionWhereArgsTaxQueryField
    | undefined;
  RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator:
    | RootQueryToTeamMemberConnectionWhereArgsTaxQueryOperator
    | undefined;
  SEOCardType: SEOCardType | undefined;
  TagIdType: TagIdType | undefined;
  TagToContentNodeConnectionWhereArgsTaxQueryField:
    | TagToContentNodeConnectionWhereArgsTaxQueryField
    | undefined;
  TagToContentNodeConnectionWhereArgsTaxQueryOperator:
    | TagToContentNodeConnectionWhereArgsTaxQueryOperator
    | undefined;
  TagToPostConnectionWhereArgsTaxQueryField: TagToPostConnectionWhereArgsTaxQueryField | undefined;
  TagToPostConnectionWhereArgsTaxQueryOperator:
    | TagToPostConnectionWhereArgsTaxQueryOperator
    | undefined;
  TaxonomyEnum: TaxonomyEnum | undefined;
  TaxonomyIdTypeEnum: TaxonomyIdTypeEnum | undefined;
  TeamMemberIdType: TeamMemberIdType | undefined;
  TermNodeIdTypeEnum: TermNodeIdTypeEnum | undefined;
  TermObjectsConnectionOrderbyEnum: TermObjectsConnectionOrderbyEnum | undefined;
  UserNodeIdTypeEnum: UserNodeIdTypeEnum | undefined;
  UserRoleEnum: UserRoleEnum | undefined;
  UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField:
    | UserToBlockEditorPreviewConnectionWhereArgsTaxQueryField
    | undefined;
  UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator:
    | UserToBlockEditorPreviewConnectionWhereArgsTaxQueryOperator
    | undefined;
  UserToMediaItemConnectionWhereArgsTaxQueryField:
    | UserToMediaItemConnectionWhereArgsTaxQueryField
    | undefined;
  UserToMediaItemConnectionWhereArgsTaxQueryOperator:
    | UserToMediaItemConnectionWhereArgsTaxQueryOperator
    | undefined;
  UserToPageConnectionWhereArgsTaxQueryField:
    | UserToPageConnectionWhereArgsTaxQueryField
    | undefined;
  UserToPageConnectionWhereArgsTaxQueryOperator:
    | UserToPageConnectionWhereArgsTaxQueryOperator
    | undefined;
  UserToPostConnectionWhereArgsTaxQueryField:
    | UserToPostConnectionWhereArgsTaxQueryField
    | undefined;
  UserToPostConnectionWhereArgsTaxQueryOperator:
    | UserToPostConnectionWhereArgsTaxQueryOperator
    | undefined;
  UserToRevisionsConnectionWhereArgsTaxQueryField:
    | UserToRevisionsConnectionWhereArgsTaxQueryField
    | undefined;
  UserToRevisionsConnectionWhereArgsTaxQueryOperator:
    | UserToRevisionsConnectionWhereArgsTaxQueryOperator
    | undefined;
  UsersConnectionOrderbyEnum: UsersConnectionOrderbyEnum | undefined;
  UsersConnectionSearchColumnEnum: UsersConnectionSearchColumnEnum | undefined;
}
